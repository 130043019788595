import React from 'react';
import { FormattedCurrency } from '@myie/interact-dom';
import { Row, Col, FormGroup } from 'reactstrap';

/** FormattedCurrency example for GBP */
export default function TextExample() {
  const currencyCode = 'GBP';
  const currencyQuantity = 1500;
  return (
    <Row>
      <Col md={6}>
        <FormGroup>
          <FormattedCurrency
            label="Current balance"
            currency={currencyCode}
            quantity={currencyQuantity}
          />
        </FormGroup>
      </Col>
    </Row>
  );
}
