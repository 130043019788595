import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-cms';

class ContentEditing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
    this.hoverOn = this.hoverOn.bind(this);
    this.hoverOff = this.hoverOff.bind(this);
  }

  hoverOn() {
    this.setState({ hover: true });
  }

  hoverOff() {
    this.setState({ hover: false });
  }

  onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    const {
      cmsTag: tag,
      editContent,
      publishedContent,
      pendingContent,
      managed,
    } = this.props;

    let content = { content: {} };

    const published = publishedContent ? publishedContent[tag] : {};
    const pending = pendingContent ? pendingContent[tag] : {};
    const keys = Object.keys(managed);
    keys.map(key => {
      if (key === 'errors') {
        content[key] = {};
        Object.keys(managed[key]).map(ekey => {
          content[key][ekey] = {
            publishedValue:
              published &&
              published[key] &&
              published[key][ekey] &&
              published[key][ekey].publishedValue
                ? published[key][ekey].publishedValue
                : '',
            pendingValue:
              pending &&
              pending[key] &&
              pending[key][ekey] &&
              pending[key][ekey].pendingValue
                ? pending[key][ekey].pendingValue
                : '',
            updatedValue:
              pending &&
              pending[key] &&
              pending[key][ekey] &&
              pending[key][ekey].updatedValue
                ? pending[key][ekey].updatedValue
                : '',
            defaultValue:
              managed &&
              managed[key] &&
              managed[key][ekey] &&
              managed[key][ekey].defaultValue
                ? managed[key][ekey].defaultValue
                : '',
          };
          return true;
        });
      } else if (key === 'options') {
        content[key] = {};
        Object.keys(managed[key]).map(ekey => {
          content[key][ekey] = {
            publishedValue:
              published &&
              published[key] &&
              published[key][ekey] &&
              published[key][ekey].publishedValue
                ? published[key][ekey].publishedValue
                : '',
            pendingValue:
              pending &&
              pending[key] &&
              pending[key][ekey] &&
              pending[key][ekey].pendingValue
                ? pending[key][ekey].pendingValue
                : '',
            updatedValue:
              pending &&
              pending[key] &&
              pending[key][ekey] &&
              pending[key][ekey].updatedValue
                ? pending[key][ekey].updatedValue
                : '',
            defaultValue:
              managed &&
              managed[key] &&
              managed[key][ekey] &&
              managed[key][ekey].defaultValue
                ? managed[key][ekey].defaultValue
                : '',
          };
          return true;
        });
      } else {
        content['content'][key] = {
          publishedValue:
            published &&
            published['content'] &&
            published['content'][key] &&
            published['content'][key].publishedValue
              ? published['content'][key].publishedValue
              : '',
          pendingValue:
            pending &&
            pending['content'] &&
            pending['content'][key] &&
            pending['content'][key].pendingValue
              ? pending['content'][key].pendingValue
              : '',
          updatedValue:
            pending &&
            pending['content'] &&
            pending['content'][key] &&
            pending['content'][key].updatedValue
              ? pending['content'][key].updatedValue
              : '',
          defaultValue:
            managed && managed[key] && managed[key].defaultValue
              ? managed[key].defaultValue
              : '',
        };
      }
      return true;
    });

    editContent(tag, content);
  };

  getClassNames() {
    const { tagEdited, cmsTag: tag } = this.props;
    const { hover } = this.state;
    let className = 'cms cms-wrapper';

    className += ' cms-wrapper-editable';

    if (tagEdited === tag) {
      className += ' cms-wrapper-editable-active';
    }
    if (hover) className += ' cms-wrapper-editable-hover';
    return className;
  }

  render() {
    const { children, onShow, noEdit, cmsTag, className } = this.props;
    const classNames = this.getClassNames();
    if (noEdit) {
      return (
        <span className="cms cms-wrapper cms-wrapper-not-editable">
          {children}
        </span>
      );
    }
    return (
      <span
        className={classNames}
        onClick={e => {
          this.onClick(e);
        }}
        onMouseEnter={e => {
          this.hoverOn(e);
        }}
        onMouseLeave={e => {
          this.hoverOff(e);
        }}
      >
        {onShow ? <div className={className}>{cmsTag}</div> : null}
        {children}
      </span>
    );
  }
}

ContentEditing.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  cmsTag: PropTypes.string,
  editContent: PropTypes.func,
  managed: PropTypes.any,
  noEdit: PropTypes.bool,
  onShow: PropTypes.bool,
  pendingContent: PropTypes.any,
  publishedContent: PropTypes.any,
  tag: PropTypes.any,
  tagEdited: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentEditing);
