import React from 'react';
import PropTypes from 'prop-types';
import contentManaged, { ContentType } from '../HO_components/contentManaged';

class Content extends React.Component {
  constructor(props) {
    super(props);
    const { copytext, managedContent } = props;
    managedContent({
      copytext: { defaultValue: copytext },
    });
  }

  render() {
    const { contentValue, managedContent, tag, ...rest } = this.props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const copytext = contentValue(ContentType.Text, 'copytext');
    if (tag) {
      var Component = tag;

      return <Component {...rest}>{copytext}</Component>;
    }
    return <React.Fragment>{copytext}</React.Fragment>;
  }
}

Content.propTypes = {
  contentValue: PropTypes.func.isRequired,
  copytext: PropTypes.any,
  managedContent: PropTypes.func.isRequired,
  tag: PropTypes.any,
  template: PropTypes.any,
};

export default contentManaged(Content);
