import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '35.813', height = '29.754' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 35.813 29.754"
    viewBox="0 0 35.813 29.754"
  >
    <path
      className={fill}
      d="M35.661.941c-.96-1.518-4.979-.182-6.211.532L21.118 6.29l-11.8-2.434-2.74 1.586 7.837 4.73-7.628 4.404-4.909-1.095L0 14.565l6.415 4.471.012-.01c.436.3.942.48 1.469.48.44 0 .883-.111 1.29-.349L32.035 5.95c1.236-.713 4.521-3.598 3.626-5.009zM4.833 28.754c0-.609.401-1 1.026-1h22.022c.66 0 1.056.333 1.056 1s-.417 1-1.063 1H5.905c-.702 0-1.072-.422-1.072-1z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
