const mapStateToProps = state => {
  const { authenticationDefinition = {} } = state;
  const {
    login,
    credentialsSetup,
    registration,
    retrivedUserName,
    forgottenPasswordDetails,
    updateSecurityDetails,
    updateForgottenDetails,
    referrer,
    isFetching,
    error = null,
  } = authenticationDefinition;
  return {
    login,
    credentialsSetup,
    registration,
    referrer,
    isFetching,
    retrivedUserName,
    forgottenPasswordDetails,
    updateSecurityDetails,
    updateForgottenDetails,
    error,
  };
};

export default mapStateToProps;
