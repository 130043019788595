import {
  CREATE_LOGIN_REQUEST,
  CREATE_LOGIN_SUCCESS,
  CREATE_LOGIN_FAILURE,
} from '../actions/loginTypes';
import {
  UPDATE_LOGIN_REQUEST,
  UPDATE_LOGIN_SUCCESS,
  UPDATE_LOGIN_FAILURE,
} from '../actions/loginTypes';
import { SET_LOGIN_REFERRER, RESET_LOGIN } from '../actions/loginTypes';
import update from 'immutability-helper';

const loginDefinition = (state = {}, action) => {
  switch (action.type) {
    case SET_LOGIN_REFERRER:
      return {
        ...state,
        referrer: action.referrer,
        login: null,
      };
    case RESET_LOGIN:
      return {
        ...state,
        login: null,
      };
    case UPDATE_LOGIN_SUCCESS: {
      const oldLogin = state.login;
      let newLogin = action.login;
      if (newLogin.Status === 'RequireActivation') {
        newLogin = update(oldLogin, {
          Status: { $set: newLogin.Status },
          RequireActivationTicket: { $set: newLogin.RequireActivationTicket },
        });
      } else {
        if (newLogin.Status !== 'Success' && newLogin.Status !== 'Incomplete') {
          if (oldLogin.statusCount) {
            if (newLogin.Status === oldLogin.Status) {
              newLogin = update(oldLogin, {
                statusCount: { $set: oldLogin.statusCount + 1 },
                Status: { $set: newLogin.Status },
              });
            } else {
              newLogin = update(oldLogin, {
                $merge: { statusCount: 1 },
                Status: { $set: newLogin.Status },
              });
            }
          } else {
            newLogin = update(oldLogin, {
              $merge: { statusCount: 1 },
              Status: { $set: newLogin.Status },
            });
          }
        }
      }
      return {
        ...state,
        login: newLogin,
        isFetching: action.isFetching,
      };
    }
    case CREATE_LOGIN_SUCCESS:
      return {
        ...state,
        login: action.login,
        isFetching: action.isFetching,
      };
    case CREATE_LOGIN_REQUEST:
    case CREATE_LOGIN_FAILURE:
    case UPDATE_LOGIN_REQUEST:
    case UPDATE_LOGIN_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    default:
      return state;
  }
};

export default loginDefinition;
