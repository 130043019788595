import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '36.074', height = '34.709' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 36.074 34.709"
    viewBox="0 0 36.074 34.709"
  >
    <g>
      <path
        className={fill}
        d="M30.036 0L30.276 5.918 36.074 6.832 19.323 15.706z"
      />
      <path
        className={fill}
        d="M28.156 14.15c.553 1.445.857 3.011.857 4.648 0 7.225-5.877 13.104-13.102 13.104S2.808 26.023 2.808 18.799c0-7.226 5.878-13.104 13.104-13.104 2.18 0 4.234.545 6.043 1.492l1.295-2.489a15.775 15.775 0 00-7.338-1.81C7.138 2.887 0 10.024 0 18.799c0 8.773 7.138 15.91 15.912 15.91 8.774 0 15.912-7.137 15.912-15.91 0-1.988-.373-3.89-1.043-5.646l-2.625.997z"
      />
      <path
        className={fill}
        d="M5.552 18.799c0 5.713 4.646 10.359 10.36 10.359 5.712 0 10.36-4.646 10.36-10.359a10.28 10.28 0 00-.678-3.675l-2.631.998c.318.833.502 1.732.502 2.677 0 4.164-3.389 7.552-7.553 7.552S8.36 22.963 8.36 18.799c0-4.165 3.388-7.553 7.552-7.553 1.256 0 2.439.316 3.483.862l1.296-2.491a10.278 10.278 0 00-4.779-1.178c-5.714 0-10.36 4.646-10.36 10.36z"
      />
      <path
        className={fill}
        d="M11.372 18.799a4.54 4.54 0 109.081 0c0-.569-.11-1.11-.301-1.608l-4.24 1.608 2.091-4.018a4.492 4.492 0 00-2.091-.523 4.54 4.54 0 00-4.54 4.541z"
      />
    </g>
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
