export const MIN_SHORT_APPLY_AGE = 18;
export const SHOW_IN_SHORTAPPLY = 'SHOW_IN_SHORTAPPLY=TRUE';
export const ACCOUNT_TYPE_CODE = ['Fixed Rate ISA', 'Cash ISA', 'ISA'];

export const DATE_FORMAT_DDMMYYY = 'DD/MM/YYYY';
export const DATE_FORMAT_YYYYMMDD = 'YYYY-MM-DD';
export const STANDARD_DATE_FORMAT = 'Do MMMM YYYY';
export const STANDARD_DATE_FORMAT_TIME = 'Do MMMM YYYY h:mma';

export const MARITAL_STATUS_CODE = [
  { C: 'Civil Partnership' },
  { H: 'Cohabiting' },
  { D: 'Divorced' },
  { E: 'Engaged' },
  { M: 'Married' },
  { P: 'Partner' },
  { S: 'Single' },
  { T: 'Separated' },
  { W: 'Widowed' },
  { O: 'Other' },
];
export const DEPOSIT_METHOD = [
  { 'Electronic Transfer': 'Electronic Transfer' },
  { Cheque: 'Cheque' },
];

export const DEPOSIT_SOURCE = [
  { 'Existing Savings': 'Existing Savings' },
  { 'Trading Income/Profit': 'Trading Income/Profit' },
  { 'Capital Injection/Loan': 'Capital Injection/Loan' },
  { 'Proceeds of a Sale': 'Proceeds of a Sale' },
  { 'Investment/Funding': 'Investment/Funding' },
  { 'Compensation/Settlement': 'Compensation/Settlement' },
  { 'Donation/Grant/Subscriptions': 'Donation/Grant/Subscriptions' },
  { 'Gift/Rebate/Inheritance': 'Gift/Rebate/Inheritance' },
];

export const GENDER = { M: 'Male', F: 'Female' };

export const INTEREST_DESTINATION = [
  { thisAccount: 'This account' },
  { nominatedAccount: 'Nominated account' },
];

export const INTEREST_PAYMENT_FREQUENCY = [{ MO: 'Monthly' }, { YE: 'Yearly' }];
export const YES = 'yes';
export const NO = 'no';
