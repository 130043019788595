import React, { Component } from 'react';
import { Form, Button, Row, Col } from 'reactstrap';
import {
  Content,
  Text,
  Markdown,
  Currency as CurrencyInput,
  Check,
} from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { Validate } from '@myie/interact';

class WithdrawalWithClose extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        amount: {
          rules: {
            title: 'Amount',
            stop: true,
            required: {
              message: 'Please enter an amount',
            },
            format: {
              regex: /^\d+(,\d{3})*\.?\d*$/,
              message: 'Please enter a valid amount',
            },
            minAmountCheck: {
              message: 'Please enter an amount greater than zero',
              comparison: () => {
                return 0.01;
              },
            },
            compareWithAvailableBalance: {
              message: 'Please enter an amount less than available balance',
              comparison: () => {
                const { account: { RemainingAllowance = 0 } = {} } = this.props;
                return RemainingAllowance;
              },
            },
          },
          value: '',
        },
        reference: {
          rules: {
            title: 'Reference',
            stop: true,
            format: {
              regex: /^[a-zA-Z- .\d/&]{0,18}$/,
              message: 'Please enter a valid reference',
            },
          },
          value: '',
        },
        closeAccount: {
          rules: {
            title: 'Close Account',
          },
        },
      },
    };
  }

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  updateForm = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  updatCurrency = e => {
    let { name, value } = e.target;
    let newValue = value;

    if (newValue !== '') {
      newValue = new Intl.NumberFormat().format(value.replace(/,/g, ''));
    }
    // Deal with trailing . or .0 or .n0
    if (value.charAt(value.length - 1) === '.') {
      newValue += '.';
    }
    if (value.slice(value.length - 2) === '.0') {
      newValue += '.0';
    }
    if (
      value.charAt(value.length - 3) === '.' &&
      value.charAt(value.length - 1) === '0'
    ) {
      if (value.charAt(value.length - 2) === '0') {
        newValue += '.0';
      }
      newValue += '0';
    }
    var { form } = this.state;
    form = Validate.input(name, newValue, form, true);
    this.setState({ ...this.state, form });
  };

  onSubmitHandler = e => {
    e.preventDefault();
    const { onSubmit } = this.props;
    let { form } = this.state;
    if (!form.closeAccount.value) {
      form = Validate.form(form);
      this.setState({ ...this.state, form });

      if (!form.approved) {
        return;
      }
      if (typeof onSubmit === 'function') {
        onSubmit(form);
      }
    }
    onSubmit(form);
  };

  onClick = e => {
    const { name, checked } = e.target;
    var { form } = this.state;
    form = Validate.input(name, checked ? 'true' : null, form);
    if (checked) {
      form.amount.value = '';
      form.reference.value = '';
    }
    this.setState({ ...this.state, form });
  };

  render() {
    const { onCancelled, account = {} } = this.props;
    const { form } = this.state;

    return (
      <Form
        autoComplete="off"
        id="notice-to-withdraw-form"
        onSubmit={this.onSubmitHandler}
      >
        <h2 className="h4">
          <Content
            id="withdrawal-details-title"
            copytext="Withdrawal details"
          />
        </h2>
        <Row>
          <Col xs={12} sm={6}>
            <Check
              label="I wish to close my account"
              id="close-account"
              field="closeAccount"
              checked={!!form.closeAccount.value}
              value="true"
              validation={form.closeAccount}
              onChange={this.onClick}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} lg={4}>
            <CurrencyInput
              label="Amount"
              showErrors={true}
              validation={form.amount}
              onChange={this.updatCurrency}
              disabled={form.closeAccount.value}
              onBlur={this.onBlur}
              code={account.CurrencyCode}
              field="amount"
              id="amount"
              dp={2}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} lg={6}>
            <Text
              cmsTag="createNotice:reference"
              label="Reference"
              id="reference"
              field="reference"
              maxLength="18"
              disabled={form.closeAccount.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={form.reference}
            />
          </Col>
        </Row>

        <Markdown id="make-witdrawal-bottom-text" markdown={'\n'} />

        <div className="form-button-group">
          <Button id="create-new-notice" type="submit" color="primary">
            <Content id="continue" copytext="Continue" />
          </Button>
          <Button
            id="cancel-notice-to-withdraw"
            type="button"
            color="secondary"
            onClick={onCancelled}
          >
            <Content id="cancel-button" copytext="Cancel" />
          </Button>
        </div>
      </Form>
    );
  }
}

WithdrawalWithClose.propTypes = {
  account: PropTypes.object,
  onCancelled: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default WithdrawalWithClose;
