import { SAVE_MATURITY_ARRAY } from '../actions/MaturityActions';

const maturityDefinition = (state = {}, action) => {
  switch (action.type) {
    case SAVE_MATURITY_ARRAY:
      return {
        ...state,
        maturityAlertVisibleArray: action.maturityAlertVisibleArray,
      };
    default:
      return state;
  }
};

export default maturityDefinition;
