import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormText, Button, Alert } from 'reactstrap';
import {
  Text,
  DropDown,
  TextArea,
  Date,
  DatePicker,
  Radio,
  RadioButton,
  Check,
  Currency,
  Numeric,
  Slider,
  RadioGroup,
  Mask,
  Value,
} from '@myie/interact-dom';
import { Validate } from '@myie/interact';
import { Link } from 'react-router-dom';

class Inputs extends React.Component {
  constructor(props) {
    super(props);
    // Define the initial state:
    this.state = {
      testdata: '',
      form: {
        textInput: {
          rules: {
            title: 'Text Input',
            stop: true,
            required: {
              trans: () => 'Required',
            },
          },
        },
        textInput2: {
          rules: {
            title: 'Text Input',
            stop: true,
            required: {
              trans: () => 'Required',
            },
          },
        },
        DropDownInput: {
          rules: {
            title: 'type of account',
            stop: true,
            required: {
              trans: () => 'Required',
            },
          },
        },
        DropDownInput2: {
          rules: {
            title: 'type of account',
            stop: true,
            required: {
              trans: () => 'Required',
            },
          },
        },
        textAreaInput: {
          rules: {
            title: 'Enter your reply',
            stop: true,
            required: {
              trans: () => 'Required',
            },
          },
        },
        textAreaInput2: {
          rules: {
            title: 'Enter your reply',
            stop: true,
            required: {
              trans: () => 'Required',
            },
          },
        },
        sliderInput: {
          rules: {
            title: 'Slider Input',
            stop: true,
            required: {
              trans: () => 'Required',
            },
          },
        },
        sliderInput2: {
          rules: {
            title: 'Slider Input',
            stop: true,
            required: {
              trans: () => 'Required',
            },
          },
        },
        dateInput: {
          rules: {
            title: 'date',
            stop: true,
            required: {
              trans: () => 'Please select a date.',
            },
            date: {
              format: 'dmy',
              trans: () => 'Please enter a valid date.',
            },
            greaterThanToday: {
              trans: () => 'Please enter a date later than today.',
            },
          },
        },
        dateInput2: {
          rules: {
            title: 'date',
            stop: true,
            required: {
              trans: () => 'Please select a date.',
            },
            date: {
              format: 'dmy',
              trans: () => 'Please enter a valid date.',
            },
            greaterThanToday: {
              trans: () => 'Please enter a date later than today.',
            },
          },
        },
        datePickerInput: {
          rules: {
            title: 'bday',
            stop: true,
            required: {
              trans: () => 'Please tell us your date of birth.',
            },
            date: {
              format: 'ymd',
              trans: () => 'Please select a valid date of birth.',
            },
          },
        },
        datePickerInput2: {
          rules: {
            title: 'bday',
            stop: true,
            required: {
              trans: () => 'Please tell us your date of birth.',
            },
            date: {
              format: 'ymd',
              trans: () => 'Please select a valid date of birth.',
            },
          },
        },
        radioInput: {
          rules: {
            required: {
              trans: () => 'Required',
            },
          },
        },
        radioInput2: {
          rules: {
            required: {
              trans: () => 'Required',
            },
          },
        },
        radioInput3: {
          rules: {
            required: {
              trans: () => 'Required',
            },
          },
        },
        checkInput: {
          value: false,
          rules: {
            required: {
              trans: () => 'Required',
            },
          },
        },
        checkInput2: {
          value: false,
          rules: {
            required: {
              trans: () => 'Required',
            },
          },
        },
        checkInput3: {
          value: false,
          rules: {
            required: {
              trans: () => 'Required',
            },
          },
        },
        checkInput4: {
          value: false,
          rules: {
            required: {
              trans: () => 'Required',
            },
          },
        },
        checkInput5: {
          value: false,
          rules: {
            required: {
              trans: () => 'Required',
            },
          },
        },
        checkInput6: {
          value: false,
          rules: {
            required: {
              trans: () => 'Required',
            },
          },
        },
        checkInput7: {
          value: false,
          rules: {
            required: {
              trans: () => 'Required',
            },
          },
        },
        currencyInput: {
          rules: {
            title: 'Currency Input',
            stop: true,
            required: true,
            currency: true,
          },
        },
        currencyInput2: {
          rules: {
            title: 'Currency Input',
            stop: true,
            required: true,
            currency: true,
          },
        },
        maskInput: {
          rules: {
            title: 'Mask Input',
            stop: true,
            required: true,
            currency: true,
          },
        },
        maskInput2: {
          rules: {
            title: 'Mask Input',
            stop: true,
            required: true,
            currency: true,
          },
        },
        numericInput: {
          rules: {
            title: 'Numeric Input',
            stop: true,
            required: {
              trans: () => 'Required',
            },
            format: {
              regex: /^([0-2]?[1-9]|3[01]|10|20)$/,
              trans: () => 'Format is Invalid',
            },
          },
        },
        numericInput2: {
          rules: {
            title: 'Numeric Input',
            stop: true,
            required: {
              trans: () => 'Required',
            },
            format: {
              regex: /^([0-2]?[1-9]|3[01]|10|20)$/,
              trans: () => 'Format is Invalid',
            },
          },
        },
        sortcode: {
          rules: {
            stop: true,
            required: {
              trans: () => 'Please enter your sort code',
            },
            sortcode: {
              trans: () => 'Please enter a valid sort code',
            },
          },
        },
        sortcode2: {
          rules: {
            stop: true,
            required: {
              trans: () => 'Please enter your sort code',
            },
            sortcode: {
              trans: () => 'Please enter a valid sort code',
            },
          },
        },
      },
    };
  }

  onChange = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  onClick = e => {
    const { name, checked } = e.target;
    var { form } = this.state;
    form = Validate.input(name, checked ? 'true' : null, form);
    this.setState({ ...this.state, form });
  };

  submit = e => {
    e.preventDefault();
    let { form } = this.state;
    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      return;
    }
  };

  render() {
    const { form } = this.state;
    const { onChange, onBlur, onClick } = this;

    return (
      <section id="inputs-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/components/forms.scss</strong>
          </code>
        </p>
        <p>
          <strong>NB:</strong> Every form element requires a label. If for some
          reason you wish to hide the label, you need to add copy text for the
          label and add this to the component:
          <br />
          <code>labelClassName=&apos;sr-only&apos;</code>
        </p>

        <Form
          autoComplete="off"
          id="inputs-section-form"
          onSubmit={this.submit}
        >
          <section>
            <h2>Standard Inputs</h2>
            <h3>Text Boxes</h3>
            <p>
              There is a component made for <strong>text boxes</strong> defined
              within <code>shared/components/Text.js</code>. To see an
              interactive technical specification for this component,{' '}
              <Link to="/documents#Text.js">click here</Link>.
            </p>
            <Text
              label="This is a text box"
              subLabel="(smaller text on label)"
              description="This is a description"
              suffix={<Link to="/">Suffix Link</Link>}
              field="textInput"
              id="textInput"
              onChange={onChange}
              required
              onBlur={onBlur}
              validation={form.textInput}
            />
            <Text
              label="This is a disabled textbox"
              subLabel="(smaller text on label)"
              description="This is a description"
              suffix="Suffix text"
              field="disabledTextInput"
              id="disabledTextInput"
              disabled
              onChange={onChange}
              onBlur={onBlur}
              validation={form.textInput2}
            />
            <hr />
            <h3>Drop Down</h3>
            <p>
              There is a component made for <strong>select boxes</strong>{' '}
              defined within <code>shared/components/DropDown.js</code>. To see
              an interactive technical specification for this component,{' '}
              <Link to="/documents#DropDown.js">click here</Link>.
            </p>
            <DropDown
              label="This is a select box"
              subLabel="(smaller text on label)"
              description="This is a description"
              suffix="Suffix text"
              id="DropDownInput"
              field="DropDownInput"
              onChange={onChange}
              onBlur={onBlur}
              required
              validation={form.DropDownInput}
            >
              <option key="1" value="">
                Please select
              </option>
              <option key="2" value="CurrentAccountWithDebitCard">
                Current Account with debt card
              </option>
              <option key="3" value="CurrentAccountWithoutDebitCard">
                Current Account without debt card
              </option>
            </DropDown>
            <DropDown
              label="This is a disabled select box"
              subLabel="(smaller text on label)"
              description="This is a description"
              suffix="Suffix text"
              id="disabledDropDownInput"
              field="disabledDropDownInput"
              disabled
              onChange={onChange}
              onBlur={onBlur}
              validation={form.DropDownInput2}
            >
              <option key="1" value="">
                Please select
              </option>
              <option key="2" value="CurrentAccountWithDebitCard">
                Current Account with debt card
              </option>
              <option key="3" value="CurrentAccountWithoutDebitCard">
                Current Account without debt card
              </option>
            </DropDown>
            <hr />
            <h3>Text Area</h3>
            <p>
              There is a component made for <strong>text areas</strong> defined
              within <code>shared/components/TextArea.js</code>. To see an
              interactive technical specification for this component,{' '}
              <Link to="/documents#TextArea.js">click here</Link>.
            </p>
            <TextArea
              label="This is a text area"
              subLabel="(smaller text on label)"
              description="This is a description"
              suffix="Suffix text"
              id="textAreaInput"
              field="textAreaInput"
              onChange={onChange}
              onBlur={onBlur}
              required
              validation={form.textAreaInput}
              maxLength="750"
            />
            <TextArea
              label="This is a disabled text area"
              subLabel="(smaller text on label)"
              description="This is a description"
              suffix="Suffix text"
              id="disabledTextAreaInput"
              field="disabledTextAreaInput"
              onChange={onChange}
              onBlur={onBlur}
              disabled
              validation={form.extAreaInput2}
              maxLength="750"
            />
            <hr />
            <h3>Slider</h3>
            <p>
              There is a component made for <strong>range sliders</strong>{' '}
              defined within <code>shared/components/Slider.js</code>. To see an
              interactive technical specification for this component,{' '}
              <Link to="/documents#Slider.js">click here</Link>.
            </p>
            <Slider
              label="This is a slider"
              subLabel="(smaller text on label)"
              description="This is a description"
              suffix="Suffix text"
              field="sliderInput"
              id="sliderInput"
              required
              onChange={onChange}
              onBlur={onBlur}
              validation={form.sliderInput}
              withInput
            ></Slider>

            <Slider
              label="This is a disabled slider"
              subLabel="(smaller text on label)"
              description="This is a description"
              suffix="Suffix text"
              field="disabledSliderInput"
              id="disabledSliderInput"
              disabled
              onChange={onChange}
              onBlur={onBlur}
              validation={form.sliderInput2}
            ></Slider>
            <hr />
          </section>
          <section>
            <h2>Date Pickers</h2>
            <h3>Date</h3>
            <p>
              There is a component made for{' '}
              <strong>single box date pickers</strong> defined within{' '}
              <code>shared/components/Date.js</code>. To see an interactive
              technical specification for this component,{' '}
              <Link to="/documents#Date.js">click here</Link>.
            </p>
            <p>
              This component uses{' '}
              <code>shared/components/FormattedDate.js</code>.
            </p>
            <Date
              label="This is a date input"
              subLabel="(smaller text on label)"
              description="This is a description"
              suffix="Suffix text"
              required
              id="dateInput"
              field="dateInput"
              showErrors={true}
              onChange={onChange}
              onBlur={onBlur}
              validation={form.dateInput}
            />
            <Date
              label="This is a disabled date input"
              subLabel="(smaller text on label)"
              description="This is a description"
              suffix="Suffix text"
              id="disabledDateInput"
              field="diabledDateInput"
              disabled
              showErrors={true}
              onChange={onChange}
              onBlur={onBlur}
              validation={form.dateInput2}
            />
            <hr />
            <h3>DatePicker</h3>
            <p>
              There is a component made for{' '}
              <strong>dropdown date pickers</strong> defined within{' '}
              <code>shared/components/DatePicker.js</code>. To see an
              interactive technical specification for this component,{' '}
              <Link to="/documents#DatePicker.js">click here</Link>.
            </p>
            <p>
              This component uses{' '}
              <code>shared/components/FormattedDate.js</code>.
            </p>
            <DatePicker
              label="This is a date picker input"
              subLabel="(smaller text on label)"
              description="This is a description"
              suffix="Suffix text"
              id="datePickerInput"
              field="datePickerInput"
              showErrors={true}
              required
              onChange={onChange}
              onBlur={onBlur}
              validation={form.datePickerInput}
            />
            <DatePicker
              label="This is a disabled date picker input"
              subLabel="(smaller text on label)"
              description="This is a description"
              suffix="Suffix text"
              id="disabledDatePickerInput"
              field="disabledDatePickerInput"
              showErrors={true}
              disabled
              onChange={onChange}
              onBlur={onBlur}
              validation={form.datePickerInput2}
            />
            <hr />
          </section>
          <section>
            <h2>Radios and Checkboxes</h2>
            <h3>Radios</h3>
            <p>
              There is a component made for <strong>radios</strong> defined
              within <code>shared/components/Radio.js</code>. To see an
              interactive technical specification for this component,{' '}
              <Link to="/documents#Radio.js">click here</Link>.
            </p>
            <p>
              All radios must be wrapped in a{' '}
              <code>&lt;RadioGroup&gt;&lt;/RadioGroup&gt;</code> tag, and legend
              copy text will need to be added.
            </p>
            <p>
              If you want to float radio buttons in a row, pass the variable{' '}
              <code>groupClassName=&apos;not-stacked&apos;</code>. You may need
              to add some max widths to the radios if they are very long.
            </p>
            <RadioGroup
              legend="This is a radio group"
              subLabel="(smaller text on label)"
              description="This is a description"
              required
            >
              <Radio
                label="This is a radio input"
                subLabel="(smaller text on label)"
                description="This is a description"
                suffix="Suffix text"
                id="radio-input-1"
                field="radioInput"
                onChange={onChange}
                validation={form.radioInput}
                value="payNow"
                groupClassName="not-stacked w-50"
              />
              <Radio
                label="This is a disabled radio input"
                subLabel="(smaller text on label)"
                description="This is a description"
                suffix="Suffix text"
                disabled
                id="radio-input-2"
                field="radioInput"
                onChange={onChange}
                validation={form.radioInput}
                value="byDate"
                groupClassName="not-stacked w-50"
              />
            </RadioGroup>
            <RadioGroup
              legend="This is a another radio group"
              subLabel="(smaller text on label)"
              description="This is a description"
            >
              <Radio
                label="This is a radio input"
                subLabel="(smaller text on label)"
                description="This is a description"
                suffix="Suffix text"
                id="radio-input-3"
                field="radioInput2"
                onChange={onChange}
                validation={form.radioInput2}
                value="payNow"
              />
              <Radio
                label="This is another radio input"
                subLabel="(smaller text on label)"
                description="This is a description"
                suffix="Suffix text"
                id="radio-input-4"
                field="radioInput2"
                onChange={onChange}
                validation={form.radioInput2}
                value="byDate"
              />
              <Radio
                label="This is yet another radio input"
                subLabel="(smaller text on label)"
                description="This is a description"
                suffix="Suffix text"
                id="radio-input-5"
                field="radioInput2"
                onChange={onChange}
                validation={form.radioInput2}
                value="byDate"
              />
            </RadioGroup>
            <RadioGroup
              legend="This is a disabled radio group"
              subLabel="(smaller text on label)"
              description="This is a description"
              disabled
            >
              <Radio
                label="This is a radio input"
                subLabel="(smaller text on label)"
                description="This is a description"
                suffix="Suffix text"
                id="radio-input-6"
                field="radioInput3"
                onChange={onChange}
                validation={form.radioInput3}
                value="payNow"
                disabled
              />
              <Radio
                label="This is another radio input"
                subLabel="(smaller text on label)"
                description="This is a description"
                suffix="Suffix text"
                id="radio-input-7"
                field="radioInput3"
                onChange={onChange}
                validation={form.radioInput3}
                value="byDate"
                disabled
              />
            </RadioGroup>
            <hr />
            <h3>RadioButton</h3>
            <p>
              There is a component made for <strong>radio buttons</strong>{' '}
              defined within <code>shared/components/Check.js</code>. To see an
              interactive technical specification for this component,{' '}
              <Link to="/documents#RadioButton.js">click here</Link>.
            </p>
            <p>
              All radios must be wrapped in a{' '}
              <code>&lt;RadioGroup&gt;&lt;/RadioGroup&gt;</code> tag, and legend
              copy text will need to be added.
            </p>
            <RadioGroup
              legend="This is a radio button group"
              subLabel="(smaller text on label)"
              description="This is a description"
            >
              <RadioButton
                key="checkbox1"
                label="Sample Checkbox 1"
                id="checkbox1"
                field="checkbox1"
                value=""
              />
              <RadioButton
                key="checkbox1"
                label="Sample Checkbox 2"
                id="checkbox2"
                field="checkbox1"
                value=""
              />
            </RadioGroup>
            <hr />
            <h3>Check boxes</h3>
            <p>
              There is a component made for <strong>check boxes</strong> defined
              within <code>shared/components/Check.js</code>. To see an
              interactive technical specification for this component,{' '}
              <Link to="/documents#Check.js">click here</Link>.
            </p>
            <p>
              All check boxes must be wrapped in a{' '}
              <code>&lt;RadioGroup&gt;&lt;/RadioGroup&gt;</code> tag, and legend
              copy text will need to be added.
            </p>
            <p>
              If you want to float check boxes in a row, pass the variable{' '}
              <code>groupClassName=&apos;not-stacked&apos;</code>. You may need
              to add some max widths to the radios if they are very long.
            </p>
            <RadioGroup
              legend="This is a checkbox group"
              subLabel="(smaller text on label)"
              description="This is a description"
            >
              <Check
                label="This is a check box"
                subLabel="(smaller text on label)"
                description="This is a description"
                suffix="Suffix text"
                id="checkInput"
                field="checkInput"
                checked={!!form.checkInput.value}
                value="true"
                validation={form.checkInput}
                onChange={onClick}
                groupClassName="not-stacked w-50"
              />
              <Check
                label="This is a disabled check box"
                subLabel="(smaller text on label)"
                description="This is a description"
                suffix="Suffix text"
                disabled
                id="checkInput2"
                field="checkInput2"
                checked={!!form.checkInput2.value}
                value="true"
                validation={form.checkInput2}
                onChange={onClick}
                groupClassName="not-stacked w-50"
              />
            </RadioGroup>
            <RadioGroup
              legend="This is a another checkbox group"
              subLabel="(smaller text on label)"
              description="This is a description"
            >
              <Check
                label="This is a checkbox"
                subLabel="(smaller text on label)"
                description="This is a description"
                suffix="Suffix text"
                id="checkInput3"
                field="checkInput3"
                checked={!!form.checkInput3.value}
                value="true"
                validation={form.checkInput3}
                onChange={onClick}
              />
              <Check
                label="This is another checkbox"
                subLabel="(smaller text on label)"
                description="This is a description"
                suffix="Suffix text"
                id="checkInput4"
                field="checkInput4"
                checked={!!form.checkInput4.value}
                value="true"
                validation={form.checkInput4}
                onChange={onClick}
              />
              <Check
                label="This is yet another checkbox"
                subLabel="(smaller text on label)"
                description="This is a description"
                suffix="Suffix text"
                id="checkInput5"
                field="checkInput5"
                checked={!!form.checkInput5.value}
                value="true"
                validation={form.checkInput5}
                onChange={onClick}
              />
            </RadioGroup>
            <RadioGroup
              legend="This is a disabled checkbox group"
              subLabel="(smaller text on label)"
              description="This is a description"
              disabled
            >
              <Check
                label="This is a checkbox"
                subLabel="(smaller text on label)"
                description="This is a description"
                suffix="Suffix text"
                id="checkInput3"
                field="checkInput3"
                checked={!!form.checkInput6.value}
                value="true"
                validation={form.checkInput6}
                onChange={onClick}
                disabled
              />
              <Check
                label="This is another checkbox"
                subLabel="(smaller text on label)"
                description="This is a description"
                suffix="Suffix text"
                id="checkInput7"
                field="checkInput7"
                checked={!!form.checkInput4.value}
                value="true"
                validation={form.checkInput4}
                onChange={onClick}
                disabled
              />
            </RadioGroup>
            <hr />
          </section>
          <section>
            <h2>Bespoke Inputs</h2>
            <h3>Currency</h3>
            <p>
              There is a component made for <strong>currency inputs</strong>{' '}
              defined within <code>shared/components/Currency.js</code>. To see
              an interactive technical specification for this component,{' '}
              <Link to="/documents#Currency.js">click here</Link>.
            </p>
            <p>
              This component uses{' '}
              <code>shared/components/FormattedCurrency.js</code> to return the
              currency. To see or edit a full list of currencies, go to{' '}
              <code>shared/components/symbols.js</code>.
            </p>
            <Currency
              label="This is a currency input"
              subLabel="(smaller text on label)"
              description="This is a description"
              suffix="Suffix text"
              code="GBP"
              required
              validation={form.currencyInput}
              onChange={onChange}
              onBlur={onBlur}
              field="currencyInput"
              id="currencyInput"
            />
            <Currency
              label="This is a disabled currency input"
              subLabel="(smaller text on label)"
              description="This is a description"
              suffix="Suffix text"
              disabled
              code="GBP"
              validation={form.currencyInput2}
              onChange={onChange}
              onBlur={onBlur}
              field="currencyInput"
              id="currencyInput"
            />
            <hr />
            <h3>AddressLookup</h3>
            <p>
              There is a component made for <strong>address look up</strong>{' '}
              defined within <code>shared/components/AddressLookup.js</code>. To
              see an interactive technical specification for this component,{' '}
              <Link to="/documents#AddressLookup.js">click here</Link>.
            </p>
            <Alert color="danger">Currently in development</Alert>
            <hr />
            <h3>InputMask</h3>
            <p>
              There is a component made for <strong>input masks</strong> defined
              within <code>shared/components/InputMask.js</code>. It can be
              configured for various values, but is mainly used for sortcodes,
              as below. To see an interactive technical specification for this
              component, <Link to="/documents#InputMask.js">click here</Link>.
            </p>
            <div key={'sortcode'}>
              <Mask
                label="This is a sortcode input"
                subLabel="(smaller text on label)"
                description="This is a description"
                suffix="Suffix text"
                id="sortcode"
                field="sortcode"
                onChange={onChange}
                onBlur={onBlur}
                maskChar={null}
                required
                validation={form.sortcode}
                mask="99-99-99"
              />
            </div>
            <div key={'sortcode'}>
              <Mask
                label="This is a disabled sortcode input"
                subLabel="(smaller text on label)"
                description="This is a description"
                suffix="Suffix text"
                id="sortcode"
                field="sortcode"
                onChange={onChange}
                onBlur={onBlur}
                maskChar={null}
                disabled
                validation={form.sortcode2}
                mask="99-99-99"
              />
            </div>
            <hr />
            <h3>Numeric</h3>
            <p>
              There is a component made for <strong>numeric inputs</strong>{' '}
              defined within <code>shared/components/Numeric.js</code>. To see
              an interactive technical specification for this component,{' '}
              <Link to="/documents#Numeric.js">click here</Link>.
            </p>
            <Numeric
              label="This is a numeric input"
              subLabel="(smaller text on label)"
              description="This is a description"
              suffix="Suffix text"
              required
              id="numericInput"
              field="numericInput"
              onChange={onChange}
              onBlur={onBlur}
              validation={form.numericInput}
              dp={2}
              maxLength={4}
            />
            <Numeric
              label="This is a disabled numeric input"
              subLabel="(smaller text on label)"
              description="This is a description"
              suffix="Suffix text"
              disabled
              id="numericInput2"
              field="numericInput2"
              onChange={onChange}
              onBlur={onBlur}
              validation={form.numericInput2}
              dp={2}
              maxLength={10}
            />
            <hr />
            <h3>Value</h3>
            <p>
              There is a component made for <strong>printed values</strong>{' '}
              defined within <code>shared/components/Value.js</code>. To see an
              interactive technical specification for this component,{' '}
              <Link to="/documents#Value.js">click here</Link>.
            </p>
            <p>
              There are no <Value value={5} /> accounts to view.
            </p>
            <Button id="form-submit" type="submit" color="primary">
              Submit
            </Button>
            <hr />
            <h3>Form Text</h3>
            <FormText>
              Note: if somebody else has already taken this username you will
              not be able to use it.
            </FormText>
          </section>
        </Form>
      </section>
    );
  }
}

Inputs.propTypes = {
  i18n: PropTypes.any,
  items: PropTypes.string,
};

export default Inputs;
