const appUtility = (function() {
  const getPayload = (
    formData,
    reviewForm,
    selectedProduct,
    userDetails,
    selectedOrganisationDetails,
  ) => {
    let accountDetails = null;
    const details = selectedOrganisationDetails;
    const obj = {
      Application: {
        DepositFundsOption: formData?.depositSource ?? '',
        ProductTypeCode: selectedProduct?.Product_Code,
        DepositAmount: {
          Amount: formData?.depositAmount.toString().replace(/,/g, ''),
          ExtendedProperties: [{}, {}, {}],
        },
        PayInterestIntoAnotherAccount: accountDetails?.interestPaymentLocation,
        InterestPaymentFrequency: selectedProduct?.Interest_Frequency,
        DOB: userDetails?.ExtendedProperties?.DateOfBirth,
        ExtendedProperties: [
          { Name: 'FirstName', Value: userDetails.FirstName },
          { Name: 'LastName', Value: userDetails.Surname },
          {
            Name: 'ChangedDetails',
            Value: formData.organisationDetailsChangedInput ?? '',
          },
          {
            Name: 'OrganisationDetailsChanged',
            Value: formData.orgDetailsChanged === 'yes' ? 'true' : 'false',
          },
          {
            Name: 'OrganisationName',
            Value: details?.FullName ?? '',
          },
          {
            Name: 'OrganisationEmail',
            Value: details?.ContactDetails?.Email ?? '',
          },
          {
            Name: 'CustomerId',
            Value: formData?.organisationSource ?? '',
          },
          {
            Name: 'DepositMeans',
            Value: formData?.depositMethod ?? '',
          },
          {
            Name: 'FCSCConfirmation',
            Value: reviewForm?.informationConfirm.value ? 'true' : 'false',
          },
        ],
      },
      ExtendedProperties: [
        {
          Name: 'ApplicationType',
          Value: 'ShortApply',
        },
      ],
    };

    return obj;
  };

  return {
    getPayload: getPayload,
  };
})();

export default appUtility;
