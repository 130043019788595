import {
  INTERESTHISTORY_REQUEST,
  INTERESTHISTORY_SUCCESS,
  INTERESTHISTORY_FAILURE,
  RESET_INTERESTHISTORY,
} from '../actions/interestRateTypes';
import update from 'immutability-helper';

const ccbInterestRateDefinition = (state = {}, action) => {
  let oldAccounts;
  let index;
  let account;
  switch (action.type) {
    case RESET_INTERESTHISTORY:
      return {
        ...state,
        interestHistory: {},
      };
    case INTERESTHISTORY_SUCCESS: {
      oldAccounts = state.accounts;
      index = oldAccounts.Accounts.findIndex(
        x => x.AccountKey.Key === action.accountkey.Key,
      );
      account = oldAccounts.Accounts[index];
      const interestHistory = action.interestHistory;

      const accountWithDocuments = update(account, {
        interestHistory: { $set: interestHistory },
      });
      const updatedAccounts = update(oldAccounts, {
        Accounts: { [index]: { $set: accountWithDocuments } },
      });
      return {
        ...state,
        accounts: updatedAccounts,
        isFetching: action.isFetching,
      };
    }
    case INTERESTHISTORY_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case INTERESTHISTORY_REQUEST:
    default:
      return state;
  }
};

export default ccbInterestRateDefinition;
