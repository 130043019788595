import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Session } from '@myie/interact';
import { Switch, appUtility } from '@myie/interact-dom';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-shared';

class TimeOutModal extends React.Component {
  clickedYes = () => {
    let currentTime = new Date().getTime();
    window.sessionStorage.setItem('lastRequestTime', currentTime);
    this.props.startTimer();
    this.props.hideTimeoutModal();
  };

  clickedNo = () => {
    if (Session.isAuthenticated()) {
      appUtility.userSignout();
    }
    Session.abandon();
    this.props.clearTimer();
    this.props.hideTimeoutModal();
    this.props.userLogout();
    this.props.history.push(this.props.timeoutUrl);
  };

  render() {
    let message = this.props.timeoutMessage || '';
    let count = 0;

    if (typeof this.props.timeoutMessage === 'function') {
      message = this.props.timeoutMessage();
    }

    if (message.startsWith('TimerCountDown')) {
      const timer = message.split(':');
      message = timer[0];
      count = timer[1];
    }

    return (
      <Modal
        id="timeout-modal-message-wrapper"
        isOpen={this.props.modalVisible}
        className={this.props.className}
      >
        <ModalBody id="timeout-modal-message">
          <Switch
            id="timeout-model-Messages"
            tag="<>"
            value={message}
            template={{
              TimerCountDown: { count: count },
            }}
            contents={{
              TimerCountDown: {
                defaultValue:
                  'For security reasons, this page will timeout in $[count] seconds. Would you like more time?',
              },
              SessionTimeout: {
                defaultValue:
                  'You will be logged out in one minute. Do you want to continue your session?',
              },
              yesButton: {
                defaultValue: 'Yes',
              },
              noButton: {
                defaultValue: 'No',
              },
              SignInTimeout: {
                defaultValue:
                  'You have taken too long to complete the sign in process. You will be returned to the first sign in page.',
              },
              ForgottenPasswordTimeout: {
                defaultValue:
                  'You have taken too long to complete the credentials reset process. You will be returned to the first forgotten credentials page.',
              },
              RegistreationTimeout: {
                defaultValue:
                  'You have taken too long to complete the registration process. You will be returned to the first registration page.',
              },
            }}
          />
        </ModalBody>
        {Session.isAuthenticated() ||
        this.props.timeoutUrl === '/registration' ||
        this.props.timeoutUrl === '/forgotten-password' ||
        this.props.timeoutUrl === '/sign-in' ? (
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.clickedYes}
              id="timeout-yes"
              size="sm"
            >
              <Switch
                id="timeout-model-Messages"
                tag="<>"
                value="yesButton"
                contents={{
                  yesButton: {
                    defaultValue: 'Yes',
                  },
                }}
              />
            </Button>
            <Button
              color="secondary"
              onClick={this.clickedNo}
              id="timeout-no"
              size="sm"
            >
              <Switch
                id="timeout-model-Messages"
                tag="<>"
                value="noButton"
                contents={{
                  noButton: {
                    defaultValue: 'No',
                  },
                }}
              />
            </Button>
          </ModalFooter>
        ) : (
          ''
        )}
      </Modal>
    );
  }
}

TimeOutModal.propTypes = {
  className: PropTypes.any,
  clearTimer: PropTypes.any,
  hideTimeoutModal: PropTypes.any,
  history: PropTypes.any,
  modalVisible: PropTypes.any,
  startTimer: PropTypes.any,
  timeoutMessage: PropTypes.any,
  timeoutUrl: PropTypes.any,
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimeOutModal);
