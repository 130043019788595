import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedDate, Content, Switch, AppMeta } from '@myie/interact-dom';
import { Row, Col, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-messaging-twoway';
import FormatBody from '../FormatBody';
import messagingUtility from './../utility';

class Confirmation extends React.Component {
  componentWillUnmount = () => {
    const { resetReply } = this.props;
    resetReply();
  };

  renderSuccessContent = () => {
    const { replyMessageStatus, selectedData } = this.props;
    return (
      <>
        <div className="confirmation-details">
          {selectedData.relatedAccountObj ? (
            <Row tag="dl" title="message account">
              <Col tag="dt" sm={6} md={3}>
                <Content id="relatedAccount" copytext="Related account:" />
              </Col>
              <Col tag="dd" sm={6} md={9}>
                {replyMessageStatus.Conversation.AccountNumber !==
                  'XXXXXXXXXable' &&
                replyMessageStatus.Conversation.AccountNumber !==
                  'Not available' ? (
                  <>
                    {replyMessageStatus.Conversation.AccountNumber +
                      (selectedData.relatedAccountObj.AccountStatus === 'Closed'
                        ? ' <Closed Account>'
                        : '')}
                  </>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          ) : (
            false
          )}
          <Row tag="dl" title="message reference">
            <Col tag="dt" sm={6} md={3}>
              <Content id="reference" copytext="Reference number:" />
            </Col>
            <Col tag="dd" xs={6} md={9}>
              {replyMessageStatus.Conversation.Reference}
            </Col>
          </Row>
          <div className="list-group messages-list">
            <div className="list-group-item" key={`message-0`}>
              <h2>
                <span className="timestamp">
                  <FormattedDate
                    date={
                      replyMessageStatus.Conversation.Messages[0].CreatedDat
                    }
                    format={'LLL'}
                  />
                </span>
              </h2>
              <p>
                {FormatBody(replyMessageStatus.Conversation.Messages[0].Body)}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const { replyMessageStatus } = this.props;

    let alertId = null;
    if (replyMessageStatus) {
      switch (replyMessageStatus.ResponseStatus) {
        case 'Success':
          alertId = 'new-message-success';
          break;
        case 'Failed':
          alertId = 'new-message-failure';
          break;
        default:
          alertId = 'new-message-unknown';
          break;
      }
    }

    return (
      <div id="new-message-confirmation">
        <AppMeta
          id="meta-data"
          stage="child"
          title="Message sent"
          metaDescription="Message sent"
        />
        <h1>
          {messagingUtility.stripEndQuotes(
            replyMessageStatus.Conversation.Reason,
          )}
        </h1>
        {replyMessageStatus && (
          <Alert
            role="alert"
            id={alertId}
            color={
              replyMessageStatus &&
              replyMessageStatus.ResponseStatus === 'Success'
                ? 'success'
                : 'danger'
            }
          >
            <Switch
              id="confirmAlert"
              tag="<>"
              value={replyMessageStatus.ResponseStatus}
              defaultText="An unknown error occured. Please check your accounts before retrying."
              contents={{
                Success: {
                  defaultValue:
                    'Your message has been sent. See details below.',
                },
                Failed: {
                  defaultValue: 'Your message was not sent. Try again.',
                },
              }}
            />
          </Alert>
        )}
        {replyMessageStatus.ResponseStatus === 'Success'
          ? this.renderSuccessContent()
          : false}
        <div className="form-button-group">
          <Link
            id="new-message-confirmation-back"
            className="btn btn-secondary btn-sm"
            role="button"
            to="/messaging-twoway/view"
          >
            <Content id="backButton" copytext="Back To Messages" />
          </Link>
        </div>
      </div>
    );
  }
}

Confirmation.propTypes = {
  replyMessageStatus: PropTypes.any,
  resetContactOptions: PropTypes.any,
  resetReply: PropTypes.any,
  resetUserDetails: PropTypes.any,
  selectedData: PropTypes.any,
  setStage: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Confirmation);
