import React from 'react';
import { SelectAccounts } from '@myie/interact-widget-select-account-dom';
import { Session } from '@myie/interact';

const DetailSideBar = () => {
  return (
    <aside className="right-container">
      {Session.isAuthenticated() ? <SelectAccounts /> : null}
    </aside>
  );
};

export default DetailSideBar;
