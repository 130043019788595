import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ width = '252.356', height = '168.569' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 252.356 168.569"
  >
    <g transform="translate(-304.445 -127.827)">
      <rect
        className="icon-fill"
        width="252.356"
        height="168.569"
        rx="16.615"
        transform="translate(304.445 127.827)"
      />
      <rect
        className="warning-fill"
        width="37.079"
        height="37.079"
        transform="translate(328.907 165.773)"
      />
      <rect
        className="white-fill"
        width="42.383"
        height="8.515"
        transform="translate(328.907 228.069)"
      />
      <rect
        className="white-fill"
        width="42.383"
        height="8.515"
        transform="translate(382.323 228.069)"
      />
      <rect
        className="white-fill"
        width="42.383"
        height="8.515"
        transform="translate(435.739 228.069)"
      />
      <rect
        className="white-fill"
        width="42.383"
        height="8.515"
        transform="translate(489.155 228.069)"
      />
      <rect
        className="white-fill"
        width="119.672"
        height="8.504"
        transform="translate(328.907 254.386)"
      />
    </g>
  </svg>
);

SVG.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default SVG;
