import { Connection } from '@myie/interact';

import {
  SET_CREDENTIALS_REQUEST,
  SET_CREDENTIALS_SUCCESS,
  SET_CREDENTIALS_FAILURE,
} from './registrationTypes';
import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from './registrationTypes';
import { RESET_REGISTRATION } from './registrationTypes';

export const resetRegistration = () => ({
  type: RESET_REGISTRATION,
});

export const setCredentialsRequest = () => ({
  type: SET_CREDENTIALS_REQUEST,
  isFetching: true,
  error: null,
});

export const setCredentialsFailed = error => ({
  type: SET_CREDENTIALS_FAILURE,
  isFetching: false,
  error: error.message,
});

export const setCredentialsReceive = json => ({
  type: SET_CREDENTIALS_SUCCESS,
  credentialsSetup: json,
  isFetching: false,
  error: null,
});

export const registerRequest = () => ({
  type: REGISTER_REQUEST,
  isFetching: true,
  error: null,
});

export const registerFailed = error => ({
  type: REGISTER_FAILURE,
  isFetching: false,
  error: error.message,
});

export const registerReceive = json => ({
  type: REGISTER_SUCCESS,
  registration: json,
  isFetching: false,
  error: null,
});

export const setCredentials = request => dispatch => {
  dispatch(setCredentialsRequest());
  return fetch(
    Connection.baseUrl('webauthentication') + `v2.0/user/credentials/setup`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(setCredentialsReceive(json)))
    .catch(error => dispatch(setCredentialsFailed(error)));
};

export const register = request => dispatch => {
  dispatch(registerRequest());
  return fetch(Connection.baseUrl('webauthentication') + `v2.0/user/register`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(registerReceive(json)))
    .catch(error => dispatch(registerFailed(error)));
};
