const preserveData = (function() {
  var formData = {};

  const setFormData = s => {
    formData = s;
  };

  const getFormData = () => {
    return formData;
  };

  return {
    setFormData: setFormData,
    getFormData: getFormData,
  };
})();

export default preserveData;
