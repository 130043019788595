import { Connection } from '@myie/interact';

import {
  FORGOTTEN_UN_REQUEST,
  FORGOTTEN_UN_SUCCESS,
  FORGOTTEN_UN_FAILURE,
  RESET_FORGOTTEN_UN,
} from './forgottenUserNameTypes.js';

export const resetForgottenUN = () => ({
  type: RESET_FORGOTTEN_UN,
});

export const forgottenUNRequest = () => ({
  type: FORGOTTEN_UN_REQUEST,
  isFetching: true,
  error: null,
});

export const forgottenUNFailed = error => ({
  type: FORGOTTEN_UN_FAILURE,
  isFetching: false,
  error: error.message,
});

export const forgottenUNReceive = json => ({
  type: FORGOTTEN_UN_SUCCESS,
  forgottenUN: json,
  isFetching: false,
  error: null,
});

export const retrieveUserName = request => dispatch => {
  dispatch(forgottenUNRequest());
  return fetch(
    Connection.baseUrl('webauthentication') +
      `v2.0/user/credentials/forgotten-username`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(forgottenUNReceive(json)))
    .catch(error => dispatch(forgottenUNFailed(error)));
};
