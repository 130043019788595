import React from 'react';
import { Currency } from '@myie/interact-dom';
import { Row, Col, FormGroup } from 'reactstrap';

/** Currency GBP example */
class CurrencyExampleGBP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        amount: {
          value: 1500,
        },
      },
    };
  }

  onChange = e => {
    const { form } = this.state;
    const value = e.target.value;
    form.amount.value = value;
    this.setState({ form });
  };

  render() {
    const currencyCode = 'GBP';
    const { form } = this.state;
    return (
      <Row>
        <Col md={6}>
          <FormGroup>
            <Currency
              label="Enter the amount to pay"
              showErrors={false}
              validation={form.amount}
              disabled={false}
              onChange={this.onChange}
              onBlur={this.onChange}
              code={currencyCode}
              field="amount"
              id="amount"
              dp={2}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default CurrencyExampleGBP;
