import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-messaging-twoway';
import { symbols, Content, Markdown, ContactNumber } from '@myie/interact-dom';
import { Link } from 'react-router-dom';
import { Validate } from '@myie/interact';
import Currency from 'react-currency-formatter';

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        relatedAccount: {
          rules: {
            title: 'Source Account',
            stop: true,
            required: {
              message: 'Please select a related account',
            },
          },
        },
        message: {
          rules: {
            stop: true,
            required: {
              message: 'Please enter a message',
            },
          },
        },
      },
    };
  }

  accountOptions = (prefix, item, id) => {
    var name = item.AccountName;
    if (item.Balance) {
      name =
        name +
        ': ' +
        Currency({ quantity: item.Balance, currency: item.CurrencyCode });
    }
    return (
      <option
        key={prefix + '-' + item.AccountKey.Key + '-' + id}
        id={id}
        value={item.AccountKey.Key}
      >
        {name}
      </option>
    );
  };

  submit = e => {
    e.preventDefault();
    let { form } = this.state;
    const { replyConversation, selectedData } = this.props;
    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      return;
    }
    let request = {
      AccountKey: selectedData.relatedAccountObj
        ? selectedData.relatedAccountObj.AccountKey
        : null,
      ConversationKey: null,
      MessageBody: selectedData.message,
      Reason: selectedData.relatedAccountObj
        ? 'Account number: ' + selectedData.relatedAccountObj.AccountNumber
        : 'General Enquiry',
      ProductType: selectedData.relatedAccountObj
        ? selectedData.relatedAccountObj.ProductType
        : null,
      ExtendedProperties: {},
    };
    replyConversation(request);
  };

  onBlur = e => {
    const { saveData } = this.props;
    let { form } = this.state;
    const { name, value } = e.target;
    form = Validate.input(name, value, form, true);
    saveData(name, value);
    this.setState({ ...this.state, form });
  };

  onChangeAccount = e => {
    const { saveData, accounts } = this.props;
    let { form } = this.state;
    const { name, value } = e.target;
    const accountKey = value;
    let source;
    form = Validate.input(name, value, form, true);
    if (accounts) {
      source = accounts.Accounts.find(
        account => account.AccountKey.Key === accountKey,
      );
    }
    saveData(name, value);
    saveData('relatedAccountObj', source);
    this.setState({ ...this.state, form });
  };

  onChange = e => {
    const { saveData } = this.props;
    let { form } = this.state;
    const { name, value } = e.target;
    form = Validate.input(name, value, form, true);
    saveData(name, value);
    this.setState({ ...this.state, form });
  };

  getCurrencySymbol = CurrencyCode => {
    return symbols[CurrencyCode];
  };

  render() {
    const { selectedData } = this.props;
    const { form } = this.state;
    if (selectedData) {
      form.relatedAccount.value = selectedData.relatedAccount;
      form.message.value = selectedData.message;
    }

    return (
      <>
        <h2>
          <Content id="title" copytext="Got a question?" />
        </h2>
        <Markdown
          id="widget-send-message.TopDescription"
          markdown={`\nIt's easy to contact us securely with any queries you have about your account.
          \nUnfortunately we can't accept online requests to change your personal details, but for all other queries simply send us a secure message using the button below.
          \nWe'll aim to reply within 2 working days.`}
        />
        <Link
          id="widget-send-message-button"
          className="btn btn-primary"
          role="button"
          to="/messaging-twoway/new"
        >
          <Content id="sendMessageButton" copytext="Send Message" />
        </Link>
        <p>
          <Content
            id="contact-para-1"
            copytext="We're also happy to speak to you directly about your account. Please call CCB Customer Services on "
          />
          <ContactNumber />
          <Content
            id="contact-para-2"
            copytext=". Lines are open Mon-Fri 9am to 5pm (excluding Bank Holidays)."
          />
        </p>
      </>
    );
  }
}

Details.propTypes = {
  accounts: PropTypes.any,
  fetchTransferMapIfNeeded: PropTypes.any,
  replyConversation: PropTypes.any,
  saveData: PropTypes.any,
  selectedData: PropTypes.any,
  setStage: PropTypes.any,
  transactionData: PropTypes.any,
  transfermap: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Details);
