import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { Content } from '@myie/interact-dom';

class AboutYou extends React.Component {
  render() {
    const { userDetails } = this.props;

    let yourTitle = '';
    let firstName = '';
    let middleNames = '';
    let lastName = '';
    let emailAddress = '';
    //let homeNumber = '';
    let workNumber = '';
    let mobileNumber = '';

    if (userDetails) {
      yourTitle = userDetails.Title;
      firstName = userDetails.FirstName;
      middleNames = userDetails.MiddleName;
      lastName = userDetails.Surname;
      emailAddress = userDetails.EmailAddress;
      //homeNumber = userDetails.HomeNumber;
      workNumber = userDetails.WorkNumber;
      mobileNumber = userDetails.MobileNumber;
    }

    return (
      <div>
        <h2>
          <Content id="about-you.title" copytext="About you" />
        </h2>
        <Row tag="dl">
          <Col tag="dt" xs={12} sm={4}>
            <Content id="about-you.yourTitle" copytext="Your title" />
          </Col>
          <Col tag="dd" xs={12} sm={8}>
            {yourTitle}
          </Col>
          <Col tag="dt" xs={12} sm={4}>
            <Content id="about-you.firstName" copytext="First name" />
          </Col>
          <Col tag="dd" xs={12} sm={8}>
            {firstName}
          </Col>
          <Col tag="dt" xs={12} sm={4}>
            <Content id="about-you.middleNames" copytext="Middle name/s" />
          </Col>
          <Col tag="dd" xs={12} sm={8}>
            {middleNames}
          </Col>
          <Col tag="dt" xs={12} sm={4}>
            <Content id="about-you.lastName" copytext="Last name" />
          </Col>
          <Col tag="dd" xs={12} sm={8}>
            {lastName}
          </Col>
          <Col tag="dt" xs={12} sm={4}>
            <Content id="about-you.emailAddress" copytext="Email address" />
          </Col>
          <Col tag="dd" xs={12} sm={8}>
            {emailAddress}
          </Col>
          <Col tag="dt" xs={12} sm={4}>
            <Content id="about-you.homeNumber" copytext="Home number" />
          </Col>
          <Col tag="dd" xs={12} sm={8}>
            {workNumber}
          </Col>
          <Col tag="dt" xs={12} sm={4}>
            <Content id="about-you.mobileNumber" copytext="Mobile number" />
          </Col>
          <Col tag="dd" xs={12} sm={8}>
            {mobileNumber}
          </Col>
        </Row>
      </div>
    );
  }
}

AboutYou.propTypes = {
  userDetails: PropTypes.object,
};

export default AboutYou;
