import React from 'react';
import PropTypes from 'prop-types';
import { Fade, TabPane } from 'reactstrap';

class TabSummary extends React.Component {
  render() {
    const { activeTab, tablist, children, name } = this.props;

    return (
      <TabPane
        id="summary"
        role="tabpanel"
        tabId="summary"
        aria-labelledby="summary-tab"
      >
        <Fade in={activeTab === 'summary'}>
          <div
            className="tab-focus"
            tabIndex="0"
            ref={tablist.refs.panels[tablist.refs.names.indexOf(name)]}
            onKeyDown={e => tablist.panelKeyDown(e, name, tablist.refs)}
          >
            {children}
          </div>
        </Fade>
      </TabPane>
    );
  }
}
TabSummary.propTypes = {
  activeTab: PropTypes.string,
  children: PropTypes.node,
  tablist: PropTypes.object,
  name: PropTypes.any,
};

export default TabSummary;
