import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Settings } from '@myie/interact';

/**
 * FormattedDate component
 * will format and return date
 */
class FormattedDate extends React.Component {
  render() {
    const { format = Settings.dateFormat, date } = this.props;
    const dateStr = moment(date).format(format);
    return <React.Fragment>{dateStr}</React.Fragment>;
  }
}

FormattedDate.propTypes = {
  /**
   * Date to format
   */
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /**
   * A format
   */
  format: PropTypes.string,
};

export default FormattedDate;
