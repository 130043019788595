import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ height = '174.416', width = '176.836' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 176.836 174.416"
  >
    <g transform="translate(-1029.966 -122.168)">
      <rect
        className="icon-fill"
        width="52.239"
        height="7.002"
        transform="translate(1092.31 197.329)"
      />
      <rect
        className="icon-fill"
        width="52.239"
        height="7.002"
        transform="translate(1092.31 187.934)"
      />
      <rect
        className="icon-fill"
        width="52.239"
        height="7.002"
        transform="translate(1092.31 178.539)"
      />
      <rect
        className="icon-fill"
        width="52.239"
        height="7.002"
        transform="translate(1092.31 169.143)"
      />
      <rect
        className="icon-fill"
        width="52.239"
        height="7.002"
        transform="translate(1092.31 159.748)"
      />
      <rect
        className="icon-fill"
        width="52.239"
        height="7.002"
        transform="translate(1092.31 150.353)"
      />
      <rect
        className="icon-fill"
        width="52.239"
        height="7.002"
        transform="translate(1092.31 140.958)"
      />
      <rect
        className="icon-fill"
        width="52.239"
        height="7.002"
        transform="translate(1092.31 131.563)"
      />
      <rect
        className="icon-fill"
        width="52.239"
        height="7.002"
        transform="translate(1092.31 122.168)"
      />
      <rect
        className="icon-fill"
        width="52.239"
        height="7.002"
        transform="translate(1149.29 197.329)"
      />
      <rect
        className="icon-fill"
        width="52.239"
        height="7.002"
        transform="translate(1149.29 187.934)"
      />
      <rect
        className="icon-fill"
        width="52.239"
        height="7.002"
        transform="translate(1149.29 178.539)"
      />
      <rect
        className="icon-fill"
        width="52.239"
        height="7.002"
        transform="translate(1149.29 169.143)"
      />
      <rect
        className="icon-fill"
        width="52.239"
        height="7.002"
        transform="translate(1149.29 159.748)"
      />
      <rect
        className="icon-fill"
        width="52.239"
        height="7.002"
        transform="translate(1035.818 197.329)"
      />
      <rect
        className="icon-fill"
        width="52.239"
        height="7.002"
        transform="translate(1035.818 187.934)"
      />
      <path
        className="warning-fill"
        d="M1118.384,296.584a88.419,88.419,0,0,0,88.418-88.418H1029.966A88.419,88.419,0,0,0,1118.384,296.584Z"
      />
    </g>
  </svg>
);

SVG.propTypes = { height: PropTypes.string, width: PropTypes.string };

export default SVG;
