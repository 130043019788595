import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ width = '100%', height = '100%' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 1024 1024"
    version="1.1"
    viewBox="0 0 1024 1024"
    width={width}
    height={height}
  >
    <title>{'Facebook'}</title>
    <path
      d="M1024 512C1024 229.2 794.8 0 512 0S0 229.2 0 512c0 255.6 187.2 467.4 432 505.8V660H302V512h130V399.2C432 270.9 508.4 200 625.4 200c56 0 114.6 10 114.6 10v126h-64.6c-63.6 0-83.4 39.5-83.4 80v96h142l-22.7 148H592v357.8c244.8-38.4 432-250.2 432-505.8z"
      className="white-fill"
    />
    <path
      d="M711.3 660L734 512H592v-96c0-40.5 19.8-80 83.4-80H740V210s-58.6-10-114.6-10c-117 0-193.4 70.9-193.4 199.2V512H302v148h130v357.8c26.1 4.1 52.8 6.2 80 6.2s53.9-2.1 80-6.2V660h119.3z"
      className="facebook-fill"
    />
  </svg>
);

SVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
