import React from 'react';
import PropTypes from 'prop-types';

import {
  Content,
  FormattedCurrency,
  Value,
  FormattedDate,
} from '@myie/interact-dom';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-account-type-savings';
import { mapStateToProps } from '@myie/interact-accounts';
import moment from 'moment';

class SavingsDetailSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static getDerivedStateFromProps = nextProps => {
    const {
      accounts = {},
      fetchSavingsAccountTransactions,
      account,
      isFetching,
    } = nextProps;
    const to = moment().startOf('day');
    const from = moment()
      .subtract(40, 'years')
      .add(1, 'day')
      .startOf('day');
    const range = {
      To: to.toISOString(),
      From: from.toISOString(),
      Latest: true,
    };
    if (accounts && !account.transactions && !isFetching) {
      fetchSavingsAccountTransactions({
        AccountKey: account.AccountKey,
        Range: range,
        ExtendedProperties: {
          FromDate: moment(from).format(moment.HTML5_FMT.DATE),
          ToDate: moment(to).format(moment.HTML5_FMT.DATE),
        },
      });
    }

    return null;
  };

  render() {
    const { account } = this.props;
    let pendingTransactions = [];
    if (
      account &&
      account.transactions &&
      account.transactions.Transactions.length > 0
    ) {
      pendingTransactions = account.transactions.Transactions.filter(
        element => element.ExtendedProperties.Type === 'PendingTransaction',
      );
    }
    const { ExtendedProperties: { InterestPaymentFrequency } = {} } = account;
    return (
      <>
        <h2 className="h4">
          <Content id="summary-title" copytext="Your Account Summary" />
        </h2>

        <Row tag="dl">
          <Col tag="dt" xs={6} md={4} lg={3}>
            <Content id="summary-balance" copytext="Balance" />
          </Col>
          <Col id="summary-balance-amount" tag="dd" xs={6} md={8} lg={9}>
            <FormattedCurrency
              quantity={account.Balance}
              currency={account.CurrencyCode}
            />
          </Col>
          <Col tag="dt" xs={6} md={4} lg={3}>
            <Content id="summary-interest-rate" copytext="Rate" />
          </Col>
          <Col id="summary-interest-rate-value" tag="dd" xs={6} md={8} lg={9}>
            <Value value={account.InterestRate} decimalPlaces={2} />%
            <Content id="savings-gross" copytext=" Gross" />
            {InterestPaymentFrequency}
          </Col>
          <Col tag="dt" xs={6} md={4} lg={3}>
            <Content id="summary-interest-date" copytext="Interest Date" />
          </Col>
          <Col id="summary-interest-date-value" tag="dd" xs={6} md={8} lg={9}>
            <FormattedDate
              date={account.ExtendedProperties.NextInterestDueDate}
            />
          </Col>
          <Col tag="dt" xs={6} md={4} lg={3}>
            <Content
              id="summary-statement-method"
              copytext="Statement method"
            />
          </Col>
          <Col
            id="summary-statement-method-value"
            tag="dd"
            xs={6}
            md={8}
            lg={9}
          >
            {account.ExtendedProperties.StatementMethod}
          </Col>

          {this.props.brandProductType === 'NOTICE_ACCOUNT' ? (
            <>
              <Col tag="dt" xs={6} md={4} lg={3}>
                <Content id="summary-notice-period" copytext="Notice period" />
              </Col>
              <Col
                id="summary-notice-period-value"
                tag="dd"
                xs={6}
                md={8}
                lg={9}
              >
                {account.ExtendedProperties.NoticePeriodInDays}
                <Content id="number-of-days" copytext=" days" />
              </Col>
              <Col tag="dt" xs={6} md={4} lg={3}>
                <Content
                  id="summary-notice-on-account"
                  copytext="Notices on account"
                />
              </Col>
              <Col
                id="summary-notice-on-account-value"
                tag="dd"
                xs={6}
                md={8}
                lg={9}
              >
                {pendingTransactions.length}
              </Col>
            </>
          ) : null}
          {this.props.brandProductType === 'EASY_ACCESS' ? (
            <>
              <Col tag="dt" xs={6} md={4} lg={3}>
                <Content
                  id="summary-pending-withdrawal"
                  copytext="Pending withdrawal"
                />
              </Col>
              <Col
                id="summary-pending-withdrawal-value"
                tag="dd"
                xs={6}
                md={8}
                lg={9}
              >
                {pendingTransactions.length}
              </Col>
            </>
          ) : null}
          {this.props.brandProductType === 'FIXED_TERM' ? (
            <>
              <Col tag="dt" xs={6} md={4} lg={3}>
                <Content id="summary-maturity-date" copytext="Maturity date" />
              </Col>
              <Col
                id="summary-maturity-date-value"
                tag="dd"
                xs={6}
                md={8}
                lg={9}
              >
                <FormattedDate date={account.ExtendedProperties.MaturityDate} />
              </Col>
            </>
          ) : null}
        </Row>
      </>
    );
  }
}

SavingsDetailSummary.propTypes = {
  account: PropTypes.any,
  loopIndex: PropTypes.any,
  mode: PropTypes.any,
  brandProductType: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SavingsDetailSummary);
