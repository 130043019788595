import React from 'react';
import PropTypes from 'prop-types';
import contentManaged, { ContentType } from '../HO_components/contentManaged';

/**
 * Switch component - Manage content text for based on the status
 * i.e. render output for Alert based on the status
 */
class Switch extends React.Component {
  constructor(props) {
    super(props);
    const { contents, defaultText, managedContent } = props;
    const content = {};
    content.defaultText = { defaultValue: defaultText };
    Object.keys(contents).forEach(k => {
      content[k] = contents[k];
    });
    managedContent(content, true);
  }

  render() {
    const { props } = this;

    const {
      tag = 'div',
      value,
      contentValue,
      managedContent,
      defaultText,
      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    let text = contentValue(ContentType.Text, value);
    if (!text) {
      text = defaultText;
    }
    if (text) {
      const Component = tag;
      delete rest['contents'];
      if (tag === '<>') {
        return <React.Fragment>{text}</React.Fragment>;
      }
      return <Component {...rest}>{text}</Component>;
    }
    return '';
  }
}

Switch.propTypes = {
  id: PropTypes.string,
  contentValue: PropTypes.func.isRequired,
  contents: PropTypes.object.isRequired,
  defaultText: PropTypes.any,
  managedContent: PropTypes.func.isRequired,
  tag: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default contentManaged(Switch);
