import React from 'react';
import PropTypes from 'prop-types';
import Currency from 'react-currency-formatter';

/**
 * FormattedCurrency component
 * will format and return currency
 */
class FormattedCurrency extends React.Component {
  render() {
    const { props } = this;

    const { quantity, ...rest } = props;
    const qty = parseFloat(quantity);
    if ((!qty || isNaN(qty)) && !(qty === 0))
      return <span>{props.fallback || ''}</span>;
    return <Currency quantity={qty} {...rest} />;
  }
}

FormattedCurrency.propTypes = {
  fallback: PropTypes.any,
  /**
   * Value to format
   */
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FormattedCurrency;
