import React from 'react';
import { Pagination } from '@myie/interact-dom';

/** Pagination example */
class PaginationExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Offset: 0,
      Size: 8,
    };
  }

  getPreviousPage = (Offset, Size) => {
    const previousOffset = Offset - Size;
    if (previousOffset >= 0) {
      this.setState({ ...this.state, Offset: previousOffset });
    }
  };

  getNextPage = (Offset, Size, TotalConversationsCount) => {
    const nextOffset = Offset + Size;
    if (nextOffset <= TotalConversationsCount) {
      this.setState({ ...this.state, Offset: nextOffset });
    }
  };

  render() {
    const TotalConversationsCount = 17;
    const { Offset, Size } = this.state;
    return (
      <Pagination
        Total={TotalConversationsCount}
        Offset={Offset}
        Size={Size}
        getPreviousPage={this.getPreviousPage}
        getNextPage={this.getNextPage}
      />
    );
  }
}

export default PaginationExample;
