import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { Content, Markdown, LinkTag } from '@myie/interact-dom';
import { Link } from 'react-router-dom';
import { Session } from '@myie/interact';

class HelpContactSidebar extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  faqClick = e => {
    if (navigator.userAgent.includes('Firefox')) {
      e.preventDefault();
      window.open('/help');
      return false;
    }
    return true;
  };

  render() {
    return (
      <Card body color="light">
        <h2 className="h4 mt-0">
          <Content id="title" copytext="Can we help?" />
        </h2>
        <p>
          <Markdown id="details" markdown={`Call:`} />{' '}
          <LinkTag id="telephone-link" href="tel:03442253939">
            <Content id="telephone-number" copytext="0344 225 3939" />
          </LinkTag>{' '}
          <Content
            id="opening-hours"
            copytext="Monday-Friday 9am to 5pm (excluding Bank Holidays)"
          />
        </p>
        <p>
          <a
            id="link-faq"
            target="_blank"
            href="https://ccbank.co.uk/business/savings/savings-help-page/#faqs"
            rel="noopener noreferrer"
          >
            <Content
              id="faqLink"
              copytext="Check our Frequently Asked Questions"
            />
            <span className="sr-only">
              <Content id="faqLink" copytext=" (opens in a new tab)" />
            </span>
          </a>
        </p>
        {Session.isAuthenticated() ? (
          <p className="pb-0 mb-0">
            <Link id="link-contact-us" to="/messaging-twoway/new">
              <Content id="contact-Link" copytext="Send us a message" />
            </Link>
          </p>
        ) : null}
      </Card>
    );
  }
}

HelpContactSidebar.propTypes = {
  items: PropTypes.string,
};

export default HelpContactSidebar;
