import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { mapDispatchToProps as mapDispatchToPropsAccounts } from '@myie/interact-accounts';
import { mapDispatchToProps as mapDispatchToPropsMessaging } from '@myie/interact-messaging-twoway';
import { TextArea, Content } from '@myie/interact-dom';
import { Validate } from '@myie/interact';
import PropTypes from 'prop-types';
import { FormattedDate, AppMeta } from '@myie/interact-dom';
import FormatBody from '../../FormatBody';
import messagingUtility from './../../utility';

class ReplyConversation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'View',
      message: null,
      data: {},
      form: {
        message: {
          rules: {
            stop: true,
            required: {
              message: 'Please enter a message',
            },
            format: {
              regex: /^[^\s](.|[\r\n]){1,750}$/,
              message: 'Please enter a valid message',
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  onBlur = e => {
    let { form } = this.state;
    const { name, value } = e.target;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  submit = e => {
    e.preventDefault();
    const { replyConversation, viewMessage } = this.props;
    let { form } = this.state;
    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      return;
    }
    if (viewMessage) {
      const request = {
        AccountKey: viewMessage.Conversation.AccountKey
          ? viewMessage.Conversation.AccountKey
          : null,
        ConversationKey: viewMessage.Conversation.ConversationKey,
        MessageBody: form.message.value,
        ExtendedProperties: {},
      };
      replyConversation(request);
    }
  };

  // select current stag
  render() {
    const { viewMessage, accounts } = this.props;
    const { form } = this.state;
    let Conversation,
      accountSuffix = '';

    let messages = [];
    if (!viewMessage) {
      return null;
    } else {
      Conversation = viewMessage.Conversation;
    }

    if (Conversation && Conversation.Messages) {
      messages = Conversation.Messages;
    }

    if (
      Conversation &&
      Conversation.AccountNumber &&
      accounts &&
      accounts.Accounts &&
      accounts.Accounts.length
    ) {
      let account = accounts.Accounts.find(
        a => a.AccountNumber === Conversation.AccountNumber,
      );
      if (account && account.AccountStatus === 'Closed') {
        accountSuffix = ' <Closed account>';
      }
    }

    return (
      <div id="conversation-reply">
        <AppMeta
          id="meta-data"
          stage="child"
          title="Message reply"
          metaDescription="Message reply"
        />
        <h1>
          <Content id="reason" copytext="Re:" />{' '}
          {messagingUtility.stripEndQuotes(Conversation.Reason)}
        </h1>
        <Row tag="dl" title="message details">
          <Col tag="dt" sm={6} md={3}>
            <Content id="relatedAccountMsg" copytext="Related account:" />
          </Col>
          <Col tag="dd" sm={6} md={9}>
            {Conversation.AccountNumber !== 'XXXXXXXXXable' &&
            Conversation.AccountNumber !== 'Not available' ? (
              <>{Conversation.AccountNumber + accountSuffix}</>
            ) : (
              ''
            )}
          </Col>
          <Col tag="dt" xs={12} md={4} lg={3}>
            <Content id="referenceNumber" copytext="Reference number:" />
          </Col>
          <Col tag="dd" xs={12} md={8} lg={9}>
            {Conversation.Reference}
          </Col>
        </Row>
        <Form
          autoComplete="off"
          id="messaging-twoway-reply-form"
          onSubmit={this.submit}
        >
          <TextArea
            cmsTag="srOnlyReplyToSend"
            label="Your message"
            id="message"
            field="message"
            onChange={this.onChange}
            onBlur={this.onBlur}
            validation={form.message}
            maxLength="750"
            autoFocus={true}
          />
          <p className="float-right caveat" aria-hidden="true">
            <Content
              id="chars-remaining"
              template={{
                copytext: {
                  counter: () => {
                    return (
                      750 -
                      (this.state.form.message.value
                        ? this.state.form.message.value.length
                        : 0)
                    );
                  },
                },
              }}
              copytext="Chars remaining: $[counter]"
            />
          </p>
          <div className="list-group messages-list">
            {messages.map((item, index) => {
              return (
                <div className="list-group-item" key={`message-${index}`}>
                  <h2>
                    {item.Sender.SenderType !== 'User'
                      ? `${item.Sender.SenderType}`
                      : ''}{' '}
                    <span className="timestamp">
                      <FormattedDate date={item.CreatedDate} format={'LLL'} />
                    </span>
                  </h2>
                  <p>{FormatBody(item.Body)}</p>
                </div>
              );
            })}
          </div>
          <div className="form-button-group">
            <Button
              id="messaging-twoway-message-submit"
              type="submit"
              color="primary"
              size="sm"
            >
              <Content id="submitButton" copytext="Send" />
            </Button>
            <Link
              id="messaging-twoway-message-back-to-link"
              className="btn btn-secondary btn-sm"
              role="button"
              to="/messaging-twoway/view"
            >
              <Content id="backButton" copytext="Back To Messages" />
            </Link>
          </div>
        </Form>
      </div>
    );
  }
}

ReplyConversation.propTypes = {
  accounts: PropTypes.object,
  fetchAccountsIfNeeded: PropTypes.func,
  baseUrl: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  replyConversation: PropTypes.any,
  setReplyStage: PropTypes.any,
  viewMessage: PropTypes.any,
};

const mapStateToProps = state => {
  const { messagingTwowayDefinition, accountsDefinition } = state;
  return {
    ...messagingTwowayDefinition,
    ...accountsDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToPropsMessaging, ...mapDispatchToPropsAccounts },
)(ReplyConversation);
