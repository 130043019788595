import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';
import { Content, Markdown } from '@myie/interact-dom';
import { Link } from 'react-router-dom';

class ExceededRequests extends React.Component {
  retry = e => {
    const { resetDestinations, resetChallenge } = this.props;
    e.preventDefault();
    resetDestinations();
    resetChallenge();
  };

  cancel = e => {
    const { finishTwoFactor } = this.props;
    e.preventDefault();
    finishTwoFactor();
  };

  render() {
    return (
      <>
        <Content
          tag="h1"
          cmsTag="Two-factor:request-cancelled:h1"
          copytext="Request Cancelled"
        />
        <Markdown
          cmsTag="Two-factor:Expired-challenge:p1"
          markdown={`\nWe're sorry, you have reached the limit of <abbr title='One Time Passcode'> OTP </abbr> requests. For security reasons please wait for 24 hours before you try again.`}
        />
        <div className="form-button-group">
          <Link
            id="exceeded-attempts-cancel"
            className="btn btn-primary"
            role="button"
            to="/sign-in/step-1"
          >
            <Content
              cmsTag="GLOBAL:Back-to-sign-in"
              copytext="Back to sign in"
            />
          </Link>
        </div>
      </>
    );
  }
}

ExceededRequests.propTypes = {
  finishTwoFactor: PropTypes.any,
  resetChallenge: PropTypes.any,
  resetDestinations: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExceededRequests);
