import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'black-fill', width = '30', height = '25' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 31.134 29.104"
    viewBox="0 0 31.134 29.104"
  >
    <g>
      <path className={fill} d="M10.702 14.997H14.594V18.889H10.702z" />
      <path
        className={fill}
        d="M26.269 0H4.864C2.113.467 0 2.875 0 5.754v17.513c0 3.212 2.626 5.838 5.837 5.838h19.458c3.212 0 5.838-2.626 5.838-5.838V5.754C31.134 2.875 29.021.467 26.269 0zM14.108 1.9h2.918c.803 0 1.46.657 1.46 1.459 0 .803-.657 1.459-1.46 1.459h-2.918c-.803 0-1.46-.656-1.46-1.459 0-.802.657-1.459 1.46-1.459zm15.08 21.367a3.897 3.897 0 01-3.893 3.892H5.837a3.896 3.896 0 01-3.892-3.892V6.727h27.243v16.54z"
      />
      <path className={fill} d="M10.702 9.159H14.594V13.051H10.702z" />
      <path className={fill} d="M4.864 14.997H8.756V18.889H4.864z" />
      <path className={fill} d="M4.864 9.159H8.756V13.051H4.864z" />
      <path className={fill} d="M22.378 9.159H26.269V13.051H22.378z" />
      <path className={fill} d="M22.378 20.834H26.269V24.727H22.378z" />
      <path className={fill} d="M22.378 14.997H26.269V18.889H22.378z" />
      <path className={fill} d="M16.54 9.159H20.432V13.051H16.54z" />
      <path className={fill} d="M16.54 14.997H20.432V18.889H16.54z" />
      <path className={fill} d="M16.54 20.834H20.432V24.727H16.54z" />
    </g>
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
