import React from 'react';
import PropTypes from 'prop-types';

/**
 * Value component
 * will display given values
 */
class Value extends React.Component {
  render() {
    const { props } = this;

    const { value, decimalPlaces, ...rest } = props;

    let formattedValue = value;

    if (decimalPlaces && Number(decimalPlaces)) {
      const convertedValue = value && Number(value) ? Number(value) : Number(0);
      formattedValue = convertedValue.toFixed(Number(decimalPlaces));
    }

    if (rest && Object.keys(rest).length) {
      return <span {...rest}>{formattedValue}</span>;
    }
    return <React.Fragment>{formattedValue}</React.Fragment>;
  }
}

Value.propTypes = {
  /**
   * Value to display
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  decimalPlaces: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Value;
