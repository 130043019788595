import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute, NoMatch } from '@myie/interact-dom';
import UpdateSecurityDetails from './UpdateSecurityDetails';
import UpdateSecurityDetailsSuccess from './UpdateSecurityDetailsSuccess';
import { Alert } from 'reactstrap';

class UpdateSecurityDetailsIndex extends React.Component {
  render() {
    const { error } = this.props;
    var re = new RegExp('^/update-security-details[/]{0,1}$');
    if (this.props.location.pathname.match(re)) {
      return <Redirect to="/update-security-details/retrieve" />;
    }
    return (
      <div id="update-security-details">
        {error ? (
          <Alert color="danger" role="alert">
            {error}
          </Alert>
        ) : (
          ''
        )}
        <Switch>
          <PrivateRoute
            exact
            path="/update-security-details/retrieve"
            component={UpdateSecurityDetails}
          />
          <PrivateRoute
            exact
            path="/update-security-details/success"
            component={UpdateSecurityDetailsSuccess}
          />
          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

UpdateSecurityDetailsIndex.propTypes = {
  error: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  location: PropTypes.any,
};

export default UpdateSecurityDetailsIndex;
