import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Content, Markdown, AppMeta } from '@myie/interact-dom';
import { Alert } from 'reactstrap';

class UserNameReminder extends React.Component {
  render() {
    return (
      <div id="forgotten-username-success">
        <AppMeta
          id="meta-data"
          stage="child"
          title="Forgotten username Step 2"
          metaDescription="Forgotten username step 2"
        />
        <h1 id="forgotten-username-success-title">
          <Content id="successTitle" copytext="Request Successful" />
        </h1>
        <Markdown
          id="forgotten-username-success-lead"
          template={{
            markdown: {
              username: this.props.username,
            },
          }}
          markdown={`Your username is: **$[username]**`}
        />

        <Alert color="info">
          <Content
            id="forgotten-username-success-alert"
            copytext="If you would like to change your username, you can do this when you have signed in by selecting the 'My Details' link at the top of the screen."
          />
        </Alert>
        <Markdown
          id="forgotten-username-success-instruction"
          template={{
            markdown: {
              username: this.props.username,
            },
          }}
          markdown={`To sign in to **$[username]** now, please press the 'Sign In' button below.`}
        />
        <div className="form-button-group">
          <Link
            className="btn btn-primary"
            role="button"
            style={{ margin: '10px 0' }}
            to="/sign-in"
          >
            <Content id="signInButton" copytext="Sign in" />
          </Link>
        </div>
      </div>
    );
  }
}

UserNameReminder.propTypes = {
  username: PropTypes.any,
};

export default UserNameReminder;
