import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';
import { Content, Markdown } from '@myie/interact-dom';
import { Link } from 'react-router-dom';
import { CONTACTS } from '@myie/interact-local-dom';
class NoDestinations extends React.Component {
  back = e => {
    const { finishTwoFactor } = this.props;
    e.preventDefault();
    finishTwoFactor();
  };

  render() {
    return (
      <>
        <Content
          tag="h1"
          cmsTag="Two-factor:No-destination:h1"
          copytext="Mobile Phone not available"
        />
        <Content
          tag="h2"
          cmsTag="Two-factor:No-destination:h2"
          copytext="Please call customer services."
        />

        <Markdown
          cmsTag="Two-factor:No-destination:p1"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
            },
          }}
          markdown={`\nBefore we can complete your request, we need to send a security
              message to the mobile phone number that is associated with your
              account. If you do not have your phone handy, or if you have changed
              your mobile number, you will need to contact us on <a href="tel:$[phoneLink]">$[phone]</a> so that we can help.`}
        />

        <div className="form-button-group">
          <Link
            id="exceeded-attempts-cancel"
            className="btn btn-primary"
            role="button"
            to="/sign-in/step-1"
          >
            <Content
              cmsTag="GLOBAL:Back-to-sign-in"
              copytext="Back to sign in"
            />
          </Link>
        </div>
      </>
    );
  }
}

NoDestinations.propTypes = {
  error: PropTypes.any,
  finishTwoFactor: PropTypes.any,
  resetChallenge: PropTypes.any,
  resetDestinations: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NoDestinations);
