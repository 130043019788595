import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
//import Transfer from '../../widget-quick-transfer/Transfer';
import { OurProducts } from '@myie/interact-dom';

class SidebarWidgets extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    return (
      <section id="sidebar-widgets-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/components/side-widgets.scss</strong>
          </code>
        </p>
        <p>
          There is a component made for the various <strong>adverts</strong> are
          defined within:
          <br />
          <code>shared/components/widget-quick-transfer/Transfer.js</code>
          <br />
          <code>shared/components/widget-quick-transfer/OurProducts.js</code>
        </p>
        <p>
          There is no interactive technical guide for this. To see its usage, go
          to the above components.
        </p>
        <Row>
          <Col xs={6}>{/* <Transfer /> */}</Col>
          <Col xs={5}>
            <OurProducts />
          </Col>
        </Row>
      </section>
    );
  }
}

SidebarWidgets.propTypes = {
  i18n: PropTypes.any,
  items: PropTypes.string,
};

export default SidebarWidgets;
