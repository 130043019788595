import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { PrivateRoute, NoMatch } from '@myie/interact-dom';
import MakeWithdrawal from './MakeWithdrawal';

class MakeWithdrawalRoute extends Component {
  render() {
    return (
      <div id="make-withdrawal-card">
        <Switch>
          <PrivateRoute
            path="/make-withdrawal/:id"
            component={MakeWithdrawal}
          />
          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

export default MakeWithdrawalRoute;
