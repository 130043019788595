import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SendNewMessage from './SendNewMessage';
import Confirmation from './Confirmation';
import { mapDispatchToProps as mapDispatchToPropsAccounts } from '@myie/interact-accounts';
import { mapDispatchToProps as mapDispatchToPropsMessaging } from '@myie/interact-messaging-twoway';
import { Content } from '@myie/interact-dom';
import { Utility } from '@myie/interact';
import { withRouter } from 'react-router-dom';

class NewMessage extends React.Component {
  constructor(props) {
    super(props);
    const { resetReply } = props;
    this.state = {
      stage: 'New',
      data: {},
    };
    resetReply();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { replyMessageStatus, match, accounts } = nextProps;
    const { data } = prevState;

    if (replyMessageStatus && replyMessageStatus.ResponseStatus) {
      if (prevState.stage === 'Confirmation') {
        return {
          data: {},
          stage: 'New',
        };
      }
      if (
        prevState.stage === 'New' &&
        prevState.data &&
        prevState.data.message
      ) {
        return {
          ...prevState,
          stage: 'Confirmation',
        };
      }
      return {
        ...prevState,
        stage: 'New',
      };
    }
    if (match.params.id && accounts) {
      const account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });

      data['relatedAccount'] = account.AccountKey.Key;
      return {
        ...prevState,
        data: data,
      };
    }
    return null;
  }

  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data });
  };

  currentStage = (accounts = []) => {
    const { data, stage } = this.state;
    const { replyMessageStatus } = this.props;
    switch (stage) {
      case 'New': {
        return (
          <SendNewMessage
            accounts={accounts}
            saveData={this.saveData}
            selectedData={data}
          />
        );
      }
      case 'Confirmation':
        return (
          <Confirmation
            replyMessageStatus={replyMessageStatus}
            selectedData={data}
          />
        );
      default:
    }
  };

  render() {
    const { accounts } = this.props;
    const { stage } = this.state;
    return (
      <>
        {stage === 'New' ? (
          <h1>
            <Content id="NewMessagetitle" copytext="New message" />
          </h1>
        ) : (
          ''
        )}
        {accounts ? this.currentStage(accounts) : false}
      </>
    );
  }
}

NewMessage.propTypes = {
  accounts: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  replyMessageStatus: PropTypes.any,
  resetReply: PropTypes.any,
  match: PropTypes.any,
};

const mapStateToProps = state => {
  const { messagingTwowayDefinition, accountsDefinition } = state;
  return {
    ...messagingTwowayDefinition,
    ...accountsDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToPropsMessaging, ...mapDispatchToPropsAccounts },
)(withRouter(NewMessage));
