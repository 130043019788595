import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormattedCurrency } from '@myie/interact-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-account-type-savings';
import { connect } from 'react-redux';
import moment from 'moment';
import { Content, FormattedDate } from '@myie/interact-dom';

class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isComponentLoaded: false,
    };
  }

  static getDerivedStateFromProps = nextProps => {
    const {
      accounts = {},
      fetchSavingsAccountTransactionsIfNeeded,
      isFetching,
      account,
    } = nextProps;
    const to = moment().startOf('day');
    const from = moment()
      .subtract(40, 'years')
      .add(1, 'day')
      .startOf('day');
    const range = {
      To: to.toISOString(),
      From: from.toISOString(),
      Latest: true,
    };

    if (accounts && !account.transactions && !isFetching) {
      fetchSavingsAccountTransactionsIfNeeded({
        AccountKey: account.AccountKey,
        Range: range,
        ExtendedProperties: {
          FromDate: moment(from).format(moment.HTML5_FMT.DATE),
          ToDate: moment(to)
            .add(1, 'day')
            .format(moment.HTML5_FMT.DATE),
        },
      });
    }

    return null;
  };

  setInitialRange() {}

  componentDidMount() {
    this.setState({ ...this.state, isComponentLoaded: true });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.account === nextProps.account &&
      this.state.isComponentLoaded === nextState.isComponentLoaded
    ) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const { props } = this;

    const { account, loopIndex = 0 } = props;

    if (account.transactions && account.transactions.Transactions.length > 0) {
      let transactions = [...account.transactions.Transactions];
      //Transactions available - show latest 3 transactions
      return (
        <div className="transactions-summary-section">
          <h3 id={`latest-easy-access-trans-${loopIndex}`} className="h6">
            <Content
              id="easy-access-latest-transactions"
              copytext="Latest transactions"
            />
          </h3>
          <Row
            tag="dl"
            aria-labelledby={`latest-easy-access-trans-${loopIndex}`}
            title="Transactions summary"
          >
            {transactions
              .filter((i, index) => index < 3)
              .map((transaction, index) => (
                <React.Fragment key={index}>
                  <Col key={index} tag="dt" xs={12} sm={7}>
                    <FormattedDate
                      date={transaction.Date}
                      format={'DD MMM YYYY'}
                    />
                  </Col>
                  <Col
                    tag="dd"
                    xs={12}
                    sm={5}
                    className="text-xs-left text-sm-right"
                  >
                    <FormattedCurrency
                      quantity={transaction.Amount}
                      currency={account.CurrencyCode}
                    />
                  </Col>
                </React.Fragment>
              ))}
          </Row>
        </div>
      );
    } else {
      if (
        account.transactions &&
        account.transactions.Transactions.length === 0
      ) {
        //No Transaction
        return (
          <div className="transactions-summary-section">
            <p>
              <Content
                id="current-nothing-in-or-out"
                copytext="Nothing in or out in the last month"
              />
            </p>
          </div>
        );
      } else {
        return null;
      }
    }
  }
}

Transactions.propTypes = {
  account: PropTypes.any,
  fetchSavingsAccountTransactionsIfNeeded: PropTypes.func,
  loopIndex: PropTypes.number,
  isFetching: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Transactions);
