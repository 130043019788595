import React from 'react';
import { Content } from '@myie/interact-dom';

const PlusGlyph = () => {
  return (
    <>
      <img
        className="plus-glyph"
        alt="plus"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAQAAABpN6lAAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfjBg4UECkgxMCOAAADV0lEQVR42u2du09TcRxHz20FCY8gJWioCRATgiGQsHZiNNRJTXTxtRl3ddTFv4KFwWjiYpyKK1MZ5BHQQRN88BYiaGip0HKvAyGAEMrAvR+893u6tM0vued3evvIL/21DgoczpMmTQ/NwDzjZBhkCU9iEziN3GeMHBts4eKyxQY5RrlHQq0WBC08Y45NvH8um8zxlBa1nt808pgZ3APT9/BwmeFR2M+C6wyzdej0PTy2yHItWKFYoEdLkKLriGPG6CZFQ3gDNNNCzZEjamglGd4AdVSXHVNNXXgDODgnMua/DXAKsQBqATUWQC2gxgKoBdRYALWAGgugFlBjAdQCaiyAWkCNBVALqLEAagE1FkAtoMYCqAXUWAC1gBoLoBZQYwHUAmosgFpAjQVQC6ixAGoBNRZALaDGAqgF1FgAtYAaC6AWUGMB1AJqzuy5niBJva9Juo6xISZBzz6rk8blN3Os7tzc+Wp6I72kaKXW1wDnuERTmTHLfOGXrwFyfGOYIX7uBmjhFjfoKrObIzzkmeQNr5mGONDIAx5ymbNqr8CoJEknLpMU4jjc5AnJiL0cOtTRwSwTDhfI0E2F2khAkY9ciZGmI5LThwra6YuRjuj0txP0OUzRFrHn/y4uUw5r1AS7Ue0U4ZGP6mO/gxtjMSo/W3AIHj9ijFFSe8goMRIjQ1HtIaPIYIwMnyKaoMhn3sXJ84cUtZF7J/BY5DnDcWCGOO0RS+AyTz8vKcSBAl/JUU8DlWqvgMgzygCvWNxdEEnQS4q2yCyIZBliBdh32jdw0fclsTukyozJ8oIPvgbYtyS2d/VtdfdunyhxteyYFcbJ+uyxh6h/FLYAFkAtoMYCqAXUWAC1gBoLoBZQYwHUAmosgFpAjQVQC6ixAGoBNRZALaDGAqgF1FgAtYAaC6AWUGMB1AJqLIBaQI0FUAuosQBqATUWQC2gxgKoBdRYALWAGgugFlBjAdQCaixAoEfzjrFFz8MNb4A11suOWWctvAEWmCZ/5Ig831kIb4AVskwecYq7TJD1fdvGPuKBBoAlquik7tAdah7zDPCWQpgDFJjFpYOqA0cuskj/9k6usJPgLiPk2KCEi0uJDXK857bib7c1myUdmugjTQ9JYJ4xMgyyrFD5CwfJyq87w8caAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE5LTA2LTE0VDE4OjE2OjQxKzAyOjAwmfHBgQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOS0wNi0xNFQxODoxNjo0MSswMjowMOiseT0AAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC"
      />
      <span className="sr-only">
        <Content id="open" copytext="open" />
      </span>
    </>
  );
};

export default PlusGlyph;
