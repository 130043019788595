import React from 'react';
import { Date } from '@myie/interact-dom';
import { FormGroup, Row, Col } from 'reactstrap';

/** Date example */
class DateExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  onChangeDate = e => {
    const { value } = e.target;
    this.setState({ value });
  };

  render() {
    return (
      <Row>
        <Col md={6}>
          <FormGroup>
            <Date
              label="Date"
              id="date"
              field="date"
              labelClassName="font-weight-normal"
              showErrors={true}
              onChange={this.onChangeDate}
              onBlur={this.onChangeDate}
              value={this.state.value}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default DateExample;
