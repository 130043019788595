import React from 'react';
import { Link } from 'react-router-dom';
import { Media } from 'reactstrap';
import { Markdown, Icon } from '@myie/interact-dom';

class PersonalDetailsServiceItems extends React.Component {
  render() {
    return (
      <Link className="nav-link" to="/personal-details">
        <Media>
          <Media left middle>
            <Icon name="MyServicesPersonalDetails" fill="primary-fill" />
          </Media>
          <Media body middle>
            <Markdown
              options={{ forceBlock: true }}
              id="update-personal-details"
              markdown={`## Personal details\nView the details we hold for you.`}
            />
          </Media>
        </Media>
      </Link>
    );
  }
}

export default PersonalDetailsServiceItems;
