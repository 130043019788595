import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { Content } from '@myie/interact-dom';
import NoticeToWithdraw from './NoticeToWithdraw';
import { Utility } from '@myie/interact';
import { RedirectByAccountDropdown } from '@myie/interact-accounts-dom';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as savingsMapDispatchToProps } from '@myie/interact-account-type-savings';
import { connect } from 'react-redux';
import moment from 'moment';

class NoticeToWithdrawList extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    const { accounts, match } = this.props;
    let account;
    if (accounts) {
      account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
    } else {
      account = null;
    }
    return {
      account: account,
      range: this.setInitialRange(this.props),
    };
  };

  setInitialRange = props => {
    if (props.range) {
      return props.range;
    }
    const to = moment
      .utc()
      .startOf('day')
      .toISOString();
    const from = moment
      .utc()
      .subtract(12, 'months')
      .add(1, 'day')
      .startOf('day')
      .toISOString();
    return {
      To: to,
      From: from,
      Latest: true,
    };
  };

  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  componentDidUpdate(prevProps, prevState) {
    const { match, accounts } = this.props;
    const { fetchSavingsAccountTransactionsIfNeeded, isFetching } = this.props;
    if (
      this.state.account &&
      prevState.account !== this.state.account &&
      !isFetching
    ) {
      fetchSavingsAccountTransactionsIfNeeded({
        AccountKey: this.state.account.AccountKey,
        Range: this.state.range,
      });
    }
    if (accounts && prevProps.accounts !== accounts) {
      const account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
      this.setState({ ...this.state, account });
    }
  }

  onChangeAccount = e => {
    const { fetchAccountsIfNeeded, accounts } = this.props;
    fetchAccountsIfNeeded();
    const { value } = e.target;
    if (accounts && accounts.Accounts) {
      const account = accounts.Accounts.find(function(element) {
        return element.AccountKey.Key === value;
      });
      this.setState({ ...this.initialState(), account });
    }
  };

  getTransactions = (transactions, account, accountKey) => {
    let list = [];
    if (
      transactions &&
      transactions.Transactions &&
      transactions.Transactions.length > 0
    ) {
      transactions.Transactions.forEach((transaction, index) => {
        if (transaction.ExtendedProperties.Type === 'PendingTransaction') {
          list.push(
            <NoticeToWithdraw
              key={index}
              withdrawal={transaction}
              account={account}
              Index={index}
              loopIndex={transaction.TransactionIdentifier}
              accountKey={accountKey}
            />,
          );
        }
      });
    }

    return list;
  };

  render() {
    const { isFetching, accounts = {} } = this.props;
    const { account } = this.state;
    if (!account) {
      return null;
    }

    const { AccountKey: { Key } = {}, transactions = {} } = account;
    const accountKey = Key && Utility.hexEncode(Key);
    const pendingTransactions = this.getTransactions(
      transactions,
      account,
      accountKey,
    );

    const allNotices = isFetching ? (
      <Content id="loadingTransactions" copytext="Loading transactions..." />
    ) : (
      pendingTransactions
    );

    return (
      <div id="notice-to-withdraw">
        <h1>
          <Content id="pageTitle" copytext="Notice to Withdraw" />
        </h1>

        <RedirectByAccountDropdown
          accounts={accounts}
          baseUrl="/notice-to-withdraw/"
          defaultSelect={`${account.AccountKey.Key}`}
          onChangeAccount={this.onChangeAccount}
          filter="notice"
        />
        {!isFetching ? (
          pendingTransactions.length > 0 ? (
            <>
              <p>
                <Content
                  id="description-notices"
                  copytext="You have set up the following notices to withdraw on this account:"
                />
              </p>
              <h2 className="h3 mt-5 mb-3">
                <Content id="accounts-details" copytext="Account details" />
              </h2>
            </>
          ) : (
            <Content
              id="description-no-notices"
              copytext="You have no notices to withdraw set up on this account."
            />
          )
        ) : null}
        <Fade in={true}>{allNotices}</Fade>
        <div className="form-button-group">
          <Link
            id="create-new-notice"
            className="btn btn-primary"
            role="button"
            to={`/notice-to-withdraw/create/${accountKey}`}
          >
            <Content id="cerate-notice" copytext="Create a new notice" />
          </Link>
          <Link
            id="cancel-notice-to-withdraw"
            className="btn btn-secondary"
            role="button"
            to="/accounts/list"
          >
            <Content id="cancel-button" copytext="Cancel" />
          </Link>
        </div>
      </div>
    );
  }
}

NoticeToWithdrawList.propTypes = {
  accounts: PropTypes.any,
  isFetching: PropTypes.bool,
  selectedAccount: PropTypes.object,
  history: PropTypes.any,
  withdrawalNotices: PropTypes.any,
  match: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  fetchSavingsAccountTransactionsIfNeeded: PropTypes.any,
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...savingsMapDispatchToProps },
)(withRouter(NoticeToWithdrawList));
