import React from 'react';
import PropTypes from 'prop-types';
//import { FormFeedback } from 'reactstrap';
import { Validate } from '@myie/interact';
import { ContentType } from '../../HO_components/contentManaged';

/**
 * HasErrors function
 * check the given object and
 * will return true or false
 */
const HasErrors = validation => {
  const { state = {} } = validation;
  const { approval = {}, dirty } = state;
  if (approval.approved || !approval.failed || !dirty) {
    return true;
  }
  return false;
};

/**
 * LinkErrors component
 * will display error message
 */
class LinkErrors extends React.Component {
  render() {
    const { props } = this;

    const { validation = {}, field, contentValue, tagType, ...rest } = props;
    const { state = {} } = validation;
    let fieldName =
      typeof field === 'string' ? field : `${field[0]}-${field[1]}`;
    const { approval = {}, dirty } = state;
    const children = [];
    if (!approval.failed || !dirty) {
      return '';
    }
    const errors = Validate.errors(validation);

    errors.forEach(error => {
      children.push(
        <React.Fragment key={`${error.rule}`}>
          <a
            href={`#${fieldName}`}
            className="mb-0 invalid-feedback"
            id={`error-list-${fieldName}-${error.rule}`}
          >
            {contentValue
              ? contentValue(ContentType.Error, error.rule)
              : error.message}
          </a>
        </React.Fragment>,
      );
    });
    return children.length ? (
      <React.Fragment>
        {tagType === 'list' ? (
          <li {...rest} id={`error-list-${fieldName}-validation`}>
            {children}
          </li>
        ) : (
          <p {...rest} id={`error-list-${fieldName}-validation`}>
            {children}
          </p>
        )}
      </React.Fragment>
    ) : (
      ''
    );
  }
}

LinkErrors.propTypes = {
  /**
   * Field names
   */
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Input validation
   */
  validation: PropTypes.object,
  contentValue: PropTypes.any,
  tagType: PropTypes.any,
  title: PropTypes.any,
};

export default LinkErrors;
export { HasErrors };
