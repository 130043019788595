import React from 'react';
import { Text } from '@myie/interact-dom';
import { FormGroup, Row, Col } from 'reactstrap';

/** Text example 1 */
class TextExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  onChange = e => {
    const { value } = e.target;
    this.setState({ value });
  };

  render() {
    return (
      <Row>
        <Col md={6}>
          <FormGroup>
            <Text
              label="Enter Name"
              id="name"
              field="name"
              onChange={this.onChange}
              onBlur={this.onChange}
              value={this.state.value}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default TextExample;
