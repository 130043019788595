import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'reactstrap';
import Balance from './Balance';
import Overview from './Overview';
import Links from './Links';
import { Content } from '@myie/interact-dom';
import moment from 'moment';

class SavingsSummary extends React.Component {
  render() {
    var colSize = this.props.mode === 1 ? 12 : 6;

    const { account, loopIndex, status } = this.props;
    return (
      <Card
        body
        className="account-card account-summary"
        color="dark"
        id={`account-type-savings-summary-${loopIndex}`}
      >
        <>
          {account.AccountStatus === 'Closed' ? (
            ''
          ) : (
            <h2>{account.AccountName}</h2>
          )}
          <Row>
            <Col xs={12} lg={colSize}>
              <p className="account-number mb-0">
                <Content
                  id="savings-account-number"
                  template={{
                    copytext: {
                      accountNumber: account.AccountNumber,
                      sortCode: account.SortCode,
                    },
                  }}
                  copytext="$[accountNumber] $[sortCode]"
                />
              </p>
              {account.AccountStatus === 'Closed' ? (
                <>
                  <p className="account-number mb-0">
                    <Content
                      id="savings-account-opened-date"
                      template={{
                        copytext: {
                          dateOpened: moment(
                            account.ExtendedProperties.DateOpened,
                            'YYYY-MM-DD',
                          ).format('DD MMM YYYY'),
                        },
                      }}
                      copytext="Opened $[dateOpened]"
                    />
                  </p>
                  <p className="account-number mb-0">
                    <Content
                      id="savings-account-closed-date"
                      template={{
                        copytext: {
                          dateClosed: moment(
                            account.ExtendedProperties.DateClosed,
                            'YYYY-MM-DD',
                          ).format('DD MMM YYYY'),
                        },
                      }}
                      copytext="Closed $[dateClosed]"
                    />
                  </p>
                </>
              ) : (
                ''
              )}
              <p className="account-name">
                {account.ExtendedProperties.CompanyName
                  ? account.ExtendedProperties.CompanyName
                  : ''}
              </p>
              {account.AccountStatus !== 'Closed' ? (
                <Balance loopIndex={loopIndex} account={account} />
              ) : (
                ''
              )}
            </Col>
            {account.AccountStatus !== 'Closed' ? (
              <Col xs={12} lg={colSize}>
                <Overview loopIndex={loopIndex} account={account} />
              </Col>
            ) : (
              ''
            )}
          </Row>

          <Links
            loopIndex={loopIndex}
            accountKey={account.AccountKey.Key}
            accountStatus={account.AccountStatus}
            BrandProductType={account.BrandProductType}
            status={status}
          />
        </>
      </Card>
    );
  }
}

SavingsSummary.propTypes = {
  account: PropTypes.any,
  loopIndex: PropTypes.any,
  mode: PropTypes.any,
  status: PropTypes.number,
};

export default SavingsSummary;
