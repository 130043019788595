import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { FormattedDate } from '@myie/interact-dom';
import DownloadDocument from './DownloadDocument';

class Document extends React.PureComponent {
  render() {
    const { props } = this;

    const {
      document,
      index,
      selectedAccount,
      setDocument,
      documentIdData,
    } = props;

    if (!document) {
      return null;
    }

    return (
      <li data-transaction-id={index}>
        <Row>
          <Col className="col-date" xs={3} md={2}>
            <FormattedDate date={document.Date} format="ll" />
          </Col>
          <Col className="col-name transaction-description" xs={3} md={6}>
            {document.Name}
          </Col>
          <Col className="col-type transaction-description" xs={3} md={2}>
            {document.ExtendedProperties.DisplayType}
          </Col>
          <Col
            className="text-right col-transaction transaction-amount"
            xs={3}
            md={2}
          >
            <DownloadDocument
              document={document}
              selectedAccount={selectedAccount}
              index={index}
              setDocument={setDocument}
              documentIdData={documentIdData}
              downloadedDocument={null}
            />
          </Col>
        </Row>
      </li>
    );
  }
}

Document.propTypes = {
  AccountCurrencyCode: PropTypes.any,
  position: PropTypes.any,
  transaction: PropTypes.any,
  selectedAccount: PropTypes.any,
  document: PropTypes.object,
  index: PropTypes.number,
  setDocument: PropTypes.any,
  documentIdData: PropTypes.object,
};

export default connect(
  null,
  mapDispatchToProps,
)(Document);
