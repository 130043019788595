import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Content, RoundBadge, FormattedCurrency } from '@myie/interact-dom';

class Transaction extends React.Component {
  render() {
    const { props } = this;

    const { transaction, AccountCurrencyCode } = props;
    const { position } = props;
    const imgBackground = function() {
      var imgColours = ['blue'];
      var coloursCount = imgColours.length;
      var color = imgColours[position % coloursCount];
      return color + '-badge align-self-center';
    };

    const character = transaction.Description.substring(0, 1).toUpperCase();

    return (
      <Col
        id={`data-transaction-id-${position}`}
        data-transaction-id={transaction.TransactionIdentifier}
        itemProp={`transactionDate-${transaction.Date}`}
        tag="dd"
        xs={12}
      >
        <Row>
          <Col xs={2} md={1} className="col-icon">
            <RoundBadge className={imgBackground(character)}>
              {character}
            </RoundBadge>
          </Col>
          <Col
            xs={4}
            sm={4}
            md={5}
            className="col-description align-self-center"
          >
            <div className="transaction-description">
              {transaction.Description}
            </div>
          </Col>
          <Col
            xs={3}
            sm={3}
            md={3}
            className="text-right col-transaction align-self-center"
          >
            <small className="transaction-in-out sr-only">
              <Content
                cmsTag={`account-in-out-title`}
                copytext="Amount in/out: "
              />
            </small>
            <div className="transaction-payments">
              <FormattedCurrency
                quantity={transaction.Amount}
                currency={AccountCurrencyCode}
              />
            </div>
          </Col>
          <Col
            xs={3}
            sm={3}
            md={3}
            className="text-right col-transaction align-self-center"
          >
            <small className="transaction-balance sr-only">
              <Content cmsTag={`account-balance-title`} copytext="Balance: " />
            </small>
            <div className="transaction-balance">
              <FormattedCurrency
                quantity={transaction.Balance}
                currency={AccountCurrencyCode}
              />
            </div>
          </Col>
        </Row>
      </Col>
    );
  }
}

Transaction.propTypes = {
  AccountCurrencyCode: PropTypes.any,
  position: PropTypes.any,
  transaction: PropTypes.any,
  accountType: PropTypes.any,
};

export default Transaction;
