import React from 'react';
import { Route } from 'react-router-dom';
import MessagingTwoway from './MessagingTwoway';

const Routes = [
  <Route
    key="/messaging-twoway"
    path="/messaging-twoway"
    component={MessagingTwoway}
  />,
];

export default Routes;
