import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-messaging-twoway';
import { Content, Switch } from '@myie/interact-dom';

class DeleteConfirmation extends React.Component {
  render() {
    const { deleteMessageStatus, viewMessage } = this.props;
    let Conversation;

    if (!deleteMessageStatus) {
      return null;
    }
    if (viewMessage) {
      Conversation = viewMessage.Conversation;
    }

    let alertId;
    if (deleteMessageStatus) {
      switch (deleteMessageStatus.ResponseStatus) {
        case 'Success':
          alertId = 'messaging-twoway-delete-successful';
          break;
        case 'Failed':
          alertId = 'messaging-twoway-delete-request-error';
          break;
        default:
          alertId = 'messaging-twoway-delete-unknown-error';
          break;
      }
    }

    return (
      <>
        <h1>
          <Content
            id="messaging-twoway:deleteMessageSuccess.title"
            copytext="Delete message"
          />
        </h1>
        {deleteMessageStatus && (
          <Alert
            role="alert"
            id={alertId}
            color={
              deleteMessageStatus &&
              deleteMessageStatus.ResponseStatus === 'Success'
                ? 'success'
                : 'danger'
            }
          >
            <Switch
              id="confirmAlert"
              tag="<>"
              value={deleteMessageStatus.ResponseStatus}
              defaultText="An unknown error occured please check your accounts befor retrying."
              contents={{
                Success: {
                  defaultValue:
                    'Your message has been deleted. See details below',
                },
                Failed: {
                  defaultValue:
                    'An error occured while trying to process your request.',
                },
              }}
            />
          </Alert>
        )}
        {Conversation && (
          <Row tag="dl" title="payee details">
            <Col tag="dt" sm={6} md={3}>
              <Content id="subject" copytext="Subject" />
            </Col>
            <Col tag="dd" sm={6} md={9}>
              <Content id="reason" copytext="Re: " />
              {Conversation.Reason}
            </Col>
            <Col tag="dt" sm={6} md={3}>
              <Content id="relatedAccountMsg" copytext="Related account:" />
            </Col>
            <Col tag="dd" sm={6} md={9}>
              {`${Conversation.AccountNumber}${Conversation.SortCode}`}
            </Col>
            <Col tag="dt" sm={6} md={3}>
              <Content id="referenceNumber" copytext="Reference number:" />
            </Col>
            <Col tag="dd" sm={6} md={9}>
              {Conversation.Reference}
            </Col>
          </Row>
        )}
        <div className="form-button-group">
          <Link
            id="messaging-twoway-delete-back-to-link"
            className="btn btn-secondary btn-sm"
            role="button"
            to="/messaging-twoway/view"
          >
            <Content id="backButton" copytext="Back to messages" />
          </Link>
        </div>
      </>
    );
  }
}

DeleteConfirmation.propTypes = {
  deleteMessageStatus: PropTypes.any,
  transactionData: PropTypes.any,
  transfer: PropTypes.any,
  viewMessage: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteConfirmation);
