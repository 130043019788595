/* eslint-disable no-mixed-spaces-and-tabs, prettier/prettier */

import { AccountsMenuItems } from '@myie/interact-accounts-dom'
import { ServicesMenuItems } from '@myie/interact-services-dom'
import { MessagingTwowayMenuItems } from '@myie/interact-messaging-twoway-dom'
import { SavingsServicingMenuItems } from '@myie/interact-savings-servicing-dom'


const RegisteredMenus = []

AccountsMenuItems.forEach(sb => {
	RegisteredMenus.push(sb)
}); 

ServicesMenuItems.forEach(sb => {
	RegisteredMenus.push(sb)
}); 

MessagingTwowayMenuItems.forEach(sb => {
	RegisteredMenus.push(sb)
}); 

SavingsServicingMenuItems.forEach(sb => {
	RegisteredMenus.push(sb)
}); 



export default RegisteredMenus
