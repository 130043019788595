import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Collapse } from 'reactstrap';
import { Icon, Content } from '@myie/interact-dom';

class OurProducts extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  render() {
    return (
      <div className="our-products-container">
        <Button color="products" onClick={this.toggle}>
          <Icon
            name="BoxProduct"
            fill="light-gray-fill"
            width="30"
            aria-hidden="true"
          />
          <Content id="ourProducts-title" copytext="Our products" />
        </Button>
        <Collapse isOpen={this.state.collapse}>
          <Row>
            <Col xs={6}>
              <a href="/savings-account">
                <Content
                  id="ourProducts-savingsAccounts"
                  copytext="Savings account"
                />
              </a>
            </Col>
            <Col xs={6}>
              <a href="/current-account">
                <Content
                  id="ourProducts-currentAccounts"
                  copytext="Current account"
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <a href="/creditcard">
                <Content id="ourProducts-creditCard" copytext="Credit card" />
              </a>
            </Col>
            <Col xs={6}>
              <a href="/loan">
                <Content id="ourProducts-loan" copytext="Loan" />
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <a href="#/mortgage">
                <Content id="ourProducts-mortgage" copytext="Mortgage" />
              </a>
            </Col>
            <Col xs={6}>
              <a href="/insurance">
                <Content id="ourProducts-insurance" copytext="Insurance" />
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <a href="/travel-money">
                <Content id="ourProducts-travelMoney" copytext="Travel Money" />
              </a>
            </Col>
            <Col xs={6}>
              <a href="/cash-isa">
                <Content id="ourProducts-cashISA" copytext="Cash ISA" />
              </a>
            </Col>
          </Row>
        </Collapse>
      </div>
    );
  }
}

OurProducts.propTypes = {
  items: PropTypes.string,
};

export default OurProducts;
