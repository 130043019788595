import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Col, Navbar, Row } from 'reactstrap';
import { Date, Content } from '@myie/interact-dom';
import { Validate } from '@myie/interact';

import moment from 'moment';

class TransactionDateRange extends React.Component {
  constructor(props) {
    super(props);
    const { range } = this.props;

    this.state = {
      form: {
        startDate: {
          rules: {
            title: 'start date',
            stop: true,
            required: {
              message: 'Please select a start date',
            },
            date: {
              format: 'dmy',
              message: 'Please select a valid start date',
            },
            compareStartDate: {
              message: 'Start date must be less than or equal to the end date',
              field: 'endDate',
              comparison: () => {
                return this.state.form.endDate.value;
              },
            },
          },

          value: this.subtractDate(range.From),
        },
        endDate: {
          rules: {
            title: 'end date',
            stop: true,
            required: {
              message: 'Please select an end date',
            },
            date: {
              format: 'dmy',
              message: 'Please select a valid end date',
            },
            compareEndDate: {
              message:
                'End date must be greater than or equal to the start date.',
              field: 'startDate',
              comparison: () => {
                return this.state.form.startDate.value;
              },
            },
          },

          value: this.subtractDate(range.From),
        },
      },
    };
  }

  subtractDate = date => {
    return moment(date).format('DD/MM/YYYY');
  };

  onChange = e => {
    let { name, value } = e.target;
    //value = moment(value).format("DD/MM/YYYY");
    var { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({
      ...this.state,
      form,
    });
  };

  onBlur = e => {
    let { name, value } = e.target;
    //value = moment(value).format("DD/MM/YYYY");
    var { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({
      ...this.state,
      form,
    });
  };

  submit = e => {
    e.preventDefault();
    const { fetchTransactions, toggle, account, setUpdateButton } = this.props;
    let { form } = this.state;
    const from = moment(form.startDate.value, 'DD/MM/YYYY');
    const to = moment(form.endDate.value, 'DD/MM/YYYY');
    const range = {
      To: to,
      From: from,
    };

    form = Validate.form(form);

    this.setState({
      ...this.state,
      form,
    });

    if (!form.approved) {
      return;
    }

    let request = {
      AccountKey: account.AccountKey,
      Range: range,
      ExtendedProperties: {
        FromDate: moment(from).format(moment.HTML5_FMT.DATE),
        ToDate: moment(to).format(moment.HTML5_FMT.DATE),
      },
    };
    setUpdateButton();
    fetchTransactions(request);
    toggle(range);

    form.endDate.value = this.subtractDate(range.To);
    form.startDate.value = this.subtractDate(range.From);
    this.setState({
      ...this.state,
      form,
    });
  };

  render() {
    const { form } = this.state;
    return (
      <Form autoComplete="off" id="date-range" onSubmit={this.submit}>
        <span className="sr-only" id="range-bar">
          <Content
            cmsTag="account-date-range-bar"
            copytext="Transactions range bar"
          />
        </span>
        <Navbar
          color="light"
          aria-labelledby="range-bar"
          id="transaction-date-nav"
        >
          <Row>
            <Col xs={12} sm={6} lg={5}>
              <Date
                cmsTag="account-date-range-start"
                labelClassName="sr-only"
                label="Start of date range"
                id="startDate"
                field="startDate"
                value={form.startDate.value}
                showErrors={true}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.startDate}
                className="mb-2 mb-lg-0 "
              />
            </Col>
            <Col xs={12} sm={6} lg={5}>
              <Date
                cmsTag="account-date-range-end"
                labelClassName="sr-only"
                label="End of date range"
                id="endDate"
                field="endDate"
                value={form.endDate.value}
                showErrors={true}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.endDate}
              />
            </Col>
            <Col xs={12} lg={2}>
              <Button
                size="sm"
                className="float-right mt-1"
                id="transaction-details-submit-current"
                type="submit"
                color="primary"
              >
                <Content
                  cmsTag="account-date-range-update-button"
                  copytext="Update"
                />
              </Button>
            </Col>
          </Row>
        </Navbar>
      </Form>
    );
  }
}

TransactionDateRange.propTypes = {
  className: PropTypes.string,
  account: PropTypes.any,
  fetchTransactions: PropTypes.any,
  range: PropTypes.any,
  toggle: PropTypes.any,
  setUpdateButton: PropTypes.any,
};

export default TransactionDateRange;
