import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

/**
 * Dialog component
 * will display a modal
 */
class Dialog extends React.Component {
  render() {
    const { props } = this;

    const { isOpen, children, id, title, ...rest } = props;
    //Accessibility - Role: Dialog and tabindex is set from <modal >
    return (
      <Modal id={id} isOpen={isOpen} {...rest}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}

Dialog.propTypes = {
  /**
   * Any children of the component
   */
  children: PropTypes.element,
  /**
   * Modal is shown or not
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Dialog ID
   */
  id: PropTypes.string,
  /**
   * Title text
   */
  title: PropTypes.string.isRequired,
};

export default Dialog;
