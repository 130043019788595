import React from 'react';
import { Settings } from '@myie/interact';

class Callback extends React.Component {
  signinRedirectCallback = () => {
    Settings.userManager
      .signinRedirectCallback()
      .then(user => {
        this.user = user;
        //Session.set(user.id_token, user.profile);
        window.sessionStorage.setItem('mgr_id_token', user.id_token);
        window.sessionStorage.setItem('mgr_access_token', user.access_token);
        window.sessionStorage.setItem('mgr_refresh_token', user.refresh_token);

        this.navigateToScreen();
      })
      .catch(err => {
        //console.log(err);
        if (err.message === 'No state in response') {
          Settings.userManager.signinRedirect({}); //TODO: If the sign in process start from Oauth provider web site then getting state error then need to be call authorise again because of client secret
        }
      });
  };

  navigateToScreen = () => {
    const redirectUri = localStorage.getItem('redirectUri')
      ? localStorage.getItem('redirectUri')
      : '/';

    window.location.replace(redirectUri);
  };

  render() {
    this.signinRedirectCallback();
    return <span>Loading...</span>;
  }
}

export default Callback;
