import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Content } from '@myie/interact-dom';
import { Link } from 'react-router-dom';

class Success extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }

  render() {
    return (
      <div id="forgotten-password-success">
        <h1 id="forgotten-password-success-title">
          <Content id="title" copytext="Request Successful" />
        </h1>

        <p>
          <Content
            id="forgotten-password-success-description"
            copytext="Your security details have been reset"
          />
        </p>

        <div className="form-button-group">
          <Link
            id="forgotten-password-continue"
            className="btn btn-primary"
            role="button"
            to="/accounts"
          >
            <Content id="doneButton" copytext="Continue" />
          </Link>
        </div>
      </div>
    );
  }
}

Success.propTypes = {
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Success);
