import React from 'react';
import { Redirect } from 'react-router-dom';
import { Session } from '@myie/interact';

const Home = () => {
  return (
    <>
      {Session.isAuthenticated() ? (
        <Redirect to="/accounts" />
      ) : (
        <Redirect to="/sign-in" />
      )}
    </>
  );
};

export default Home;
