import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';
import { Alert } from 'reactstrap';
import { Content, Markdown } from '@myie/interact-dom';
import { Link } from 'react-router-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class ExceededAttempts extends React.Component {
  goBackHome = e => {
    const { finishTwoFactor } = this.props;
    e.preventDefault();
    finishTwoFactor();
  };

  render() {
    return (
      <>
        <Content
          tag="h1"
          cmsTag="Two-factor:Exceeded-attempts:h1"
          copytext="Request Cancelled"
        />
        <Alert color="danger" id="alert-exceeded-attempts" role="alert">
          <Markdown
            className="font-weight-bold"
            markdown={`\nYou have entered an incorrect <abbr title='one time passcode'> OTP </abbr> too many times.
            \nFor security reasons we have cancelled your request.`}
          />
          <Markdown
            id="twofa-ContactDesc"
            template={{
              markdown: {
                phone: CONTACTS.phone,
                phoneLink: CONTACTS.phoneLink,
              },
            }}
            markdown={`\nPlease try again, or contact us on <a href="tel:$[phoneLink]">$[phone]</a> so we can help you.`}
          />
        </Alert>
        <div className="form-button-group">
          <Link
            id="exceeded-attempts-cancel"
            className="btn btn-primary"
            role="button"
            to="/sign-in/step-1"
          >
            <Content
              cmsTag="GLOBAL:Back-to-sign-in"
              copytext="Back to sign in"
            />
          </Link>
        </div>
      </>
    );
  }
}

ExceededAttempts.propTypes = {
  finishTwoFactor: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExceededAttempts);
