import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute, NoMatch } from '@myie/interact-dom';
import AccountsList from './AccountsList';
import AccountDetailsTemplate from './AccountDetailsTemplate';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-accounts';
import { Alert } from 'reactstrap';
import NominatedAccount from './NominatedAccount';

class Accounts extends React.Component {
  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  render() {
    const { error } = this.props;
    const re = new RegExp('^/accounts[/]{0,1}$');
    if (this.props.location.pathname.match(re)) {
      return <Redirect to="/accounts/list" />;
    }
    return (
      <div id="accounts">
        {error ? (
          <Alert color="danger" role="alert">
            {error}
          </Alert>
        ) : (
          ''
        )}
        <Switch>
          <PrivateRoute
            exact
            path="/accounts/list"
            render={routeProps => <AccountsList {...routeProps} />}
          />
          <PrivateRoute
            path="/accounts/details/:id"
            component={AccountDetailsTemplate}
          />
          <PrivateRoute
            path="/accounts/nominated-account/:id"
            component={NominatedAccount}
          />
          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

Accounts.propTypes = {
  error: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  location: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Accounts);
