import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Alert } from 'reactstrap';
import { FormattedCurrency, Content } from '@myie/interact-dom';
import PropTypes from 'prop-types';

const SuccessfulPayment = props => {
  const { account, form, referrer = '/accounts', payment } = props;
  return (
    <div id="paysavings-success">
      <Alert color="success" role="alert">
        <Content
          id="successfulPayment-alert"
          copytext="Payment completed successfully"
        />
      </Alert>
      <Row tag="dl" title="card details">
        <Col tag="dt" sm={6} md={3}>
          <Content id="successfulPayment-fromCard" copytext="From" />
        </Col>
        <Col tag="dd" sm={6} md={9}>
          <Content
            id="successfulPayment-endingIn"
            template={{
              copytext: {
                cardNumber: form.cardNumber.value.substr(-4),
              },
            }}
            copytext="ending in $[cardNumber]"
          />
        </Col>
        <Col tag="dt" sm={6} md={3}>
          To
        </Col>
        <Col tag="dd" sm={6} md={9}>
          {account.AccountName}{' '}
          <FormattedCurrency
            quantity={account.Balance}
            currency={account.CurrencyCode}
          />
        </Col>
        <Col tag="dt" sm={6} md={3}>
          <Content id="successfulPayment-paymentAmount" copytext="Amount" />
        </Col>
        <Col tag="dd" sm={6} md={9}>
          <FormattedCurrency
            quantity={form.amount.value}
            currency={account.CurrencyCode}
          />
        </Col>
        <Col tag="dt" sm={6} md={3}>
          <Content
            id="successfulPayment-paymentReference"
            copytext="Payment reference"
          />
        </Col>
        <Col tag="dd" sm={6} md={9}>
          {payment.PaymentReference}
        </Col>
      </Row>
      <div className="form-button-group">
        <Link className="btn btn-secondary" role="button" to={referrer}>
          <Content
            id="successfulPayment-backButton"
            copytext="Back to payments"
          />
        </Link>
      </div>
    </div>
  );
};

SuccessfulPayment.propTypes = {
  account: PropTypes.any,
  form: PropTypes.any,
  info: PropTypes.any,
  payment: PropTypes.any,
  referrer: PropTypes.any,
};

export default SuccessfulPayment;
