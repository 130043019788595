import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = '#fff', width = '119.66407' }) => (
  <svg
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 82.97 77.45"
  >
    <path
      fill={fill}
      d="M91.5 30.41L63.87 12.69 50 22.17l-13.84-9.48L8.53 30.41l15.21 9.28L8.53 49l14.39 9.2v14.74L49 89.48l1 .67 1.05-.67 26.1-16.54V58.13L91.5 49l-15.21-9.31zM49 87.11L24.92 71.84V59.48l11.24 7.21L49 57.9zm1-32.32l-22.84-15.1L50 24.6l22.86 15.1zm25.2 17.05L51.12 87.11V58l12.76 8.73 11.34-7.27z"
      transform="translate(-8.53 -12.69)"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
};

export default SVG;
