import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'info-fill', width = '33.251', height = '33.251' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 33.251 33.251"
    viewBox="0 0 33.251 33.251"
  >
    <path
      className={fill}
      d="M16.625 0C7.443 0 0 7.443 0 16.625S7.443 33.25 16.625 33.25 33.25 25.807 33.25 16.625 25.808 0 16.625 0zm1.709 26.833H15V13.167h3.334v13.666zm0-16.291H15V8.125h3.334v2.417z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
