import SummarySidebar from './SummarySideBar';
import DetailSidebar from './DetailSideBar';

const AccountsSidebars = [
  {
    url: '/accounts/list',
    Sidebar: SummarySidebar,
  },
  {
    url: '/accounts/details/',
    Sidebar: DetailSidebar,
  },
];

export default AccountsSidebars;
