import React from 'react';
import { DatePicker } from '@myie/interact-dom';
import { Row, Col, FormGroup } from 'reactstrap';

/** DatePicker example */
class DateExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  onChange = e => {
    const { value } = e.target;
    this.setState({ value });
  };

  render() {
    return (
      <Row>
        <Col md={6}>
          <FormGroup>
            <DatePicker
              label="Tell us your date of birth"
              id="b_day"
              field="b_day"
              showErrors={true}
              onChange={this.onChange}
              onBlur={this.onChange}
              value={this.state.value}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default DateExample;
