import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

class PublicRoute extends React.Component {
  render() {
    const { component: Component, ...rest } = this.props;

    return <Route {...rest} render={props => <Component {...props} />} />;
  }
}

PublicRoute.propTypes = {
  component: PropTypes.any,
};

export default PublicRoute;
