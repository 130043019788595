import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';
import { Content, Markdown } from '@myie/interact-dom';
import { Link } from 'react-router-dom';
class ExpiredCode extends React.Component {
  cancel = e => {
    const { finishTwoFactor } = this.props;
    e.preventDefault();
    finishTwoFactor();
  };

  render() {
    return (
      <>
        <Content
          tag="h1"
          cmsTag="Two-factor:expired-code:h1"
          copytext="OTP (one time passcode) expired"
        />
        <Markdown
          cmsTag="Two-factor:expired-code:section-1"
          markdown={`\nThe <abbr title='One Time Passcode'> OTP </abbr> you entered has expired. Please start the session again by clicking the button below.`}
        />
        <div className="form-button-group">
          <Link className="btn btn-primary" role="button" to="/sign-in/step-1">
            <Content
              cmsTag="GLOBAL:Back-to-sign-in"
              copytext="Back to sign in"
            />
          </Link>
        </div>
      </>
    );
  }
}

ExpiredCode.propTypes = {
  error: PropTypes.any,
  finishTwoFactor: PropTypes.any,
  reSet: PropTypes.any,
  failed: PropTypes.any,
  challenge: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpiredCode);
