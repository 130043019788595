import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Content, FormattedDate, FormattedCurrency } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class NoticeToWithdraw extends Component {
  onCancelHandler = () => {
    const { onCancel, loopIndex } = this.props;
    onCancel(loopIndex);
  };

  render() {
    const {
      withdrawal,
      index,
      accountKey,
      account: {
        CurrencyCode,
        ExtendedProperties: {
          NominatedAccountNumber,
          NominatedSortCode,
          NominatedAccountName,
        } = {},
      } = {},
      loopIndex,
    } = this.props;

    const { ExtendedProperties: { Cancellable } = {} } = withdrawal;

    return (
      <div className="notice-to-withdraw-summary">
        <Row>
          <Col sm={12} lg={8}>
            <Row
              tag="dl"
              title={`Account ${NominatedAccountNumber} details summary`}
            >
              <Col tag="dt" xs={12} sm={6} md={4}>
                <Content id="label-to" copytext="To" />
              </Col>
              <Col tag="dd" xs={12} sm={6} md={8}>
                {NominatedAccountName}
                <span className="fw-normal d-block">{`${NominatedSortCode} ${NominatedAccountNumber} `}</span>
              </Col>
              <Col tag="dt" xs={12} sm={6} md={4}>
                <Content id="label-amount" copytext="Amount" />
              </Col>
              <Col tag="dd" xs={12} sm={6} md={8}>
                {withdrawal.Amount !== 0 ? (
                  <FormattedCurrency
                    quantity={withdrawal.Amount}
                    currency={withdrawal.CurrencyCode || CurrencyCode}
                  />
                ) : (
                  <Content id="all-amount" copytext="All" />
                )}
              </Col>
              <Col tag="dt" xs={12} sm={6} md={4}>
                <Content id="label-withdraw-date" copytext="Withdraw date" />
              </Col>
              <Col tag="dd" xs={12} sm={6} md={8}>
                <FormattedDate date={withdrawal.Date} format="LL" />
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            {Cancellable && (
              <Link
                id={`cancel-notice-${index}`}
                className={`btn btn-primary`}
                role="button"
                to={`/notice-to-withdraw/cancel/${accountKey}/${loopIndex}`}
              >
                <Content
                  id="savings-notice-to-withdraw-linkk"
                  copytext="Cancel Notice"
                />
                <span className="sr-only"> {loopIndex}</span>
              </Link>
            )}
          </Col>
        </Row>
        <hr />
      </div>
    );
  }
}

NoticeToWithdraw.propTypes = {
  withdrawal: PropTypes.any,
  account: PropTypes.any,
  onCancel: PropTypes.func,
  loopIndex: PropTypes.any,
  index: PropTypes.any,
  accountKey: PropTypes.any,
};

export default NoticeToWithdraw;
