import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Content } from '@myie/interact-dom';

class PaymentBreadcrumbs extends React.Component {
  render() {
    const { stage } = this.props;

    return (
      <>
        <div className="breadcrumb-container">
          <Breadcrumb tag="nav">
            <BreadcrumbItem active={stage === 'MakePayment'}>
              <Content id="bc-step1" copytext="1 Transfer details" />
            </BreadcrumbItem>
            <BreadcrumbItem active={stage === 'ReviewPayment'}>
              <Content id="bc-step2" copytext="2 Review" />
            </BreadcrumbItem>
            <BreadcrumbItem active={stage === 'CompletePayment'}>
              <Content id="bc-step3" copytext="3 Verification" />
            </BreadcrumbItem>
            <BreadcrumbItem active={stage === 'SuccessfulPayment'}>
              <Content id="bc-step4" copytext="4 Confirmation" />
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="clearfix"></div>
      </>
    );
  }
}

PaymentBreadcrumbs.propTypes = {
  stage: PropTypes.any,
};

export default PaymentBreadcrumbs;
