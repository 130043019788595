import ConversationSidebar from './Conversation/Sidebar';
import NewMessageSidebar from './New/Sidebar';
import ViewMessageSidebar from './View/Sidebar';
import ReplyMessageSidebar from './Conversation/Delete/Sidebar';
import DeleteMessageSidebar from './Conversation/Reply/Sidebar';

const MessagingTwowaySidebars = [
  { url: '/messaging-twoway/message/view/', Sidebar: ConversationSidebar },
  { url: '/messaging-twoway/new', Sidebar: NewMessageSidebar },
  { url: '/messaging-twoway/view', Sidebar: ViewMessageSidebar },
  { url: '/messaging-twoway/message/reply/', Sidebar: ReplyMessageSidebar },
  {
    url: '/messaging-twoway/message/delete/',
    Sidebar: DeleteMessageSidebar,
  },
];

export default MessagingTwowaySidebars;
