import React from 'react';
import Navigation from './Navigation';
import ComponentPage from './ComponentPage';
import componentData from './componentData';
//import '../../../node_modules/highlight.js/styles/ocean.css';
import './index.css';

export default class Docs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      route: window.location.hash.substr(1),
    };
  }

  componentDidMount() {
    window.addEventListener('hashchange', () => {
      this.setState({ route: window.location.hash.substr(1) });
    });
  }

  render() {
    const { route } = this.state;
    const component = route
      ? componentData.filter(
          component =>
            (component && component.name ? component.name : '') === route,
        )[0]
      : componentData[0];
    let test = componentData.map(component => {
      if (component && component.name) {
        return component.name;
      }
      return null;
    });

    return (
      <div className="content-background">
        <div className="content-container clearfix mb-2 ml-5">
          <Navigation components={test} />
          <ComponentPage component={component} />
        </div>
      </div>
    );
  }
}
