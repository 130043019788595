import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Session } from '@myie/interact';
import { mapDispatchToProps } from '@myie/interact-two-factor';
import SelectDestination from './SelectDestination';
import Failed from './Failed';
import ExpiredCode from './ExpiredCode';
import ExpiredChallenge from './ExpiredChallenge';
import ExceededAttempts from './ExceededAttempts';
import Error from './Error';
import Code from './Code';
import ExceededRequests from './ExceededRequests';
import { mapDispatchToProps as mapDispatchToProps_auth } from '@myie/interact-authentication';
import SelectForgotttenDestination from './SelectDestinationAuth';
import CodeAuth from './CodeAuth';

class TwoFactorFlow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDestination: null,
      newCodeRequested: false,
      localStatus: false,
      count: 0,
      isForgottenPasswordDetails: false,
    };
    this.reSetTwoFactor();
  }

  static getDerivedStateFromProps = nextProps => {
    const { status } = nextProps;

    if (status === 'FAILED') {
      return {
        localStatus: 'FAILED',
      };
    }

    return null;
  };

  componentDidMount() {
    const { forgottenPasswordDetails, resetForgottenPassword } = this.props;
    if (forgottenPasswordDetails) {
      this.setState({ ...this.state, isForgottenPasswordDetails: true }, () => {
        resetForgottenPassword();
      });
    }
  }

  addCount = () => {
    let { count } = this.state;
    const { status } = this.props;
    if (status !== 'FAILED') {
      return {
        count: 1,
      };
    } else {
      this.setState({
        ...this.state,
        count: count + 1,
      });
    }
  };

  setDestination = selectedDestination => {
    this.setState({ ...this.state, selectedDestination });
  };

  setCodeResendStatus = newCodeRequested => {
    this.setState({ ...this.state, newCodeRequested });
  };
  reSetTwoFactor = () => {
    const { resetDestinations, resetChallenge } = this.props;
    resetDestinations();
    resetChallenge();
  };

  reSet = () => {
    this.setState({ ...this.state, selectedDestination: null });
    this.reSetTwoFactor();
  };

  render() {
    const { status, challenge = {} } = this.props;
    const {
      selectedDestination,
      newCodeRequested,
      localStatus,
      time,
      count,
      addCounter,
    } = this.state;
    const isAuthenticated = Session.isAuthenticated();

    if (localStatus === 'FAILED' && status !== 'EXCEEDED') {
      if (this.state.isForgottenPasswordDetails) {
        return (
          <CodeAuth
            selectedDestination={selectedDestination}
            retry={this.reSet}
            time={time}
            count={count}
            addCount={this.addCount}
            addCounter={addCounter}
            setCodeResendStatus={this.setCodeResendStatus}
            failed={true}
          />
        );
      } else {
        return (
          <Code
            selectedDestination={selectedDestination}
            retry={this.reSet}
            time={time}
            count={count}
            addCount={this.addCount}
            addCounter={addCounter}
            setCodeResendStatus={this.setCodeResendStatus}
            failed={true}
          />
        );
      }
    }
    switch (status) {
      case 'EXPIRED_CODE':
        return (
          <ExpiredCode
            setDestination={this.setDestination}
            selectedDestination={selectedDestination}
            challenge={challenge ? challenge.ResponseStatus : null}
            status={status}
            retry={this.reSet}
            failed={false}
            isAuthenticated={isAuthenticated}
          />
        );
      case 'EXPIRED_CHALLENGE':
        return (
          <ExpiredChallenge
            setDestination={this.setDestination}
            selectedDestination={selectedDestination}
            challenge={challenge ? challenge.ResponseStatus : null}
            status={status}
            retry={this.reSet}
            failed={false}
            isAuthenticated={isAuthenticated}
          />
        );
      case 'EXCEEDED':
        return <ExceededAttempts isAuthenticated={isAuthenticated} />;
      default:
    }
    if (challenge) {
      switch (challenge.ResponseStatus) {
        case 'Success':
          if (this.state.isForgottenPasswordDetails) {
            return (
              <CodeAuth
                selectedDestination={selectedDestination}
                setCodeResendStatus={this.setCodeResendStatus}
                retry={this.reSet}
                addCount={this.addCount}
              />
            );
          } else {
            return (
              <Code
                selectedDestination={selectedDestination}
                setCodeResendStatus={this.setCodeResendStatus}
                retry={this.reSet}
                addCount={this.addCount}
              />
            );
          }
        case 'Failed':
          return <Failed retry={this.reSet} />;
        case 'ExceededMaximumActivationCodeRequests':
          return <ExceededRequests isAuthenticated={isAuthenticated} />;
        default:
      }
    }
    if (!newCodeRequested) {
      switch (status) {
        case 'ISSUED':
          if (this.state.isForgottenPasswordDetails) {
            return (
              <SelectForgotttenDestination
                setDestination={this.setDestination}
              />
            );
          } else {
            return <SelectDestination setDestination={this.setDestination} />;
          }
        default:
          return <Error />;
      }
    } else {
      if (this.state.isForgottenPasswordDetails) {
        return (
          <CodeAuth
            selectedDestination={selectedDestination}
            setCodeResendStatus={this.setCodeResendStatus}
            retry={this.reSet}
            addCount={this.addCount}
          />
        );
      } else {
        return (
          <Code
            selectedDestination={selectedDestination}
            setCodeResendStatus={this.setCodeResendStatus}
            retry={this.reSet}
            addCount={this.addCount}
          />
        );
      }
    }
  }
}

TwoFactorFlow.propTypes = {
  challenge: PropTypes.object,
  destinations: PropTypes.any,
  resetChallenge: PropTypes.any,
  resetDestinations: PropTypes.any,
  status: PropTypes.any,
  forgottenPasswordDetails: PropTypes.any,
  resetForgottenPassword: PropTypes.any,
};

const mapStateToProps = state => {
  const { twoFactorDefinition, authenticationDefinition } = state;
  return {
    ...twoFactorDefinition,
    ...authenticationDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps,
    ...mapDispatchToProps_auth,
  },
)(TwoFactorFlow);
