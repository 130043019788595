import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '35.901', height = '35.907' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    version="1.1"
    viewBox="-3.7 -10 38.2 42.9"
    width={width}
    height={height}
  >
    <path
      className="secondary-fill"
      d="M14.9 16.3c0-1.2 1-2.2 2.2-2.2h1.8c0-.5-.1-.9-.1-1.3.9-1.3 1.5-3 2.2-4.8 1.6-1 2.5-2.3 2.5-3.8 0-.7-.2-1.4-.7-2.1v-3c0-4.9-3.9-8.9-8.7-8.9-4.9 0-8.7 4-8.7 8.9v3c-.7.7-.8 1.4-.8 2.1 0 1.5 1 2.8 2.5 3.8.7 1.8 1.2 3.5 2.1 4.8v1.9c0 1.8-1.2 2.3-2.9 2.6 0 0-3.1.9-4.1 1.1-3.3.8-5.6 4-5.6 7.6v2.4H15V16.3z"
    />
    <path
      className={fill}
      d="M32 15.5H18.5c-1.2 0-2.2 1-2.2 2.2v12.7c0 1.2 1 2.2 2.2 2.2H32c1.2 0 2.2-1 2.2-2.2V17.7c0-1.2-1-2.2-2.2-2.2zm0 16H18.5c-.6 0-1.1-.5-1.1-1.1V17.7c0-.6.5-1.1 1.1-1.1H32c.6 0 1.1.5 1.1 1.1v12.7c0 .6-.5 1.1-1.1 1.1z"
    />
    <path className={fill} d="M19.6 18.9H30.8V20.299999999999997H19.6z" />
    <path className={fill} d="M19.6 21.9H30.8V23.299999999999997H19.6z" />
    <path className={fill} d="M19.7 25H30.799999999999997V26.4H19.7z" />
    <path className={fill} d="M19.7 28H30.799999999999997V29.4H19.7z" />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
