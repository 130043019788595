import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Content, Markdown, AppMeta } from '@myie/interact-dom';

class UpdateSecurityDetailsSuccess extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }

  render() {
    return (
      <div id="update-security-details-success">
        <AppMeta
          id="meta-data"
          stage="child"
          title="Update security details success"
          metaDescription="Update security details success"
        />
        <h1 id="update-security-details-success-title">
          <Content id="title" copytext="Security details updated" />
        </h1>
        <Markdown
          id="description"
          markdown={`You have changed your security credentials.
          
          \nPlease ensure that you keep a secure record of your updated details.`}
        />
        <div className="form-button-group">
          <Link
            id="security-details-done"
            className="btn btn-primary"
            role="button"
            to="/accounts"
          >
            <Content id="doneButton" copytext="Back to Accounts" />
          </Link>
        </div>
      </div>
    );
  }
}

UpdateSecurityDetailsSuccess.propTypes = {
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateSecurityDetailsSuccess);
