import { PureComponent } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

/**
 * ScrollToTop component
 * The given content will be scrolled to the top
 */
class ScrollToTop extends PureComponent {
  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    if (this.props.refreshMe) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

ScrollToTop.propTypes = {
  /**
   * Any children of the component
   */
  children: PropTypes.element.isRequired,
  /**
   * If true the content will be forcefully scrolled to the top
   */
  refreshMe: PropTypes.bool,
  /**
   * Browser address
   */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(ScrollToTop);
