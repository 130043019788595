export const returnPath = origin => {
  switch (origin) {
    case 'editUserDetails':
      return '/personal-details/edit';
    case 'editSecurityDetails':
      return '/details/index';
    case 'transferIntoNewAccount':
      return '/transfer-to-another/create/moveMoney';
    case 'accountsList':
      return '/accounts/list';
    case 'EditnominatedAccountDetails':
    case 'nominatedAccountDetails':
      return '/nominated-account-for-withdrawals';
    default:
      return '';
  }
};
