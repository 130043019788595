import { Connection, Settings } from '@myie/interact';
import {
  CREATE_LOGIN_REQUEST,
  CREATE_LOGIN_SUCCESS,
  CREATE_LOGIN_FAILURE,
} from './loginTypes';
import {
  UPDATE_LOGIN_REQUEST,
  UPDATE_LOGIN_SUCCESS,
  UPDATE_LOGIN_FAILURE,
} from './loginTypes';
import { SET_LOGIN_REFERRER, RESET_LOGIN } from './loginTypes';

export const resetLogin = () => ({
  type: RESET_LOGIN,
});

export const setReferrer = (
  referrer = Settings.loggedInPage || { pathname: '/accounts' },
) => ({
  type: SET_LOGIN_REFERRER,
  referrer: referrer,
});

export const createLoginRequest = () => ({
  type: CREATE_LOGIN_REQUEST,
  isFetching: true,
  error: null,
});

export const createLoginFailed = error => ({
  type: CREATE_LOGIN_FAILURE,
  isFetching: false,
  error: error.message,
});

export const createLoginReceive = json => ({
  type: CREATE_LOGIN_SUCCESS,
  login: json,
  isFetching: false,
  error: null,
});

export const updateLoginRequest = () => ({
  type: UPDATE_LOGIN_REQUEST,
  isFetching: true,
  error: null,
});

export const updateLoginFailed = error => ({
  type: UPDATE_LOGIN_FAILURE,
  isFetching: false,
  error: error.message,
});

export const updateLoginReceive = json => ({
  type: UPDATE_LOGIN_SUCCESS,
  login: json,
  isFetching: false,
  error: null,
});

export const createLogin = request => dispatch => {
  dispatch(createLoginRequest());
  return fetch(
    Connection.baseUrl('webauthentication') + `v2.0/user/sign-in/create`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(createLoginReceive(json)))
    .catch(error => dispatch(createLoginFailed(error)));
};

export const updateLogin = request => dispatch => {
  dispatch(updateLoginRequest());
  return fetch(
    Connection.baseUrl('webauthentication') + `v2.0/user/sign-in/update`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(updateLoginReceive(json)))
    .catch(error => dispatch(updateLoginFailed(error)));
};
