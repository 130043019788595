import SummaryMenuItems from './SummaryMenuItems';
import { URLS } from '@myie/interact-local-dom';

const SavingsServicingMenuItems = [
  {
    url: URLS.ShortApply,
    menuItem: SummaryMenuItems,
  },
];

export default SavingsServicingMenuItems;
