import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '32.986', height = '34.859' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 32.986 34.859"
    viewBox="0 0 32.986 34.859"
  >
    <path
      className={fill}
      d="M11.987 25.859v7.846c0 1.076.577 1.154 1.652 1.154h17.996c1.076 0 1.352-.078 1.352-1.154v-7.846h-21zM32.986 23.859v-2.012c0-1.076-.275-1.988-1.352-1.988H13.639c-1.075 0-1.652.912-1.652 1.988v2.012h20.999z"
    />
    <path
      className={fill}
      d="M12.474.14c-5.662 0-9.595 3.55-9.595 9.308 0 1.583.479 3.214 1.007 4.894h-.575l-3.214.24v3.31h4.798c.096.672.192 1.296.192 1.967 0 3.503-2.015 6.43-5.086 7.965v3.406h9.944v-4.701H7.437v-.192c1.727-1.824 2.542-3.6 2.542-6.43 0-.719-.048-1.343-.144-2.015l6.541-.016v-3.534H9.02c-.432-1.536-.815-3.023-.815-4.605 0-3.215 1.774-5.086 4.75-5.086 1.823 0 3.119.863 4.317 2.255l3.07-3.022C18.424 1.579 15.977.14 12.474.14z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
