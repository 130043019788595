import React from 'react';
import { Link } from 'react-router-dom';
import { Content } from '@myie/interact-dom';

class SummaryMenuItems extends React.Component {
  render() {
    return (
      <Link id="services-menu-item" className="nav-link" to="/services/">
        <Content cmsTag="services:menuItem" copytext="My Details" />
      </Link>
    );
  }
}

export default SummaryMenuItems;
