import React from 'react';

import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Content, Markdown, ContactNumber } from '@myie/interact-dom';

AccessDenied.propTypes = {};

function AccessDenied() {
  return (
    <div id="registration-blocked">
      <h1 id="registration-blocked-title">
        <Content cmsTag="title" copytext="Access unavailable" />
      </h1>
      <Alert color="danger" role="alert">
        <Markdown
          cmsTag="blockedText-top"
          markdown={`\nIn order to register for online servicing you must be a signatory if an account is held.`}
        />
        <p>
          <Content
            cmsTag="blocked-contact-details"
            copytext="Please contact us on "
          />
          <ContactNumber />
          <Markdown
            cmsTag="blockedText-bottom"
            markdown=" and we can help you."
          />
        </p>
      </Alert>
      <div className="form-button-group">
        <Link
          id="registration-blocked-home-button"
          className="btn btn-primary"
          role="button"
          to="/"
        >
          <Content cmsTag="homeButton" copytext="Home" />
        </Link>
      </div>
    </div>
  );
}

export default AccessDenied;
