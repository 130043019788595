import ccbInterestRateDefinition from './reducers/interestRateDefinition';
import ccbDocumentsDefinition from './reducers/documentsDefinition';
import maturityDefinition from './reducers/maturityDefinition';

const accountsDefinition = (state = {}, action) => {
  var newState = state;
  newState = ccbInterestRateDefinition(newState, action);
  newState = ccbDocumentsDefinition(newState, action);
  newState = maturityDefinition(newState, action);
  return newState;
};

export default accountsDefinition;
