import React, { Component, Fragment } from 'react';
import { mapDispatchToProps } from '@myie/interact-ccb-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Content, LinkTag } from '@myie/interact-dom';
import { Utility } from '@myie/interact';
import { Card, CardHeader, CardBody, CardFooter, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
class ApplyAccountSummaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getProducts = products => {
    let list = [];
    if (products && products.length > 0) {
      const sortedProductsList = this.sortProducts(products);
      sortedProductsList.forEach((product, index) => {
        if (
          product.Display_For_Short_Apply === 'Y' &&
          product.Product_Status === 'A'
        )
          list.push(
            <Card
              key={product.Product_Code}
              className="apply-card"
              color="dark"
            >
              <CardHeader>
                <h2>
                  <span className="sr-only">
                    <Content cmsTag="GLOBAL:Option-" copytext="Option " />
                    {index + 1}:{' '}
                  </span>
                  {product.Product_Name}
                </h2>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" sm="12" md="6">
                    <h3 className="h6" id={`apply-list-${index}`}>
                      <Content
                        cmsTag="Savings-servicing:Shared:Apply-account-summary-component:h3-1"
                        copytext="To apply you must:"
                      />
                    </h3>
                    <ul
                      className="bullet-list"
                      aria-labelledby={`apply-list-${index}`}
                    >
                      {product?.To_apply_you_must_list.map((item, index) => {
                        if (item) {
                          return <li key={index}>{item}</li>;
                        }
                      })}
                    </ul>
                  </Col>
                  <Col xs="12" sm="12" md="6">
                    <h3 className="h6" id={`features-list-${index}`}>
                      <Content
                        cmsTag="Savings-servicing:Shared:Apply-account-summary-component:h3-2"
                        copytext="Key facts:"
                      />
                    </h3>
                    <ul
                      className="bullet-list"
                      aria-labelledby={`features-list-${index}`}
                    >
                      {product?.Key_facts_list.map((item, index) => {
                        if (item) {
                          return <li key={index}>{item}</li>;
                        }
                      })}
                    </ul>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Link
                  className="btn btn-primary apply-now-link"
                  role="button"
                  to={`/short-apply/index/${Utility.hexEncode(
                    product.Product_Code,
                  )}`}
                >
                  <Content cmsTag="GLOBAL:Apply-now" copytext="Apply now" />
                </Link>
                <LinkTag
                  className="btn btn-secondary product-summary-button"
                  target="_blank"
                  role="button"
                  href={product.Summary_box_url}
                >
                  <Fragment>
                    <Content
                      cmsTag="Savings-servicing:Shared:Apply-account-summary-component:Product-summary-box"
                      copytext="Product Summary box"
                    />
                    <span className="sr-only">
                      <Content
                        id="productSummaryLink"
                        copytext=" (opens in a new tab)"
                        cmsTag="GLOBAL:-opens-in-a-new-tab"
                      />
                    </span>
                  </Fragment>
                </LinkTag>
              </CardFooter>
            </Card>,
          );
      });
    }

    return list;
  };

  sortProducts(list = []) {
    let sortedList = orderBy(list, 'Sort_order', 'asc');
    return sortedList;
  }

  render() {
    const { DepositProducts = [] } = this.props;

    return <React.Fragment>{this.getProducts(DepositProducts)}</React.Fragment>;
  }
}

ApplyAccountSummaryComponent.propTypes = {
  match: PropTypes.any,
  DepositProducts: PropTypes.any,
  page: PropTypes.string,
};

const mapStateToProps = state => {
  const { customccbSavingsServicingDefinition } = state;
  return {
    ...customccbSavingsServicingDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplyAccountSummaryComponent);
