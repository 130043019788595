import React from 'react';
import PropTypes from 'prop-types';
import { Check, Content } from '@myie/interact-dom';
import { Validate } from '@myie/interact';

class ContactPreferences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      contactOptions: null,
      submitForm: {},
      form: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      contactOptions: nextProps.contactOptions
        ? nextProps.contactOptions.Options
        : null,
    };
  }

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    if (Object.hasOwnProperty.call(form, name)) {
      form = Validate.input(name, value, form);
    }
    this.setState({
      ...this.state,
      form,
    });
  };

  componentDidMount() {
    this.setState({ contactOptions: null });
  }

  onChangeContactOptions = e => {
    const { name, value } = e.target;
    const { contactOptions } = this.state;
    const { saveDataContactOptions } = this.props;
    let newContactOptions = contactOptions;
    newContactOptions.forEach(Option => {
      if (Option.Type === name) {
        Option.Value = !Option.Value;
      }
    });
    saveDataContactOptions(name, value);
    this.setState({ contactOptions: newContactOptions });
  };

  onBlur = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    if (Object.hasOwnProperty.call(form, name) && e.target.value) {
      form = Validate.input(name, value, form, true);
    }
    this.setState({
      ...this.state,
      form,
    });
  };

  submit = e => {
    e.preventDefault();
  };

  getContactOptionLabels = contactOptions => {
    const label = [
      {
        Type: 'Email',
        name: 'Email',
      },
      {
        Type: 'Mobile',
        name: 'Mobile',
      },
      {
        Type: 'TextMessage',
        name: 'Text Messages (SMS)',
      },
      {
        Type: 'Post',
        name: 'Post',
      },
      {
        Type: 'OffersFromTheBank',
        name: 'Bank related offers',
      },
      {
        Type: 'OffersFromSelectedPartners',
        name: 'Third party offers',
      },
    ];

    let contactListOption = [];
    let otherOptions = [];
    contactOptions.forEach(item => {
      let lebelItem = label.find(label => label.Type === item.Type);
      item.name = lebelItem.name;
      if (
        item.Type === 'OffersFromTheBank' ||
        item.Type === 'OffersFromSelectedPartners'
      ) {
        otherOptions.push(item);
      } else {
        contactListOption.push(item);
      }
    });

    return {
      contactListOption: contactListOption,
      otherOptions: otherOptions,
    };
  };

  render() {
    const { contactOptions } = this.state;
    if (!contactOptions) {
      return null;
    }
    const newOptions = this.getContactOptionLabels(contactOptions);
    return (
      <>
        <h2>
          <Content
            id="contact-preferences.title"
            copytext="Contact preferences"
          />
        </h2>

        <fieldset>
          <legend className="h3">
            <Content
              id="contact-preferences.description"
              copytext={
                'Please select the types of message you are willing to receive from us.'
              }
            />
          </legend>
          {newOptions.otherOptions.map(item => {
            return (
              <div key={item.Type}>
                <Check
                  key={item.Type}
                  label={item.name}
                  id={item.Type}
                  field={item.Type}
                  onChange={this.onChangeContactOptions}
                  checked={item.Value}
                />
              </div>
            );
          })}
          <h3>
            <Content
              id="contact-preferences.methods"
              copytext="Please select the methods that we can use to contact you."
            />
          </h3>
          {newOptions.contactListOption.map(item => {
            return (
              <div key={item.Type}>
                <Check
                  key={item.Type}
                  label={item.name}
                  id={item.Type}
                  field={item.Type}
                  onChange={this.onChangeContactOptions}
                  checked={item.Value}
                />
              </div>
            );
          })}
          <p>
            <Content
              id="contact-preferences.confidential"
              copytext="We will never share your details with any third party organisation without your express consent."
            />
          </p>
        </fieldset>
      </>
    );
  }
}

ContactPreferences.propTypes = {
  contactOptions: PropTypes.any,
  saveDataContactOptions: PropTypes.any,
};

export default ContactPreferences;
