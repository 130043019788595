import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import { mapStateToProps, mapDispatchToProps } from '@myie/interact-cms';

const contentContainer = Component => {
  let container = class extends React.Component {
    constructor(props) {
      super(props);

      const { publishedContent, fetchPublishedContentIfNeeded } = this.props;
      if (!publishedContent) fetchPublishedContentIfNeeded();
    }
    get displayName() {
      return 'contentContainer';
    }

    render() {
      return (
        <>
          <Component />
        </>
      );
    }
  };

  container.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),

    publishedContent: PropTypes.object,
    pendingContent: PropTypes.object,
    fetchPublishedContentIfNeeded: PropTypes.func,
    fetchPendingContentIfNeeded: PropTypes.func,
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true },
  )(container);
};

export default contentContainer;
