import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label, FormGroup } from 'reactstrap';
import Errors, { HasErrors } from './Errors';
import { Validate } from '@myie/interact';
import contentManaged, { ContentType } from '../HO_components/contentManaged';

/**
 * Numeric component
 * will display a numeric input feild
 */
class Numeric extends React.Component {
  constructor(props) {
    super(props);
    const {
      label,
      subLabel,
      placeholder,
      description,
      suffix,
      managedContent,
      validation,
    } = props;
    managedContent({
      label: { defaultValue: label },
      subLabel: { defaultValue: subLabel },
      description: { defaultValue: description },
      placeholder: { defaultValue: placeholder },
      suffix: { defaultValue: suffix },
      validation: validation,
    });
  }

  render() {
    const { props } = this;

    const {
      dp = 0,
      children,
      validation = {},
      disabled,
      required,
      labelClassName = '',
      groupClassName = '',
      showErrors = true,
      id,
      field,
      onChange,
      onBlur,
      contentValue,
      managedContent,
      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const { value = '', state = {} } = validation;
    const label = contentValue(ContentType.Text, 'label');
    const subLabel = contentValue(ContentType.Text, 'subLabel');
    const description = contentValue(ContentType.Text, 'description');
    const placeholder = contentValue(ContentType.Text, 'placeholder');
    const suffix = contentValue(ContentType.Text, 'suffix');
    const dpPat = !dp || dp === 0 ? '' : `[.]{0,1}[0-9]{0,${dp}}`;
    const pattern = `^[0-9]*${dpPat}$`;

    const inputOverride = (e, dp, func) => {
      const { target = {} } = e;
      const { value = '' } = target;
      if (
        dp > 0 &&
        value.indexOf('.') !== -1 &&
        value.length - value.indexOf('.') > dp + 1
      ) {
        return;
      }
      const found = value.toString().match(new RegExp(pattern));
      if (value && !found) {
        return;
      }

      if (value && target.validity && !target.validity.valid) {
        return;
      }
      if (!value || !isNaN(parseFloat(value))) {
        if (func) {
          func(e);
        }
      }
    };

    var fieldName =
      typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;
    const isRequired =
      required || (validation && validation.rules && validation.rules.required);
    return (
      <FormGroup
        className={`${groupClassName} ${disabled ? 'disabled-input' : ''} ${
          isRequired ? 'required-input' : ''
        }`.trim()}
      >
        {label || subLabel || description ? (
          <Label className={labelClassName} id={`${id}-label`} htmlFor={id}>
            {label}
            {isRequired ? (
              <React.Fragment>
                <span aria-hidden="true">*</span>
                <span className="sr-only">(required field)</span>
              </React.Fragment>
            ) : (
              ''
            )}{' '}
            {subLabel.length > 0 ? <small>{subLabel}</small> : ''}
            {description.length > 0 ? (
              <span className="description">{description}</span>
            ) : (
              ''
            )}
          </Label>
        ) : (
          ''
        )}
        <Input
          aria-describedby={
            HasErrors(validation) ? null : `error-${fieldName}-validation`
          }
          //aria-labelledby={`${id}-label`}
          onBlur={event => inputOverride(event, dp, onBlur)}
          onChange={event => inputOverride(event, dp, onChange)}
          invalid={Validate.isInvalid(state)}
          valid={Validate.isValid(state)}
          id={id}
          name={fieldName}
          value={value}
          disabled={disabled}
          required={required}
          placeholder={placeholder}
          type="text"
          inputMode={dp === 0 ? 'numeric' : 'decimal'}
          pattern={pattern}
          {...rest}
        />
        {suffix && <p className="suffix">{suffix}</p>}
        {children}
        {showErrors ? (
          <Errors
            contentValue={contentValue}
            validation={validation}
            field={field}
          />
        ) : (
          ''
        )}
      </FormGroup>
    );
  }
}

Numeric.propTypes = {
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  /**
   * Any children of the component
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  /**
   * Text placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Numeric description text
   */
  description: PropTypes.string,
  /**
   * Input disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * decimal places
   */
  dp: PropTypes.number,
  /**
   * Input field names
   */
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Style class name for group
   */
  groupClassName: PropTypes.string,
  /**
   * Numeric ID
   */
  id: PropTypes.string.isRequired,
  /**
   * Label text
   */
  label: PropTypes.string.isRequired,
  /**
   * Style class name for label
   */
  labelClassName: PropTypes.string,
  /**
   * onBlur function
   */
  onBlur: PropTypes.func.isRequired,
  /**
   * onChange function
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Input required or not
   */
  required: PropTypes.bool,
  /**
   * Show errors or not
   */
  showErrors: PropTypes.bool,
  /**
   * Sub label text
   */
  subLabel: PropTypes.string,
  /**
   * Suffix text
   */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Input validation
   */
  validation: PropTypes.object,
};

export default contentManaged(Numeric);
