export const CANCEL_NOTICE_TO_WITHDRAWAL_REQUEST =
  'CANCEL_NOTICE_TO_WITHDRAWAL_REQUEST';
export const CANCEL_NOTICE_TO_WITHDRAWAL_SUCCESS =
  'CANCEL_NOTICE_TO_WITHDRAWAL_SUCCESS';
export const CANCEL_NOTICE_TO_WITHDRAWAL_FAILURE =
  'CANCEL_NOTICE_TO_WITHDRAWAL_FAILURE';
export const RESET_CANCEL_NOTICE_TO_WITHDRAWAL =
  'RESET_CANCEL_NOTICE_TO_WITHDRAWAL';

export const SET_CREATE_NOTICE_TO_WITHDRAWAL_PARAMS =
  'SET_CREATE_NOTICE_TO_WITHDRAWAL_PARAMS';

export const CREATE_NOTICE_TO_WITHDRAWAL_REQUEST =
  'CREATE_NOTICE_TO_WITHDRAWAL_REQUEST';
export const CREATE_NOTICE_TO_WITHDRAWAL_SUCCESS =
  'CREATE_NOTICE_TO_WITHDRAWAL_SUCCESS';
export const CREATE_NOTICE_TO_WITHDRAWAL_FAILURE =
  'CREATE_NOTICE_TO_WITHDRAWAL_FAILURE';
export const RESET_CREATE_NOTICE_TO_WITHDRAWAL =
  'RESET_CREATE_NOTICE_TO_WITHDRAWAL';

export const SET_MAKE_A_WITHDRAW_PARAM = 'SET_MAKE_A_WITHDRAW_PARAM';
export const MAKE_A_WITHDRAW_REQUEST = 'MAKE_A_WITHDRAW_REQUEST';
export const MAKE_A_WITHDRAW_SUCCESS = 'MAKE_A_WITHDRAW_SUCCESS';
export const MAKE_A_WITHDRAW_FALIURE = 'MAKE_A_WITHDRAW_FALIURE';
export const RESET_MAKE_A_WITHDRAW = 'RESET_MAKE_A_WITHDRAW';
export const BANK_HOLIDAYS = 'BANK_HOLIDAYS';
