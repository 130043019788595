import { Validate } from '@myie/interact';

function CommonError(errorList) {
  let indexValue;
  let showError = false;

  if (errorList.length > 0) {
    errorList.forEach((validation, index) => {
      const error = Validate.errors(validation);
      if (error[0] && error[0].rule === 'required') {
        indexValue = index;
        showError = true;
        return true;
      }
    });

    if (!showError) {
      errorList.forEach((validation, index) => {
        const error = Validate.errors(validation);
        if (error[0] && error[0].rule === 'format') {
          indexValue = index;
          showError = true;
          return true;
        }
      });
    }
  }

  return {
    showError: showError,
    index: indexValue,
  };
}

export default CommonError;
