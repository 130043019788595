import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Alert } from 'reactstrap';
import { mapDispatchToProps as mapDispatchToProps_shared } from '@myie/interact-shared';
import { mapDispatchToProps } from '@myie/interact-authentication';
import { mapDispatchToProps as ccbAuthenticationDispatch } from '@myie/interact-ccb-authentication';
import { Redirect } from 'react-router-dom';
import PersonalDetails from '../shared/PersonalDetails';
// import PersonalDetailsActivation from './PersonalDetailsActivation';
// import ActivationCode from './activation-code/ActivationCode';
import SecurityDetails from './SecurityDetails';
import { Session } from '@myie/interact';
import {
  TimeOut,
  Markdown,
  Content,
  Switch,
  AppMeta,
} from '@myie/interact-dom';
import queryString from 'query-string';

class Register extends React.Component {
  constructor(props) {
    super(props);
    let stage = 'PersonalDetails';
    // if (props.match) {
    //   const { id } = props.match.params;
    //   if (id === 'activate') {
    //     stage = 'PersonalDetailsActivate';
    //   }
    // }
    if (props?.location) {
      const { token } = queryString.parse(props?.location.search);
      if (token) {
        props.getTokenAccount({ AccountTokenId: token });
      }
    }
    this.state = {
      stage: stage,
      message: null,
      data: {},
      fetching: false,
    };
  }

  componentWillUnmount() {
    const { resetActivation } = this.props;
    resetActivation();
  }

  componentDidMount() {
    let stage;
    if (this.props.match) {
      const { id } = this.props.match.params;
      if (id) {
        stage = id;
      }
    }
    if (stage !== 'activate') {
      return <Redirect to="/registration/register/" />;
    }
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (nextProps, prevState) => {
    const {
      resetRegistration,
      resetActivation,
      registration = {},
      activationCheck = {},
      credentialsSetup = {},
    } = nextProps;
    let activationStage = 'activationCode';
    // if (nextProps.match) {
    //   const { id } = nextProps.match.params;
    //   if (id === 'activate') {
    //     activationStage = 'PersonalDetailsActivate';
    //   }
    // }
    if (registration.Status === 'IdentifyAndVerifyFailed') {
      if (
        registration.ExtendedProperties &&
        registration.ExtendedProperties.IdentityResponse &&
        registration.ExtendedProperties.IdentityResponse.ActivationStatus ===
          'Expired'
      ) {
        return {
          stage: 'activationCode',
          message: '',
          fetching: false,
        };
      }
      if (
        registration.ExtendedProperties &&
        registration.ExtendedProperties.IdentityResponse &&
        registration.ExtendedProperties.IdentityResponse.ActivationStatus ===
          '5'
      ) {
        return {
          stage: 'activationCode',
          message: '',
          fetching: false,
        };
      }
      resetRegistration();
      if (
        registration.ExtendedProperties &&
        registration.ExtendedProperties.IdentityResponse &&
        registration.ExtendedProperties.IdentityResponse.ActivationStatus ===
          '4'
      ) {
        return {
          stage: activationStage,
          message: 'IdentifyAndVerifyFailedWarning',
          fetching: false,
        };
      }

      return {
        stage: activationStage,
        message: 'IdentifyAndVerifyFailed',
        fetching: false,
      };
    }

    if (registration.Status === 'Success') {
      if (activationCheck.CheckStatus) {
        resetActivation();
      }
      return {
        stage: 'SecurityDetails',
        fetching: false,
      };
    }

    if (activationCheck.CheckStatus === 'Invalid') {
      resetActivation();
      return {
        stage: 'PersonalDetails',
        message: 'Invalid',
        fetching: false,
      };
    }

    if (
      activationCheck.CheckStatus &&
      activationCheck.CheckStatus !== 'Registered'
    ) {
      return {
        stage: 'SecurityDetails',
        fetching: false,
      };
    }

    if (credentialsSetup.Status === 'InvalidTicket') {
      resetRegistration();
      return {
        data: {},
        stage: 'PersonalDetails',
        message: 'InvalidTicket',
        fetching: false,
      };
    }

    if (!activationCheck.CheckStatus && prevState.stage === 'activationCode') {
      return {
        data: {},
        stage: 'PersonalDetails',
        fetching: false,
      };
    }

    if (!registration.Status && prevState.stage === 'SecurityDetails') {
      return {
        data: {},
        stage: 'PersonalDetails',
        fetching: false,
      };
    }

    return null;
  };

  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };

  // change the stage of the form
  setStage = stage => {
    this.setState({ ...this.state, stage, message: null });
  };

  timeoutReset = () => {
    this.setState({
      ...this.state,
      data: {},
      stage: 'PersonalDetails',
      message: 'Timeout',
    });
  };

  timeoutObj = {
    history: null,
    userLogout: this.props.userLogout,
    message: 'RegistrationTimeout',
    url: '/registration',
    onTimeout: this.timeoutReset,
    showTimeoutModal: this.props.showTimeoutModal,
    type: 'SECURITY_DETAILS',
  };

  timeout = new TimeOut(this.timeoutObj);

  // verify the identification and verfication entries
  verify = (code = '') => {
    const { data } = this.state;
    const { register } = this.props;
    this.setState({ fetching: true });
    // personal data
    const request = {
      TermsAndConditionsDocumentVersion: 'v1.0',
      Entries: [
        {
          Type: 'firstName',
          Value: data.firstname,
        },
        {
          Type: 'lastName',
          Value: data.lastname,
        },
        {
          Type: 'dateOfBirth',
          Value: data.b_day,
        },
        {
          Type: 'postCode',
          Value: data.postcode,
        },
        {
          Type: 'accountNumber',
          Value: data.account_number,
        },
      ],
      ExtendedProperties: {},
    };
    request.Entries.push({
      Type: 'SecurityDetails',
      Value: code,
    });
    register(request);
  };

  activate = () => {
    const { data } = this.state;
    const { register } = this.props;

    // personal data
    const request = {
      TermsAndConditionsDocumentVersion: 'v1.0',
      Entries: [
        {
          Type: 'firstName',
          Value: data.firstname,
        },
      ],
      ExtendedProperties: {},
    };
    register(request);
  };

  checkIfFetching = () => {
    const { fetching } = this.state;
    return fetching;
  };

  // select current stage
  currentStage = message => {
    // let { activationCheck = {} } = this.props;
    let { tokenAccountDetails = {}, isFetching } = this.props;
    switch (this.state.stage) {
      case 'PersonalDetails':
        return (
          <PersonalDetails
            saveData={this.saveData}
            setStage={this.setStage}
            verify={this.verify}
            disableForm={this.checkIfFetching()}
            message={message}
            accountdetails={tokenAccountDetails}
          />
        );
      // case 'PersonalDetailsActivate':
      //   return (
      //     <PersonalDetailsActivation
      //       saveData={this.saveData}
      //       setStage={this.setStage}
      //       verify={this.verify}
      //       disableForm={this.checkIfFetching()}
      //       message={message}
      //     />
      //   );
      // case 'activationCode':
      //   return (
      //     <ActivationCode
      //       saveData={this.saveData}
      //       setStage={this.setStage}
      //       activate={this.activate}
      //       registrationRes={activationCheck}
      //       verify={this.verify}
      //       disableForm={this.checkIfFetching()}
      //     />
      //   );
      case 'SecurityDetails':
        return (
          <SecurityDetails
            formData={this.state.data}
            timeout={this.timeout}
            setStage={this.setStage}
            disableForm={this.checkIfFetching()}
            isFetching={isFetching}
          />
        );
      default:
    }
  };

  // select current stage
  getActivationTitle = () => {
    switch (this.state.stage) {
      case 'PersonalDetails':
        return (
          <h1 id="PersonalDetails-activate">
            <Content id="Register-title-activate-1" copytext="Register" />
          </h1>
        );
      case 'SecurityDetails':
        return (
          <h1 id="SecurityDetails-activate">
            <Markdown id="Register-title-activate" markdown={`Register`} />
          </h1>
        );
      case 'PersonalDetailsActivate':
        return (
          <h1 id="PersonalDetailsActivate-activate">
            <Content id="activate-2-title" copytext="Activation Code" />
          </h1>
        );

      default:
    }
  };

  render() {
    const {
      registration = {},
      credentialsSetup = {},
      activationCheck = {},
    } = this.props;
    let { stage } = this.state;
    if (Session.isAuthenticated()) {
      Session.abandon(null);
    }
    const isNonSignatoryUser = registration?.ExtendedProperties?.IdentityResponse?.CredentialErrors?.includes(
      'NonSignatory',
    );
    if (isNonSignatoryUser) {
      return <Redirect to="/registration/accessdenied" />;
    }

    // redirect on these credential setup responses
    switch (credentialsSetup.Status) {
      case 'Success':
        return <Redirect to="/registration/success" />;
      case 'Unsuccessful':
        return <Redirect to="/registration/unsuccessful" />;
      case 'Blocked':
        return <Redirect to="/registration/blocked" />;
      default:
    }

    // redirect on these varification responses
    switch (activationCheck.CheckStatus) {
      case 'Registered':
        return <Redirect to="/registration/alreadyhascredentials" />;

      default:
    }
    // redirect on these varification responses
    switch (registration.Status) {
      case 'Blocked':
        return <Redirect to="/registration/blocked" />;
      case 'AlreadyActive':
        return <Redirect to="/registration/alreadyhascredentials" />;
      case 'NotAllowed':
        return <Redirect to="/registration/notallowed" />;

      default:
    }

    const { message } = this.state;
    let stageId;
    if (this.props.match) {
      const { id } = this.props.match.params;
      if (id) {
        stageId = id;
      }
    }
    return (
      <div id="registration-register">
        {stageId === 'activate' ? (
          <>
            <AppMeta
              id="meta-data"
              stage="child"
              title="Registration step 2 - activation code"
              metaDescription="Registration step 2 - activation code"
            />
            {this.getActivationTitle()}
            <div className="breadcrumb-container">
              <Breadcrumb tag="nav">
                <BreadcrumbItem active={this.state.stage === 'PersonalDetails'}>
                  <Content
                    id="personalDetails-step1"
                    copytext="1 Personal details"
                  />
                </BreadcrumbItem>
                {/* <BreadcrumbItem
                  active={
                    this.state.stage === 'PersonalDetailsActivate' ||
                    this.state.stage === 'activationCode'
                  }
                >
                  <Content
                    id="personalDetails-step2"
                    copytext="2 Activation Code"
                  />
                </BreadcrumbItem> */}
                <BreadcrumbItem active={this.state.stage === 'SecurityDetails'}>
                  <Content id="personalDetails-step3" copytext="2 Register" />
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </>
        ) : (
          <>
            <AppMeta
              id="meta-data"
              stage="child"
              title="Registration stage 1 - enter your personal details"
              metaDescription="Registration stage 1 - enter your personal details"
            />
            <h1>
              <Content id="stage-1-title" copytext="Register" />
            </h1>
            <div className="breadcrumb-container">
              <Breadcrumb tag="nav">
                <BreadcrumbItem active={this.state.stage === 'PersonalDetails'}>
                  <Content
                    id="personalDetails-step1"
                    copytext="1 Personal details"
                  />
                </BreadcrumbItem>
                {/* <BreadcrumbItem active={this.state.stage === 'activationCode'}>
                  <Content
                    id="personalDetails-step2"
                    copytext="2 Activation Code"
                  />
                </BreadcrumbItem> */}
                <BreadcrumbItem active={this.state.stage === 'SecurityDetails'}>
                  <Content id="personalDetails-step3" copytext="2 Register" />
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </>
        )}

        <div className="clearfix"></div>
        <Switch
          id="registration-alert"
          value={message || ''}
          tag="div"
          className="alert alert-danger"
          role="alert"
          contents={{
            Timeout: {
              defaultValue:
                'For security reasons we have logged you out. Please restart the registration process.',
            },
            IdentifyAndVerifyFailedWarning: {
              defaultValue:
                'Please be aware, as this is your 4th attempt, you only have 1 attempt left before a new Activation Code needs to be generated',
            },
            IdentifyAndVerifyFailed: {
              defaultValue:
                'The details you have entered do not match our records. Please try again.',
            },
            InvalidTicket: {
              defaultValue:
                'Your session has timed out. Please restart the registration process.',
            },
            Invalid: {
              defaultValue: `The details you have entered have not been recognised. Please check and try again.`,
            },
          }}
        />
        {stage === 'PersonalDetails' ? (
          <Alert color="info">
            <Markdown
              id="importantNotice"
              markdown={`**Important:**  On successful completion of the registration information below, an OTP (one time passcode) will be sent to the mobile phone number provided for you on the account, so please ensure you have it with you. Don’t worry if you haven’t provided a mobile phone number, or if it has changed, you will be able to call us and we can update it.`}
            />
          </Alert>
        ) : null}

        {this.currentStage(message)}
      </div>
    );
  }
}

Register.propTypes = {
  credentialsSetup: PropTypes.object,
  history: PropTypes.any,
  register: PropTypes.any,
  activationCheck: PropTypes.any,
  registrationcheck: PropTypes.any,
  registration: PropTypes.object,
  showTimeoutModal: PropTypes.any,
  userLogout: PropTypes.any,
  isFetching: PropTypes.bool,
  resetActivation: PropTypes.any,
  match: PropTypes.any,
  tokenAccountDetails: PropTypes.object,
  getTokenAccount: PropTypes.func,
};

const mapStateToProps = state => {
  const { authenticationDefinition, customccbAuthenticationDefinition } = state;
  return {
    ...authenticationDefinition,
    ...customccbAuthenticationDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps,
    ...mapDispatchToProps_shared,
    ...ccbAuthenticationDispatch,
  },
)(Register);
