import { Session } from '@myie/interact';

const NO_SESSION = 'no session';
const getSessionTicket = session => {
  try {
    if (session && session.ticket && session.ticket()) {
      return session.ticket();
    }
  } catch (error) {
    // assume no session
  }
  return NO_SESSION;
};

const secureFetch = previousFetch => {
  return (url, options) => {
    const savedSession = getSessionTicket(Session);

    try {
      return Promise.resolve(
        previousFetch(url, options).then(response => {
          const currentSession = getSessionTicket(Session);
          if (savedSession === NO_SESSION || savedSession === currentSession) {
            return Promise.resolve(response);
          } else {
            return Promise.resolve({});
          }
        }),
      );
    } catch (err) {
      return Promise.reject(err);
    }
  };
};

export default secureFetch;
