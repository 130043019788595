export const ACTIVATION_REQUEST = 'ACTIVATION_REQUEST';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAILURE = 'ACTIVATION_FAILURE';
export const RESET_ACTIVATION = 'RESET_ACTIVATION';

export const SETUP_CREDENTIALS_REQUEST = 'SETUP_CREDENTIALS_REQUEST';
export const SETUP_CREDENTIALS_SUCCESS = 'SETUP_CREDENTIALS_SUCCESS';
export const SETUP_CREDENTIALS_FAILURE = 'SETUP_CREDENTIALS_FAILURE';
export const RESET_SETUP_CREDENTIALS = 'RESET_SETUP_CREDENTIALS';

export const TOKEN_ACCOUNT_REQUEST = 'TOKEN_ACCOUNT_REQUEST';
export const TOKEN_ACCOUNT_SUCCESS = 'TOKEN_ACCOUNT_SUCCESS';
export const TOKEN_ACCOUNT_FAILURE = 'TOKEN_ACCOUNT_FAILURE';
export const RESET_TOKEN_ACCOUNT = 'RESET_TOKEN_ACCOUNT';

export const SETUP_CREDENTIALS_ACTIVATE_REQUEST =
  'SETUP_CREDENTIALS_ACTIVATE_REQUEST';
export const SETUP_CREDENTIALS_ACTIVATE_SUCCESS =
  'SETUP_CREDENTIALS_ACTIVATE_SUCCESS';
export const SETUP_CREDENTIALS_ACTIVATE_FAILURE =
  'SETUP_CREDENTIALS_ACTIVATE_FAILURE';
export const RESET_SETUP_CREDENTIALS_ACTIVATE =
  'RESET_SETUP_CREDENTIALS_ACTIVATE';
