import ccbOrganisationDefinition from './reducers/organisationDefinition';

const customccbOrganisationDefinition = (state = {}, action) => {
  var newState = state;
  newState = ccbOrganisationDefinition(newState, action);

  return newState;
};

export default customccbOrganisationDefinition;
