import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import { Content, AppMeta } from '@myie/interact-dom';

class NetworkError extends React.Component {
  onGoBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    return (
      <div id="network-error">
        <AppMeta
          id="meta-data"
          stage="child"
          title="Error"
          metaDescription="Network error"
        />
        <h1 aria-describedby="network-error-alert">
          <Content id="title" copytext="Network Error" />
        </h1>
        <Alert color="danger" id="network-error-alert" role="alert">
          <Content
            id="errormsg"
            copytext="The operation couldn't be completed."
          />
        </Alert>
        <div className="form-button-group">
          <Button
            id="network-error-go-back"
            type="submit"
            color="primary"
            onClick={this.onGoBack}
          >
            <Content id="goback" copytext="Back to previous page" />
          </Button>
        </div>
      </div>
    );
  }
}

NetworkError.propTypes = {
  history: PropTypes.any,
};

export default NetworkError;
