import React from 'react';
import PropTypes from 'prop-types';
import { DropDown } from '@myie/interact-dom';
import { withRouter } from 'react-router-dom';
import { Validate, Utility } from '@myie/interact';
import Currency from 'react-currency-formatter';

class RedirectByAccountDropdown extends React.Component {
  constructor(props) {
    super(props);
    const { defaultSelect } = this.props;
    this.state = {
      form: {
        accountType: {
          value: defaultSelect,
          rules: {
            title: 'Source Account',
            stop: true,
            required: {
              message: 'Please select your account',
            },
          },
        },
      },
    };
  }

  withdrawalFilter = accounts => {
    if (accounts) {
      return accounts.filter(
        account =>
          account.AccountStatus !== 'Closed' &&
          account.BrandProductType === 'EASY_ACCESS',
      );
    }
    return [];
  };

  noticeFilter = accounts => {
    if (accounts) {
      return accounts.filter(
        account =>
          account.AccountStatus !== 'Closed' &&
          account.BrandProductType === 'NOTICE_ACCOUNT',
      );
    }
    return [];
  };

  onChangeTarget = e => {
    const { name, value } = e.target;
    if (value === '') {
      return;
    }
    let { form } = this.state;
    const { baseUrl, accounts, history, onChangeAccount } = this.props;
    form = Validate.input(name, value, form);
    if (accounts && accounts.Accounts) {
      const account = accounts.Accounts.find(function(element) {
        return element.AccountKey.Key === value;
      });

      this.setState({ ...this.state, form });
      onChangeAccount(e);
      const newUrl = baseUrl + Utility.hexEncode(account.AccountKey.Key);
      history.push(newUrl);
    }
  };

  accountOptions(prefix, item, index) {
    return (
      <option
        id={`accountType-option-${index}`}
        key={prefix + item.AccountKey.Context}
        value={item.AccountKey.Key}
      >
        {item.AccountNumber} -{' '}
        {Currency({ quantity: item.Balance, currency: item.CurrencyCode })}
      </option>
    );
  }

  render() {
    const { accounts, defaultSelect, filter } = this.props;

    const { form } = this.state;
    if (form.accountType.value === undefined) {
      form.accountType.value = defaultSelect;
    }

    let accOptions = [];
    switch (filter) {
      case 'withdrawal':
        accOptions = accounts ? this.withdrawalFilter(accounts.Accounts) : [];
        break;
      case 'notice':
        accOptions = accounts ? this.noticeFilter(accounts.Accounts) : [];
        break;
      default:
    }

    return (
      <DropDown
        cmsTag="accounts:accountDropdown.accountType"
        label="Your withdrawal account"
        id="accountType"
        field="accountType"
        labelClassName="sr-only"
        className="w-auto"
        onChange={this.onChangeTarget}
        validation={form.accountType}
      >
        <option
          id="accountType-option-empty"
          key="1"
          value=""
          defaultText="Please select"
        ></option>
        {accOptions.map((item, index) => {
          return this.accountOptions('accountType', item, index);
        })}
      </DropDown>
    );
  }
}

RedirectByAccountDropdown.propTypes = {
  accounts: PropTypes.any,
  baseUrl: PropTypes.any,
  defaultSelect: PropTypes.any,
  filterAccounts: PropTypes.any,
  history: PropTypes.any,
  onChangeAccount: PropTypes.any,
  filter: PropTypes.any,
};

export default withRouter(RedirectByAccountDropdown);
