import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '29.123' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 35.968 35.969"
    viewBox="0 0 35.968 35.969"
    width={width}
  >
    <g className={fill}>
      <path d="M17.984 0C8.068 0 0 8.068 0 17.984c0 9.917 8.068 17.984 17.984 17.984s17.984-8.067 17.984-17.984C35.968 8.068 27.901 0 17.984 0zm2.443 27.881h-4.886v-4.887h4.886v4.887zm4.519-12.674c-.356.77-.916 1.527-2.137 2.29-.625.39-1.665.986-2.138 1.527-.268.308-.306.893-.306 1.527H15.48s0-1.018.306-2.036c.334-1.115 1.684-2.45 3.028-3.476.716-.537 1.075-1.03 1.075-1.478 0-.447-.145-.795-.432-1.044-.287-.248-.722-.373-1.306-.373-.628 0-1.148.192-1.559.574-.412.383-.675 1.05-.789 2.003l-5.146-.587c.177-1.742.865-3.144 2.063-4.206 1.198-1.063 3.036-1.594 5.511-1.594 1.928 0 3.485.371 4.669 1.111 1.609 1.001 2.415 2.337 2.415 4.005-.001.694-.055 1.075-.369 1.757z" />
    </g>
  </svg>
);

SVG.propTypes = { fill: PropTypes.string, width: PropTypes.string };

export default SVG;
