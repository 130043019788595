import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Content, Markdown } from '@myie/interact-dom';

class AccountAlreadyExists extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }

  render() {
    return (
      <div id="registration-account-already-exists">
        <h1 id="registration-account-already-exists-title">
          <Content id="title" copytext="Account already exists" />
        </h1>
        <h2>
          <Content
            id="subTitle"
            copytext={`This account has already been registered.`}
          />
        </h2>
        <Alert color="danger" role="alert">
          <Markdown
            id="alertText"
            markdown={`You are trying to register an account that already exists on our
            system.

            \nPlease press **Sign in** to access your account.
            
            \n If you have forgotten your account details please press **I forgot my details**`}
          />
        </Alert>
        <div className="form-button-group">
          <Link
            id="registration-account-already-exists-sign-in-button"
            className="btn btn-primary"
            role="button"
            to="/sign-in"
          >
            <Content id="signInButton" copytext={`Sign in`} />
          </Link>
          <Link
            id="registration-account-already-exists-forgotten-username-button"
            className="btn btn-secondary"
            role="button"
            to="/forgotten-username"
          >
            <Content
              id="forgottenUsernameButton"
              copytext={`I forgot my details`}
            />
          </Link>
        </div>
      </div>
    );
  }
}

AccountAlreadyExists.propTypes = {
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountAlreadyExists);
