import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { mapDispatchToProps as mapDispatchToPropsAccounts } from '@myie/interact-accounts';
import { mapDispatchToProps as mapDispatchToPropsMessaging } from '@myie/interact-messaging-twoway';
import { FormattedDate, Content, Switch, AppMeta } from '@myie/interact-dom';
import FormatBody from '../../FormatBody';
import messagingUtility from './../../utility';
class ReplyConfirmation extends React.Component {
  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  render() {
    const { replyMessageStatus, accounts } = this.props;
    let Conversation,
      accountSuffix = '';

    let messages = [];
    if (!replyMessageStatus) {
      return null;
    } else {
      Conversation = replyMessageStatus.Conversation;
    }

    if (Conversation && Conversation.Messages) {
      messages = Conversation.Messages;
    }

    if (
      Conversation &&
      Conversation.AccountNumber &&
      accounts &&
      accounts.Accounts &&
      accounts.Accounts.length
    ) {
      let account = accounts.Accounts.find(
        a => a.AccountNumber === Conversation.AccountNumber,
      );
      if (account && account.AccountStatus === 'Closed') {
        accountSuffix = ' <Closed account>';
      }
    }

    let alertId;
    if (replyMessageStatus) {
      switch (replyMessageStatus.ResponseStatus) {
        case 'Success':
          alertId = 'messaging-twoway-reply-successful';
          break;
        case 'Failed':
          alertId = 'messaging-twoway-reply-request-error';
          break;
        default:
          alertId = 'messaging-twoway-reply-unknown-error';
          break;
      }
    }

    return (
      <>
        <AppMeta
          id="meta-data"
          stage="child"
          title="Message sent"
          metaDescription="Message sent"
        />
        <h1>
          {replyMessageStatus &&
          replyMessageStatus.ResponseStatus === 'Success' ? (
            <>
              {' '}
              {messages.length > 1 ? (
                <Content id="reason" copytext="Re:" />
              ) : (
                ''
              )}{' '}
              {messagingUtility.stripEndQuotes(
                Conversation.Reason.replace(/\\/g, ''),
              )}
            </>
          ) : (
            ''
          )}
          {replyMessageStatus &&
          replyMessageStatus.ResponseStatus === 'Failed' ? (
            <>
              <Content id="reply-failed" copytext="Reply failed" />
            </>
          ) : (
            ''
          )}
        </h1>
        {replyMessageStatus && (
          <Alert
            role="alert"
            id={alertId}
            color={
              replyMessageStatus &&
              replyMessageStatus.ResponseStatus === 'Success'
                ? 'success'
                : 'danger'
            }
          >
            <Switch
              id="confirmAlert"
              value={replyMessageStatus.ResponseStatus}
              tag="<>"
              defaultText="An unknown error occured please check your accounts befor retrying."
              contents={{
                Success: {
                  defaultValue:
                    'Your message has been sent. Please see details below',
                },
                Failed: {
                  defaultValue:
                    'Unfortunately we were unable to send this message at this time.',
                },
              }}
            />
          </Alert>
        )}
        <Row tag="dl" title="message details">
          {Conversation.AccountNumber !== 'XXXXXXXXXable' &&
          Conversation.AccountNumber !== '' &&
          Conversation.AccountNumber !== 'Not available' ? (
            <>
              <Col tag="dt" sm={6} md={3}>
                <Content id="relatedAccountMsg" copytext="Related account:" />
              </Col>
              <Col tag="dd" sm={6} md={9}>
                {Conversation.AccountNumber + accountSuffix}
              </Col>
            </>
          ) : (
            ''
          )}
          <Col tag="dt" sm={6} md={3}>
            <Content id="referenceNumber" copytext="Reference number:" />
          </Col>
          <Col tag="dd" sm={6} md={9}>
            {Conversation.Reference}
          </Col>
        </Row>
        <div className="list-group messages-list mb-2">
          {messages.map((item, index) => {
            return (
              <div className="list-group-item" key={`message-${index}`}>
                <h2>
                  {item.Sender.SenderType !== 'User'
                    ? `${item.Sender.SenderType}`
                    : ''}{' '}
                  <span className="timestamp">
                    <FormattedDate date={item.CreatedDate} format={'LLL'} />
                  </span>
                </h2>
                {FormatBody(item.Body)}
              </div>
            );
          })}
        </div>
        <div className="form-button-group">
          <Link
            id="messaging-twoway-message-back-to-link"
            className="btn btn-secondary btn-sm"
            role="button"
            to="/messaging-twoway/view"
          >
            <Content id="backButton" copytext="Back To Messages" />
          </Link>
        </div>
      </>
    );
  }
}

ReplyConfirmation.propTypes = {
  accounts: PropTypes.object,
  fetchAccountsIfNeeded: PropTypes.func,
  replyMessageStatus: PropTypes.any,
  transactionData: PropTypes.any,
  transfer: PropTypes.any,
};

const mapStateToProps = state => {
  const { messagingTwowayDefinition, accountsDefinition } = state;
  return {
    ...messagingTwowayDefinition,
    ...accountsDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToPropsMessaging, ...mapDispatchToPropsAccounts },
)(ReplyConfirmation);
