import { Connection } from '@myie/interact';
const appUtility = (function() {
  const userSignout = () => {
    fetch(Connection.baseUrl('webauthentication') + `v2.0/user/sign-out`, {
      method: 'post',
      headers: Connection.headers(),
      body: '{}',
    });
  };

  return {
    userSignout: userSignout,
  };
})();

export default appUtility;
