import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '35.901', height = '35.907' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 35.901 35.907"
    viewBox="0 0 35.901 35.907"
  >
    <path
      className={fill}
      d="M13.943 26.483c.136 1.611.764 2.942 1.679 3.938-1.511 1.035-3.599 3.418-3.28 4.158.368.856 1.483 1.798 1.776 1.063-.109-1.289.81-3.099 2.146-4.617 2.519 2.07 6.515 2.098 9.165-.677 3.395-3.549 3.075-8.52 10.473-11.701-6.797-2.304-10.263-1.751-14.843-.401-4.144 1.223-7.377 5.194-7.116 8.237zm14.911-6.74c-.085-.104-2.577 1.733-4.899 4.894-2.325 3.16-5.594 4.068-5.681 3.966.087.103 2.577-1.734 4.902-4.896 2.322-3.16 5.593-4.068 5.678-3.964z"
    />
    <path className={fill} d="M5.987 5.907H20.987000000000002V7.907H5.987z" />
    <path className={fill} d="M5.987 10.907H20.987000000000002V12.907H5.987z" />
    <path
      className={fill}
      d="M5.912 15.85v2.031h9.016c.712-.66 2.154-1.486 2.953-2.031H5.912zM5.912 20.896v1.992h5.755c.357-.626.829-1.414 1.245-1.992h-7zM5.912 25.881v2.007H9.68c.168-.616.559-1.416.779-2.007H5.912z"
    />
    <path
      className={fill}
      d="M9.051 32.765c0-.264.01-.522.019-.783H1.811V1.811h23.533V12.679a18.213 18.213 0 011.81-.205V2.289c0-.542-.048-1.168-.461-1.582C26.275.291 25.893 0 25.35 0H1.968C1.424 0 .959.291.543.707.129 1.121 0 1.747 0 2.289v29.764c0 .545.128.896.543 1.31.416.416.881.43 1.425.43h7.108a22.039 22.039 0 01-.025-1.028z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
