import React from 'react';
import { CardColumns, Card } from 'reactstrap';
import PropTypes from 'prop-types';
import { Content, AppMeta } from '@myie/interact-dom';
import RegisteredServiceItems from './RegisteredServiceItems';

class ServicesIndex extends React.Component {
  render() {
    return (
      <div id="services">
        <AppMeta
          id="meta-data"
          stage="child"
          title="Services"
          metaDescription="Services landing page"
        />
        <h1>
          <Content cmsTag="services:launchPage.title" copytext="My Details" />
        </h1>
        <CardColumns className="grid-mode">
          {RegisteredServiceItems.map(sb => (
            <Card key={sb.name} id={sb.name} className="card-item bg-light">
              {<sb.serviceItem />}
            </Card>
          ))}
        </CardColumns>
      </div>
    );
  }
}

ServicesIndex.propTypes = {
  accounts: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
};

export default ServicesIndex;
