import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';
import { Link } from 'react-router-dom';

class ProgressBars extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    return (
      <section id="alerts-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/components/progress-bars.scss</strong>
          </code>
        </p>
        <p>
          There is a component made for the <strong>progress bars</strong>{' '}
          defined within reactstrap. To see an interactive technical
          specification for this component, go to{' '}
          <Link to="https://reactstrap.github.io/components/progress/">
            https://reactstrap.github.io/components/progress/
          </Link>
          .{' '}
        </p>
        <Progress value={2 * 5} />
        <Progress color="success" value="25" />
        <Progress color="info" value={50} />
        <Progress color="warning" value={75} />
        <Progress color="danger" value="100" />
        <Progress multi>
          <Progress bar value="15">
            Meh
          </Progress>
          <Progress bar color="success" value="30">
            30%
          </Progress>
          <Progress bar color="info" value="25">
            25%
          </Progress>
          <Progress bar color="warning" value="20">
            20%
          </Progress>
          <Progress bar color="danger" value="5">
            5%
          </Progress>
        </Progress>
      </section>
    );
  }
}

ProgressBars.propTypes = {
  items: PropTypes.string,
};

export default ProgressBars;
