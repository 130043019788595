import React from 'react';
import { Content } from '@myie/interact-dom';

const MinusGlyph = () => {
  return (
    <>
      <img
        className="minus-glyph"
        alt="minus"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAQAAABpN6lAAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfjBg4UEB6YeWWBAAABcklEQVR42u3YvUojURjG8f/M+FGoiFoIWVBBxEX0AlKIpcRW0EtYvAGvwKuwsVLwBqKtlTauoFgoiOIXsXAX0RAhMrEQcbO9OZDz/1VTPu8zczjDC5IkSZIkSZIkSZIkSZIkSZIkSZKk9pH88zxIgX7S0JG+Wc4Td/z9v4Ah5igySm8EBbxwxQF7PAJ0ADDCMotM0xM6XYtUmWWcba4hA4b4xQo/6Q6dq2W6KDBFzgm1jIQlVim0/affLKGPSW45ThimzAydoRMFUOeU+ZQFJqMcHzqZoJSyEOn4HxWUEi4Yi+z8f8m5SHimp+l3KCYNqrG++095SoVG6BTBNHhIOeItdI5g3jhMKVMPnSOYOjspZc4iraDOObsZVV4p0hvdTdCgwhoHGXBDxkRkFeTcs84mtQyocckL/QzQFTpXi1T5zQZbVL4WIoPMUWQsmoXIPnv8geaV2AA/4luJSZIkSZIkSZIkSZIkSZIkSZIkSZLayTvUOEu8Qd1B8wAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0wNi0xNFQxODoxNjozMCswMjowMDVDwywAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMDYtMTRUMTg6MTY6MzArMDI6MDBEHnuQAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg=="
      />
      <span className="sr-only">
        <Content id="close" copytext="Close" />
      </span>
    </>
  );
};

export default MinusGlyph;
