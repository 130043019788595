import { Connection } from '@myie/interact';

import {
  CANCEL_NOTICE_TO_WITHDRAWAL_FAILURE,
  CANCEL_NOTICE_TO_WITHDRAWAL_SUCCESS,
  CANCEL_NOTICE_TO_WITHDRAWAL_REQUEST,
  RESET_CANCEL_NOTICE_TO_WITHDRAWAL,
  SET_CREATE_NOTICE_TO_WITHDRAWAL_PARAMS,
  RESET_CREATE_NOTICE_TO_WITHDRAWAL,
  SET_MAKE_A_WITHDRAW_PARAM,
  MAKE_A_WITHDRAW_REQUEST,
  MAKE_A_WITHDRAW_SUCCESS,
  MAKE_A_WITHDRAW_FALIURE,
  RESET_MAKE_A_WITHDRAW,
  BANK_HOLIDAYS,
} from './withdrawalTypes';

export const cancellationRequest = () => ({
  type: CANCEL_NOTICE_TO_WITHDRAWAL_REQUEST,
  isFetching: true,
  error: null,
});

export const cancellationFailed = error => ({
  type: CANCEL_NOTICE_TO_WITHDRAWAL_FAILURE,
  isFetching: false,
  error: error.message,
});

export const cancellationSuccess = json => ({
  type: CANCEL_NOTICE_TO_WITHDRAWAL_SUCCESS,
  cancellationResponse: json,
  isFetching: false,
  error: null,
});

export const resetCancellation = () => ({
  type: RESET_CANCEL_NOTICE_TO_WITHDRAWAL,
});

export const setCreateNoticeToWithdrawParam = data => ({
  type: SET_CREATE_NOTICE_TO_WITHDRAWAL_PARAMS,
  createNoticeParam: data,
});

export const resetCreateNoticeToWithdraw = () => ({
  type: RESET_CREATE_NOTICE_TO_WITHDRAWAL,
});

export const setBankHolidays = json => ({
  type: BANK_HOLIDAYS,
  holidays: json,
  isFetching: false,
  error: null,
});

export const createWithdrawalRequest = () => ({
  type: MAKE_A_WITHDRAW_REQUEST,
  isFetching: true,
  error: null,
});

export const makeaWithdrawSuccess = json => ({
  type: MAKE_A_WITHDRAW_SUCCESS,
  makeWithdrawResponse: json,
  isFetching: false,
  error: null,
});

export const makeaWithdrawFaliure = error => ({
  type: MAKE_A_WITHDRAW_FALIURE,
  makeWithdrawResponse: null,
  isFetching: false,
  error: error.message,
});

export const setWithdrawalParam = data => ({
  type: SET_MAKE_A_WITHDRAW_PARAM,
  withdrawalParam: data,
});

export const resetMakeWithdrawal = () => ({
  type: RESET_MAKE_A_WITHDRAW,
});

export const cancelNoticeToWithdraw = request => dispatch => {
  dispatch(cancellationRequest());
  return fetch(
    Connection.baseUrl('customccb') + `v1.0/cancelpendingtransaction`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(cancellationSuccess(json)))
    .catch(error => {
      dispatch(cancellationFailed(error));
    });
};

export const makeaWithdraw = request => dispatch => {
  dispatch(createWithdrawalRequest());
  return fetch(Connection.baseUrl('customccb') + `v1.0/setupwithdrawal`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(makeaWithdrawSuccess(json)))
    .catch(error => dispatch(makeaWithdrawFaliure(error)));
};
