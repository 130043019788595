import React from 'react';
import PropTypes from 'prop-types';
import { Content } from '@myie/interact-dom';
import { MaturityAlert } from '@myie/interact-accounts-dom';
import moment from 'moment';

class MaturityDetails extends React.Component {
  render() {
    const { loopIndex, account } = this.props;
    const { ExtendedProperties: { MaturityDate } = {} } = account;

    return (
      <div
        id={`account-type-fixed-rate-summary-${loopIndex}`}
        className="maturity-icon"
      >
        <h3 className="h6">
          <Content
            id="type-fixed-rate-months"
            copytext={`Matures on ${moment(MaturityDate)
              .format('DD MMM YYYY')
              .toString()}`}
          />
        </h3>
        <p>
          <MaturityAlert alert={false} MaturityDate={MaturityDate} />
        </p>
      </div>
    );
  }
}

MaturityDetails.propTypes = {
  account: PropTypes.any,
  loopIndex: PropTypes.any,
  mode: PropTypes.any,
};

export default MaturityDetails;
