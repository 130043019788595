import ccbWithdrawalDefinition from './reducers/withdrawalDefinition';
import ccbShortApplyDefinition from './reducers/shortApplyDefinition';
const customccbSavingsServicingDefinition = (state = {}, action) => {
  var newState = state;
  newState = ccbWithdrawalDefinition(newState, action);
  newState = ccbShortApplyDefinition(newState, action);
  return newState;
};

export default customccbSavingsServicingDefinition;
