export const CONTACTS = {
  phone: '0344 225 3939',
  phoneLink: '03442253939',
  email: 'savings@ccbank.co.uk',
  openTimes: 'Monday to Friday, 9.00am to 5.00pm',
  companyName: 'Cambridge & Counties Bank',
  companySiteName: '',
  companyFormattedAddress: '',
  cashISAFormURL: '',
  minimumAge: '18',
  sortcode: '',
  accountNumber: '',
};
