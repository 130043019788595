import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Content, Switch } from '@myie/interact-dom';

class Confirmation extends React.Component {
  renderDoneButton = () => {
    return (
      <Link
        id="edit-details-confirmation-done"
        className="btn btn-primary"
        role="button"
        to="/personal-details/view"
      >
        <Content id="stage.confirmation.done" copytext="Done" />
      </Link>
    );
  };

  render() {
    const { updateUserDetailSuccess } = this.props;
    let title = null;
    let id;
    if (updateUserDetailSuccess) {
      switch (updateUserDetailSuccess.ResponseStatus) {
        case 'Success':
          id = 'update-personal-detail-success';
          title = (
            <Content
              id="stage.confirmation.sucess"
              copytext="Personal details updated"
            />
          );
          break;
        case 'Failed':
          id = 'update-personal-detail-failure';
          title = (
            <Content
              id="stage.confirmation.failed"
              copytext="Personal details update failed"
            />
          );
          break;
        default:
          id = 'update-personal-detail-unknown';
          title = (
            <Content id="stage.confirmation.unknown" copytext="Unkown Error" />
          );
      }
    }
    return (
      <div id="personal-detail-confirmation">
        <div className="page-title">
          <h1>{title}</h1>
        </div>
        {updateUserDetailSuccess ? (
          <Alert
            role="alert"
            id={id}
            color={
              updateUserDetailSuccess.ResponseStatus === 'Success'
                ? 'success'
                : 'danger'
            }
          >
            <Switch
              id="stage.confirmation.alert"
              tag="<>"
              value={updateUserDetailSuccess.ResponseStatus}
              defaultText="An unknown error occured please check your accounts before retrying."
              contents={{
                Success: {
                  defaultValue: 'You have updated your personal details',
                },
                Failed: {
                  defaultValue:
                    'An error occured while trying to process your request',
                },
              }}
            />
          </Alert>
        ) : null}
        {this.renderDoneButton()}
      </div>
    );
  }
}

Confirmation.propTypes = {
  resetContactOptions: PropTypes.any,
  resetUserDetails: PropTypes.any,
  setStage: PropTypes.any,
  updateUserDetailSuccess: PropTypes.any,
};

export default Confirmation;
