import React from 'react';
import PropTypes from 'prop-types';
import {
  Fade,
  Navbar,
  Nav,
  NavItem,
  Button,
  TabPane,
  Row,
  Col,
} from 'reactstrap';
import ExportAccountCSV from './ExportAccountCSV.js';
import TransactionDateRange from './TransactionDateRange';
import TransactionDate from './TransactionDate';
import Transaction from './Transaction';
import { connect } from 'react-redux';
import { FormattedDate, Content, Icon, Markdown } from '@myie/interact-dom';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as ccbAccountsMapDispatch } from '@myie/interact-ccb-accounts';
import moment from 'moment';

class TabTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.toggleTransactions = this.toggleTransactions.bind(this);
    this.state = {
      range: props.range ? props.range : null,
      transactions: [],
      updateButton: false,
    };
  }

  setUpdateButton = () => {
    this.setState({ ...this.state, updateButton: true });
  };

  static getDerivedStateFromProps(props, state) {
    const {
      range,
      activeTab,
      accounts,
      account,
      isFetching,
      getInterestHistoryIfNeeded,
    } = props;
    if (
      activeTab === 'transactions' &&
      accounts &&
      !account.interestHistory &&
      !isFetching
    ) {
      getInterestHistoryIfNeeded({
        AccountKey: account.AccountKey,
      });
    }
    if (range && state.range == null) {
      if (range.Latest) {
        var latestDates = { ...range };
        delete latestDates.Latest;
        return { range, latestDates };
      }
      return { range };
    }

    return null;
  }

  sortTransactions = () => {
    if (
      this.props.account &&
      this.props.account.transactions &&
      this.props.account.transactions.Transactions
    ) {
      this.props.account.transactions.Transactions.reverse();
      this.setState({
        reverse: !this.state.reverse,
      });
    }
    //this.setState({ ...this.state });
  };

  toggleTransactions(range) {
    if (range) {
      this.setState({ collapse: !this.state.collapse, range: range });
    } else {
      this.setState({ collapse: !this.state.collapse });
    }
  }

  shouldComponentUpdate() {
    const { range } = this.props;
    if (!range) {
      return false;
    } else {
      return true;
    }
  }

  olderTransactions = () => {
    const { fetchTransactions, account, setCount, count } = this.props;
    let months = (count + 1) * 6 + 24;
    setCount(count + 1);
    const to = moment().startOf('day');
    const from = moment()
      .subtract(months, 'months')
      .add(1, 'day')
      .startOf('day');
    const range = {
      To: to.toISOString(),
      From: from.toISOString(),
    };
    let request = {
      AccountKey: account.AccountKey,
      Range: range,
      ExtendedProperties: {
        FromDate: moment(from).format(moment.HTML5_FMT.DATE),
        ToDate: moment(to).format(moment.HTML5_FMT.DATE),
      },
    };
    this.setState({ ...this.state, updateButton: false });
    fetchTransactions(request);
    this.toggleTransactions(range);
  };

  getRates(account) {
    let Rates = [];
    if (
      account.interestHistory.InterestRateHistory &&
      account.interestHistory.InterestRateHistory.InterestHistories
    ) {
      let InterestRates = [
        ...account.interestHistory.InterestRateHistory.InterestHistories,
      ];

      InterestRates.reverse().forEach((element, index) => {
        let today = moment(new Date()).format('YYYY-MM-DD');
        let toDate = moment(element.DateTo).format('YYYY-MM-DD');
        Rates.push(
          <React.Fragment key={index}>
            <Col xs={8} md={8} className="text-left" tag="dt">
              <FormattedDate date={element.DateFrom} format={'DD MMM YYYY'} />
              {' - '}
              {moment(toDate).isSame(today, 'day') ? (
                <Content cmsTag={`present`} copytext="Present" />
              ) : (
                <FormattedDate date={element.DateTo} format={'DD MMM YYYY'} />
              )}
            </Col>

            <Col xs={4} md={4} className="text-right" tag="dd">
              {element.AnnualInterestRate}
              {'%'}
            </Col>
          </React.Fragment>,
        );
      });
    }

    return Rates;
  }

  render() {
    const {
      name,
      fetchTransactions,
      account,
      activeTab,
      tablist,
      count,
    } = this.props;
    const { range, updateButton } = this.state;
    const transactions = [];
    if (account.transactions && account.transactions.Transactions) {
      let index = 0;
      const listCount = (count + 1) * 20;
      let previousDate = null;

      account.transactions.Transactions.forEach(transaction => {
        if (
          transaction &&
          transaction.ExtendedProperties &&
          transaction.ExtendedProperties.Type &&
          transaction.ExtendedProperties.Type !== 'PendingTransaction'
        ) {
          if (index < listCount || updateButton) {
            let thisDate = moment(transaction.Date)
              .startOf('day')
              .toString();
            if (previousDate !== thisDate) {
              transactions.push(
                <TransactionDate
                  transactionDate={transaction.Date}
                  key={`td-${index}`}
                />,
              );
              previousDate = moment(transaction.Date)
                .startOf('day')
                .toString();
            }
            transactions.push(
              <Transaction
                transaction={transaction}
                AccountCurrencyCode={account.CurrencyCode}
                key={index}
                position={index}
              />,
            );
            index++;
          }
        }
      });
    }

    return (
      <TabPane
        id="transactions"
        role="tabpanel"
        tabId="transactions"
        aria-labelledby="transactions-tab"
      >
        <Fade in={activeTab === 'transactions'}>
          <div
            className="tab-focus"
            tabIndex="0"
            ref={tablist.refs.panels[tablist.refs.names.indexOf(name)]}
            onKeyDown={e => tablist.panelKeyDown(e, name, tablist.refs)}
          >
            <span className="sr-only" id="tab-transaction-filter-bar">
              <Content
                cmsTag={`account-action-and-filter-bar`}
                copytext="Transactions action and filter bar"
              />
            </span>
            <Navbar
              color="light"
              className="form-inline transactions-navbar"
              aria-labelledby="tab-transaction-filter-bar"
            >
              <Nav>
                <NavItem onClick={this.sortTransactions}>
                  <Button
                    id="sort-by-date"
                    size="sm"
                    color="link"
                    className={
                      this.state.reverse
                        ? 'float-right ascending'
                        : 'float-right descending'
                    }
                  >
                    <Content
                      cmsTag={`account-sort-by-date-button`}
                      copytext="Sort by Date"
                    />
                    <span aria-hidden="true" className="chevron">
                      {'>'}
                    </span>
                    <span className="sr-only">
                      {this.state.reverse
                        ? 'you are sorting by date ascending'
                        : 'you are sorting by date descending'}
                      . Click here to reverse this.
                    </span>
                  </Button>
                </NavItem>
                <NavItem>
                  <Button
                    onClick={() => {
                      window.print();
                    }}
                    size="sm"
                    className="btn-nav float-right mr-0"
                    color="link"
                  >
                    <Icon name="Print" fill="secondary-fill" />
                    <span className="sr-only">
                      <Content
                        cmsTag={`account-print-button`}
                        copytext="Click here to print. Please note formatting in some browsers may not fully work – please contact us if you need help."
                      />
                    </span>
                    <span aria-hidden="true">*</span>
                  </Button>
                  <ExportAccountCSV account={account} />
                </NavItem>
              </Nav>
              <TransactionDateRange
                setUpdateButton={this.setUpdateButton}
                account={account}
                range={range}
                toggle={this.toggleTransactions}
                fetchTransactions={fetchTransactions}
              />
              <p className="small mb-0 mt-3" aria-hidden="true">
                * Please note re printing: formatting in some browsers may not
                fully work – please contact us if you need help.
              </p>
            </Navbar>

            <div
              title="list of transactions in date order"
              className="transactions-container pt-4"
            >
              {transactions.length > 0 ? (
                <Row aria-hidden="true" className="transactions-column-headers">
                  <Col xs={2} md={1} className="col-icon"></Col>
                  <Col xs={4} sm={4} md={5}></Col>
                  <Col xs={3} sm={3} md={3} className="text-right">
                    <small className="transaction-payments">
                      <Content
                        cmsTag={`account-all-payments`}
                        copytext="Amount in/out"
                      />
                    </small>
                  </Col>
                  <Col xs={3} sm={3} md={3} className="text-right">
                    <small className="transaction-balance">
                      <Content
                        cmsTag={`account-balance-title`}
                        copytext="Balance"
                      />
                    </small>
                  </Col>
                </Row>
              ) : (
                <p>
                  <Content
                    cmsTag={`transactions-unavailable`}
                    copytext="There are no transactions available"
                  />
                </p>
              )}
              <dl>{transactions}</dl>

              <Row className="mt-5">
                <Col xs={6} md={6} className="text-left">
                  <Button
                    onClick={() => {
                      this.olderTransactions();
                    }}
                    size="sm"
                    className="btn-nav float-left mr-0"
                    color="link"
                  >
                    <Content
                      cmsTag={`older-transactions`}
                      copytext="SHOW OLDER TRANSACTIONS"
                    />
                  </Button>
                </Col>

                <Col xs={6} md={6} className="text-right">
                  <Button
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    size="sm"
                    className="btn-nav float-right mr-0"
                    color="link"
                  >
                    <Content cmsTag={`back-to-top`} copytext="BACK TO TOP" />
                  </Button>
                </Col>
              </Row>
              {account.interestHistory &&
              account.interestHistory.InterestRateHistory &&
              account.interestHistory.InterestRateHistory.InterestHistories &&
              account.interestHistory.InterestRateHistory.InterestHistories
                .length > 0 ? (
                <>
                  <h2 className="pt-5">
                    <Content
                      cmsTag={`historic-interest-rates-title`}
                      copytext="Historic Interest Rates"
                    />
                  </h2>
                  <Markdown
                    id="historic-interest-rates-detail"
                    markdown={`\nPlease find a list of historic interest rates for this account below:`}
                  />
                  <Row>
                    <Col xs={12} md={7}>
                      <Row
                        tag="dl"
                        title="Interest rates"
                        className="interest-rates-container"
                      >
                        {this.getRates(account)}
                      </Row>
                    </Col>
                  </Row>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </Fade>
      </TabPane>
    );
  }
}
TabTransaction.propTypes = {
  account: PropTypes.object,
  activeTab: PropTypes.string,
  fetchTransactions: PropTypes.func,
  range: PropTypes.object,
  tablist: PropTypes.object,
  name: PropTypes.any,
  getInterestHistoryIfNeeded: PropTypes.any,
  isFetching: PropTypes.any,
  interestRates: PropTypes.any,
  accounts: PropTypes.any,
  count: PropTypes.any,
  setCount: PropTypes.any,
  transRange: PropTypes.any,
};
const mapStateToProps = state => {
  const { accountsDefinition, customccbAccountsDefinition } = state;
  return {
    ...accountsDefinition,
    ...customccbAccountsDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...ccbAccountsMapDispatch },
)(TabTransaction);
