import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

class Buttons extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    return (
      <section id="buttons-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/components/buttons.scss</strong>
          </code>
        </p>
        <p>
          There is a component made for the <strong>buttons</strong> defined
          within reactstrap. To see an interactive technical specification for
          this component, go to:
          <br />
          <Link to="https://reactstrap.github.io/components/buttons/">
            https://reactstrap.github.io/components/buttons/
          </Link>
          <br />
          <Link to="https://reactstrap.github.io/components/button-group/">
            https://reactstrap.github.io/components/button-group/
          </Link>
          <br />
          <Link to="https://reactstrap.github.io/components/button-dropdown/">
            https://reactstrap.github.io/components/button-dropdown/
          </Link>
        </p>
        <Row>
          <Col xs={4} sm={3}>
            <Button color="primary">Primary</Button>
            <span>Type: primary action</span>
            <span>Class: btn-primary</span>
          </Col>
          <Col xs={4} sm={3}>
            <Button color="secondary">Secondary</Button>
            <span>Type: secondary action</span>
            <span>Class: btn-secondary</span>
          </Col>
          <Col xs={4} sm={3}>
            <Button color="default">Default</Button>
            <span>Type: Cancel / back action</span>
            <span>Class: btn-default</span>
          </Col>
          <Col xs={4} sm={3}>
            <Button color="link">Link</Button>
            <span>Type: link action</span>
            <span>Class: btn-link</span>
          </Col>
        </Row>
        <Row>
          <Col xs={4} sm={3}>
            <Button color="primary" disabled>
              Primary
            </Button>
            <span>Type: primary action</span>
            <span>Class: btn-primary</span>
          </Col>
          <Col xs={4} sm={3}>
            <Button color="secondary" disabled>
              Secondary
            </Button>
            <span>Type: secondary action</span>
            <span>Class: btn-secondary</span>
          </Col>
          <Col xs={4} sm={3}>
            <Button color="default" disabled>
              Default
            </Button>
            <span>Type: Cancel / back action</span>
            <span>Class: btn-default</span>
          </Col>
          <Col xs={4} sm={3}>
            <Button color="link" disabled>
              Link
            </Button>
            <span>Type: link action</span>
            <span>Class: btn-link</span>
          </Col>
        </Row>
        <Row>
          <Col xs={4} sm={3}>
            <Button className="btn btn-primary btn-loading">loading</Button>
            <span>Type: loading action</span>
            <span>Class: btn-loading</span>
          </Col>
          <Col xs={4} sm={3}>
            <Button color="primary" size="lg">
              Large
            </Button>
            <span>Type: Large button</span>
            <span>Class: bt-lg</span>
          </Col>
          <Col xs={4} sm={3}>
            <Button color="primary" size="sm">
              Small
            </Button>
            <span>Type: Small button</span>
            <span>Class: btn-sm</span>
          </Col>
          <Col xs={4} sm={3}>
            <ButtonGroup>
              <Button color="secondary active">Active</Button>
              <Button>Closed</Button>
            </ButtonGroup>

            <span>Type: Toggle state buttons</span>
            <span>Class: stackbutton &amp; gridbutton</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <ButtonDropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.toggle}
            >
              <DropdownToggle caret>Button Dropdown</DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>Header</DropdownItem>
                <DropdownItem disabled>Action</DropdownItem>
                <DropdownItem>Another Action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Another Action</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            <span>Type: Button Dropdown</span>
            <span>Class: btn-group</span>
          </Col>
          <Col xs={6}>
            <ButtonDropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.toggle}
            >
              <DropdownToggle caret color="secondary alt-group">
                Button Dropdown
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>Header</DropdownItem>
                <DropdownItem disabled>Action</DropdownItem>
                <DropdownItem>Another Action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Another Action</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            <span>Type: Button Dropdown</span>
            <span>Class: btn-group alt-group</span>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <ButtonDropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.toggle}
            >
              <DropdownToggle caret disabled>
                Button Dropdown
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>Header</DropdownItem>
                <DropdownItem disabled>Action</DropdownItem>
                <DropdownItem>Another Action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Another Action</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            <span>Type: Button Dropdown</span>
            <span>Class: btn-group</span>
          </Col>
          <Col xs={6}>
            <ButtonDropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.toggle}
            >
              <DropdownToggle caret color="secondary alt-group" disabled>
                Button Dropdown
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>Header</DropdownItem>
                <DropdownItem disabled>Action</DropdownItem>
                <DropdownItem>Another Action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Another Action</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            <span>Type: Button Dropdown</span>
            <span>Class: btn-group alt-group</span>
          </Col>
        </Row>
      </section>
    );
  }
}

Buttons.propTypes = {
  items: PropTypes.string,
};

export default Buttons;
