import React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { Validate } from '@myie/interact';
import { Row, Col, Form, Button, BreadcrumbItem, Spinner } from 'reactstrap';
import {
  Content,
  FormErrorList,
  FormattedCurrency,
  Check,
  Markdown,
} from '@myie/interact-dom';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-ccb-savings-servicing';
import PropTypes from 'prop-types';
import requestPayload from '../shortApply/requestPayload';
import { mapDispatchToProps as ccbOrganisationDispatches } from '@myie/interact-ccb-organisation';
import { mapDispatchToProps as userManagementDispatches } from '@myie/interact-user-management';
class ShortApplyApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState(props);
  }

  initialState = props => {
    const { selectedProduct } = props;
    let data = null;
    const formItems = {
      accuracyConfirm: {
        rules: {
          required: {
            message:
              'You cannot proceed without opening and reading the Account Terms & Conditions, Summary Box & Tariff of Charges. Please tick the box to authorise the application.',
          },
        },
        value: false,
      },
      informationConfirm: {
        rules: {
          required: {
            message:
              'You cannot proceed without opening and reading the Information Sheet & Exclusions List. Please tick the box to authorise the application.',
          },
        },
        value: false,
      },
    };

    if (
      selectedProduct &&
      selectedProduct.InterestPaymentFrequency &&
      selectedProduct.InterestPaymentFrequency === 'both'
    ) {
      formItems.interestPaymentFrequency = {
        rules: {
          stop: true,
          required: {
            message:
              'Please tell us when you would like us to pay your interest.',
          },
        },
        value: data ? data.interestPaymentFrequency : '',
      };
    }
    return {
      data: data ? data : {},
      form: formItems,
    };
  };

  dropdownOptionValue(item, value) {
    let keys = Object.keys(item);
    if (keys[0] === value) {
      return item[keys[0]];
    }
  }

  componentDidUpdate() {
    this.initialState(this.props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { fetchUserDetailsIfNeeded } = this.props;
    let request = {
      ExtendedProperties: {},
    };
    fetchUserDetailsIfNeeded(request);
  }

  updateCurrency = e => {
    let { name, value } = e.target;
    const { data } = this.state;
    let newValue = value;
    newValue = new Intl.NumberFormat().format(value.replace(/,/g, ''));

    // Deal with trailing . or .0 or .n0
    if (value.charAt(value.length - 1) === '.') {
      newValue += '.';
    }
    if (value.slice(value.length - 2) === '.0') {
      newValue += '.0';
    }
    if (
      value.charAt(value.length - 3) === '.' &&
      value.charAt(value.length - 1) === '0'
    ) {
      if (value.charAt(value.length - 2) === '0') {
        newValue += '.0';
      }
      newValue += '0';
    }
    if (newValue === '0') {
      newValue = '';
    }
    let { form } = this.state;
    form = Validate.input(name, newValue, form, true);
    data[name] = value;

    this.setState({ ...this.state, form });
  };

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  updateForm = e => {
    const { name, checked } = e.target;
    let { form } = this.state;
    form = Validate.input(name, checked, form);
    this.setState({ ...this.state, form });
  };

  onSubmitHandler = e => {
    e.preventDefault();
    const { userDetails } = this.props;
    let {
      saveShortApply,
      selectedProduct,
      formData,
      updateUrl,
      selectedOrganisationDetails,
    } = this.props;
    let { form } = this.state;

    form = Validate.form(form);
    if (form.approved) {
      let request = requestPayload.getPayload(
        formData,
        form,
        selectedProduct,
        userDetails,
        selectedOrganisationDetails,
      );
      saveShortApply(request);
      let stateData = {
        formData: formData,
      };
      updateUrl(stateData);
    } else {
      window.scrollTo(0, 0);
      this.setState({ form });
    }
  };

  onClick = e => {
    const { name, checked } = e.target;
    let { form, data } = this.state;

    form = Validate.input(name, checked ? 'true' : null, form);
    data[name] = checked ? 'true' : null;

    this.setState({ ...this.state, form, data });
  };

  dropdownOptions(item, index, name) {
    let keys = Object.keys(item);
    return (
      <option id={`${name}-option-${keys[0]}`} key={index} value={keys[0]}>
        {item[keys[0]]}
      </option>
    );
  }

  changeDetails = () => {
    const { formData, updateUrl, setStateDynamic } = this.props;
    setStateDynamic({
      stage: 'apply',
    });
    let stateData = {
      formData: formData,
      url: {
        pageStage: 'apply',
      },
    };
    setStateDynamic({ stage: 'apply' });
    updateUrl(stateData);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  changeStage = () => {
    const { setStateDynamic } = this.props;
    setStateDynamic({ stage: 'list' });
  };

  render() {
    const {
      selectedProduct,
      formData,
      userDetails,
      selectedOrganisationDetails,
      isFetching,
    } = this.props;
    const details = selectedOrganisationDetails;
    let { CorrespondenceAddress = null, RegisteredAddress = null } =
      details || {};
    if (!userDetails) {
      return (
        <p key="loading">
          <Content id="loadingDetails" copytext="Loading details" />
          {`...`}
        </p>
      );
    }
    const { Title, FirstName, MiddleName, Surname } = userDetails;
    const { form } = this.state;

    return (
      <div id="short-apply-application">
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Shared:Short-apply-review:h1"
          template={{
            copytext: {
              productName: selectedProduct.Product_Name,
            },
          }}
          copytext="Apply for $[productName]"
        />

        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem className="completed">
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Account-details"
                copytext="1 Account details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active>
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Review-and-submit"
                copytext="2 Review and submit"
              />
            </BreadcrumbItem>
          </ol>
        </div>
        <div className="clear-both"></div>
        <FormErrorList
          validations={form}
          disabled={false}
          required={true}
          groupClassName=""
          title="h2"
          showErrors={true}
        />
        <Form
          autoComplete="off"
          onSubmit={this.onSubmitHandler}
          className="short-apply-form"
        >
          {isFetching && (
            <>
              <div className="text-center">
                <Spinner className="m-5" color="primary"></Spinner>
              </div>
              <Content
                tag="p"
                cmsTag="Savings-servicing:Shared:Short-apply-application:Submit-loading-message"
                copytext={`Thank you, your submission is in progress. Please wait for your application reference number on the next screen.`}
              />
            </>
          )}
          {!isFetching && (
            <>
              <Content
                tag="h2"
                cmsTag="GLOBAL:Account-details"
                copytext="Account Details"
              />
              <Row tag="dl" title="Account details">
                <Col tag="dt" xs="12" md="6">
                  <Content cmsTag="GLOBAL:Account" copytext="Account" />
                </Col>
                <Col tag="dd" xs="12" md="6">
                  {selectedProduct.Product_Name}
                </Col>
                <Col tag="dt" xs="12" md="6">
                  <Content
                    cmsTag="GLOBAL:Organisation-name"
                    copytext="Name of business/Organisation"
                  />
                </Col>
                <Col tag="dd" xs="12" md="6">
                  {details?.FullName}
                </Col>
              </Row>
              <Row tag="dl" title="Organisation details" className="mb-5">
                <Col tag="dt" xs="12" md="6">
                  <Content
                    cmsTag="GLOBAL:Organisation-address"
                    copytext="Organisation Address"
                  />
                </Col>
                <Col tag="dd" xs="12" md="6">
                  <address className="organisation-address">
                    {CorrespondenceAddress?.Street ? (
                      <React.Fragment>
                        {CorrespondenceAddress?.Street}
                        <br />
                      </React.Fragment>
                    ) : null}
                    {CorrespondenceAddress?.District ? (
                      <React.Fragment>
                        {CorrespondenceAddress?.District}
                        <br />
                      </React.Fragment>
                    ) : null}
                    {CorrespondenceAddress?.Town ? (
                      <React.Fragment>
                        {CorrespondenceAddress?.Town}
                        <br />
                      </React.Fragment>
                    ) : null}
                    {CorrespondenceAddress?.County ? (
                      <React.Fragment>
                        {CorrespondenceAddress?.County}
                        <br />
                      </React.Fragment>
                    ) : null}
                    {CorrespondenceAddress?.Postcode ? (
                      <React.Fragment>
                        {CorrespondenceAddress?.Postcode}
                        <br />
                      </React.Fragment>
                    ) : null}
                  </address>
                </Col>
                <Col tag="dt" xs="12" md="6">
                  <Content
                    cmsTag="GLOBAL:Registered-office"
                    copytext="Registered Office"
                  />
                </Col>
                <Col tag="dd" xs="12" md="6">
                  <address className="registered-office">
                    {RegisteredAddress?.Street ? (
                      <React.Fragment>
                        {RegisteredAddress?.Street}
                        <br />
                      </React.Fragment>
                    ) : null}
                    {RegisteredAddress?.District ? (
                      <React.Fragment>
                        {RegisteredAddress?.District}
                        <br />
                      </React.Fragment>
                    ) : null}
                    {RegisteredAddress?.Town ? (
                      <React.Fragment>
                        {RegisteredAddress?.Town}
                        <br />
                      </React.Fragment>
                    ) : null}
                    {RegisteredAddress?.County ? (
                      <React.Fragment>
                        {RegisteredAddress?.County}
                        <br />
                      </React.Fragment>
                    ) : null}
                    {RegisteredAddress?.Postcode ? (
                      <React.Fragment>
                        {RegisteredAddress?.Postcode}
                        <br />
                      </React.Fragment>
                    ) : null}
                  </address>
                </Col>
                <Col tag="dt" xs="12" md="6">
                  <Content
                    cmsTag="GLOBAL:Organisation-Details-Changed"
                    copytext="Have any of your organisation details changed?"
                  />
                </Col>
                <Col tag="dd" xs="12" md="6">
                  {formData.orgDetailsChanged === 'yes' ? 'Yes' : 'No'}
                </Col>
                {formData.orgDetailsChanged === 'yes' && (
                  <>
                    <Col tag="dt" xs="12" md="6">
                      <Content
                        cmsTag="GLOBAL:Changed-details"
                        copytext="Changed details"
                      />
                    </Col>
                    <Col tag="dd" xs="12" md="6">
                      {formData.organisationDetailsChangedInput}
                    </Col>
                  </>
                )}
              </Row>
              <Content
                tag="h3"
                cmsTag="Savings-servicing:Shared:Short-apply-application:h3-4"
                copytext="Deposit Details"
              />
              <Row tag="dl" title="Deposit details">
                <Col tag="dt" xs="12" md="6">
                  <Content
                    cmsTag="GLOBAL:deposit-amount"
                    copytext="How much would you like to invest?"
                  />
                </Col>
                <Col tag="dd" xs="12" md="6">
                  <FormattedCurrency
                    quantity={formData.depositAmount.replace(/,/g, '')}
                    currency={'GBP'}
                  />
                </Col>
                <Col tag="dt" xs="12" md="6">
                  <Content
                    cmsTag="GLOBAL:initial-deposit-source"
                    copytext="Where has the deposit come from?"
                  />
                </Col>
                <Col tag="dd" xs="12" md="6">
                  {formData.depositSource}
                </Col>
                <Col tag="dt" xs="12" md="6">
                  <Content
                    cmsTag="GLOBAL:initial-deposit-method"
                    copytext="How will you make your deposit?"
                  />
                </Col>
                <Col tag="dd" xs="12" md="6">
                  {formData.depositMethod}
                </Col>
              </Row>
              <Check
                cmsTag="GLOBAL:I-confirm-the-details-I-have-given-are-accurate"
                label={
                  <Markdown
                    cmsTag="GLOBAL:Agree-privacy-policy"
                    markdown={`I, ${Title ?? ''} ${FirstName ??
                      ''} ${MiddleName ?? ''} ${Surname ??
                      ''}, authorise the application on behalf of ${details.FullName ??
                      ''}. I confirm that by ticking this box:`}
                  />
                }
                id={`accuracyConfirm`}
                field="accuracyConfirm"
                value={false}
                onChange={this.onChange}
                validation={form.accuracyConfirm}
                showErrors={true}
              />
              <Markdown
                cmsTag="Savings-servicing:Shared:Short-apply-application:section-1"
                markdown={`\nI agree to the <a target="_blank" href="${selectedProduct.Product_Specific_TAndCs_Link}">Account Terms & Conditions</a>,  the <a target="_blank" href="${selectedProduct.Summary_box_url}">Summary Box</a> and the <a target="_blank" href="https://ccbank.co.uk/s-t-c"> Tariff of Charges</a> (together the Agreement) being presented to me electronically. That I have full legal authority to enter into the Agreement on behalf of the organisation I represent, and I have read and understand the Agreement and agree to be bound by its terms.\n\nI agree that the savings product will be provided subject to the Agreement, as specified and amended from time to time.\n\nI understand that the Agreement is legally binding and is enforceable under the laws of England and Wales.\n\nI confirm that I am eligible to apply for this savings account and that the information provided as part of the account opening is true and accurate.\n\nI confirm that the organisation I represent authorise you to act on the instructions of the signatories (as set out to you in the Terms & Conditions) and you will not be responsible if the organisation suffers a loss as a result of you acting on the authority and instructions of the signatories. I confirm that the signatories are authorised to act on behalf of the organisation and will tell you immediately in writing if the signatories are to be changed or removed;\n\nWe are covered by the Financial Services Compensation Scheme (FSCS). The FSCS can pay compensation to depositors if a bank is unable to meet its financial obligations.\n\nFor the current level of cover, further details and to view/download the Information Sheet & Exclusions list, please visit our site at <a target="_blank" href="https://www.ccbank.co.uk/fscs">www.ccbank.co.uk/fscs</a>.`}
              />
              <Check
                cmsTag="GLOBAL:I-confirm-the-details-I-have-given-are-accurate"
                label="I/We confirm that I/we have received the Information Sheet & Exclusions List. This box must be ticked to proceed."
                id={`informationConfirm`}
                field="informationConfirm"
                checked={!!form.informationConfirm.value}
                value={false}
                validation={form.informationConfirm}
                onChange={this.onChange}
                showErrors={true}
              />
              <Markdown
                cmsTag="Savings-servicing:Shared:Short-apply-application:section-2"
                markdown={`\nIt is a regulatory requirement that all banks have to maintain records to help determine the eligibility of their customers.\n\nIf the organisation's circumstance change during the term of the account, you must inform us of the change.  Most depositors, including individuals and businesses, are covered by the scheme.\n\nIf you are unsure as to whether you are eligible or not, you can call us on <a href="tel:03442253939">0344 225 3939</a>  or email <a  href="mailto:savings@ccbank.co.uk">savings@ccbank.co.uk</a>.`}
              />
              <img
                className="fscs-svg"
                src="/fscs-logo.png"
                alt="Financial Services Compensation Scheme logo"
              />

              <Markdown
                cmsTag="Savings-servicing:Shared:Short-apply-application:section-3"
                markdown={`\nAlternatively, you can contact FSCS directly on <a href="tel:08006781100">0800 678 1100</a> or visit <a target="_blank" href="http://www.fscs.org.uk">www.fscs.org.uk</a>.`}
              />

              <Markdown
                className="alert alert-danger"
                cmsTag="Savings-servicing:Shared:Short-apply-application:alert-1"
                markdown={`Please review the summary of your application above. You may also wish to print a copy of this page using your browser print facility or save a copy. Please submit your application once you have checked it.\n\nConfirmation of your application and further instructions will be sent to the email address that is held for your organisation following submission of the application.`}
              />
            </>
          )}
          <div className="form-button-group">
            <Button
              id="create-new-notice"
              type="submit"
              color="primary"
              disabled={isFetching}
            >
              <Content
                cmsTag="GLOBAL:Submit-application"
                copytext="Submit application"
              />
            </Button>
            <Button
              id="change-details-link"
              className="btn btn-light"
              onClick={this.changeDetails}
              disabled={isFetching}
            >
              <Content
                cmsTag="GLOBAL:Change-details"
                copytext="Back to step 1"
              />
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

ShortApplyApplication.propTypes = {
  accounts: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  onSubmit: PropTypes.func,
  setStage: PropTypes.func,
  userDetails: PropTypes.any,
  setStateDynamic: PropTypes.any,
  formData: PropTypes.any,
  selectedProduct: PropTypes.any,
  data: PropTypes.any,
  saveShortApply: PropTypes.func,
  updateUrl: PropTypes.func,
  organisation: PropTypes.any,
  fetchUserDetailsIfNeeded: PropTypes.func,
  selectedOrganisationDetails: PropTypes.object,
  isFetching: PropTypes.bool,
};
const mapStateToProps = state => {
  const {
    accountsDefinition,
    customccbSavingsServicingDefinition,
    customccbOrganisationDefinition,
    userManagementDefinition,
  } = state;
  return {
    ...accountsDefinition,
    ...customccbOrganisationDefinition,
    ...userManagementDefinition,
    ...customccbSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps,
    ...mapSavingsServicingDispatchToProps,
    ...ccbOrganisationDispatches,
    ...userManagementDispatches,
  },
)(ShortApplyApplication);
