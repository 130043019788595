import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute, NoMatch } from '@myie/interact-dom';
import UpdateOrganisationDetails from './UpdateOrganisationDetails';
import UpdateOrganisationDetailsSuccess from './UpdateOrganisationDetailsSuccess';
import { Alert } from 'reactstrap';

class OrganisationDetailsIndex extends React.Component {
  render() {
    const { error } = this.props;
    var re = new RegExp('^/update-organisation-details[/]{0,1}$');
    if (this.props.location.pathname.match(re)) {
      return <Redirect to="/update-organisation-details/retrieve" />;
    }
    return (
      <div id="update-organisation-details">
        {error ? (
          <Alert color="danger" role="alert">
            {error}
          </Alert>
        ) : (
          ''
        )}
        <Switch>
          <PrivateRoute
            exact
            path="/update-organisation-details/retrieve"
            component={UpdateOrganisationDetails}
          />
          <PrivateRoute
            exact
            path="/update-organisation-details/success"
            component={UpdateOrganisationDetailsSuccess}
          />
          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

OrganisationDetailsIndex.propTypes = {
  error: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  location: PropTypes.any,
};

export default OrganisationDetailsIndex;
