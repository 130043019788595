import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'reactstrap';
import { Icon, Content } from '@myie/interact-dom';

class FeaturesAdvert3 extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    return (
      <Card className="advert">
        <h2>
          <Content
            id="FeaturesAdvert3:Advert.title"
            copytext="Features and benefits"
          />
        </h2>
        <Row>
          <Col xs={6}>
            <p>
              <Content
                id="FeaturesAdvert3:Advert.lead"
                copytext="2% AER fixed"
              />
            </p>
            <p>
              <Content
                id="FeaturesAdvert3:Advert.paragraph1"
                copytext="Start with just £1 Manage it online"
              />
            </p>
            <a href="/">
              <Content
                id="FeaturesAdvert3:Advert.Link"
                copytext="View full details (PDF)"
              />
            </a>
          </Col>
          <Col xs={6}>
            <Icon name="LoanIcon" />
          </Col>
        </Row>
      </Card>
    );
  }
}

FeaturesAdvert3.propTypes = {
  items: PropTypes.string,
};

export default FeaturesAdvert3;
