import SummaryMenuItems from './SummaryMenuItems';

const MessagingTwowayMenuItems = [
  {
    url: '/messaging-twoway',
    menuItem: SummaryMenuItems,
  },
];

export default MessagingTwowayMenuItems;
