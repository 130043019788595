import React from 'react';
import { Route } from 'react-router-dom';
import PersonalDetails from './PersonalDetails';
//import ManageStatementsIndex from './ManageStatements';

const Routes = [
  <Route
    key="/personal-details"
    path="/personal-details"
    component={PersonalDetails}
  />,
];

export default Routes;
