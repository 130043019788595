import React from 'react';
import TransferWidgetTest from './MessageWidgetTest';
import Confirmation from './Confirmation';
import { PrivateRoute } from '@myie/interact-dom';

const Routes = [
  <PrivateRoute
    key="/message-widget-test"
    path="/message-widget-test"
    component={TransferWidgetTest}
  />,
  <PrivateRoute
    key="/message-widget-success"
    path="/message-widget/message/success/"
    render={routeProps => <Confirmation {...routeProps} />}
  />,
];

export default Routes;
