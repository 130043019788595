import React, { Component } from 'react';
import { AppMeta, Content } from '@myie/interact-dom';
import { BreadcrumbItem, Breadcrumb } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Utility } from '@myie/interact';
import PropTypes from 'prop-types';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-ccb-savings-servicing';
import { connect } from 'react-redux';
import CancellationSuccess from './CancellationSuccess';
import Cancel from './Cancel';
import moment from 'moment';

class CancelNoticeToWithdrawView extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    const { accounts, match } = this.props;
    let account;
    if (accounts) {
      account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
    } else {
      account = null;
    }
    return {
      account: account,
      stage: 'ConfirmCancel',
      withdrawDate: moment(),
      form: {},
      identifier: match.params.referenceNo,
    };
  };

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (nextProps, state) => {
    const {
      cancellationResponse = {},
      accounts = {},
      fetchSavingsAccountTransactions,
      isFetching,
    } = nextProps;

    const setInitialRange = props => {
      if (props.range) {
        return props.range;
      }
      const to = moment
        .utc()
        .startOf('day')
        .toISOString();
      const from = moment
        .utc()
        .subtract(12, 'months')
        .add(1, 'day')
        .startOf('day')
        .toISOString();
      return {
        To: to,
        From: from,
        Latest: true,
      };
    };
    if (
      accounts &&
      state.account &&
      !state.account.transactions &&
      !isFetching
    ) {
      fetchSavingsAccountTransactions({
        AccountKey: state.account.AccountKey,
        Range: setInitialRange(nextProps),
      });
    }

    if (cancellationResponse && cancellationResponse.Success) {
      return {
        stage: 'Success',
      };
    }

    return null;
  };

  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  onConfirmCancellation = () => {
    const { cancelNoticeToWithdraw } = this.props;
    const { selectedAccount, selectedTransaction } = this.state;
    cancelNoticeToWithdraw({
      AccountKey: selectedAccount && selectedAccount.AccountKey,
      TransactionIdentifier: selectedTransaction.TransactionIdentifier,
    });
  };

  // select current stage
  currentStage = account => {
    const { accounts = {} } = this.props;
    const { AccountKey: { Key } = {} } = account;
    const accountKey = Key && Utility.hexEncode(Key);

    switch (this.state.stage) {
      case 'ConfirmCancel':
        return (
          <Cancel
            accounts={accounts}
            account={account}
            identifier={this.state.identifier}
          />
        );
      case 'Success':
        return <CancellationSuccess accountKey={accountKey} />;

      default:
    }
  };

  render() {
    const { stage, account } = this.state;
    if (!account) {
      return null;
    }

    return (
      <>
        {stage === 'ConfirmCancel' ? (
          <>
            <AppMeta
              id="meta-data"
              stage="child"
              title="Confirm notice to withdraw cancellation"
              metaDescription="Confirm notice to withdraw cancellation"
            />
            <h1>
              <Content
                id="pageTitle"
                copytext="Confirm notice to withdraw cancellation"
              />
            </h1>
            <div className="breadcrumb-container">
              <Breadcrumb tag="nav">
                <BreadcrumbItem>
                  <Content
                    id="notice-to-withdrawal-step1"
                    copytext="1 Withdrawal Details"
                  />
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <Content
                    id="notice-to-withdrawal-step2"
                    copytext="2 Review"
                  />
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Content
                    id="notice-to-withdrawal-step3"
                    copytext="3 Confirmation"
                  />
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </>
        ) : (
          ''
        )}
        {stage === 'Success' ? (
          <>
            <AppMeta
              id="meta-data"
              stage="child"
              title="Notice to withdraw cancellation successful"
              metaDescription="Notice to withdraw cancellation successful"
            />
            <h1>
              <Content
                id="pageTitle"
                copytext="Notice to withdraw cancellation successful"
              />
            </h1>
            <div className="breadcrumb-container">
              <Breadcrumb tag="nav">
                <BreadcrumbItem>
                  <Content
                    id="notice-to-withdrawal-step1"
                    copytext="1 Withdrawal Details"
                  />
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Content
                    id="notice-to-withdrawal-step2"
                    copytext="2 Review"
                  />
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <Content
                    id="notice-to-withdrawal-step3"
                    copytext="3 Confirmation - Successful"
                  />
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </>
        ) : (
          ''
        )}

        {this.currentStage(account)}
      </>
    );
  }
}

CancelNoticeToWithdrawView.propTypes = {
  match: PropTypes.any,
  accounts: PropTypes.any,
  cancellationResponse: PropTypes.any,
  message: PropTypes.any,
  history: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.func,
  cancelNoticeToWithdraw: PropTypes.func,
  fetchSavingsAccountTransactionsIfNeeded: PropTypes.func,
  isFetching: PropTypes.any,
  referenceNo: PropTypes.any,
  range: PropTypes.any,
};

const mapStateToProps = state => {
  const { accountsDefinition, customccbSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...customccbSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
)(withRouter(CancelNoticeToWithdrawView));
