import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormattedDate, FormattedCurrency } from '@myie/interact-dom';

class ListPageTransaction extends React.Component {
  render() {
    const { props } = this;

    const { account, transaction } = props;

    return (
      <Row
        className="mb-2"
        data-transaction-id={transaction.TransactionIdentifier}
      >
        <Col xs={12} sm={4}>
          <div className="label mb-0">
            {transaction.Description.charAt(0).toUpperCase() +
              transaction.Description.slice(1).toLowerCase()}
          </div>
        </Col>
        <Col xs={12} sm={4}>
          <FormattedDate date={transaction.Date} />
        </Col>
        <Col xs={12} sm={4} className="text-xs-left text-sm-right">
          <FormattedCurrency
            quantity={transaction.Amount}
            currency={account.CurrencyCode}
          />
        </Col>
      </Row>
    );
  }
}

ListPageTransaction.propTypes = {
  account: PropTypes.object,
  position: PropTypes.any,
  transaction: PropTypes.any,
};

export default ListPageTransaction;
