/* eslint-disable no-mixed-spaces-and-tabs, prettier/prettier */

import { Routes as AccountsRoutes } from '@myie/interact-accounts-dom'
import { Routes as AuthenticationRoutes } from '@myie/interact-authentication-dom'
import { Routes as ServicesRoutes } from '@myie/interact-services-dom'
import { Routes as StyleguideRoutes } from '@myie/interact-styleguide-dom'
import { Routes as MessagingTwowayRoutes } from '@myie/interact-messaging-twoway-dom'
import { Routes as SavingsServicingRoutes } from '@myie/interact-savings-servicing-dom'
import { Routes as WidgetSelectAccountRoutes } from '@myie/interact-widget-select-account-dom'
import { Routes as WidgetSendMessageRoutes } from '@myie/interact-widget-send-message-dom'
import { Routes as DocumentsRoutes } from '@myie/interact-documents-dom'
import { Routes as UserManagementRoutes } from '@myie/interact-user-management-dom'


const AvailableRoutes = [];

AvailableRoutes.push(AccountsRoutes)
AvailableRoutes.push(AuthenticationRoutes)
AvailableRoutes.push(ServicesRoutes)
AvailableRoutes.push(StyleguideRoutes)
AvailableRoutes.push(MessagingTwowayRoutes)
AvailableRoutes.push(SavingsServicingRoutes)
AvailableRoutes.push(WidgetSelectAccountRoutes)
AvailableRoutes.push(WidgetSendMessageRoutes)
AvailableRoutes.push(DocumentsRoutes)
AvailableRoutes.push(UserManagementRoutes)


export default AvailableRoutes;

