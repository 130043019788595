import React, { Component } from 'react';
import {
  Navbar,
  Container,
  Collapse,
  Nav,
  NavItem,
  NavbarToggler,
} from 'reactstrap';
import { Session } from '@myie/interact';
import RegisteredMenus from './Registered';

class Menu extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  isActive(route, exact) {
    if (exact) {
      return window.location.pathname === route;
    }
    var re = new RegExp(`^${route}[/]{0,1}`);
    return window.location.pathname.match(re) !== null;
  }

  toggle() {
    this.setState({
      ...this.state,
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    // don't show menu if not logged in
    if (!Session.isAuthenticated()) {
      return <></>;
    } else {
      return (
        <Navbar id="main-menu" expand="md" aria-label="Main menu">
          <Container>
            <NavbarToggler
              aria-label="Click here to open or close the toggle bar"
              onClick={this.toggle}
            >
              <span></span>
              <span></span>
              <span></span>
            </NavbarToggler>
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav navbar className="mr-auto">
                {RegisteredMenus.map(sb => (
                  <NavItem key={sb.url} active={this.isActive(sb.url)}>
                    {<sb.menuItem />}
                  </NavItem>
                ))}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      );
    }
  }
}

export default Menu;
