import React from 'react';
import SelectAccountsTest from './SelectAccountsTest';
import { PrivateRoute } from '@myie/interact-dom';

const Routes = [
  <PrivateRoute
    key="/select-accounts-test"
    path="/select-accounts-test"
    component={SelectAccountsTest}
  />,
];

export default Routes;
