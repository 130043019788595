import React, { Component } from 'react';
import { PrivateRoute, NoMatch } from '@myie/interact-dom';
import { Switch, Route } from 'react-router-dom';
import CreateNotice from './CreateNoticeToWithdraw/CreateNotice';
import NoticeToWithdrawList from './ListNoticeToWithdraws';
import CancelNoticeToWithdrawView from './CancelNoticeToWithdraw';

class NoticeToWithdrawals extends Component {
  render() {
    return (
      <div id="notice-to-withdrawal-card">
        <Switch>
          <PrivateRoute
            path="/notice-to-withdraw/cancel/:id/:referenceNo"
            component={CancelNoticeToWithdrawView}
          />

          <PrivateRoute
            path="/notice-to-withdraw/create/:id"
            component={CreateNotice}
          />
          <PrivateRoute
            path="/notice-to-withdraw/:id"
            component={NoticeToWithdrawList}
          />
          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

export { NoticeToWithdrawals };
