import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Content, ContactNumber } from '@myie/interact-dom';

class Unsuccessful extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }

  render() {
    return (
      <div id="registration-unsuccessful">
        <h1 id="registration-success-title">
          <Content id="title" copytext="Registration Unsuccessful" />
        </h1>

        <p>
          <Content
            id="unsuccessful-description"
            copytext="We are sorry but your registration process has been unsuccessful"
          />
        </p>
        <p>
          <Content
            id="unsuccessful-contact-details"
            copytext="Please contact us on "
          />
          <ContactNumber />
          <Content
            id="unsuccessful-contact"
            copytext=" (Mon - Fri 9am to 5pm)."
          />
        </p>

        <div className="form-button-group">
          <Link
            id="registration-unsuccess-home-button"
            className="btn btn-primary"
            role="button"
            to="/registration/register"
          >
            <Content id="TryAgain" copytext="Try again" />
          </Link>
        </div>
      </div>
    );
  }
}

Unsuccessful.propTypes = {
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Unsuccessful);
