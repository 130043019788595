import React from 'react';
import { BreadcrumbItem, Breadcrumb, Alert } from 'reactstrap';
import { Content, AppMeta } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-ccb-savings-servicing';
import { connect } from 'react-redux';
import { Utility } from '@myie/interact';
import moment from 'moment';
import MakePayment from './MakePayment';
import Confirmation from './Confirmation';
import WithdrawalSuccess from './WithdrawalSuccess';

class MakeWithdrawal extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    const { accounts, match } = this.props;
    let account;
    if (accounts) {
      account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
    } else {
      account = null;
    }
    return {
      account: account,
      stage: 'MakePayment',
      actionDate: moment(new Date())
        .utc()
        .format('YYYY-MM-DD'),
      form: {},
    };
  };

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = nextProps => {
    const { makeWithdrawResponse = {} } = nextProps;

    if (
      makeWithdrawResponse &&
      makeWithdrawResponse.ResponseStatus === 'Success'
    ) {
      return {
        stage: 'CompletePayment',
      };
    }

    return null;
  };

  componentDidMount() {
    const { resetMakeWithdrawal, fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
    resetMakeWithdrawal();
  }

  componentWillUnmount() {
    const { resetMakeWithdrawal } = this.props;
    resetMakeWithdrawal();
  }

  componentDidUpdate(prevProps) {
    const { match, accounts } = this.props;
    if (accounts && prevProps.accounts !== accounts) {
      const account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
      this.setState({ ...this.state, account });
    }
  }

  // common onChangeAccount function
  onChangeAccount = e => {
    const { resetMakeWithdrawal, fetchAccountsIfNeeded, accounts } = this.props;
    fetchAccountsIfNeeded();
    resetMakeWithdrawal();
    const { value } = e.target;
    if (accounts && accounts.Accounts) {
      const account = accounts.Accounts.find(function(element) {
        return element.AccountKey.Key === value;
      });
      this.setState({ ...this.initialState(), account });
    }
  };

  // change the stage of the form
  setStage = stage => {
    this.setState({ ...this.state, stage, message: null });
  };

  setForm = form => {
    this.setState({ ...this.state, form, message: null });
  };

  onCancelleWithdrawal = () => {
    const { history } = this.props;
    history.push('/accounts/list');
  };

  //update the stage in state
  submitForm = (stage, form) => {
    if (stage === 'MakePayment') {
      const { resetMakeWithdrawal } = this.props;
      resetMakeWithdrawal();
    }

    this.setState({ ...this.state, stage, form, message: null });
  };

  onCancelleWithdrawal = () => {
    const { history } = this.props;
    history.push('/accounts/list');
  };

  onSubmitWithdrawal = () => {
    const { account, form } = this.state;
    const { makeaWithdraw } = this.props;
    let formCloseAccount = form.closeAccount.value;
    let request = {
      AccountKey: account.AccountKey,
      WithdrawalInformation: {
        ActionDate: this.state.actionDate,
        Amount: form.amount.value.replace(/,/g, ''),
        CloseAccount: false,
        PaymentReference: form.reference.value ? form.reference.value : null,
      },
    };
    if (formCloseAccount) {
      request = {
        AccountKey: account.AccountKey,
        WithdrawalInformation: {
          ActionDate: this.state.actionDate,
          CloseAccount: true,
        },
      };
    }

    makeaWithdraw(request);
  };

  // select current stage
  currentStage = () => {
    const { accounts = {} } = this.props;
    const { account = {} } = this.state;
    switch (this.state.stage) {
      case 'MakePayment':
        return (
          <MakePayment
            key={account.AccountNumber}
            setForm={this.setForm}
            submitForm={this.submitForm}
            onChangeAccount={this.onChangeAccount}
            actionDate={this.state.actionDate}
            accounts={accounts}
            account={account}
            onCancelled={this.onCancelleWithdrawal}
          />
        );
      case 'ReviewPayment':
        return (
          <Confirmation
            onSubmitWithdrawal={this.onSubmitWithdrawal}
            form={this.state.form}
            setStage={this.setStage}
            account={account}
          />
        );
      case 'CompletePayment':
        return (
          <WithdrawalSuccess
            form={this.state.form}
            setStage={this.setStage}
            activate={this.activate}
            account={account}
          />
        );

      default:
    }
  };

  render() {
    const { account, stage } = this.state;
    const { error, isFetching } = this.props;
    if (!account) {
      return null;
    }
    return (
      <>
        {stage === 'MakePayment' ? (
          <>
            <AppMeta
              id="meta-data"
              stage="child"
              title="Make a withdrawal"
              metaDescription="Make a withdrawal"
            />
            <h1>
              <Content id="pageTitle" copytext="Make a withdrawal" />
            </h1>
            <div className="breadcrumb-container">
              <Breadcrumb tag="nav">
                <BreadcrumbItem active={stage === 'MakePayment'}>
                  <Content
                    id="make-a-withdrawal-step1"
                    copytext="1 Withdrawal Details"
                  />
                </BreadcrumbItem>
                <BreadcrumbItem active={stage === 'ReviewPayment'}>
                  <Content id="make-a-withdrawal-step2" copytext="2 Review" />
                </BreadcrumbItem>
                <BreadcrumbItem active={stage === 'CompletePayment'}>
                  <Content
                    id="make-a-withdrawal-step3"
                    copytext="3 Confirmation"
                  />
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </>
        ) : (
          ''
        )}

        {stage === 'ReviewPayment' ? (
          <>
            <AppMeta
              id="meta-data"
              stage="child"
              title="Confirm Withdrawal Request"
              metaDescription="Confirm Withdrawal Request"
            />
            <h1>
              <Content id="pageTitle" copytext="Confirm Withdrawal Request" />
            </h1>
            <div className="breadcrumb-container">
              <Breadcrumb tag="nav">
                <BreadcrumbItem active={stage === 'MakePayment'}>
                  <Content
                    id="make-a-withdrawal-step1"
                    copytext="1 Withdrawal Details"
                  />
                </BreadcrumbItem>
                <BreadcrumbItem active={stage === 'ReviewPayment'}>
                  <Content id="make-a-withdrawal-step2" copytext="2 Review" />
                </BreadcrumbItem>
                <BreadcrumbItem active={stage === 'CompletePayment'}>
                  <Content
                    id="make-a-withdrawal-step3"
                    copytext="3 Confirmation"
                  />
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </>
        ) : (
          ''
        )}

        {stage === 'CompletePayment' ? (
          <>
            <AppMeta
              id="meta-data"
              stage="child"
              title="Withdrawal request accepted"
              metaDescription="Withdrawal request accepted"
            />
            <h1>
              <Content id="pageTitle" copytext="Withdrawal request accepted" />
            </h1>
            <div className="breadcrumb-container">
              <Breadcrumb tag="nav">
                <BreadcrumbItem active={stage === 'MakePayment'}>
                  <Content
                    id="make-a-withdrawal-step1"
                    copytext="1 Withdrawal Details"
                  />
                </BreadcrumbItem>
                <BreadcrumbItem active={stage === 'ReviewPayment'}>
                  <Content id="make-a-withdrawal-step2" copytext="2 Review" />
                </BreadcrumbItem>
                <BreadcrumbItem active={stage === 'CompletePayment'}>
                  <Content
                    id="make-a-withdrawal-step3"
                    copytext="3 Confirmation"
                  />
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </>
        ) : (
          ''
        )}
        {!isFetching && error ? (
          <Alert color="danger" role="alert">
            {error}
          </Alert>
        ) : null}
        {this.currentStage()}
      </>
    );
  }
}

MakeWithdrawal.propTypes = {
  withdrawalAccount: PropTypes.object,
  history: PropTypes.object,
  resetMakeWithdrawal: PropTypes.func,
  accounts: PropTypes.object,
  fetchAccountsIfNeeded: PropTypes.func,
  makeaWithdraw: PropTypes.func,
  match: PropTypes.any,
  error: PropTypes.any,
  holidays: PropTypes.any,
  isFetching: PropTypes.any,
};

const mapStateToProps = state => {
  const { accountsDefinition, customccbSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...customccbSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
)(withRouter(MakeWithdrawal));
