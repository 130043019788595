import { Connection, TwoFactor } from '@myie/interact';

import {
  SETUP_CREDENTIALS_REQUEST,
  SETUP_CREDENTIALS_SUCCESS,
  SETUP_CREDENTIALS_FAILURE,
  RESET_SETUP_CREDENTIALS,
} from './registrationTypes';

export const resetSetupCredentials = () => ({
  type: RESET_SETUP_CREDENTIALS,
});
export const setupCredentialsRequest = () => ({
  type: SETUP_CREDENTIALS_REQUEST,
  isFetching: true,
  error: null,
});

export const setupCredentialsFailed = error => ({
  type: SETUP_CREDENTIALS_FAILURE,
  isFetching: false,
  error: error.message,
});

export const setupCredentialsReceive = json => ({
  type: SETUP_CREDENTIALS_SUCCESS,
  setupCredentialsActivate: json,
  isFetching: false,
  error: null,
});

export const setupCredentials = request => dispatch => {
  return TwoFactor.run(() => {
    dispatch(setupCredentialsRequest());
    return fetch(
      Connection.baseUrl('webauthentication') +
        `v2.0/user/forgottencredentials/activate`,
      {
        method: 'post',
        headers: Connection.headers(),
        body: JSON.stringify(request),
      },
    )
      .then(response => Connection.errored(response))
      .then(response => response.json())
      .then(json => dispatch(setupCredentialsReceive(json)))
      .catch(error => dispatch(setupCredentialsFailed(error)));
  });
};
