import {
  ORGANISATION_REQUEST,
  ORGANISATION_PROGRESS,
  ORGANISATION_SUCCESS,
  ORGANISATION_FAILURE,
} from '../actions/organisationTypes';
import { uniqBy } from 'lodash';

const ccbOrganisationDefinition = (state = {}, action) => {
  switch (action.type) {
    case ORGANISATION_SUCCESS:
      return {
        ...state,
        organisation: action.organisation,
        isFetching: action.isFetching,
      };
    case ORGANISATION_PROGRESS: {
      let allDetails = uniqBy(
        [...state.organisation.details, { ...action.detail }],
        'CustomerId',
      );
      return {
        ...state,
        organisation: {
          ...state.organisation,
          details: allDetails,
        },
        isFetching: action.isFetching,
      };
    }
    case ORGANISATION_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case ORGANISATION_REQUEST:
    default:
      return state;
  }
};

export default ccbOrganisationDefinition;
