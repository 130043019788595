import * as interestRateActions from './actions/interestRateActions';
import * as ccbDocumentActions from './actions/documentActions';
import * as MaturityActions from './actions/MaturityActions';

const mapDispatchToProps = {
  ...interestRateActions,
  ...ccbDocumentActions,
  ...MaturityActions,
};

export default mapDispatchToProps;
