//HOC
import requireAuthentication from './HO_components/requireAuthentication';
import contentContainer from './HO_components/contentContainer';

// components
import Check from './components/Check';
import Errors, { HasErrors } from './components/Errors';
import ErrorPanel from './components/ErrorPanel';
import CommonError from './CommonError';
import FormattedDate from './components/FormattedDate';
import FormattedCurrency from './components/FormattedCurrency';
import Radio from './components/Radio';
import RadioButton from './components/RadioButton';
import RadioGroup from './components/RadioGroup';
import RoundBadge from './components/RoundBadge';
import Text from './components/Text';
import Mask from './components/InputMask';
import TextArea from './components/TextArea';
import DropDown from './components/DropDown';
import Numeric from './components/Numeric';
import Currency from './components/Currency';
import Slider from './components/Slider';
import Date from './components/Date';
import Value from './components/Value';
import AddressLookup from './components/AddressLookup';
import DatePicker from './components/DatePicker';
import GoogleTagManager from './components/GoogleTagManager';
import IfAuthenticated from './components/IfAuthenticated';
import Dialog from './components/Dialog';
import BankHolidays from './components/BankHolidays';
//Symbols
import symbols from './components/symbols';
import Pagination from './components/Pagination';

// Adverts & Sidebar widgets
import FeaturesAdvert1 from './components/FeaturesAdvert1';
import FeaturesAdvert2 from './components/FeaturesAdvert2';
import FeaturesAdvert3 from './components/FeaturesAdvert3';
import HelpAdvert1 from './components/HelpAdvert1';
import HelpAdvert2 from './components/HelpAdvert2';
import HelpContactSidebar from './components/HelpContactSidebar';
import TravelInsuranceAdvert1 from './components/TravelInsuranceAdvert1';
import OurProducts from './components/OurProducts';

// timeout
import TimeOutModal from './timeout/TimeOutModal';
import TimeOut from './timeout/timeout';
import sessionTimer from './timeout/sessionTimer';
// routing
import PrivateRoute from './routing/PrivateRoute';
import PublicRoute from './routing/PublicRoute';
import NoMatch from './routing/NoMatch';
import NetworkError from './routing/NetworkError';
import AvailableRoutes from './routing/AvailableRoutes';
import Interceptor from './routing/Interceptor';
import AuthButton from './components/AuthButton';
import AppMeta from './components/AppMeta';
import ScrollToTop from './components/ScrollToTop';
import TabNavigation from './components/TabNavigation';
import Content from './components/Content';
import Markdown from './components/Markdown';
import Switch from './components/Switch';

import { confirm } from './components/confirmdialog';
import SideLink from './components/SideLink';
import ErrorScroll from './ErrorScroll';
import './validation-ccb-tests';
import ContactNumber from './components/ContactNumber';
import LinkTag from './components/LinkTag';
import EmailAddress from './components/EmailAddress';
import appUtility from './utility';
// setup initial moment config
import moment from 'moment';
import 'moment/locale/en-gb';

import Icon from './svgs';

import { RULES, CONSTANTS } from './constants/';
import loadStateHOC from './stateManager/loadStateHOC';
import stateUtility from './stateManager/stateUtility';
import AERAbbreviation from './components/AERAbbreviation';
import FormErrorList from './components/formErrors/FormErrorList';
import ListItem from './components/formErrors/ListItem';
moment.locale(navigator.language);

export {
  Switch,
  Content,
  Markdown,
  ErrorScroll,
  HasErrors,
  TabNavigation,
  IfAuthenticated,
  Check,
  Errors,
  ErrorPanel,
  CommonError,
  FormattedDate,
  FormattedCurrency,
  Radio,
  RoundBadge,
  Text,
  TextArea,
  LinkTag,
  Currency,
  Numeric,
  Slider,
  Date,
  Value,
  DropDown,
  DatePicker,
  RadioGroup,
  RadioButton,
  AddressLookup,
  GoogleTagManager,
  PublicRoute,
  PrivateRoute,
  Mask,
  NoMatch,
  NetworkError,
  Interceptor,
  AvailableRoutes,
  TimeOutModal,
  TimeOut,
  sessionTimer,
  symbols,
  Pagination,
  requireAuthentication,
  contentContainer,
  AuthButton,
  AppMeta,
  ScrollToTop,
  Dialog,
  confirm,
  SideLink,
  FeaturesAdvert1,
  FeaturesAdvert2,
  FeaturesAdvert3,
  HelpAdvert1,
  HelpAdvert2,
  HelpContactSidebar,
  TravelInsuranceAdvert1,
  OurProducts,
  ContactNumber,
  Icon,
  EmailAddress,
  BankHolidays,
  CONSTANTS,
  RULES,
  stateUtility,
  loadStateHOC,
  AERAbbreviation,
  ListItem,
  FormErrorList,
  appUtility,
};
