import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'reactstrap';
import { FormattedCurrency, Content, Value } from '@myie/interact-dom';

class Balance extends React.Component {
  render() {
    const { props } = this;
    const { account, loopIndex } = props;
    const { ExtendedProperties: { InterestPaymentFrequency } = {} } = account;

    return (
      <Row id={`account-type-savings-balance-${loopIndex}`}>
        <Col xs={12} md={8}>
          <Card body className="balance-card" color="dark">
            <Row tag="dl" title="Account balance">
              <Col tag="dt" className="h6" xs={12}>
                <Content id="savings-balance" copytext="Your balance is" />
              </Col>
              <Col tag="dd" className="h4" xs={12}>
                <FormattedCurrency
                  quantity={account.Balance}
                  currency={account.CurrencyCode}
                />
              </Col>
              <Col tag="dt" className="sr-only">
                <Content id="interest-rate" copytext="Your interest rate is" />
              </Col>
              <Col tag="dd" className="h6 savings-aer-card-container" xs={12}>
                <Value value={account.InterestRate} decimalPlaces={2} />
                {'%'}
                <Content id="savings-gross" copytext=" Gross" />
                {InterestPaymentFrequency}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={5} />
      </Row>
    );
  }
}

Balance.propTypes = {
  account: PropTypes.any,
  loopIndex: PropTypes.any,
};

export default Balance;
