import React from 'react';
import PropTypes from 'prop-types';
import { Fade, Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap';
import { TabNavigation } from '@myie/interact-dom';

class Tabs extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      tablist: TabNavigation.init(1),
      testdata: '',
    };
  }

  setTab(e) {
    e.preventDefault();
    const current = e.target.href.substring(e.target.href.indexOf('#') + 1);
    this.setState({ ...this.state, activeTab: current });
  }

  render() {
    const { activeTab, tablist } = this.state;

    return (
      <section id="tabs-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/components/tabs.scss</strong>
          </code>
        </p>
        <p>
          There is a component made for <strong>tabs</strong> defined within{' '}
          <code>demo/account-type-savings.js</code> and{' '}
          <code>demo/account-type-loan.js</code>. There is no interactive
          technical guide for this. To see its usage, go to{' '}
          <code>styleguide/components/Tabs.js</code>.
        </p>
        <p>
          <strong>
            Please note this is a very complicated set of components, so it is
            advisable to look at the example before building your own.
          </strong>
        </p>

        <Nav tabs aria-label="Navigation Tabs" role="tablist">
          <NavItem role="presentation" id="transactions-tab-item">
            <NavLink
              role="tab"
              aria-selected={activeTab === 'transactions'}
              aria-controls="transactions"
              id="transactions-tab"
              href="#transactions"
              active={activeTab === 'transactions'}
              onClick={e => this.setTab(e)}
              innerRef={tablist.refs.tabs[0]}
              onKeyDown={e => tablist.tabKeyDown(e, 0, tablist.refs)}
            >
              Transactions
            </NavLink>
          </NavItem>
          <NavItem role="presentation" id="downloads-tab-item">
            <NavLink
              role="tab"
              aria-selected={activeTab === 'downloads'}
              aria-controls="downloads"
              id="downloads-tab"
              href="#downloads"
              active={activeTab === 'downloads'}
              onClick={e => this.setTab(e)}
              innerRef={tablist.refs.tabs[1]}
              onKeyDown={e => tablist.tabKeyDown(e, 1, tablist.refs)}
            >
              Downloads
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane
            role="tabpanel"
            id="transactions"
            tabId="transactions"
            aria-labelledby="transactions-tab"
          >
            <Fade in={activeTab === 'transactions'}>
              <div
                className="tab-focus"
                tabIndex="0"
                ref={tablist.refs.panels[0]}
                onKeyDown={e => tablist.panelKeyDown(e, 0, tablist.refs)}
              >
                This is a panel
              </div>
            </Fade>
          </TabPane>
          <TabPane
            role="tabpanel"
            id="downloads"
            tabId="downloads"
            aria-labelledby="downloads-tab"
          >
            <Fade in={activeTab === 'downloads'}>
              <div
                className="tab-focus"
                tabIndex="0"
                ref={tablist.refs.panels[1]}
                onKeyDown={e => tablist.panelKeyDown(e, 1, tablist.refs)}
              >
                And another panel
              </div>
            </Fade>
          </TabPane>
        </TabContent>
      </section>
    );
  }
}

Tabs.propTypes = {
  items: PropTypes.string,
};

export default Tabs;
