/* eslint-disable no-mixed-spaces-and-tabs, prettier/prettier */

import { AuthenticationServiceItems } from '@myie/interact-authentication-dom'
import { UserManagementServiceItems } from '@myie/interact-user-management-dom'


const RegisteredServiceItems = []

AuthenticationServiceItems.forEach(sb => {
	RegisteredServiceItems.push(sb)
}); 

UserManagementServiceItems.forEach(sb => {
	RegisteredServiceItems.push(sb)
}); 



export default RegisteredServiceItems
