import React from 'react';
import { Link } from 'react-router-dom';
import { Media } from 'reactstrap';
import { Markdown, Icon } from '@myie/interact-dom';

class UpdateSecurityDetails extends React.Component {
  render() {
    return (
      <Link
        className="nav-link"
        role="button"
        id="update-security-details-service-item-link"
        to="/update-security-details"
      >
        <Media>
          <Media left middle>
            <Icon name="MyServicesSecuritySettings" fill="primary-fill" />
          </Media>
          <Media body middle>
            <Markdown
              options={{ forceBlock: true }}
              id="update-security-details"
              markdown={`## Security details\nView and update your security details.`}
            />
          </Media>
        </Media>
      </Link>
    );
  }
}

export default UpdateSecurityDetails;
