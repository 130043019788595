import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, Row, Col, Spinner } from 'reactstrap';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-authentication';
import { mapDispatchToProps as mapDispatchToProps_shared } from '@myie/interact-shared';
import { Text, Switch, Content } from '@myie/interact-dom';
import { Alert } from 'reactstrap';
import { Validate } from '@myie/interact';

class SecurityDetails extends React.Component {
  constructor(props) {
    super(props);
    const { history, resetRegistration } = this.props;
    this.state = {
      history,
      resetRegistration,
      form: {
        username: {
          rules: {
            title: 'username',
            stop: true,
            required: {
              message: 'Please enter a username',
            },
            range: {
              min: 6,
              max: 23,
              message:
                'Your username must be at least 6 characters, and no more than 23 characters',
            },
            format: {
              regex: /^[^\s$&+,/:;=?%#<>{}^~[\]`\\]{6,23}$/,
              message:
                'Your username contains one or more of these invalid symbols $&+,/:;=?%#<>{}^~[]` or spaces. Please remove them and try again',
            },
          },
        },
        confirmUsername: {
          rules: {
            title: 'confirm username',
            stop: true,
            required: {
              message: 'Please confirm your username',
            },
            format: {
              regex: /^[^\s$&+,/:;=?%#<>{}^~[\]`\\]{6,23}$/,
              message:
                'Your username contains one or more of these invalid symbols $&+,/:;=?%#<>{}^~[]` or spaces. Please remove them and try again',
            },
            compare: {
              message: 'Please ensure the username fields match',
              field: 'username',
              comparison: () => {
                return this.state.form.username.value;
              },
            },
          },
        },
        password: {
          rules: {
            title: 'password',
            stop: true,
            required: {
              message: 'Please enter a password',
            },
            format: {
              regex: /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s$).{10,50}$/,
              message: 'Please enter valid a password',
            },
          },
        },
        confirmPassword: {
          rules: {
            title: 'confirm password',
            stop: true,
            required: {
              message: 'Please confirm your password',
            },
            format: {
              regex: /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s$).{10,50}$/,
              message: 'Please enter valid a password',
            },
            compare: {
              message: 'Please ensure the password fields match',
              field: 'password',
              comparison: () => {
                return this.state.form.password.value;
              },
            },
          },
        },
        memorableNumber: {
          rules: {
            title: 'memorable number',
            stop: true,
            required: {
              message: 'Please enter your memorable number',
            },
            format: {
              regex: /^[0-9]{6}$/,
              message: 'Please enter a valid memorable number',
            },
          },
        },
        confirmMemorableNumber: {
          rules: {
            title: 'confirm memorable number',
            stop: true,
            required: {
              message: 'Please confirm your memorable number',
            },
            format: {
              regex: /^[0-9]{6}$/,
              message: 'Please enter a valid memorable number',
            },
            compare: {
              message: 'Please ensure the memorable number fields match',
              field: 'memorableNumber',
              comparison: () => {
                return this.state.form.memorableNumber.value;
              },
            },
          },
        },
        // EmailAddress: {
        //   rules: {
        //     title: 'EmailAddress',
        //     stop: true,
        //     required: {
        //       message: 'Please enter Email address',
        //     },
        //     ccbEmail: {
        //       message: 'Please enter a valid Email address',
        //     },
        //   },
        // },
        // MobileNumber: {
        //   rules: {
        //     title: 'MobileNumber',
        //     stop: true,
        //     format: {
        //       regex: /^$|^(?=^(\D*(\d\D*){11,}$))[0+][0-9\s]{10,16}$/,
        //       message: 'Please enter a valid Mobile number',
        //     },
        //   },
        //   value: '',
        // },
      },
    };
  }
  componentDidMount = () => {
    this.props.timeout.startTimer();
  };

  componentWillUnmount = () => {
    this.props.timeout.clearTimer();
  };

  resetStage = () => {
    let { resetRegistration } = this.props;
    resetRegistration();
  };

  onChange = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  submit = e => {
    e.preventDefault();
    var { form } = this.state;
    const { setCredentials, registration } = this.props;
    form = Validate.form(form);
    const lastUsername = form.username.value;
    this.setState({ ...this.state, form, lastUsername });
    if (!form.approved) {
      return;
    }
    let birthDay;
    if (this.props.formData) {
      birthDay = this.props.formData.b_day ? this.props.formData.b_day : '';
    }

    const request = {
      Ticket: registration.Ticket,
      Username: form.username.value,
      CredentialValues: [
        {
          Name: 'Password',
          Value: form.password.value,
          Context: '',
        },
        {
          Name: 'Pin',
          Value: form.memorableNumber.value,
          Context: '',
        },
      ],
      TermsAndConditionsDocumentVersion: '1.0',
      ExtendedProperties: {
        IdentityRequest: {
          // email: form.EmailAddress.value,
          // mobileTelephone: form.MobileNumber.value.replace(/\s/g, ''),
          DateOfBirth: birthDay,
        },
      },
    };
    setCredentials(request);
  };

  getErrors() {
    const { credentialsSetup = {} } = this.props;
    let errors = [];
    if (
      credentialsSetup.ExtendedProperties &&
      credentialsSetup.ExtendedProperties.IdentityResponse &&
      credentialsSetup.ExtendedProperties.IdentityResponse.CredentialErrors
    ) {
      credentialsSetup.ExtendedProperties.IdentityResponse.CredentialErrors.forEach(
        element => {
          switch (element) {
            case 'Dob':
              return errors.push(
                <Alert id={element} key={element} color="danger" role="alert">
                  <Content
                    id={element}
                    copytext="Memorable number cannot be your date of birth"
                  />
                </Alert>,
              );
            case 'AllSame':
              return errors.push(
                <Alert id={element} key={element} color="danger" role="alert">
                  <Content
                    id={element}
                    copytext="You cannot have all the digits the same"
                  />
                </Alert>,
              );
            case 'Sequential':
              return errors.push(
                <Alert id={element} key={element} color="danger" role="alert">
                  <Content
                    id={element}
                    copytext="You aren't allowed to have a sequential sequence of digits"
                  />
                </Alert>,
              );
            case 'Known':
              return errors.push(
                <Alert id={element} key={element} color="danger" role="alert">
                  <Content
                    id={element}
                    copytext="Your chosen password is on a list of well known passwords"
                  />
                </Alert>,
              );
            case 'Username':
              return errors.push(
                <Alert id={element} key={element} color="danger" role="alert">
                  <Content
                    id={element}
                    copytext="Your password cannot be the same as your username"
                  />
                </Alert>,
              );
            default:
          }
        },
      );
    }

    return errors;
  }

  buildErrorMsg = credentialsSetup => {
    var errorsExist = false;
    var errorMsg = [];

    if (credentialsSetup.Status === 'InvalidCredentials') {
      errorMsg = this.getErrors();
      if (errorMsg.length) {
        errorsExist = true;
      }
    } else {
      if (
        credentialsSetup &&
        credentialsSetup.Status &&
        credentialsSetup.Status !== ''
      ) {
        errorsExist = true;
      }
      errorMsg = (
        <Switch
          id="credentialsAlert"
          value={credentialsSetup.Status || ''}
          tag="div"
          className="alert alert-danger"
          template={{
            UsernameAlreadyExists: {
              name: () => {
                return this.state.lastUsername;
              },
              random: Math.floor(Math.random() * (9999 - 1000)) + 1000,
            },
          }}
          contents={{
            UsernameAlreadyExists: {
              defaultValue:
                'Please select another username as your chosen one already exists',
            },

            NotEnoughCredentials: {
              defaultValue: 'Not enough credentials were submitted',
            },
            InvalidUsername: {
              defaultValue:
                'The username entered is not valid. Please enter a valid username.',
            },
          }}
        />
      );
    }

    return {
      errorMsg: errorMsg,
      errorsExist: errorsExist,
    };
  };

  render() {
    const { credentialsSetup = {} } = this.props;
    const { form } = this.state;
    var errorObj = this.buildErrorMsg(credentialsSetup);
    let disabledForm = false;
    if (this.props.disableForm || this.props.isFetching) {
      disabledForm = true;
    }
    return (
      <Form
        autoComplete="off"
        id="registration-security-details"
        onSubmit={this.submit}
      >
        {this.props.isFetching && (
          <Spinner className="m-5" color="primary">
            Loading...
          </Spinner>
        )}
        {!this.props.isFetching && (
          <>
            {errorObj.errorMsg}
            <Row>
              <Col sm={12} lg={6}>
                <Text
                  description="You can use letters, numbers and special characters."
                  label="Create your username"
                  id="username"
                  field="username"
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  validation={form.username}
                ></Text>
                <Text
                  label="Confirm your username"
                  id="confirmUsername"
                  field="confirmUsername"
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  validation={form.confirmUsername}
                ></Text>
                <Text
                  description="Your password must be between 10 and 50 characters, and must include lower and uppercase letters, a number and a symbol"
                  label="Create your password"
                  id="password"
                  field="password"
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  validation={form.password}
                  type="password"
                />
                <Text
                  label="Confirm your password"
                  id="confirmPassword"
                  field="confirmPassword"
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  validation={form.confirmPassword}
                  type="password"
                />
                <Text
                  description="Your memorable number must be 6 numbers long. You will be asked for 3 numbers from this code when you login."
                  label="Create your memorable number"
                  id="memorableNumber"
                  field="memorableNumber"
                  onChange={this.onChange}
                  maxLength={'6'}
                  onBlur={this.onBlur}
                  validation={form.memorableNumber}
                  type="password"
                />

                <Text
                  label="Confirm your memorable number"
                  id="confirmMemorableNumber"
                  field="confirmMemorableNumber"
                  onChange={this.onChange}
                  maxLength={'6'}
                  onBlur={this.onBlur}
                  validation={form.confirmMemorableNumber}
                  type="password"
                />
                {/* <h2>
              <Content id="contact-title" copytext="Your contact details" />
            </h2>
            <Text
              label="Your personal email address"
              description="This will be used to send a one time passcode to if you struggle to log in"
              id="EmailAddress"
              field="EmailAddress"
              maxLength="50"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={form.EmailAddress}
            />
            <Text
              label="Your personal mobile number"
              description="This can be used to send a one time passcode to if you struggle to log in"
              id="MobileNumber"
              field="MobileNumber"
              maxLength="18"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={form.MobileNumber}
            /> */}
              </Col>
            </Row>
            {errorObj.errorsExist ? (
              <Alert
                id="error-exists"
                color="danger"
                role="alert"
                aria-live="assertive"
                className="mt-4"
              >
                <Content
                  cmsTag="credential-setup-error-exists"
                  copytext="Unfortunately something  is incorrect – please scroll to the top for more details."
                />
              </Alert>
            ) : (
              ''
            )}
          </>
        )}
        <div className="form-button-group">
          <Button
            id="security-details-submit"
            type="submit"
            color="primary"
            disabled={disabledForm}
          >
            <Content id="continue" copytext="Complete registration" />
          </Button>
          <Button
            id="security-details-cancel"
            className="btn btn-secondary"
            onClick={this.resetStage}
            disabled={disabledForm}
          >
            <Content id="cancel" copytext="Cancel" />
          </Button>
        </div>
      </Form>
    );
  }
}

SecurityDetails.propTypes = {
  history: PropTypes.any,
  registration: PropTypes.any,
  resetRegistration: PropTypes.any,
  credentialsSetup: PropTypes.any,
  setCredentials: PropTypes.any,
  timeout: PropTypes.any,
  setStage: PropTypes.any,
  formData: PropTypes.any,
  isFetching: PropTypes.bool,
  disableForm: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps,
    ...mapDispatchToProps_shared,
  },
)(SecurityDetails);
