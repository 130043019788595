import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Utility } from '@myie/interact';
import { Content } from '@myie/interact-dom';

class Links extends React.Component {
  getLinks = () => {
    const {
      BrandProductType,
      loopIndex,
      accountKey,
      accountStatus,
    } = this.props;
    switch (BrandProductType) {
      case 'NOTICE_ACCOUNT':
        if (accountStatus === 'Active') {
          return (
            <Link
              id={`account-list-notice-to-withdraw-link-${loopIndex}`}
              className="mr-0 btn-primary btn"
              to={'/notice-to-withdraw/' + Utility.hexEncode(accountKey)}
            >
              <Content
                id="savings-notice-to-withdraw-link"
                copytext="Notice to withdraw"
              />
            </Link>
          );
        }
        break;
      case 'EASY_ACCESS':
        if (accountStatus === 'Active') {
          return (
            <Link
              id={`account-list-make-a-payment-link-${loopIndex}`}
              className="mr-0 btn-primary btn"
              to={'/make-withdrawal/' + Utility.hexEncode(accountKey)}
            >
              <Content
                id="savings-make-withdrawl-link"
                copytext="Make a withdrawal"
              />
            </Link>
          );
        }
        break;
      default:
    }
  };

  render() {
    const { props } = this;
    const { loopIndex } = props;

    return (
      <div id={`account-type-savings-link-${loopIndex}`}>
        <Link
          id={`account-list-show-details-link-${loopIndex}`}
          className="btn-secondary btn"
          to={'/accounts/details/' + Utility.hexEncode(props.accountKey)}
        >
          <Content
            id="savings-account-details-link"
            copytext="Account details"
          />
        </Link>
        {this.getLinks()}
      </div>
    );
  }
}

Links.propTypes = {
  accountKey: PropTypes.any,
  loopIndex: PropTypes.any,
  BrandProductType: PropTypes.string,
  accountStatus: PropTypes.string,
};

export default Links;
