import React from 'react';
import PropTypes from 'prop-types';
import ListItem from './ListItem';
import { Validate } from '@myie/interact';
import { Markdown } from '@myie/interact-dom';

class FormErrorList extends React.Component {
  content = items => {
    return (
      <React.Fragment>
        {items.length > 1 ? (
          <React.Fragment>
            <Markdown
              cmsTag="Shared:Components:Form-errors:Form-error-list:h-part-1"
              markdown="There are "
            />
            {items.length}
            <Markdown
              cmsTag="Shared:Components:Form-errors:Form-error-list:h-part-2"
              markdown=" errors in this form:"
            />
          </React.Fragment>
        ) : null}
        {items.length === 1 ? (
          <Markdown
            cmsTag="Shared:Components:Form-errors:Form-error-list:h-singular"
            markdown="There is 1 error in this form:"
          />
        ) : null}
      </React.Fragment>
    );
  };

  getTitle = items => {
    const { title = 'h2', bespokeTitle = '', addressId = '' } = this.props;

    if (!items.length > 0) {
      return;
    }

    switch (title) {
      case 'h2':
        return (
          <React.Fragment>
            {bespokeTitle !== '' ? (
              <h2 id={`${addressId}-error-message-title`}>{bespokeTitle}</h2>
            ) : (
              <h2>{this.content(items)}</h2>
            )}
          </React.Fragment>
        );
      case 'h3':
        return (
          <React.Fragment>
            {bespokeTitle !== '' ? (
              <h3 id={`${addressId}-error-message-title`}>{bespokeTitle}</h3>
            ) : (
              <h3>{this.content(items)}</h3>
            )}
          </React.Fragment>
        );
      case 'h4':
        return (
          <React.Fragment>
            {bespokeTitle !== '' ? (
              <h4 id={`${addressId}-error-message-title`}>{bespokeTitle}</h4>
            ) : (
              <h4>{this.content(items)}</h4>
            )}
          </React.Fragment>
        );

      default:
    }
  };

  updateFieldKey(keys, key) {
    let output = key;
    if (key === 'addressInput') {
      if (keys.includes('currentAddressLivingSince')) {
        output = 'currentAddressInput';
      } else if (keys.includes('previousAddressLivingSinceform1')) {
        output = 'addressInputform1';
      } else if (keys.includes('previousAddressLivingSinceform2')) {
        output = 'addressInputform2';
      }
    }
    return output;
  }
  render() {
    const { props } = this;
    const {
      validations = {},
      groupClassName = '',
      showErrors = true,
      continainingElement = '',
      addressId = '',
    } = props;
    let keys = Object.keys(validations);
    let errors = [];
    const items = [];

    keys.forEach((key, index) => {
      let validationValue = {};
      if (typeof validations[key] !== 'boolean') {
        validationValue = validations[key];

        errors = Validate.errors(validations[key]);
      }
      if (key !== 'approved' && errors.length > 0) {
        let fieldkey = this.updateFieldKey(keys, key);
        items.push(
          <ListItem
            key={index}
            validation={validationValue}
            cmsTag={validations[key].cmsTag}
            index={index}
            field={fieldkey}
          />,
        );
      }
    });

    return (
      <React.Fragment>
        {continainingElement === 'section' ? (
          <section
            className={`${groupClassName} ${
              items.length > 0 ? 'alert alert-danger form-error-list' : ''
            }`.trim()}
            aria-describedby={`${addressId}-error-message-title`}
          >
            {showErrors ? (
              <React.Fragment>
                {this.getTitle(items)}
                {items.length > 0 ? (
                  <ul className="bullet-list"> {items} </ul>
                ) : (
                  ''
                )}
              </React.Fragment>
            ) : (
              ''
            )}
          </section>
        ) : (
          <div
            aria-atomic="true"
            role="alert"
            className={`${groupClassName} ${
              items.length > 0 ? 'alert alert-danger form-error-list' : ''
            }`.trim()}
          >
            {showErrors ? (
              <React.Fragment>
                {this.getTitle(items)}
                {items.length > 0 ? (
                  <ul className="bullet-list"> {items} </ul>
                ) : (
                  ''
                )}
              </React.Fragment>
            ) : (
              ''
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

FormErrorList.propTypes = {
  title: PropTypes.string,
  validations: PropTypes.object,
  groupClassName: PropTypes.string,
  showErrors: PropTypes.bool,
  bespokeTitle: PropTypes.string,
  continainingElement: PropTypes.string,
  addressId: PropTypes.string,
};

export default FormErrorList;
