import { Connection } from '@myie/interact';

import {
  UPDATE_FORGOTTEN_REQUEST,
  UPDATE_FORGOTTEN_SUCCESS,
  UPDATE_FORGOTTEN_FAILURE,
} from './forgottenPasswordTypes';
import {
  FORGOTTEN_PW_REQUEST,
  FORGOTTEN_PW_SUCCESS,
  FORGOTTEN_PW_FAILURE,
} from './forgottenPasswordTypes';
import { RESET_FORGOTTEN_PW } from './forgottenPasswordTypes';

export const resetForgottenPassword = () => ({
  type: RESET_FORGOTTEN_PW,
});

export const updateForgottenRequest = () => ({
  type: UPDATE_FORGOTTEN_REQUEST,
  isFetching: true,
  error: null,
});

export const updateForgottenReceiveFailed = error => ({
  type: UPDATE_FORGOTTEN_FAILURE,
  isFetching: false,
  error: error.message,
});

export const updateForgottenReceive = json => ({
  type: UPDATE_FORGOTTEN_SUCCESS,
  updateForgotten: json,
  isFetching: false,
  error: null,
});

export const forgottenPasswordRequest = () => ({
  type: FORGOTTEN_PW_REQUEST,
  isFetching: true,
  error: null,
});

export const forgottenPasswordFailed = error => ({
  type: FORGOTTEN_PW_FAILURE,
  isFetching: false,
  error: error.message,
});

export const forgottenPasswordReceive = json => ({
  type: FORGOTTEN_PW_SUCCESS,
  forgottenPassword: json,
  isFetching: false,
  error: null,
});

export const updateForgotten = credentials => dispatch => {
  dispatch(updateForgottenRequest());
  return fetch(
    Connection.baseUrl('webauthentication') +
      `v2.0/user/credentials/update-forgotten`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(credentials),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(updateForgottenReceive(json)))
    .catch(error => dispatch(updateForgottenReceiveFailed(error)));
};

export const resetForgotten = request => dispatch => {
  dispatch(forgottenPasswordRequest());
  return fetch(
    Connection.baseUrl('webauthentication') +
      `v2.0/user/credentials/reset-forgotten`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(forgottenPasswordReceive(json)))
    .catch(error => dispatch(forgottenPasswordFailed(error)));
};
