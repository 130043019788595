import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ width = '89.289', height = '123.833' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89.289 123.833"
    width={width}
    height={height}
  >
    <rect
      className="primary-fill"
      width="19.577"
      height="19.577"
      transform="translate(34.864 104.256)"
    />
    <rect
      className="secondary-fill"
      width="19.577"
      height="19.577"
      transform="translate(34.864 75.32)"
    />
    <path
      className="secondary-fill"
      d="M44.637,0A44.683,44.683,0,0,0,0,44.637v1.532H17.126V44.637A27.573,27.573,0,1,1,44.7,72.21h-9.85V88.187A44.638,44.638,0,1,0,44.637,0Z"
    />
  </svg>
);

SVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
