import React from 'react';
import { RoundBadge } from '@myie/interact-dom';

/** RoundBadge example */
class RoundBadgeExample extends React.Component {
  render() {
    return <RoundBadge className="red">10</RoundBadge>;
  }
}

export default RoundBadgeExample;
