import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label, FormGroup } from 'reactstrap';
import Errors, { HasErrors } from './Errors';
import { Validate } from '@myie/interact';
import contentManaged, { ContentType } from '../HO_components/contentManaged';

/**
 * TextArea component
 * will display a text area
 */
class TextArea extends React.Component {
  constructor(props) {
    super(props);
    const {
      label,
      subLabel,
      description,
      suffix,
      placeholder,
      managedContent,
      validation,
    } = props;
    managedContent({
      label: { defaultValue: label },
      subLabel: { defaultValue: subLabel },
      description: { defaultValue: description },
      placeholder: { defaultValue: placeholder },
      suffix: { defaultValue: suffix },
      validation: validation,
    });
  }

  render() {
    const { props } = this;

    const {
      children,
      validation = {},
      labelClassName = '',
      groupClassName = '',
      showErrors = true,
      id,
      field,
      disabled,
      required,
      contentValue,
      managedContent,
      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const { value = '', state = {} } = validation;
    const label = contentValue(ContentType.Text, 'label');
    const subLabel = contentValue(ContentType.Text, 'subLabel');
    const description = contentValue(ContentType.Text, 'description');
    const placeholder = contentValue(ContentType.Text, 'placeholder');
    const suffix = contentValue(ContentType.Text, 'suffix');
    var fieldName =
      typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;
    const isRequired =
      required || (validation && validation.rules && validation.rules.required);
    return (
      <FormGroup
        className={`${groupClassName} ${disabled ? 'disabled-input' : ''} ${
          isRequired ? 'required-input' : ''
        }`.trim()}
      >
        {label || subLabel || description ? (
          <Label className={labelClassName} id={`${id}-label`} htmlFor={id}>
            {label}
            {isRequired ? (
              <React.Fragment>
                <span aria-hidden="true">*</span>
                <span className="sr-only">(required field)</span>
              </React.Fragment>
            ) : (
              ''
            )}{' '}
            {subLabel.length > 0 ? <small>{subLabel}</small> : ''}
            {description.length > 0 ? (
              <span className="description">{description}</span>
            ) : (
              ''
            )}
          </Label>
        ) : (
          ''
        )}
        <Input
          aria-describedby={
            HasErrors(validation) ? null : `error-${fieldName}-validation`
          }
          //aria-labelledby={`${id}-label`}
          invalid={Validate.isInvalid(state)}
          valid={Validate.isValid(state)}
          value={value}
          id={id}
          name={fieldName}
          disabled={disabled}
          required={required}
          placeholder={placeholder}
          type="textarea"
          {...rest}
        />
        {suffix && <p className="suffix">{suffix}</p>}
        {children}
        {showErrors ? (
          <Errors
            contentValue={contentValue}
            validation={validation}
            field={field}
          />
        ) : (
          ''
        )}
      </FormGroup>
    );
  }
}

TextArea.propTypes = {
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  /**
   * Any children of the component
   */
  children: PropTypes.element,
  /**
   * TextArea description text
   */
  description: PropTypes.string,
  /**
   * TextArea disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * Input field names
   */
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Style class name for group
   */
  groupClassName: PropTypes.string,
  /**
   * TextArea ID
   */
  id: PropTypes.string.isRequired,
  /**
   * Label text
   */
  label: PropTypes.string.isRequired,
  /**
   * Style class name for label
   */
  labelClassName: PropTypes.string,
  /**
   * TextArea required or not
   */
  required: PropTypes.bool,
  /**
   * Show errors or not
   */
  showErrors: PropTypes.bool,
  /**
   * Sub label text
   */
  subLabel: PropTypes.string,
  /**
   * Suffix text
   */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Text placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Input validation
   */
  validation: PropTypes.object,
};

export default contentManaged(TextArea);
