import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'reactstrap';
import { Icon, Content, Markdown } from '@myie/interact-dom';

class HelpAdvert2 extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    return (
      <Card className="advert help-advert">
        <Row>
          <Col xs={8} sm={9}>
            <h2>
              <Content id="HelpAdvert2:Advert.title" copytext="Need Help?" />
            </h2>
            <p className="staggered-sentence">
              <Markdown
                id="help-advert-2-paragraph"
                markdown={`Check out our FAQs 
                or 
                Call us now on <a href="#">0800 000 0000</a>.`}
              />
            </p>
          </Col>
          <Col xs={4} sm={3}>
            <Icon name="HelpAdIcon" />
          </Col>
        </Row>
      </Card>
    );
  }
}

HelpAdvert2.propTypes = {
  items: PropTypes.string,
};

export default HelpAdvert2;
