export const SET_LOGIN_REFERRER = 'SET_LOGIN_REFERRER';
export const RESET_LOGIN = 'RESET_LOGIN';

export const CREATE_LOGIN_REQUEST = 'CREATE_LOGIN_REQUEST';
export const CREATE_LOGIN_SUCCESS = 'CREATE_LOGIN_SUCCESS';
export const CREATE_LOGIN_FAILURE = 'CREATE_LOGIN_FAILURE';

export const UPDATE_LOGIN_REQUEST = 'UPDATE_LOGIN_REQUEST';
export const UPDATE_LOGIN_SUCCESS = 'UPDATE_LOGIN_SUCCESS';
export const UPDATE_LOGIN_FAILURE = 'UPDATE_LOGIN_FAILURE';
