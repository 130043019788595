import approve from 'approvejs';

const ccbEmail = {
  expects: [],
  message: '{title} must be a valid email address',
  validate: function(value) {
    //^(?=.{1,20}$)[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$
    let reg = new RegExp(/^(?=(.+@.+))(?=(.+\..+))[^\s]{3,50}$/);
    return reg.test(value);
  },
};

const ccbPhone = {
  expects: [],
  message: '{title} must be a valid phone number.',
  validate: function(value) {
    let reg = new RegExp(/^(?=(.[\d]))07[\d\s]{9,20}/);
    return reg.test(value);
  },
};

const compareWithAvailableBalance = {
  expects: ['comparison'],
  message: 'Please enter an amount less than available balance',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const balance = comparison();
      const num = value && Number(String(value).replace(/,/g, ''));
      return num <= balance;
    }
    return false;
  },
};

const minAmountCheck = {
  expects: ['comparison'],
  message: 'Please enter an amount greater than zero',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const balance = comparison();
      const num = value && Number(String(value).replace(/,/g, ''));
      return num >= balance;
    }
    return false;
  },
};

const minAmountCheckWithInputValue = {
  expects: ['comparison'],
  message: '',
  validate: function(value, par) {
    const { comparison } = par;
    if (typeof comparison === 'function') {
      const parValue = comparison();
      if (parValue) {
        const num = value && Number(String(value).replace(/,/g, ''));

        return num >= parValue;
      }
    }
    return true;
  },
};

const maxAmountCheckWithInputValue = {
  expects: ['comparison'],
  message: '',
  validate: function(value, par) {
    const { comparison } = par;
    if (typeof comparison === 'function') {
      const parValue = comparison();
      if (parValue) {
        const num = value && Number(String(value).replace(/,/g, ''));

        return num <= parValue;
      }
    }
    return true;
  },
};

approve.addTest(compareWithAvailableBalance, 'compareWithAvailableBalance');
approve.addTest(minAmountCheck, 'minAmountCheck');
approve.addTest(ccbEmail, 'ccbEmail');
approve.addTest(ccbPhone, 'ccbPhone');
approve.addTest(minAmountCheckWithInputValue, 'minAmountCheckWithInputValue');
approve.addTest(maxAmountCheckWithInputValue, 'maxAmountCheckWithInputValue');
