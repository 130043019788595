import React from 'react';
import { Route } from 'react-router-dom';

import {
  SignOut,
  Callback,
  SignOutCallback,
  SignIn,
} from '@myie/interact-manager-dom';

const Routes = [
  <Route key="mgr-sign-in" path="/mgr-sign-in" component={SignIn} />,
  <Route key="signin-oidc" path="/signin-oidc" component={Callback} />,
  <Route key="mgr-signed-out" path="/mgr-signed-out" component={SignOut} />,
  <Route
    key="mgr-signed-out-callback"
    path="/mgr-signed-out/callback"
    component={SignOutCallback}
  />,
];

export default Routes;
