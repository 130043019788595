import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { mapDispatchToProps as mapDispatchToPropsAccounts } from '@myie/interact-accounts';
import { mapDispatchToProps as mapDispatchToPropsMessaging } from '@myie/interact-messaging-twoway';
import { Utility } from '@myie/interact';
import PropTypes from 'prop-types';
import { FormattedDate, Value, Content, AppMeta } from '@myie/interact-dom';
import FormatBody from '../FormatBody';

class ViewConversation extends React.Component {
  constructor(props) {
    super(props);
    const {
      match,
      allMessages,
      history,
      viewMessage,
      viewConversation,
    } = this.props;
    let conversation;
    this.state = {
      stage: 'View',
      message: null,
      data: {
        accountTypeField: 'All',
        accountField: 'All',
      },
    };
    if (allMessages) {
      if (!viewMessage) {
        conversation = allMessages.Conversations.find(function(element) {
          if (element) {
            return (
              Utility.hexEncode(element.ConversationKey.Key) === match.params.id
            );
          }
          return false;
        });

        let request = {
          ConversationKey: conversation.ConversationKey,
          ProductType: 'string',
          ExtendedProperties: {},
        };
        viewConversation(request);
      }
    } else {
      const newUrl = '/messaging-twoway/view';
      history.push(newUrl);
    }
  }

  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      allMessages: nextProps.allMessages ? nextProps.allMessages : null,
    };
  }

  isConversationClosed = Conversation => {
    let closed = false;
    if (Conversation) {
      if (Conversation.ConversationProperties) {
        let convProperties = Conversation.ConversationProperties;
        convProperties.forEach(convProp => {
          if (convProp.Name === 'Closed') {
            if (convProp.Value === 'True') {
              closed = true;
            }
          }
        });
      }
    }
    return closed;
  };

  executeAction = e => {
    const value = e.target.value;

    const { history, match } = this.props;
    let newUrl;
    switch (value) {
      case 'Reply':
        newUrl =
          '/messaging-twoway/message/' +
          value.toLowerCase() +
          '/' +
          match.params.id;
        history.push(newUrl);
        break;
      case 'Delete':
        newUrl =
          '/messaging-twoway/message/' +
          value.toLowerCase() +
          '/' +
          match.params.id;
        history.push(newUrl);
        break;
      default:
    }
  };
  // select current stage
  render() {
    const { viewMessage, accounts } = this.props;
    let Conversation;
    let supportActions = [];
    let messages = [];
    let accountSuffix = '';
    if (!viewMessage) {
      return (
        <p aria-live="polite">
          <Content id="loading-messages" copytext="Loading messages..." />
        </p>
      );
    } else {
      Conversation = viewMessage.Conversation;
    }

    if (Conversation && Conversation.Supports) {
      supportActions = Conversation.Supports;
    }

    if (Conversation && Conversation.Messages) {
      messages = Conversation.Messages;
    }

    if (
      Conversation &&
      Conversation.AccountNumber &&
      accounts &&
      accounts.Accounts &&
      accounts.Accounts.length
    ) {
      let account = accounts.Accounts.find(
        a => a.AccountNumber === Conversation.AccountNumber,
      );
      if (account && account.AccountStatus === 'Closed') {
        accountSuffix = ' <Closed account>';
      }
    }

    return (
      <div id="conversation-view">
        <AppMeta
          id="meta-data"
          stage="child"
          title="Message details"
          metaDescription="Message details"
        />
        <h1>
          {messages.length > 1 ? <Content id="reason" copytext="Re:" /> : ''}{' '}
          <Value value={Conversation.Reason} />
        </h1>
        <Row tag="dl" title="message details">
          <Col tag="dt" sm={6} md={3}>
            <Content id="relatedAccountMsg" copytext="Related account:" />
          </Col>
          <Col tag="dd" sm={6} md={9}>
            {Conversation.AccountNumber !== 'XXXXXXXXXable' &&
            Conversation.AccountNumber !== 'Not available' ? (
              <>{Conversation.AccountNumber + accountSuffix}</>
            ) : (
              ''
            )}
          </Col>
          <Col tag="dt" sm={6} md={3}>
            <Content id="referenceNumber" copytext="Reference number:" />
          </Col>
          <Col tag="dd" sm={6} md={9}>
            {Conversation.Reference}
            {this.isConversationClosed(Conversation)
              ? ' (Conversation closed)'
              : ''}
          </Col>
        </Row>
        <div className="list-group messages-list">
          {messages.map((item, index) => {
            return (
              <div className="list-group-item" key={`message-${index}`}>
                <h2>
                  {item.Sender.SenderType !== 'User'
                    ? `${item.Sender.SenderType}`
                    : ''}{' '}
                  <span className="timestamp">
                    <FormattedDate date={item.CreatedDate} format={'LLL'} />
                  </span>
                </h2>
                <div id={`view-message-body-${index}`}>
                  {FormatBody(item.Body)}
                </div>
              </div>
            );
          })}
        </div>
        <div className="form-button-group">
          <Button
            key={`button-Reply`}
            id="confirm-submit-reply"
            color="primary"
            onClick={this.executeAction}
            value={'Reply'}
            size="sm"
            disabled={
              supportActions.includes('Reply') &&
              !this.isConversationClosed(Conversation)
                ? false
                : true
            }
          >
            <Content id="ReplyButton" copytext="Reply" />
          </Button>
          <Link
            id="back-to-messages-link-messaging"
            className="btn btn-secondary btn-sm"
            to="/messaging-twoway/view"
          >
            <Content id="backButton" copytext="Back To Messages" />
          </Link>
        </div>
      </div>
    );
  }
}

ViewConversation.propTypes = {
  allMessages: PropTypes.any,
  baseUrl: PropTypes.any,
  fetchAllMessagesIfNeeded: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  viewConversation: PropTypes.any,
  viewMessage: PropTypes.any,
  accounts: PropTypes.object,
  fetchAccountsIfNeeded: PropTypes.func,
};

const mapStateToProps = state => {
  const { messagingTwowayDefinition, accountsDefinition } = state;
  return {
    ...messagingTwowayDefinition,
    ...accountsDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToPropsMessaging, ...mapDispatchToPropsAccounts },
)(ViewConversation);
