import React from 'react';
import PropTypes from 'prop-types';
import LinkErrors from './LinkErrors';
import contentManaged from '../../HO_components/contentManaged';

class ListItem extends React.Component {
  constructor(props) {
    super(props);
    const { validation, managedContent } = props;
    managedContent({
      validation: validation,
    });
  }

  render() {
    const { props } = this;

    const {
      validation = {},
      field,
      tagType = 'list',
      contentValue,
      managedContent,
      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });

    return (
      <LinkErrors
        contentValue={contentValue}
        validation={validation}
        tagType={tagType}
        field={field}
      />
    );
  }
}

ListItem.propTypes = {
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  field: PropTypes.string,
  tagType: PropTypes.any,
  validation: PropTypes.object,
};

export default contentManaged(ListItem);
