import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DefaultSidebar from './DefaultSidebar';
import RegisteredSidebars from './Registered';
import { TwoFactorSidebars } from '@myie/interact-two-factor-dom';

TwoFactorSidebars.forEach(sb => {
  RegisteredSidebars.push(sb);
});

const HasSidebar = props => {
  const pathname = props.location.pathname;
  let Sidebar = DefaultSidebar;

  RegisteredSidebars.forEach(sb => {
    if (pathname && pathname.startsWith(sb.url)) {
      Sidebar = sb.Sidebar;
    }
  });

  if (Sidebar) {
    return true;
  } else {
    return false;
  }
};

class Sidebar extends Component {
  render() {
    const pathname = this.props.location.pathname;
    let Sidebar = DefaultSidebar;

    RegisteredSidebars.forEach(sb => {
      if (pathname && pathname.startsWith(sb.url)) {
        Sidebar = sb.Sidebar;
      }
    });

    if (Sidebar != null) {
      return <Sidebar />;
    } else {
      return null;
    }
  }
}

Sidebar.propTypes = {
  location: PropTypes.any,
};

export default Sidebar;
export { HasSidebar };
