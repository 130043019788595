import React from 'react';
import PropTypes from 'prop-types';
import { Content } from '@myie/interact-dom';

class AERAbbreviation extends React.Component {
  render() {
    return (
      <dfn>
        <abbr title="Annual Equivalent Rate">
          <Content cmsTag="GLOBAL:AER" copytext="AER" />
        </abbr>
      </dfn>
    );
  }
}

AERAbbreviation.propTypes = {
  items: PropTypes.string,
};

export default AERAbbreviation;
