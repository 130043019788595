import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
} from 'reactstrap';
import Errors, { HasErrors } from './Errors';
import { Validate } from '@myie/interact';
import contentManaged, { ContentType } from '../HO_components/contentManaged';

class Text extends React.Component {
  constructor(props) {
    super(props);
    const {
      label,
      subLabel,
      description,
      suffix,
      placeholder,
      inputPrefix,
      inputSuffix,
      validation,
      managedContent,
    } = props;
    managedContent({
      label: { defaultValue: label },
      subLabel: { defaultValue: subLabel },
      description: { defaultValue: description },
      suffix: { defaultValue: suffix },
      placeholder: { defaultValue: placeholder },
      inputPrefix: { defaultValue: inputPrefix },
      inputSuffix: { defaultValue: inputSuffix },
      validation: validation,
    });
  }

  attachAddons(input, inputPrefix, inputSuffix, inputGroupClassName, id) {
    const items = [];
    if (inputPrefix || inputSuffix) {
      if (inputPrefix) {
        items.push(
          <InputGroupAddon
            addonType="prepend"
            key="prepend"
            id={`${id}-prepend`}
          >
            <InputGroupText id={`${id}-prepend-text`}>
              {inputPrefix}
            </InputGroupText>
          </InputGroupAddon>,
        );
      }
      items.push(input);
      if (inputSuffix) {
        items.push(
          <InputGroupAddon addonType="append" key="append" id={`${id}-append`}>
            <InputGroupText id={`${id}-append-text`}>
              {inputSuffix}
            </InputGroupText>
          </InputGroupAddon>,
        );
      }
      return <InputGroup className={inputGroupClassName}>{items}</InputGroup>;
    }
    return input;
  }

  render() {
    const { props } = this;

    const {
      children,
      validation = {},
      disabled,
      required,
      labelClassName = '',
      groupClassName = '',
      inputGroupClassName,
      showErrors = true,
      id,
      field,
      contentValue,
      managedContent,
      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });

    const { value = '', state = {} } = validation;
    const label = contentValue(ContentType.Text, 'label');
    const subLabel = contentValue(ContentType.Text, 'subLabel');
    const description = contentValue(ContentType.Text, 'description');
    const suffix = contentValue(ContentType.Text, 'suffix');
    const placeholder = contentValue(ContentType.Text, 'placeholder');
    const inputPrefix = contentValue(ContentType.Text, 'inputPrefix');
    const inputSuffix = contentValue(ContentType.Text, 'inputSuffix');
    var fieldName =
      typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;
    const isRequired =
      required || (validation && validation.rules && validation.rules.required);
    return (
      <FormGroup
        className={`${groupClassName} ${disabled ? 'disabled-input' : ''} ${
          isRequired ? 'required-input' : ''
        }`.trim()}
      >
        {label || subLabel || description ? (
          <Label className={labelClassName} id={`${id}-label`} htmlFor={id}>
            {label}
            {isRequired ? (
              <React.Fragment>
                <span aria-hidden="true">*</span>
                <span className="sr-only">(required field)</span>
              </React.Fragment>
            ) : (
              ''
            )}{' '}
            {subLabel.length > 0 ? <small>{subLabel}</small> : ''}
            {description.length > 0 ? (
              <span className="description">{description}</span>
            ) : (
              ''
            )}
          </Label>
        ) : (
          ''
        )}
        {this.attachAddons(
          <Input
            key="input"
            aria-describedby={
              HasErrors(validation)
                ? null
                : `error-${fieldName
                    .replace('[', '-')
                    .replace(']', '')}-validation`
            }
            //aria-labelledby={`${id}-label`}
            invalid={Validate.isInvalid(state)}
            valid={Validate.isValid(state)}
            value={value}
            disabled={disabled}
            required={required}
            id={id}
            name={fieldName}
            maxLength={props.maxLength}
            placeholder={placeholder}
            {...rest}
          />,
          inputPrefix,
          inputSuffix,
          inputGroupClassName,
          id,
        )}

        {suffix && <p className="suffix">{suffix}</p>}
        {children}
        {showErrors ? (
          <Errors
            contentValue={contentValue}
            validation={validation}
            field={field}
          />
        ) : (
          ''
        )}
      </FormGroup>
    );
  }
}

Text.propTypes = {
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  /**
   * Any children of the component
   */
  children: PropTypes.element,
  /**
   * Text placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Text description text
   */
  description: PropTypes.string,
  /**
   * Text input disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * Input field names
   */
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Style class name for group
   */
  groupClassName: PropTypes.string,
  /**
   * Date ID
   */
  id: PropTypes.string.isRequired,
  /**
   * Style class name for input group
   */
  inputGroupClassName: PropTypes.string,
  /**
   * A prefix for the input
   */
  inputPrefix: PropTypes.string,
  /**
   * A suffix for the input
   */
  inputSuffix: PropTypes.string,
  /**
   * Label text
   */
  label: PropTypes.string,
  /**
   * Style class name for label
   */
  labelClassName: PropTypes.string,
  /**
   * Maximum character limit
   */
  maxLength: PropTypes.any,
  /**
   * Input required or not
   */
  required: PropTypes.bool,
  /**
   * Show errors or not
   */
  showErrors: PropTypes.bool,
  /**
   * Sub label text
   */
  subLabel: PropTypes.string,
  /**
   * Suffix text
   */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Input validation
   */
  validation: PropTypes.object,
};

export default contentManaged(Text);
