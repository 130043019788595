import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

class Badges extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    return (
      <section id="breadcrumbs-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/components/breadcrumbs.scss</strong>
          </code>
        </p>
        <p>
          There is a component made for the <strong>breadcrumb</strong> defined
          within reactstrap. To see an interactive technical specification for
          this component, go to{' '}
          <Link to="https://reactstrap.github.io/components/breadcrumbs/">
            https://reactstrap.github.io/components/breadcrumbs/
          </Link>
          .
        </p>
        <div className="breadcrumb-container">
          <Breadcrumb tag="nav">
            <BreadcrumbItem
              active={
                this.state.stage === 'ViewDetails' ||
                this.state.stage === 'Details'
              }
            >
              1 Payment details
            </BreadcrumbItem>
            <BreadcrumbItem active={true}>2 Review</BreadcrumbItem>
            <BreadcrumbItem active={this.state.stage === 'Confirmation'}>
              3 Confirmation
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="clearfix"></div>
      </section>
    );
  }
}

Badges.propTypes = {
  items: PropTypes.string,
};

export default Badges;
