import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '30', height = '30' }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 32 32.098"
    height={height}
    width={width}
  >
    <g>
      <rect
        x="10.156"
        y="27.074"
        className={fill}
        width="12.077"
        height="1.007"
      />
      <rect
        x="10.156"
        y="24.056"
        className={fill}
        width="12.077"
        height="1.006"
      />
      <path
        className={fill}
        d="M26.078,1.105c-0.785-0.786-1.822-1.203-2.854-1.203H9.135
          c-1.03,0-2.052,0.417-2.838,1.203C5.51,1.89,5.124,2.945,5.124,3.975v6.997h2.013V3.975c0-0.538,0.203-1.066,0.583-1.447
          C8.1,2.148,8.598,1.916,9.136,1.916h14.089c0.538,0,1.048,0.232,1.429,0.613s0.596,0.909,0.596,1.447v6.997h2.014V3.975
          C27.264,2.945,26.863,1.89,26.078,1.105z"
      />
      <path
        className={fill}
        d="M30.659,13.535c-0.982-0.983-2.122-1.438-3.409-1.438H5.11
          c-1.288,0-2.62,0.455-3.603,1.438c-0.982,0.984-1.52,2.234-1.52,3.522v9.058c0,1.213,1.464,2.982,3.104,2.982h1.923
          c0,0,0.188,3,4.122,3h14.089c1.031,0,1.943-0.317,2.729-1.103c0.785-0.787,1.061-1.897,1.061-1.897h2.256
          c1.479,0,2.717-1.66,2.717-2.982v-9.058C31.986,15.77,31.642,14.519,30.659,13.535z M24.986,28.138
          c0,0.533-0.085,1.013-0.464,1.39c-0.381,0.381-0.759,0.57-1.298,0.57H9.135c-0.537,0-1.111-0.189-1.491-0.57
          c-0.381-0.379-0.658-0.877-0.658-1.414v-6.016h18V28.138z M27.25,19.07c-1.111,0-2.012-0.9-2.012-2.013
          c0-1.111,0.9-2.014,2.012-2.014s2.014,0.902,2.014,2.014C29.264,18.17,28.361,19.07,27.25,19.07z"
      />
    </g>
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
