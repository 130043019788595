import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import contentManaged, { ContentType } from '../HO_components/contentManaged';

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    const { managedContent } = props;
    managedContent({
      previous: { defaultValue: 'Previous' },
      text: { defaultValue: 'Page $[currentPage] of $[totalPages]' },
      next: { defaultValue: 'Next' },
    });
  }

  render() {
    const {
      Total,
      Offset,
      Size,
      getNextPage,
      getPreviousPage,
      contentValue,
    } = this.props;
    const totalPages =
      Math.ceil(Total / Size) === 0 ? 1 : Math.ceil(Total / Size);
    const currentPage = Math.ceil(Offset / Size) + 1;
    const previous = contentValue(ContentType.Text, 'previous');
    const next = contentValue(ContentType.Text, 'next');
    const text = contentValue(ContentType.Text, 'text', {
      currentPage: currentPage,
      totalPages: totalPages,
    });

    return (
      <div className="pagination-container">
        <p>{text}</p>
        <Button
          id="previous-page-btn"
          color={'secondary'}
          size="sm"
          onClick={() => getPreviousPage(Offset, Size)}
          disabled={currentPage === 1 ? true : false}
        >
          <span className="sr-only">{previous}</span>
          <span className="icon"></span>
        </Button>
        <Button
          id="next-page-btn"
          color={'secondary'}
          size="sm"
          onClick={() => getNextPage(Offset, Size, Total)}
          disabled={currentPage === totalPages ? true : false}
        >
          <span className="sr-only">{next}</span>
          <span className="icon"></span>
        </Button>
      </div>
    );
  }
}

Pagination.propTypes = {
  Offset: PropTypes.number.isRequired,
  Size: PropTypes.number.isRequired,
  Total: PropTypes.number.isRequired,
  contentValue: PropTypes.any,
  getNextPage: PropTypes.func.isRequired,
  getPreviousPage: PropTypes.func.isRequired,
  managedContent: PropTypes.any,
  next: PropTypes.any,
  previous: PropTypes.any,
  subLabel: PropTypes.any,
};

export default contentManaged(Pagination);
