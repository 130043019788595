import React from 'react';
import { DropDown } from '@myie/interact-dom';
import { FormGroup, Row, Col } from 'reactstrap';

/** Dropdown example */
class DropDownExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  onChange = e => {
    const { value } = e.target;
    this.setState({ value });
  };

  render() {
    return (
      <Row>
        <Col md={6}>
          <FormGroup>
            <DropDown
              label="Select Items"
              id="pay-from"
              field="payFrom"
              onChange={this.onChange}
              onBlur={this.onChange}
            >
              <option id="item-1" value="1">
                Item 1
              </option>
              <option id="item-2" value="2">
                Item 2
              </option>
            </DropDown>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default DropDownExample;
