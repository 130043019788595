// start fetch process - get list of "customers" for person
export const ORGANISATION_REQUEST = 'ORGANISATION_REQUEST';

// get details of a "customer"
export const ORGANISATION_PROGRESS = 'ORGANISATION_PROGRESS';

// show details of each customer on list
export const ORGANISATION_SUCCESS = 'ORGANISATION_SUCCESS';

// we failed to fetch list or we failed to fetch details - show error
export const ORGANISATION_FAILURE = 'ORGANISATION_FAILURE';

// clear the organisation details - how is this different from ORGANISATION_REQUEST?
export const RESET_ORGANISATION = 'RESET_ORGANISATION';

//Reset organisation details
export const RESET_ORGANISATION_DETAILS = 'RESET_ORGANISATION_DETAILS';

//Get Organisation details
export const ORGANISATION_DETAILS_REQUEST = 'ORGANISATION_DETAILS_REQUEST';
