import React from 'react';
import { Dialog } from '@myie/interact-dom';
import { Row, Col, FormGroup } from 'reactstrap';

/** Dialog example */
class DialogExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: true,
    };
  }

  onClick = () => {
    const { value } = this.state;
    this.setState({ value: !value });
  };

  render() {
    const { value } = this.state;
    return (
      <Row>
        <Col md={6}>
          <FormGroup>
            <Dialog
              id="modal-sample-modal"
              isOpen={value}
              title="Two Factor Authentication"
            >
              <h2 onClick={this.onClick}>click Here to close the modal</h2>
            </Dialog>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default DialogExample;
