import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'reactstrap';
import { Validate } from '@myie/interact';
import { Link } from 'react-router-dom';
import { DropDown, Text, TextArea, Content, AppMeta } from '@myie/interact-dom';
import Currency from 'react-currency-formatter';

import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-messaging-twoway';

class SendNewMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        relatedAccount: {
          rules: {},
        },
        subject: {
          rules: {
            format: {
              regex: /^.{0,100}$/,
              message: 'Please enter a valid subject',
            },
          },
        },
        message: {
          rules: {
            stop: true,
            required: {
              message: 'Please enter a message',
            },
            format: {
              regex: /^[^\s](.|[\r\n]){1,750}$/,
              message: 'Please enter a valid message',
            },
          },
        },
      },
      disabled: false,
    };
  }

  accountOptions = (prefix, item, id) => {
    var name = item.AccountNumber;
    if (item.Balance) {
      name =
        name +
        ': ' +
        Currency({ quantity: item.Balance, currency: item.CurrencyCode });
    }
    if (item.AccountStatus === 'Closed') {
      name = name + ' <Closed account>';
    }
    return (
      <option
        key={`${prefix}-${item.AccountKey.Key}-${id}`}
        id={id}
        value={item.AccountKey.Key}
      >
        {name}
      </option>
    );
  };

  submit = e => {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    e.preventDefault();
    let { form } = this.state;
    const { replyConversation, selectedData, accounts } = this.props;

    let messageBody = '';

    if (selectedData) {
      messageBody = selectedData.message;
      if (selectedData.relatedAccountObj) {
        let account;
        if (accounts) {
          account = accounts.Accounts.find(function(element) {
            return (
              element.AccountKey.Key ===
              selectedData.relatedAccountObj.AccountKey.Key
            );
          });
        }

        messageBody = `Message about account number ${account.AccountNumber} \n ${selectedData.message}`;
      }
    }

    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      this.setState({ disabled: false });
      return;
    }
    this.setState({ disabled: true });
    let request = {
      AccountKey: selectedData.relatedAccountObj
        ? selectedData.relatedAccountObj.AccountKey
        : null,
      ConversationKey: null,
      MessageBody: messageBody,
      Reason: selectedData.subject ? selectedData.subject : 'General Enquiry',
      ProductType: selectedData.relatedAccountObj
        ? selectedData.relatedAccountObj.ProductType
        : null,
      ExtendedProperties: {},
    };
    replyConversation(request);
  };

  onBlur = e => {
    let { form } = this.state;
    const { name, value } = e.target;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  onChangeAccount = e => {
    const { saveData, accounts } = this.props;
    let { form } = this.state;
    const { name, value } = e.target;
    const accountKey = value;
    let source;
    form = Validate.input(name, value, form, true);
    if (accounts) {
      source = accounts.Accounts.find(
        account => account.AccountKey.Key === accountKey,
      );
    }
    saveData(name, value);
    saveData('relatedAccountObj', source);
    this.setState({ ...this.state, form });
  };

  onChange = e => {
    const { saveData } = this.props;
    let { form } = this.state;
    const { name, value } = e.target;
    form = Validate.input(name, value, form, true);
    saveData(name, value);
    this.setState({ ...this.state, form });
  };

  render() {
    const { accounts, selectedData } = this.props;
    const { form } = this.state;
    let accountList = accounts.Accounts;
    if (selectedData) {
      form.relatedAccount.value = selectedData.relatedAccount;
      form.subject.value = selectedData.subject;
      form.message.value = selectedData.message;
    }
    return (
      <>
        <AppMeta
          id="meta-data"
          stage="child"
          title="Send a new message"
          metaDescription="Send a new message"
        />
        <Form autoComplete="off" id="messaging-twoway-new-message">
          <Row>
            <Col xs={12} md={8} lg={6}>
              <DropDown
                cmsTag="messaging-twoway:new.relatedAccount"
                label="Regarding a specific account?"
                disabled={accountList.length === 0}
                id="related-account"
                field="relatedAccount"
                onChange={this.onChangeAccount}
                onBlur={this.onBlur}
                validation={form.relatedAccount}
              >
                <option
                  key="accounts-empty"
                  value=""
                  defaultText="Please select"
                ></option>
                {accountList.map((item, index) => {
                  return this.accountOptions(
                    'related-account',
                    item,
                    'related-account-' + index,
                  );
                })}
              </DropDown>
              <Text
                cmsTag="messaging-twoway:new.subject"
                label="Please enter a subject"
                id="subject"
                field="subject"
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.subject}
                maxLength="100"
              />
            </Col>
          </Row>
          <TextArea
            cmsTag="messaging-twoway:new.message"
            label="Your message"
            id="message"
            field="message"
            onChange={this.onChange}
            //description="Please make sure you include the account you’re asking about"
            onBlur={this.onBlur}
            validation={form.message}
            //placeholder="You have not reached the minimum amount of text in your message, please look to include details where possible, thank you."
            maxLength="750"
          />
          <p className="float-right caveat" aria-hidden="true">
            <Content
              id="chars-remaining"
              template={{
                copytext: {
                  counter: () => {
                    return (
                      750 -
                      (this.state.form.message.value
                        ? this.state.form.message.value.length
                        : 0)
                    );
                  },
                },
              }}
              copytext="Chars remaining: $[counter]"
            />
          </p>
          <div className="form-button-group">
            <Button
              id="messaging-twoway-send-message"
              type="submit"
              color="primary"
              onClick={this.submit}
              disabled={this.state.disabled}
            >
              <Content id="sendMessageButton" copytext="Send" />
            </Button>
            <Link
              id="messaging-twoway-send-cancel"
              className="btn btn-secondary"
              role="button"
              to="/messaging-twoway/view"
            >
              <Content id="CancelButton" copytext="Cancel" />
            </Link>
          </div>
        </Form>
      </>
    );
  }
}

SendNewMessage.propTypes = {
  accounts: PropTypes.any,
  replyConversation: PropTypes.any,
  saveData: PropTypes.any,
  selectedData: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SendNewMessage);
