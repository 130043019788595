import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Content } from '@myie/interact-dom';

class OrganisationDetails extends React.Component {
  render() {
    const { organisation } = this.props;
    return (
      <>
        <section>
          <h2 className="h3">
            <Content id="summary-title" copytext={organisation.FullName} />
          </h2>
          <Row tag="dl" title="Organisation Details">
            <Col tag="dt" xs={12} sm={4} md={3}>
              <Content id="address-line-1" copytext="Address line 1" />
            </Col>
            <Col tag="dd" xs={12} sm={8} md={9}>
              {organisation.CorrespondenceAddress.Street}
            </Col>
            <Col tag="dt" xs={12} sm={4} md={3}>
              <Content id="address-line-2" copytext="Address line 2" />
            </Col>
            <Col tag="dd" xs={12} sm={8} md={9}>
              {organisation.CorrespondenceAddress.District}
            </Col>
            <Col tag="dt" xs={12} sm={4} md={3}>
              <Content id="address-town" copytext="Town / City" />
            </Col>
            <Col tag="dd" xs={12} sm={8} md={9}>
              {organisation.CorrespondenceAddress.Town}
            </Col>
            <Col tag="dt" xs={12} sm={4} md={3}>
              <Content id="address-county" copytext="County" />
            </Col>
            <Col tag="dd" xs={12} sm={8} md={9}>
              {organisation.CorrespondenceAddress.County}
            </Col>
            <Col tag="dt" xs={12} sm={4} md={3}>
              <Content id="address-postcode" copytext="Postcode" />
            </Col>
            <Col tag="dd" xs={12} sm={8} md={9}>
              {organisation.CorrespondenceAddress.Postcode}
            </Col>
            {!organisation.ContactDetails ? (
              <>
                <Col tag="dt" xs={12} sm={4} md={3}>
                  <Content id="address-landline" copytext="Landline" />
                </Col>
                <Col tag="dd" xs={12} sm={8} md={9}></Col>
                <Col tag="dt" xs={12} sm={4} md={3}>
                  <Content id="address-mobile-1" copytext="Mobile" />
                </Col>
                <Col tag="dd" xs={12} sm={8} md={9}></Col>
                <Col tag="dt" xs={12} sm={4} md={3}>
                  <Content id="address-email-1" copytext="Email" />
                </Col>
                <Col tag="dd" xs={12} sm={8} md={9}></Col>
              </>
            ) : (
              <>
                <Col tag="dt" xs={12} sm={4} md={3}>
                  <Content id="address-landline" copytext="Landline" />
                </Col>
                <Col tag="dd" xs={12} sm={8} md={9}>
                  {organisation.ContactDetails.LandlineTelephone}
                </Col>
                <Col tag="dt" xs={12} sm={4} md={3}>
                  <Content id="address-mobile-1" copytext="Mobile" />
                </Col>
                <Col tag="dd" xs={12} sm={8} md={9}>
                  {organisation.ContactDetails.MobileTelephone}
                </Col>
                <Col tag="dt" xs={12} sm={4} md={3}>
                  <Content id="address-email-1" copytext="Email" />
                </Col>
                <Col tag="dd" xs={12} sm={8} md={9}>
                  {organisation.ContactDetails.Email}
                </Col>
              </>
            )}
          </Row>
          <h3 className="h4">
            <Content
              id="preferences-1-title-1"
              copytext="Contact Preferences"
            />
          </h3>
          <p>
            <Content
              id="preferences-description"
              copytext={`We will only keep you informed of products and services and special rates, terms and offers that we think you may be interested in via the following methods.`}
            />
          </p>
          <p>
            <Content
              id="preferences-description2"
              copytext={`We can contact you via:`}
            />
          </p>

          {!organisation.MarketingPreferences ? (
            <p className="alert alert-danger mt-3">
              <Content
                id="preferences-unknown"
                copytext="Sorry - we don't have a record of your contact preferences"
              />
            </p>
          ) : (
            <Row className="mt-3" tag="dl" title="Contact list">
              <Col tag="dt" xs={6} sm={4} md={3}>
                <Content id="preference-email" copytext="Email" />
              </Col>
              <Col tag="dd" xs={6} sm={8} md={9}>
                {organisation.MarketingPreferences.Email ? 'Yes' : 'No'}
              </Col>
              <Col tag="dt" xs={6} sm={4} md={3}>
                <Content id="preference-telephone" copytext="Telephone" />
              </Col>
              <Col tag="dd" xs={6} sm={8} md={9}>
                {organisation.MarketingPreferences.Telephone ? 'Yes' : 'No'}
              </Col>
              <Col tag="dt" xs={6} sm={4} md={3}>
                <Content id="preference-post" copytext="Post" />
              </Col>
              <Col tag="dd" xs={6} sm={8} md={9}>
                {organisation.MarketingPreferences.Post ? 'Yes' : 'No'}
              </Col>
            </Row>
          )}
          <hr />
        </section>
      </>
    );
  }
}

OrganisationDetails.propTypes = {
  organisation: PropTypes.any,
};

export default OrganisationDetails;
