import React from 'react';
import { Link } from 'react-router-dom';
import { Content } from '@myie/interact-dom';

class SummaryMenuItems extends React.Component {
  render() {
    return (
      <Link id="accounts-menu-item" className="nav-link" to="/accounts/">
        <Content id="menuItem-Accounts" copytext="My Accounts" />
      </Link>
    );
  }
}

export default SummaryMenuItems;
