import React, { Component } from 'react';
import { Content, FormattedCurrency, FormattedDate } from '@myie/interact-dom';
import { Col, Row } from 'reactstrap';
import { Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-ccb-savings-servicing';
import { connect } from 'react-redux';
import { Utility } from '@myie/interact';
import Currency from 'react-currency-formatter';

class Cancel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: [],
      selectedAccountKey: null,
      selectedAccount: {},
      selectedTransaction: {},
      disabled: false,
    };
  }

  onConfirmCancellation = () => {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    const { cancelNoticeToWithdraw, identifier, account = {} } = this.props;
    const { transactions: { Transactions = [] } = {} } = account;
    let transaction = Transactions.find(
      element => element.TransactionIdentifier === identifier,
    );
    cancelNoticeToWithdraw({
      AccountKey: account && account.AccountKey,
      TransactionIdentifier: transaction.TransactionIdentifier,
    });
  };

  render() {
    const { AccountKey: { Key } = {} } = this.props.account;
    const accountKey = Key && Utility.hexEncode(Key);

    const { account = {}, identifier } = this.props;
    const {
      CurrencyCode,
      RemainingAllowance,
      ExtendedProperties: {
        NominatedAccountNumber,
        NominatedSortCode,
        NominatedAccountName,
      } = {},
    } = account;
    let transaction = {};

    const { transactions: { Transactions = [] } = {} } = account;

    transaction = Transactions.find(
      element => element.TransactionIdentifier === identifier,
    );

    return (
      <>
        <Content
          id="description"
          copytext="You are cancelling the notice to withdraw on the following account"
        />

        <div className="confirm-notice-to-withdraw-details">
          <Row tag="dl" title="Account details review">
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content id="lbl-from-account" copytext="From" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              <Content id="nominated-account" copytext="Notice Account" />{' '}
              {Currency({
                quantity: RemainingAllowance,
                currency: CurrencyCode,
              })}
              <span className="fw-normal d-block">
                {account.SortCode} - {account.AccountNumber}
              </span>
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content id="lbl-to-account" copytext="To" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {NominatedAccountName}
              <span className="fw-normal d-block">
                {NominatedSortCode}-{NominatedAccountNumber}
              </span>
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content id="lbl-amount" copytext="Amount" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {transaction.Amount !== 0 ? (
                <FormattedCurrency
                  quantity={transaction.Amount}
                  currency={transaction.CurrencyCode || CurrencyCode}
                />
              ) : (
                <Content id="all-amount" copytext="All" />
              )}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content id="lbl-withdraw-date" copytext="Withdraw date" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              <FormattedDate date={transaction.Date} />
            </Col>
          </Row>
        </div>

        <div className="form-button-group">
          <Button
            id="confirm-create-new-notice"
            type="submit"
            onClick={this.onConfirmCancellation}
            color="primary"
            disabled={this.state.disabled}
          >
            <Content id="confirm" copytext="Confirm" />
          </Button>
          <Link
            id="cancel-notice-to-withdraw"
            className="btn btn-secondary"
            role="button"
            to={`/notice-to-withdraw/${accountKey}`}
          >
            <Content
              id="cancel-button-notice"
              copytext="Back to notice to withdraw"
            />
          </Link>
        </div>
      </>
    );
  }
}

Cancel.propTypes = {
  match: PropTypes.any,
  accounts: PropTypes.any,
  cancellationResponse: PropTypes.any,
  message: PropTypes.any,
  history: PropTypes.any,
  account: PropTypes.any,
  identifier: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.func,
  cancelNoticeToWithdraw: PropTypes.func,
};

const mapStateToProps = state => {
  const { accountsDefinition, customccbSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...customccbSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
)(withRouter(Cancel));
