import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '33.875', height = '34.129' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 33.875 34.129"
    viewBox="0 0 33.875 34.129"
  >
    <path
      className={fill}
      d="M15.967 18.491a24.926 24.926 0 012.619-5.958 4.176 4.176 0 00-6.663-1.027 4.178 4.178 0 004.044 6.985z"
    />
    <path
      className={fill}
      d="M15.032 29.69a28.889 28.889 0 01.04-6.88c-.067.002-.13.008-.196.008a8.322 8.322 0 01-5.909-2.448 8.354 8.354 0 010-11.818 8.353 8.353 0 0111.819 0c.156.157.301.322.441.488 1.563-1.669 3.355-3.007 5.309-3.924L23.47 2.048 20.792 4.67c-1.029-.628-2.02-1.08-3.329-1.354V0h-5.239v3.314c-.872.275-2.266.726-3.294 1.353L6.295 2.044l-3.83 3.822 2.621 2.637c-.627 1.028-1.082 2.412-1.355 3.286H0v5.676h3.731c.274.873.726 2.092 1.352 3.121L2.46 23.132l3.822 3.784 2.648-2.645c1.028.628 2.421 1.063 3.295 1.337v4.082h2.807z"
    />
    <g>
      <path
        className={fill}
        d="M33.875 30.589v3.54H18.528v-2.565c2.311-1.154 3.829-3.357 3.829-5.993 0-.507-.074-.975-.146-1.479h-3.61v-2.493l2.419-.181h.433c-.396-1.262-.758-2.49-.758-3.683 0-4.333 2.961-7.004 7.223-7.004 2.635 0 4.477 1.083 5.92 2.815l-2.311 2.275c-.902-1.046-1.877-1.698-3.25-1.698-2.238 0-3.574 1.409-3.574 3.828 0 1.192.289 2.312.615 3.467h5.559v2.674H25.93c.072.505.109.973.109 1.515 0 2.131-.613 3.467-1.913 4.839v.145h9.749z"
      />
    </g>
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
