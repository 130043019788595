import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Button, Form, Row, Col } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import {
  mapDispatchToProps,
  mapStateToProps,
} from '@myie/interact-authentication';
// Add additional CCB reducer function for clearing error state on dismount
import { mapDispatchToProps as ccbUpdateDispatch } from '@myie/interact-ccb-authentication';
import { Text, Switch, Content, Markdown, AppMeta } from '@myie/interact-dom';
import { Validate, Session } from '@myie/interact';

class UpdateSecurityDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      submitForm: {},
      form: {
        username: {
          rules: {
            title: 'username',
            stop: true,
            range: {
              min: 6,
              max: 23,
              message:
                'Your username must be at least 6 characters, and no more than 23 characters',
            },
            format: {
              regex: /^[^\s$&+,/:;=?%#<>{}^~[\]`\\]{6,23}$/,
              message:
                'Your username contains one or more of these invalid symbols $&+,/:;=?%#<>{}^~[]` or spaces. Please remove them and try again',
            },
          },
        },
        password: {
          rules: {
            title: 'password',
            stop: true,
            format: {
              regex: /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s$).{10,50}$/,
              message: 'Please enter valid a password',
            },
          },
        },
        confirmPassword: {
          rules: {
            title: 'confirm password',
            stop: true,
            format: {
              regex: /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s$).{10,50}$/,
              message: 'Please enter valid a password',
            },
            compare: {
              message:
                'Your passwords do not match. Please check and try again.',
              field: 'password',
              comparison: () => {
                return this.state.form.password.value;
              },
            },
          },
        },
        memorableNumber: {
          rules: {
            title: 'memorable number',
            stop: true,
            required: {
              message: 'Please enter your memorable number',
            },
            format: {
              regex: /^[0-9]{6}$/,
              message: 'Please enter a valid memorable number',
            },
          },
        },
        confirmMemorableNumber: {
          rules: {
            title: 'confirm memorable number',
            stop: true,
            required: {
              message: 'Please confirm your memorable number',
            },
            format: {
              regex: /^[0-9]{6}$/,
              message: 'Please enter a valid memorable number',
            },
            compare: {
              message: 'Please ensure the memorable number fields match',
              field: 'memorableNumber',
              comparison: () => {
                return this.state.form.memorableNumber.value;
              },
            },
          },
        },
      },
    };
  }

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    if (form[name]) {
      form = Validate.input(name, value, form);
    }
    this.setState({
      ...this.state,
      form,
    });
  };

  onBlur = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    if (form[name] && e.target.value) {
      form = Validate.input(name, value, form, true);
    }
    this.setState({
      ...this.state,
      form,
    });
  };

  submit = e => {
    e.preventDefault();
    const { updateCredentials } = this.props;
    let { form, message = {}, submitForm = {} } = this.state;
    let {
      username,
      password,
      confirmPassword,
      memorableNumber,
      confirmMemorableNumber,
    } = form;
    if (form.username.value) {
      const usernameRules = { username };
      submitForm = { ...submitForm, ...usernameRules };
    } else {
      delete submitForm.username;
    }

    if (form.password.value) {
      const passwordRules = { password };
      const confirmPasswordRules = { confirmPassword };
      submitForm = { ...submitForm, ...passwordRules, ...confirmPasswordRules };
      form = Validate.input('password', form.password.value, form, true);
      form = Validate.input(
        'confirmPassword',
        form.confirmPassword.value,
        form,
        true,
      );
    } else {
      delete submitForm.password;
      delete submitForm.confirmPassword;
    }

    if (form.memorableNumber.value) {
      const memorableNumberRules = { memorableNumber };
      const confirmMemorableNumberRules = {
        confirmMemorableNumber,
      };
      submitForm = {
        ...submitForm,
        ...memorableNumberRules,
        ...confirmMemorableNumberRules,
      };
      form = Validate.input(
        'memorableNumber',
        form.memorableNumber.value,
        form,
        true,
      );
      form = Validate.input(
        'confirmMemorableNumber',
        form.confirmMemorableNumber.value,
        form,
        true,
      );
    } else {
      delete submitForm.memorableNumber;
      delete submitForm.confirmMemorableNumber;
    }

    if (Object.keys(submitForm).length === 0) {
      message = 'MustHaveCredential';
      this.setState({
        ...this.state,
        form,
        submitForm,
        message,
      });
      return;
    } else {
      message = null;
    }
    submitForm = Validate.form(submitForm);
    if (!submitForm.approved) {
      this.setState({
        ...this.state,
        form,
        submitForm,
        message,
      });
      return;
    }

    const request = {
      Username: form.username.value,
      CredentialValues: [],
      ExtendedProperties: null,
    };

    if (form.password.value) {
      request.CredentialValues.push({
        Name: 'Password',
        Value: form.password.value,
        Context: 'string',
      });
    }

    if (form.memorableNumber.value) {
      request.CredentialValues.push({
        Name: 'Pin',
        Value: form.memorableNumber.value,
        Context: 'string',
      });
    }

    updateCredentials(request);
    this.setState({
      ...this.state,
      form,
      submitForm,
      message,
    });
  };

  componentWillUnmount = () => {
    // Call CCB reducer function to clear error in redux store
    this.props.updateSecurityDetailsReceive({});
  };

  getErrors = () => {
    const { updateSecurityDetails = {} } = this.props;
    let errors = [];
    let fieldErrors = {};
    if (
      updateSecurityDetails &&
      updateSecurityDetails.ExtendedProperties &&
      updateSecurityDetails.ExtendedProperties.IdentityResponse &&
      updateSecurityDetails.ExtendedProperties.IdentityResponse.CredentialErrors
    ) {
      updateSecurityDetails.ExtendedProperties.IdentityResponse.CredentialErrors.forEach(
        element => {
          switch (element) {
            case 'Dob':
              fieldErrors.memorableNumber = element;
              return errors.push(
                <Alert id={element} key={element} color="danger" role="alert">
                  <Content
                    id={element}
                    copytext="Your memorable number cannot be your date of birth"
                  />
                </Alert>,
              );
            case 'AllSame':
              fieldErrors.memorableNumber = element;
              return errors.push(
                <Alert id={element} key={element} color="danger" role="alert">
                  <Content
                    id={element}
                    copytext="You cannot have all the digits the same in your memorable number"
                  />
                </Alert>,
              );
            case 'Sequential':
              fieldErrors.memorableNumber = element;
              return errors.push(
                <Alert id={element} key={element} color="danger" role="alert">
                  <Content
                    id={element}
                    copytext="You aren't allowed to have a sequential sequence of digits for your memorable number"
                  />
                </Alert>,
              );
            case 'Known':
              fieldErrors.password = element;
              return errors.push(
                <Alert id={element} key={element} color="danger" role="alert">
                  <Content
                    id={element}
                    copytext="Your chosen password is not allowed as it is on a list of well known passwords"
                  />
                </Alert>,
              );
            case 'Username':
              fieldErrors.password = element;
              return errors.push(
                <Alert id={element} key={element} color="danger" role="alert">
                  <Content
                    id={element}
                    copytext="Your password cannot be the same as your username"
                  />
                </Alert>,
              );
            default:
          }
        },
      );
    }

    return {
      errorMsg: errors,
      fieldErrors: fieldErrors,
    };
  };

  buildErrorMsg = updateSecurityDetails => {
    var errorsExist = false;
    var errorMsg = [];

    if (
      updateSecurityDetails &&
      updateSecurityDetails.Status === 'InvalidCredentials'
    ) {
      errorMsg = this.getErrors().errorMsg;
      errorsExist = true;
    } else {
      if (
        updateSecurityDetails &&
        updateSecurityDetails.Status &&
        updateSecurityDetails.Status !== '' &&
        updateSecurityDetails.Status !== 'Success'
      ) {
        errorsExist = true;

        errorMsg = (
          <Switch
            id="update-security-details-alert"
            value={updateSecurityDetails.Status || ''}
            tag="div"
            className="alert alert-danger"
            contents={{
              MustHaveCredential: {
                defaultValue:
                  'You must input at least one credential to change.',
              },
              UsernameExists: {
                defaultValue: 'The username you specified is already in use.',
              },
              NoRegistered: {
                defaultValue:
                  'The details you have entered do not match our records. Please try again.',
              },
              Blocked: {
                defaultValue:
                  'Access to your account was denied for security reasons.',
              },
              NotEnoughCredentials: {
                defaultValue: 'Not enough credentials were submitted',
              },
              InvalidCredentials: {
                defaultValue:
                  'Either the password or memorable number you have entered are invalid.',
              },
              InvalidUsername: {
                defaultValue:
                  'Your username must be between 6 and 23 characters.',
              },
            }}
          />
        );
      }
    }

    return { errorMsg: errorMsg, errorsExist: errorsExist };
  };

  render() {
    const { updateSecurityDetails } = this.props;
    const retrivedUserName = Session.customer();
    let { form, message } = this.state;
    var errorMsgObj = this.buildErrorMsg(updateSecurityDetails);
    if (updateSecurityDetails) {
      switch (updateSecurityDetails.Status) {
        case 'Success': {
          const customer = Session.customer();
          const ticket = Session.ticket();
          customer.Username = this.state.form.username.value
            ? this.state.form.username.value
            : retrivedUserName.Username;
          Session.set(ticket, customer);
          return <Redirect to="/update-security-details/success" />;
        }
        case 'UsernameExists':
          message = 'UsernameExists';
          break;
        case 'NoRegistered':
          message = 'NoRegistered';
          break;
        case 'Blocked':
          message = 'Blocked';
          break;
        case 'NotEnoughCredentials':
          message = 'NotEnoughCredentials';
          break;
        case 'InvalidCredentials':
          message = 'InvalidCredentials';
          break;
        case 'InvalidUsername':
          message = 'InvalidUsername';
          break;
        default:
      }
    }

    return (
      <div id="update-security-details-inner">
        <AppMeta
          id="meta-data"
          stage="child"
          title="Update security details"
          metaDescription="Update security details"
        />
        <h1 id="update-security-details-title">
          <Content id="title" copytext="Security details" />
        </h1>
        {errorMsgObj.errorMsg}
        <Form
          autoComplete="off"
          id="update-security-details-form"
          onSubmit={this.submit}
        >
          <Markdown
            id="generalDescription"
            markdown={`You can update your security details below. Press **Apply** when 
             you have made all the required changes. If you do not want to change 
             something, simply leave the associated field empty.`}
          />

          {/* <Alert color="info">
            <Markdown
              id="infoBox"
              markdown={`**Important:** in order to change any of your
                security details you will need to enter a security code that we
                will send to the mobile number that is currently associated with
                your account. Please ensure that you have your phone handy.`}
            />
          </Alert> */}

          <Markdown
            id="usernameInstructions"
            template={{
              markdown: {
                userName: retrivedUserName.Username,
              },
            }}
            markdown={`## Your username\nYour current username is **$[userName]**. You must enter this every time you sign in.

              \n ###Changing your username\nTo change your username (e.g. to something more memorable), just
              enter your desired new username below. Your username must be
              unique and between 6 and 23 characters long`}
          />

          <Row>
            <Col sm={12} lg={6}>
              <Text
                id="username"
                label="Enter desired new username"
                field="username"
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.username}
                suffix={
                  <Markdown
                    id="userNameNote"
                    markdown="Note: if somebody else has already taken this username you will not be able to use it."
                  />
                }
              />
            </Col>
          </Row>

          <Markdown
            id="passwordInstructions"
            markdown={`## Your password\nYou must enter your password every time that you sign in.

              \nChanging your password\nFor security reasons we cannot display your current password,
              but if you wish you can change it by entering your desired new
              password below. 
              \nYour password must be between 6 and 23
              characters, and must be a mixture of lower and uppercase letters, numbers and symbols.`}
          />

          <Row>
            <Col sm={12} lg={6}>
              <Text
                id="password"
                label="Enter new password"
                field="password"
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.password}
                type="password"
              />
              <Text
                id="confirmPassword"
                label="Confirm new password"
                field="confirmPassword"
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.confirmPassword}
                type="password"
              />
            </Col>
          </Row>

          <Markdown
            id="memorableWordInstructions"
            markdown={`## Your memorable number\nYou must enter your memorable number every time that you sign in
             
                \n### Changing your memorable number\nFor security reasons we cannot display your current memorable
                word, but if you wish you can change it by entering your desired
                new memorable number below.
                
                \nYour memorable number must consist of
                six characters.`}
          />

          <Row>
            <Col sm={12} lg={6}>
              <Text
                id="memorableNumber"
                label="Enter your memorable number"
                field="memorableNumber"
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.memorableNumber}
                type="password"
              />
              <Text
                id="confirmMemorableNumber"
                label="Confirm your memorable number"
                field="confirmMemorableNumber"
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.confirmMemorableNumber}
                type="password"
              />
            </Col>
          </Row>
          {message ? (
            <Alert
              id="reg-error-exists"
              color="danger"
              role="alert"
              aria-live="assertive"
              className="mt-4"
            >
              <Content
                id="register-error-exists"
                copytext="Unfortunately something  is incorrect – please scroll to the top for more details."
              />
            </Alert>
          ) : null}
          <div className="form-button-group">
            <Button id="security-details-submit" type="submit" color="primary">
              <Content id="continueButton" copytext="Apply" />
            </Button>
            <Link
              id="security-details-cancel"
              className="btn btn-secondary"
              role="button"
              to="/services"
            >
              <Content
                id="cancelButton-update-details"
                copytext="Back to services"
              />
            </Link>
          </div>
        </Form>
      </div>
    );
  }
}

UpdateSecurityDetails.propTypes = {
  retrivedUserName: PropTypes.object,
  updateCredentials: PropTypes.any,
  updateSecurityDetails: PropTypes.any,
  updateSecurityDetailsReceive: PropTypes.func,
  login: PropTypes.any,
};

export default connect(
  mapStateToProps,
  // Combine reducer functions
  {
    ...mapDispatchToProps,
    ...ccbUpdateDispatch,
  },
)(UpdateSecurityDetails);
