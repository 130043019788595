import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, Breadcrumb, BreadcrumbItem, Card } from 'reactstrap';
import { Redirect, Link } from 'react-router-dom';
import { Text, Check } from '@myie/interact-dom';
import { Content, Switch, AppMeta } from '@myie/interact-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-authentication';
import { mapDispatchToProps as mapSharedDispatchToProps } from '@myie/interact-shared';
import { Session, Validate, Utility } from '@myie/interact';
import { SystemMessage } from '@myie/interact-widget-system-message-dom';

class CreateLogin extends React.Component {
  constructor(props) {
    super(props);
    const { login, toggleRememberMe } = this.props;
    let signoutMessage = window.sessionStorage.getItem('timeoutMessage');
    window.sessionStorage.removeItem('timeoutMessage');
    let error;
    if (signoutMessage) {
      error = signoutMessage;
    }
    if (login && login.Status === 'InvalidTicket') {
      error = 'InvalidTicket';
    }

    const remembermeUsername = localStorage.getItem('rememberMe');
    toggleRememberMe(!!remembermeUsername);
    this.state = {
      error: error,
      count: 0,
      rememberMeFlag: remembermeUsername,
      loginStatus: '',
      cachedUserName: remembermeUsername
        ? Utility.hexDecode(remembermeUsername)
        : '',
      form: {
        username: {
          value: '',
          rules: {
            title: 'Username',
            stop: true,
            required: {
              message:
                'Please enter your username – this will be something you selected when you first registered. If you need a reminder of it, select ‘forgotten your username’',
            },
            range: {
              min: 6,
              max: 23,
              message:
                'Your username must be at least 6 characters, and no more than 23 characters',
            },
            format: {
              regex: /^[^\s\\$\\&\\+\\,\\/\\:\\;\\=\\?\\%\\#\\<\\>\\{\\}\\^\\~\\[\\\]\\`\\\\]{6,23}$/,
              message:
                'Your username contains one or more of these invalid symbols $&+,/:;=?%#<>{}^~[]` or spaces. Please remove them and try again',
            },
          },
        },
        password: {
          rules: {
            stop: true,
            title: 'Password',
            required: {
              message: 'Please enter a password',
            },
          },
        },
        rememberMe: {
          value: !!remembermeUsername,
          rules: {
            title:
              'Remember me (please do not use this option if you are using a shared computer)',
          },
        },
      },
    };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const { login } = nextProps;

    if (login) {
      if (prevState.count === 4) {
        return {
          loginStatus: 'warning',
        };
      }
      if (login.Status !== 'Failed') {
        return {
          loginStatus: login.Status,
          count: 0,
        };
      }
      if (prevState.count === 4 && prevState.loginStatus === 'warning') {
        return null;
      }
      return {
        loginStatus: login.Status,
      };
    }
    return null;
  };

  componentDidMount() {
    const { setReferrer, userLogout } = this.props;
    const { referrer } = this.props || { referrer: { pathname: '/accounts' } };
    userLogout();
    setReferrer(referrer);
  }

  onChange = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  onClick = e => {
    const { name, checked } = e.target;
    const { toggleRememberMe } = this.props;
    var { form } = this.state;
    form = Validate.input(name, checked ? 'true' : null, form);
    this.setState({ ...this.state, form });
    toggleRememberMe(checked);
  };

  onBlur = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  submit = e => {
    e.preventDefault();
    const { createLogin, setRememberMeUserName } = this.props;
    var { form, count, rememberMeFlag, cachedUserName } = this.state;
    let countValue = count;
    let formCopy = { ...form };
    let Username = form.username.value;
    if (rememberMeFlag && form.rememberMe.value && !form.username.value) {
      formCopy.username.value = cachedUserName;
      Username = cachedUserName;
      formCopy = Validate.form(formCopy);
      if (!formCopy.approved) {
        return;
      }
      countValue = count + 1;
    } else {
      form = Validate.form(form);

      if (!form.approved) {
        return;
      }
      countValue = count + 1;
    }
    this.setState({ ...this.state, form, count: countValue, error: null });
    const request = {
      Username,
      CredentialValues: [
        {
          Name: 'Password',
          Value: form.password.value,
          Context: null,
        },
      ],
      ExtendedProperties: null,
    };
    setRememberMeUserName(Username);
    createLogin(request);
  };

  render() {
    const { login } = this.props;
    const { form, loginStatus, rememberMeFlag } = this.state;
    if (Session.isAuthenticated()) {
      Session.abandon(null);
    }
    const isNonSignatoryUser = login?.ExtendedProperties?.IdentityResponse?.CredentialErrors?.includes(
      'NonSignatory',
    );
    if (isNonSignatoryUser) {
      return <Redirect to="/sign-in/blocked" />;
    }

    var error;
    if (login) {
      switch (loginStatus) {
        case 'Success':
          Session.set(login.SessionTicket, login.Customer);
          return <Redirect to={this.props.referrer.pathname} />;
        case 'Failed':
          error = 'Failed';
          break;
        case 'warning':
          error = 'warning';
          break;
        case 'Blocked':
          return <Redirect to="/sign-in/blocked" />;
        case 'CredentialBlocked':
          error = 'CredentialBlocked';
          break;
        case 'Inactive':
          error = 'Inactive';
          break;
        case 'Incomplete':
          return <Redirect to="/sign-in/step-2" />;
        default:
      }
    }
    return (
      <div id="sign-in-step-1">
        <AppMeta
          id="meta-data"
          stage="child"
          title="Sign in Step 1"
          metaDescription="Sign in step 1"
        />
        <h1>
          <Content id="title" copytext="Welcome" />
        </h1>
        <div className="breadcrumb-container w-100">
          <Breadcrumb tag="nav">
            <BreadcrumbItem active>
              <Content id="signin-step1" copytext="Sign in step 1" />
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Content id="signin-step2" copytext="Sign in step 2" />
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="clearfix"></div>
        {<SystemMessage />}
        <Switch
          id="login-user-alert"
          value={this.state.error ? this.state.error : error ? error : ''}
          defaultText={this.state.error ? this.state.error : error ? error : ''}
          tag="div"
          className="alert alert-danger"
          contents={{
            TimeoutReset: {
              defaultValue:
                'You took too long to complete the sign in process. Please try again.',
            },
            UnauthorizedSignedOut: {
              defaultValue:
                'You have been logged out of your current session due to issues with your session on the server. Please sign in again to continue using the system.',
            },
            Timeout: {
              defaultValue:
                'For security reasons we have logged you out. Please restart the registration process.',
            },
            CredentialBlocked: {
              defaultValue:
                'Please contact the CCB Helpline on 0800 1234 5678 as you have reached the maximum number of failed attempts, therefore your account is now blocked',
            },
            Inactive: {
              defaultValue: `Please be aware your account status is set to 'inactive' and therefore access to the Servicing Portal is unavailable. Please contact the CCB Helpline on 0800 1234 5678`,
            },
            warning: {
              defaultValue:
                'Please be aware, as this is your 4th attempt, you only have 1 attempt left before your account will be blocked. Please use the links below to reset your security credentials if you are unsure of your log in details.',
            },
            Failed: {
              defaultValue: 'Unknown Username/Password combination',
            },
          }}
        />
        <Form autoComplete="off" id="login-create-form" onSubmit={this.submit}>
          <Card color="light">
            {rememberMeFlag && form.rememberMe.value && !form.username.value ? (
              <>
                <h2>
                  <Content id="sign-in-hi" copytext="Hi " />
                  {this.state.cachedUserName}
                </h2>
                <Check
                  label={'Not you? Click here'}
                  id="remember-me2"
                  field="rememberMe"
                  className="hidden-checkbox"
                  checked={!!form.rememberMe.value}
                  value="true"
                  validation={form.rememberMe}
                  onChange={this.onClick}
                />
              </>
            ) : (
              <Text
                label="Username"
                id="username"
                field="username"
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.username}
                suffix={
                  <Link to="/forgotten-username">
                    <Content
                      id="signIn-createForm-forgottenUsername"
                      copytext="Forgotten your username?"
                    />
                  </Link>
                }
              />
            )}

            <Text
              label="Password"
              id="password"
              field="password"
              onChange={this.onChange}
              onBlur={this.onBlur}
              type="password"
              validation={form.password}
              suffix={
                <Link to="/forgotten-password">
                  <Content
                    id="forgottenPassword"
                    copytext="Forgotten your password?"
                  />
                </Link>
              }
            />
            <Check
              label="Remember me (please do not use this option if you are using a shared computer)"
              id="remember-me"
              field="rememberMe"
              checked={!!form.rememberMe.value}
              value="true"
              validation={form.rememberMe}
              onChange={this.onClick}
            />

            <div className="form-button-group">
              <Button
                id="login-create-submit"
                type="submit"
                color="primary"
                disabled={this.props.isFetching}
              >
                <Content id="continue" copytext="Continue" />
              </Button>
            </div>
          </Card>
          <Card color="light">
            <p>
              <Content
                id="register-link"
                copytext="To register for online access to your account, click below"
              />
            </p>
            <div className="form-button-group pt-0">
              <Link
                className="btn btn-primary"
                role="button"
                to="/registration"
              >
                <Content id="register-button" copytext="Register" />
              </Link>
            </div>
            {/* <p className="mt-5">
              <Content
                id="activation-link"
                copytext="If you have received an Activation Code – select below to enter this."
              />
            </p> */}
            {/* <div className="form-button-group pt-0">
              <Link
                className="btn btn-primary"
                role="button"
                to="/registration/register/activate"
              >
                <Content
                  id="activation-button"
                  copytext="Enter Activation Code"
                />
              </Link>
            </div> */}
          </Card>
        </Form>
      </div>
    );
  }
}

CreateLogin.propTypes = {
  createLogin: PropTypes.any,
  login: PropTypes.any,
  referrer: PropTypes.any,
  setReferrer: PropTypes.any,
  userLogout: PropTypes.any,
  toggleRememberMe: PropTypes.func,
  setRememberMeUserName: PropTypes.func,
  isFetching: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps,
    ...mapSharedDispatchToProps,
  },
)(CreateLogin);
