import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill = 'secondary-2-fill',
  width = '36.074',
  height = '34.709',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 342.357 342.357"
    version="1.1"
    viewBox="0 0 342.357 342.357"
    height={height}
    width={width}
  >
    <path
      className={fill}
      d="M290.04 33.286L118.861 204.427 52.32 137.907 0 190.226 118.862 309.071 342.357 85.606z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
