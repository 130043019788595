import React from 'react';
import { HelpContactSidebar } from '@myie/interact-dom';

class RegistrationSidebar extends React.Component {
  render() {
    return (
      <aside className="right-container">
        <HelpContactSidebar />
      </aside>
    );
  }
}

RegistrationSidebar.propTypes = {};

export default RegistrationSidebar;
