import React from 'react';
import { Radio } from '@myie/interact-dom';
import { FormGroup, Row, Col } from 'reactstrap';

/** Radio Button example */
class RadioExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  onChangeRadioButton = e => {
    const { value } = e.target;
    this.setState({ value });
  };

  render() {
    return (
      <Row>
        <Col md={6}>
          <FormGroup>
            <Radio
              label="Option 1"
              id="option1"
              field="paymentChoice1"
              onChange={this.onChangeRadioButton}
              value={this.state.value}
            ></Radio>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default RadioExample;
