import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { Content, Icon } from '@myie/interact-dom';

class ExportAccountCSV extends React.Component {
  render() {
    const { account } = this.props;

    const csvTransactions = [];
    let csvTransactionsFilename = '';
    const transactions = account.transactions;

    if (
      account.transactions &&
      transactions.Transactions &&
      transactions.Transactions.length
    ) {
      csvTransactions.push([
        account.AccountName +
          ' Transactions exported on ' +
          moment(new Date()).format('YYYY-MM-DD h:mm'),
      ]);
      if (transactions.Range) {
        const fromDate = transactions.Range.From
          ? moment(transactions.Range.From).format('YYYY-MM-DD')
          : '';
        const toDate = transactions.Range.To
          ? moment(transactions.Range.To).format('YYYY-MM-DD')
          : '';
        csvTransactions.push(['Start', fromDate, 'End', toDate]);
        csvTransactionsFilename =
          account.AccountName + '_' + fromDate + '_' + toDate + '.csv';
      } else {
        csvTransactions.push(['Latest transactions']);
        csvTransactionsFilename =
          account.AccountName + '_Latest transactions.csv';
      }
      csvTransactions.push(['Date', 'Description', 'Amount', 'Balance']);

      transactions.Transactions.forEach(transaction => {
        if (
          transaction &&
          transaction.ExtendedProperties &&
          transaction.ExtendedProperties.Type &&
          transaction.ExtendedProperties.Type !== 'PendingTransaction'
        ) {
          csvTransactions.push([
            moment(transaction.Date)
              .utc()
              .format('YYYY-MM-DD'),
            transaction.Description,
            transaction.Amount,
            transaction.Balance ? transaction.Balance : 0,
          ]);
        }
      });

      return (
        <CSVLink
          className="btn btn-link btn-nav"
          filename={csvTransactionsFilename}
          data={csvTransactions}
        >
          <Icon name="Export" fill="secondary-fill" />
          <span className="sr-only">
            <Content cmsTag="account-export-button" copytext="Export" />
          </span>
        </CSVLink>
      );
    } else {
      return '';
    }
  }
}

ExportAccountCSV.propTypes = {
  account: PropTypes.any,
};

export default ExportAccountCSV;
