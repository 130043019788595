import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

class AlertsStyleguide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };

    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <section id="alerts-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/components/alerts.scss</strong>
          </code>
        </p>
        <p>
          There is a component made for the <strong>alerts</strong> defined
          within reactstrap. To see an interactive technical specification for
          this component, go to{' '}
          <Link to="https://reactstrap.github.io/components/alerts/">
            https://reactstrap.github.io/components/alerts/
          </Link>
          .{' '}
        </p>
        <Alert
          color="primary"
          isOpen={this.state.visible}
          toggle={this.onDismiss}
        >
          This is a primary alert with{' '}
          <a href="/" className="alert-link">
            an example link
          </a>
          . Give it a click if you like.
        </Alert>
        <Alert color="secondary">
          This is a secondary alert with{' '}
          <a href="/" className="alert-link">
            an example link
          </a>
          . Give it a click if you like.
        </Alert>
        <Alert color="success">
          This is a success alert with{' '}
          <a href="/" className="alert-link">
            an example link
          </a>
          . Give it a click if you like.
        </Alert>
        <Alert
          color="danger"
          isOpen={this.state.visible}
          toggle={this.onDismiss}
        >
          This is a danger alert with{' '}
          <a href="/" className="alert-link">
            an example link
          </a>
          . Give it a click if you like.
        </Alert>
        <Alert color="warning">
          This is a warning alert with{' '}
          <a href="/" className="alert-link">
            an example link
          </a>
          . Give it a click if you like.
        </Alert>
        <Alert color="info goal">
          <p>This is a button to click</p>
          <Button color="primary" size="sm">
            Click here
          </Button>
        </Alert>
        <Alert color="light">
          This is a light alert with{' '}
          <a href="/" className="alert-link">
            an example link
          </a>
          . Give it a click if you like.
        </Alert>
        <Alert color="dark">
          This is a dark alert with{' '}
          <a href="/" className="alert-link">
            an example link
          </a>
          . Give it a click if you like.
        </Alert>
      </section>
    );
  }
}

AlertsStyleguide.propTypes = {
  items: PropTypes.string,
};

export default AlertsStyleguide;
