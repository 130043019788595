import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, NavItem } from 'reactstrap';
import { Content } from '@myie/interact-dom';

class TabNavItem extends React.Component {
  render() {
    const { name, caption, activeTab, tablist, ...rest } = this.props;
    return (
      <NavItem role="presentation" id={`${name}-tab-item`}>
        <NavLink
          role="tab"
          aria-selected={activeTab === name}
          aria-controls={name}
          id={`${name}-tab`}
          href={`#${name}`}
          active={activeTab === name}
          innerRef={tablist.refs.tabs[tablist.refs.names.indexOf(name)]}
          onKeyDown={e => tablist.tabKeyDown(e, name, tablist.refs)}
          {...rest}
        >
          <Content cmsTag={`account-${name}-tab`} copytext={caption} />
        </NavLink>
      </NavItem>
    );
  }
}
TabNavItem.propTypes = {
  activeTab: PropTypes.string,
  caption: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  tablist: PropTypes.object,
};

export default TabNavItem;
