import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '33.35', height = '35.687' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    width={width}
    height={height}
    enableBackground="new 0 0 33.35 35.687"
    viewBox="0 0 33.35 35.687"
  >
    <g>
      <path
        className={fill}
        d="M11.333 26.851c0-.156.006-.31.012-.462H7.437v-.192c1.727-1.824 2.542-3.6 2.542-6.43 0-.72-.048-1.343-.144-2.015h5.533c.33-.298.678-.578 1.04-.84v-2.709H9.02c-.432-1.536-.815-3.023-.815-4.605 0-3.215 1.774-5.086 4.75-5.086 1.823 0 3.119.863 4.318 2.255l3.07-3.022C18.424 1.439 15.977 0 12.474 0 6.812 0 2.879 3.55 2.879 9.308c0 1.583.479 3.214 1.007 4.894h-.575l-3.214.24v3.31h4.798c.096.672.192 1.295.192 1.967 0 3.503-2.015 6.43-5.086 7.965v3.406h12.085a12.24 12.24 0 01-.753-4.239z"
      />
      <path
        className={fill}
        d="M29.598 29.865l3.752-6.479h-2.117c-.984-2.597-3.129-4.713-6.004-5.53a9.088 9.088 0 00-4.966 17.484 9.032 9.032 0 007.122-.939l-.84-2.819a6.258 6.258 0 01-5.512 1.046 6.265 6.265 0 01-4.317-7.742 6.27 6.27 0 017.741-4.317 6.232 6.232 0 013.658 2.818H25.97l3.628 6.478z"
      />
    </g>
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
