import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import {
  FeaturesAdvert1,
  FeaturesAdvert2,
  FeaturesAdvert3,
  HelpAdvert1,
  HelpAdvert2,
  TravelInsuranceAdvert1,
} from '@myie/interact-dom';

class Adverts extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    return (
      <section id="adverts-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/components/side-widgets.scss</strong>
          </code>
        </p>
        <p>
          There is a component made for the various <strong>adverts</strong> are
          defined within:
          <br />
          <code>shared/components/FeaturesAdvert1.js</code>
          <br />
          <code>shared/components/FeaturesAdvert2.js</code>
          <br />
          <code>shared/components/FeaturesAdvert3.js</code>
          <br />
          <code>shared/components/HelpAdvert1.js</code>
          <br />
          <code>shared/components/HelpAdvert2.js</code>
          <br />
          <code>shared/components/TravelInsuranceAdvert1.js</code>
        </p>
        <Row>
          <Col xs={12} sm={6}>
            <FeaturesAdvert1 />
          </Col>
          <Col xs={12} sm={6}>
            <FeaturesAdvert2 />
          </Col>
          <Col xs={12} sm={6}>
            <FeaturesAdvert3 />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <HelpAdvert1 />
          </Col>
          <Col xs={12} sm={6}>
            <HelpAdvert2 />
          </Col>
          <Col xs={12} sm={6}>
            <TravelInsuranceAdvert1 />
          </Col>
        </Row>
      </section>
    );
  }
}

Adverts.propTypes = {
  items: PropTypes.string,
};

export default Adverts;
