import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { Content, AppMeta } from '@myie/interact-dom';

const NoMatch = props => {
  return (
    <div id="not-found">
      <AppMeta
        id="meta-data"
        stage="child"
        title="Error"
        metaDescription="Resource not found"
      />
      <h1>
        <Content id="common-error404-title" copytext="Resource not found." />
      </h1>
      <Alert color="danger" role="alert">
        <p>
          <Content
            id="common-error404-message"
            copytext="The requested resource could not be found."
          />
        </p>
        <strong>
          {props.location.pathname}
          {props.location.hash}
        </strong>
      </Alert>
    </div>
  );
};

NoMatch.propTypes = {
  location: PropTypes.object,
};

export default NoMatch;
