import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { Content } from '@myie/interact-dom';

class YourAddress extends React.Component {
  render() {
    const { userDetails } = this.props;

    let houseNameFlatNumber = '';
    let houseNumber = '';
    let addressLine1 = '';
    let addressLine2 = '';
    let townCity = '';
    let county = '';
    let postcode = '';

    if (userDetails && userDetails.CorrespondenceAddress) {
      houseNameFlatNumber = userDetails.CorrespondenceAddress.BuildingName;
      houseNumber = userDetails.CorrespondenceAddress.BuildingNumber;
      addressLine1 = userDetails.CorrespondenceAddress.Street;
      addressLine2 = userDetails.CorrespondenceAddress.AddressLine2;
      townCity = userDetails.CorrespondenceAddress.PostTown;
      county = userDetails.CorrespondenceAddress.County;
      postcode = userDetails.CorrespondenceAddress.PostCode;
    }

    return (
      <div>
        <h2>
          <Content id="your-address.title" copytext="Your address" />
        </h2>
        <Row tag="dl">
          <Col tag="dt" xs={12} sm={4}>
            <Content id="your-address.addressLine1" copytext="Address line 1" />
          </Col>
          <Col tag="dd" xs={12} sm={8}>
            {houseNameFlatNumber} {houseNumber} {addressLine1}
          </Col>
          <Col tag="dt" xs={12} sm={4}>
            <Content id="your-address.addressLine2" copytext="Address line 2" />
          </Col>
          <Col tag="dd" xs={12} sm={8}>
            {addressLine2}
          </Col>
          <Col tag="dt" xs={12} sm={4}>
            <Content id="your-address.townCity" copytext="Town/City" />
          </Col>
          <Col tag="dd" xs={12} sm={8}>
            {townCity}
          </Col>
          <Col tag="dt" xs={12} sm={4}>
            <Content id="your-address.county" copytext="County" />
          </Col>
          <Col tag="dd" xs={12} sm={8}>
            {county}
          </Col>
          <Col tag="dt" xs={12} sm={4}>
            <Content id="your-address.postcode" copytext="Postcode" />
          </Col>
          <Col tag="dd" xs={12} sm={8}>
            {postcode}
          </Col>
        </Row>
      </div>
    );
  }
}

YourAddress.propTypes = {
  userDetails: PropTypes.object,
};

export default YourAddress;
