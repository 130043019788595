import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute, NoMatch } from '@myie/interact-dom';
import PayByCard from './PayByCard';

class Savings extends React.Component {
  render() {
    return (
      <div id="card">
        <Switch>
          <PrivateRoute
            path="/savings-servicing/pay-into-account/:id"
            component={PayByCard}
          />
          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}
export default Savings;
