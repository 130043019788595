import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '33.498', height = '35.734' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 33.498 35.734"
    viewBox="0 0 33.498 35.734"
  >
    <path
      className={fill}
      d="M33.498 21.844L28.07 16.93 28.07 20.014 17.213 20.014 17.213 23.672 28.07 23.672 28.07 26.755z"
    />
    <path
      className={fill}
      d="M12.111 30.82L17.539 35.734 17.539 32.651 27.889 32.651 27.889 28.992 17.539 28.992 17.539 25.909z"
    />
    <path
      className={fill}
      d="M12.474 0C6.813 0 2.879 3.55 2.879 9.307c0 1.583.479 3.214 1.007 4.894h-.575l-3.214.24v3.31h4.798c.096.672.192 1.296.192 1.967 0 3.503-2.015 6.43-5.086 7.965v3.406h9.944v-4.701H7.437v-.192c1.727-1.824 2.542-3.6 2.542-6.43 0-.719-.048-1.343-.144-2.015l6.541-.016v-3.534H9.02c-.432-1.536-.815-3.023-.815-4.605 0-3.215 1.774-5.086 4.75-5.086 1.823 0 3.119.863 4.318 2.255l3.07-3.022C18.424 1.438 15.977 0 12.474 0z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
