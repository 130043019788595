import {
  UPDATE_SECURITY_DETAILS_REQUEST,
  UPDATE_SECURITY_DETAILS_SUCCESS,
  UPDATE_SECURITY_DETAILS_FAILURE,
  RESET_UPDATE_SECURITY_DETAILS,
} from '../actions/updateSecurityDetailsTypes';

const updateSecurityDetailsDefinition = (state = {}, action) => {
  switch (action.type) {
    case RESET_UPDATE_SECURITY_DETAILS:
      return {
        ...state,
        updateSecurityDetails: {},
      };
    case UPDATE_SECURITY_DETAILS_SUCCESS:
      return {
        ...state,
        updateSecurityDetails: action.updateSecurityDetails,
        isFetching: action.isFetching,
      };
    case UPDATE_SECURITY_DETAILS_REQUEST:
    case UPDATE_SECURITY_DETAILS_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    default:
      return state;
  }
};

export default updateSecurityDetailsDefinition;
