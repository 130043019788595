import { Connection } from '@myie/interact';

import {
  TOKEN_ACCOUNT_REQUEST,
  TOKEN_ACCOUNT_SUCCESS,
  TOKEN_ACCOUNT_FAILURE,
  RESET_TOKEN_ACCOUNT,
} from './registrationTypes';

export const resetTokenAccount = () => ({
  type: RESET_TOKEN_ACCOUNT,
});
export const tokenAccountRequest = () => ({
  type: TOKEN_ACCOUNT_REQUEST,
  isFetching: true,
  error: null,
});

export const tokenAccountFailed = error => ({
  type: TOKEN_ACCOUNT_FAILURE,
  isFetching: false,
  error: error.message,
});

export const tokenAccountReceive = json => ({
  type: TOKEN_ACCOUNT_SUCCESS,
  tokenAccountDetails: json,
  isFetching: false,
  error: null,
});

export const getTokenAccount = request => dispatch => {
  dispatch(tokenAccountRequest());
  return fetch(Connection.baseUrl('customccb') + `v1.0/token`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(tokenAccountReceive(json)))
    .catch(error => dispatch(tokenAccountFailed(error)));
};
