import { Connection } from '@myie/interact';

import {
  ACTIVATION_REQUEST,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAILURE,
  RESET_ACTIVATION,
} from './registrationTypes';

export const resetActivation = () => ({
  type: RESET_ACTIVATION,
});
export const activationRequest = () => ({
  type: ACTIVATION_REQUEST,
  isFetching: true,
  error: null,
});

export const activationFailed = error => ({
  type: ACTIVATION_FAILURE,
  isFetching: false,
  error: error.message,
});

export const activationReceive = json => ({
  type: ACTIVATION_SUCCESS,
  activationCheck: json,
  isFetching: false,
  error: null,
});

export const registrationcheck = request => dispatch => {
  dispatch(activationRequest());
  return fetch(Connection.baseUrl('customccb') + `v1.0/registrationcheck`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(activationReceive(json)))
    .catch(error => dispatch(activationFailed(error)));
};
