import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import {
  Switch,
  Content,
  Markdown,
  AppMeta,
  LinkTag,
} from '@myie/interact-dom';

class SignedOut extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    window.sessionStorage.removeItem('timeoutMessage');
    userLogout();
  }

  constructor(props) {
    super(props);
    let signoutMessage = window.sessionStorage.getItem('timeoutMessage');
    let error;
    if (signoutMessage) {
      error = signoutMessage;
    }
    this.state = { error: error };
  }

  render() {
    const { error } = this.state;
    return (
      <div id="signed-out">
        <AppMeta
          id="meta-data"
          stage="child"
          title="Signed Out"
          metaDescription="You have signed out"
        />
        <h1 id="signed-out-title">
          <Content id="title" copytext="Signed out" />
        </h1>
        <Switch
          id="message"
          className="alert alert-danger"
          value={error || ''}
          contents={{
            TimeoutReset: {
              defaultValue:
                'You took too long to complete the sign in process. Please try again.',
            },
            UnauthorizedSignedOut: {
              defaultValue:
                'You have been logged out of your current session due to issues with your session on the server. Please sign in again to continue using the system.',
            },
            SignedOut: {
              defaultValue:
                'You have been logged out of your current session due to inactivity. Please sign in again to continue using the system.',
            },
          }}
        />

        <Markdown id="body" markdown={'\nYou have securely signed out.'} />

        <div className="form-button-group">
          <Link className="btn btn-info" role="button" to="/sign-in">
            <Content id="signInButton" copytext="Sign in" />
          </Link>
          <LinkTag
            className="btn btn-primary"
            role="button"
            href="https://ccbank.co.uk/"
          >
            <Content id="homeButton" copytext="Home" />
          </LinkTag>
        </div>
      </div>
    );
  }
}

SignedOut.propTypes = {
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignedOut);
