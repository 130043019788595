import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ width = '119.696', height = '113.112' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 119.696 113.112"
  >
    <g transform="translate(0 0)">
      <path
        className="primary-fill"
        d="M59.848,0A59.781,59.781,0,0,0,0,59.714H119.7A59.781,59.781,0,0,0,59.848,0Z"
        transform="translate(0)"
      />
      <rect
        className="icon-fill"
        width="73.061"
        height="45.189"
        transform="translate(24.225 67.923)"
      />
      <rect
        className="icon-fill"
        width="16.074"
        height="2.335"
        transform="translate(52.718 64.587)"
      />
      <rect
        className="white-fill"
        width="8.24"
        height="8.221"
        rx="4.111"
        transform="translate(38.433 88.138)"
      />
      <rect
        className="white-fill"
        width="9.635"
        height="8.221"
        transform="translate(50.435 74.834)"
      />
      <rect
        className="white-fill"
        width="4.821"
        height="9.437"
        transform="translate(75.186 80.157)"
      />
      <path
        className="white-fill"
        d="M105.605,152.092,94.6,145.75v12.677Z"
        transform="translate(-34.593 -53.505)"
      />
    </g>
  </svg>
);

SVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
