import React from 'react';
import { Mask } from '@myie/interact-dom';
import { FormGroup, Row, Col } from 'reactstrap';

/** Mask example */
class MaskExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      form: {
        sortcode: {},
      },
    };
  }

  onChange = e => {
    const { value } = e.target;
    const { form } = this.state;
    form.sortcode.value = value;
    this.setState({ ...this.state, form });
  };

  render() {
    const { form } = this.state;
    return (
      <Row>
        <Col md={6}>
          <FormGroup>
            <Mask
              label="Enter your sort code"
              id="sortcode"
              field="sortcode"
              onChange={this.onChange}
              maskChar={null}
              mask="99-99-99"
              validation={form.sortcode}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default MaskExample;
