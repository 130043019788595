import React from 'react';
import Savings from './Savings';
import { NoticeToWithdrawals } from './NoticeToWithdrawal/Routes';
import MakeWithdrawalRoute from './MakeWithdrawal/Routes';
import { Route } from 'react-router-dom';
import ShortApply from './shortApply/ShortApply';

const Routes = [
  <Route
    key="/savings-servicing"
    path="/savings-servicing"
    component={Savings}
  />,
  <Route
    key="/notice-to-withdraw"
    path="/notice-to-withdraw"
    component={NoticeToWithdrawals}
  />,
  <Route
    key="/make-withdrawal"
    path="/make-withdrawal"
    component={MakeWithdrawalRoute}
  />,
  <Route key="/short-apply" path="/short-apply" component={ShortApply} />,
];

export default Routes;
