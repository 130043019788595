import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-user-management';

import AboutYou from './AboutYou';
import YourAddress from './YourAddress';
//import CorrespondenceAddress from './CorrespondenceAddress';
//import ContactPreferences from './ContactPreferences';
import { Content } from '@myie/interact-dom';

class View extends React.Component {
  constructor(props) {
    super(props);
    const { resetUserDetails, resetContactOptions } = this.props;
    resetUserDetails();
    resetContactOptions();
  }

  componentDidMount() {
    const {
      fetchUserDetailsIfNeeded,
      fetchContactOptionsIfNeeded,
    } = this.props;
    let request = {
      ExtendedProperties: {},
    };
    fetchUserDetailsIfNeeded(request);
    fetchContactOptionsIfNeeded(request);
  }

  render() {
    const { userDetails /* contactOptions */ } = this.props;

    return (
      <div id="personal-details">
        <h1>
          <Content id="title" copytext="Personal details" />
        </h1>
        <p>
          <Content
            id="title-desc"
            copytext="You can view your contact details below."
          />
        </p>
        {<AboutYou userDetails={userDetails} />}
        {<YourAddress userDetails={userDetails} />}
        {/*
        {<ContactPreferences contactOptions={contactOptions} />}
        <p />
        */}

        <div className="form-button-group">
          {/* <Link
            className="btn btn-primary"
            role="button"
            to="/personal-details/edit"
          >
            <Content id="edit-details" copytext="Edit details" />
          </Link>{' '} */}
          <Link className="btn btn-secondary" role="button" to="/services">
            <Content id="back-services" copytext="Back to services" />
          </Link>
        </div>
      </div>
    );
  }
}

View.propTypes = {
  contactOptions: PropTypes.object,
  fetchContactOptionsIfNeeded: PropTypes.func,
  fetchUserDetailsIfNeeded: PropTypes.func,
  resetContactOptions: PropTypes.any,
  resetUserDetails: PropTypes.any,
  userDetails: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View);
