import React, { Component } from 'react';
import { Content, FormattedCurrency, Switch } from '@myie/interact-dom';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { mapDispatchToProps } from '@myie/interact-ccb-savings-servicing';
import Currency from 'react-currency-formatter';

class Confirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment(new Date())
        .utc()
        .format('YYYY-MM-DD'),
      message: '',
      disabled: false,
    };
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = nextProps => {
    const { makeWithdrawResponse = {} } = nextProps;

    if (
      makeWithdrawResponse &&
      makeWithdrawResponse.ResponseStatus === 'Failed'
    ) {
      return {
        message: 'failed',
        disabled: false,
      };
    }

    return null;
  };

  submit = () => {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    const { onSubmitWithdrawal } = this.props;
    onSubmitWithdrawal();
  };

  cancelWithdrawal = () => {
    const { setStage } = this.props;
    setStage('MakePayment');
  };
  render() {
    const { form = {}, account = {} } = this.props;
    let amount =
      form && form.amount && form.amount.value ? form.amount.value : '';
    let reference =
      form && form.reference && form.reference.value
        ? form.reference.value
        : '';
    const closeOption = form.closeAccount ? form.closeAccount.value : false;
    const {
      CurrencyCode,
      RemainingAllowance,
      ExtendedProperties: {
        NominatedAccountNumber,
        NominatedSortCode,
        NominatedAccountName,
      } = {},
    } = account;
    return (
      <>
        <Switch
          id="make-a-withdraw-alert"
          value={this.state.message || ''}
          tag="div"
          className="alert alert-danger"
          role="alert"
          contents={{
            failed: {
              defaultValue: 'Make a Withdraw Failed',
            },
          }}
        />
        <p>
          <Content
            id="description"
            copytext="You are setting a notice to withdraw on the following account:"
          />
        </p>

        <div className="confirm-make-withdrawal-details">
          <Row tag="dl" title="Account details review">
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content id="lbl-from-account" copytext="From" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {account.AccountName}{' '}
              {Currency({
                quantity: RemainingAllowance,
                currency: CurrencyCode,
              })}
              <span className="fw-normal d-block">
                {account.SortCode} - {account.AccountNumber}
              </span>
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content id="lbl-to-account" copytext="To" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {NominatedAccountName}
              <span className="fw-normal d-block">
                {NominatedSortCode} - {NominatedAccountNumber}
              </span>
            </Col>
            {!closeOption ? (
              <>
                {' '}
                <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
                  <Content id="lbl-amount" copytext="Amount" />
                </Col>
                <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
                  <FormattedCurrency
                    quantity={amount.replace(/,/g, '')}
                    currency={CurrencyCode}
                  />
                </Col>
                {reference ? (
                  <>
                    {' '}
                    <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
                      <Content id="lbl-reference" copytext="Reference" />
                    </Col>
                    <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
                      {reference}
                    </Col>
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              <Col xs={12} sm={12} md={4} lg={12}>
                <Content
                  id="account-closed-message"
                  copytext="You have requested that the account is closed with this withdrawal"
                />
              </Col>
            )}
          </Row>
        </div>
        <div className="form-button-group">
          <Button
            id="confirm-make-withdrawal"
            type="submit"
            onClick={this.submit}
            color="primary"
            disabled={this.state.disabled}
          >
            <Content id="confirm" copytext="Confirm" />
          </Button>
          <Button
            id="cancel-create-new-notice"
            onClick={this.cancelWithdrawal}
            color="secondary"
            disabled={this.state.disabled}
          >
            <Content id="cancel-button" copytext="Cancel" />
          </Button>
        </div>
      </>
    );
  }
}

Confirmation.propTypes = {
  history: PropTypes.any,
  withdrawalParam: PropTypes.any,
  error: PropTypes.any,
  isFetching: PropTypes.any,
  makeWithdrawResponse: PropTypes.any,
  makeaWithdraw: PropTypes.func,
  account: PropTypes.any,
  form: PropTypes.any,
  setStage: PropTypes.any,
  onSubmitWithdrawal: PropTypes.any,
};

const mapStateToProps = state => {
  const { customccbSavingsServicingDefinition } = state;
  return {
    ...customccbSavingsServicingDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Confirmation));
