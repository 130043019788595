import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '29.123' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    width={width}
    enableBackground="new 0 0 25.803 20.956"
    viewBox="0 0 25.803 20.956"
  >
    <g className={fill}>
      <path d="M14.557 9.836L24.953.146a1.586 1.586 0 00-.344-.138C24.586.006 24.563 0 24.54 0H1.264c-.023 0-.045.006-.068.008C1.074.04.959.088.85.147l10.396 9.69c.965.897 2.345.896 3.311-.001z" />
      <path d="M14.86 12.259c-1.14 1.065-2.773 1.066-3.917.001L0 2.061V19.353c0 .638.381 1.188.924 1.446.207.098.436.158.679.158H24.2c.244 0 .473-.06.681-.158.543-.258.923-.807.923-1.445V2.062L14.86 12.259z" />
    </g>
  </svg>
);

SVG.propTypes = { fill: PropTypes.string, width: PropTypes.string };

export default SVG;
