import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { mapDispatchToProps as mapDispatchToPropsAccounts } from '@myie/interact-accounts';

import { Markdown, FormattedCurrency } from '@myie/interact-dom';
import { Utility } from '@myie/interact';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

class SelectAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'Details',
      message: null,
      data: {},
    };
  }

  static getDerivedStateFromProps = nextProps => {
    const { accounts = {}, fetchAccountsIfNeeded, isFetching } = nextProps;

    if (!accounts && !isFetching) {
      fetchAccountsIfNeeded();
    }

    return null;
  };

  generateAccountInfo(account) {
    switch (account.ProductType) {
      case 'Loan':
        return (
          <>
            {account.AccountNumber} {account.SortCode}
          </>
        );
      case 'Savings':
        return (
          <>
            {account.AccountNumber} {account.SortCode}
          </>
        );
      case 'Current':
      case 'LoyaltyCard':
      case 'MotorLoan':
        return <>{account.AccountNumber}</>;

      case 'CreditCard':
        return (
          <Markdown
            id="credit-card-account-ending"
            template={{
              markdown: {
                value: account.AccountNumber,
              },
            }}
            markdown={`ending $[value]`}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { accounts, location = { pathname: '' } } = this.props;
    var currentAccount = null;

    if (accounts && accounts.Accounts) {
      currentAccount = accounts.Accounts.find(function(element) {
        if (element) {
          return (
            Utility.hexEncode(element.AccountKey.Key) ===
            location.pathname.split('/').pop()
          );
        }
        return false;
      });
    }
    return (
      <div id="select-accounts-widget">
        {accounts && accounts.Accounts && accounts.Accounts.length > 0
          ? accounts.Accounts.map((account, index) => {
              // status === 1 means only show closed accounts
              if (
                account.AccountStatus !== 'Closed' &&
                this.props.status === 1
              ) {
                return null;
              }
              // Ccb have asked not to see closed accounts in sidebar
              if (
                account.AccountStatus === 'Closed' /*&& !this.props.status*/
              ) {
                return null;
              }

              return (
                <Link
                  to={
                    '/accounts/details/' +
                    Utility.hexEncode(account.AccountKey.Key)
                  }
                  className={`select-account ${
                    currentAccount &&
                    currentAccount.AccountKey.Key === account.AccountKey.Key
                      ? 'active'
                      : ''
                  }`.trim()}
                  key={`select-account-list-item-${index}`}
                >
                  <span
                    id={`select-account-list-item-${index}`}
                    className="d-block"
                  >
                    {account.AccountName}
                  </span>
                  <span className="float-right pt-4">
                    <FormattedCurrency
                      quantity={account.Balance}
                      currency={account.CurrencyCode}
                    />
                  </span>
                  <span className="d-block float-left pt-4">
                    {this.generateAccountInfo(account)}
                  </span>
                </Link>
              );
            })
          : ''}
      </div>
    );
  }
}

SelectAccounts.propTypes = {
  accounts: PropTypes.any,
  error: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  i18n: PropTypes.any,
  isFetching: PropTypes.any,
  location: PropTypes.any,
  status: PropTypes.any,
};

const mapStateToProps = state => {
  const { accountsDefinition } = state;
  return {
    ...accountsDefinition,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { ...mapDispatchToPropsAccounts },
  )(SelectAccounts),
);
