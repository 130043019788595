import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

class Colours extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  //Convert RGB code to hex
  rgbToHex = rgb => {
    rgb = rgb.match(
      /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i,
    );
    return rgb && rgb.length === 4
      ? '#' +
          ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
          ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
          ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2)
      : '';
  };

  //Create an array of the colours from the css, and combine the hext and rgb into a string
  componentDidMount() {
    const colourPanels = document.querySelectorAll(
      'div[class^="brand-colour"]',
    );

    var list = [];
    for (var i = 0; i < colourPanels.length; i++) {
      var colourPanel = colourPanels[i];
      const style = getComputedStyle(colourPanel, null);
      const RGBBackgroundColor = style.backgroundColor;
      const HexBackgroundColour = this.rgbToHex(RGBBackgroundColor);
      const backgroundColourCombination =
        RGBBackgroundColor + ' ' + HexBackgroundColour;

      list.push(backgroundColourCombination);
    }

    this.setState({
      testdata: list,
    });
  }

  render() {
    /* This will eventually dynamically cycle through the colours so manual html below wont be needed, but it doesn't work yet.
    
    let items = [];

    for (var i = 0; i < this.state.testdata.length; i++) {
      var a = i + 1;
      var classNameVariable = 'brand-colour-' + a;
      items.push(
        <Col xs={2}>
          <div className={classNameVariable}>{this.state.testdata[i]}</div>
        </Col>,
      );
    }*/

    return (
      <section id="colours-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/abstracts/colours.scss</strong>
          </code>
        </p>
        <h3>Main Colours</h3>
        <Row className="colour-palette">
          <Col xs={2}>
            <div className="brand-colour-1">{this.state.testdata[0]}</div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-2">{this.state.testdata[1]}</div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-3">{this.state.testdata[2]}</div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-4">{this.state.testdata[3]}</div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-5">{this.state.testdata[4]}</div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-6">{this.state.testdata[5]}</div>
          </Col>
        </Row>
        <h3>Text and Links</h3>
        <Row className="colour-palette">
          <Col xs={2}>
            <div className="brand-colour-7">{this.state.testdata[6]}</div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-8">{this.state.testdata[7]}</div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-9">{this.state.testdata[8]}</div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-10">{this.state.testdata[9]}</div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-11">{this.state.testdata[10]}</div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-12">{this.state.testdata[11]}</div>
          </Col>
        </Row>
        <h3>Shades and Light Colours</h3>
        <Row className="colour-palette">
          <Col xs={2}>
            <div className="brand-colour-13 dark">
              {this.state.testdata[12]}
            </div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-14 dark">
              {this.state.testdata[13]}
            </div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-15 dark">
              {this.state.testdata[14]}
            </div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-16 dark">
              {this.state.testdata[15]}
            </div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-17 dark">
              {this.state.testdata[16]}
            </div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-18">{this.state.testdata[17]}</div>
          </Col>
        </Row>
        <Row className="colour-palette">
          <Col xs={2}>
            <div className="brand-colour-19 dark">
              {this.state.testdata[18]}
            </div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-20 dark">
              {this.state.testdata[19]}
            </div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-21 dark">
              {this.state.testdata[20]}
            </div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-22 dark">
              {this.state.testdata[21]}
            </div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-23 dark">
              {this.state.testdata[22]}
            </div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-24">{this.state.testdata[23]}</div>
          </Col>
        </Row>
        <Row className="colour-palette">
          <Col xs={2}>
            <div className="brand-colour-31">{this.state.testdata[30]}</div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-32">{this.state.testdata[31]}</div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-33">{this.state.testdata[32]}</div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-34">{this.state.testdata[33]}</div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-35">{this.state.testdata[34]}</div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-36">{this.state.testdata[35]}</div>
          </Col>
        </Row>
        <h3>Supporting colours</h3>
        <Row className="colour-palette">
          <Col xs={2}>
            <div className="brand-colour-25 dark">
              {this.state.testdata[24]}
            </div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-26 dark">
              {this.state.testdata[25]}
            </div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-27 dark">
              {this.state.testdata[26]}
            </div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-28 dark">
              {this.state.testdata[27]}
            </div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-29">{this.state.testdata[28]}</div>
          </Col>
          <Col xs={2}>
            <div className="brand-colour-30">{this.state.testdata[29]}</div>
          </Col>
        </Row>
      </section>
    );
  }
}

Colours.propTypes = {
  items: PropTypes.string,
};

export default Colours;
