import React from 'react';
import PropTypes from 'prop-types';
import { Validate } from '@myie/interact';
import { connect } from 'react-redux';
import { Content, ContactNumber, AppMeta } from '@myie/interact-dom';
import { TwoFactor } from '@myie/interact';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';
import NoDestinations from './NoDestinations';
import DestinationAuth from './DestinationAuth';
import { CONTACTS } from '@myie/interact-local-dom';
class SelectDestinationAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobNumberNotFound: false,
    };
  }

  componentDidMount() {
    const { getDestinations } = this.props;
    getDestinations({
      ChallengeKey: TwoFactor.getKey(),
    });
  }

  componentWillUnmount() {
    const { resetDestinations } = this.props;
    resetDestinations();
  }

  setMobNumberNotFound = () => {
    this.setState({ ...this.state, mobNumberNotFound: true });
  };

  cancel = () => {
    const { finishTwoFactor } = this.props;
    finishTwoFactor({});
  };

  onChange = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  getErrorMessage = () => {
    const { form } = this.state;

    let message = null;
    if (form.destination.state && Validate.isInvalid(form.destination.state)) {
      message = (
        <Content
          cmsTag="Two-factor:Select-destination:alert-1"
          copytext="Please select a mobile phone number."
        />
      );
    }

    return message;
  };

  render() {
    const { destinations, error } = this.props;
    const { mobNumberNotFound } = this.state;

    if (error) {
      return <NoDestinations error={true} />;
    }
    if (!destinations) {
      return <Content cmsTag="GLOBAL:Loading" copytext="Loading..." />;
    }
    if (
      mobNumberNotFound ||
      !destinations.Destinations ||
      destinations.Destinations.length === 0
    ) {
      return <NoDestinations />;
    }

    return (
      <>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Two Factor Authentication Step 1"
          metaDescription="Two Factor Authentication Step 1"
        />
        <Content
          tag="h1"
          cmsTag="Two-factor:Select-destination:h1"
          copytext="Additional authentication"
        />
        <p>
          <Content
            cmsTag="Two-factor:Select-destination:p1"
            copytext={`Don't have access to your mobile or email? you will need to contact us on `}
          />
          <ContactNumber />
          <Content copytext={` so we can help you reset your credentials.`} />
        </p>

        <DestinationAuth
          page="activate"
          setDestination={this.props.setDestination}
          setMobNumberNotFound={this.setMobNumberNotFound}
          mobNumberNotFound={this.mobNumberNotFound}
        />
      </>
    );
  }
}

SelectDestinationAuth.propTypes = {
  destinations: PropTypes.any,
  error: PropTypes.any,
  finishTwoFactor: PropTypes.any,
  getDestinations: PropTypes.any,
  resetDestinations: PropTypes.any,
  sendChallenge: PropTypes.any,
  setDestination: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectDestinationAuth);
