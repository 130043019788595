import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { mapDispatchToProps as mapDispatchToProps_shared } from '@myie/interact-shared';
import { mapDispatchToProps as ccbAuthenticationDispatch } from '@myie/interact-ccb-authentication';
import { mapDispatchToProps as authenticationDispatch } from '@myie/interact-authentication';
import { Redirect } from 'react-router-dom';
import PersonalDetails from '../shared/PersonalDetails';
import SecurityDetails from './SecurityDetails';
import { Session } from '@myie/interact';
import { ContactNumber } from '@myie/interact-dom';
import preserveData from './preserveData';
import {
  TimeOut,
  Switch,
  Content,
  Markdown,
  AppMeta,
} from '@myie/interact-dom';

class Retrieve extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'PersonalDetails',
      message: null,
      data: {},
      keyCount: 1,
    };
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (prevProps, nextState) => {
    const {
      resetForgottenPassword,
      setupCredentials,
      setupCredentialsActivate,
      forgottenPasswordDetails = {},
      updateForgottenDetails = {},
    } = prevProps;

    // If personal details step - Success
    if (
      setupCredentialsActivate &&
      setupCredentialsActivate.Status === 'Success'
    ) {
      return {
        stage: 'Security', //Go to next step - Security
        message: null,
        data: {}, // Security details step does not require previous data
      };
    }

    // If personal details step - RequireActivation
    if (forgottenPasswordDetails.Status === 'RequireActivation') {
      // personal data
      preserveData.setFormData(nextState.data);
      const request = {
        RequireActivationTicket:
          forgottenPasswordDetails.RequireActivationTicket,
        ExtendedProperties: null,
      };
      setupCredentials(request);
      return null;
    }

    if (forgottenPasswordDetails.Status === 'NotRegistered') {
      resetForgottenPassword();
      return {
        data: {},
        stage: 'PersonalDetails',
        message: 'NotRegistered',
      };
    }

    if (forgottenPasswordDetails.Status === 'IdentifyAndVerifyFailed') {
      resetForgottenPassword();
      return {
        stage: 'PersonalDetails',
        message: 'IdentifyAndVerifyFailed',
      };
    }

    if (updateForgottenDetails.Status === 'InvalidTicket') {
      resetForgottenPassword();
      return {
        data: {},
        stage: 'PersonalDetails',
        message: 'InvalidTicket',
      };
    }

    // If ticket invalid
    if (
      setupCredentialsActivate &&
      setupCredentialsActivate.Status === 'InvalidTicket'
    ) {
      return {
        stage: 'PersonalDetails',
        message: 'InvalidTicket',
      };
    }

    // If ticket invalid
    if (
      setupCredentialsActivate &&
      setupCredentialsActivate.Status === 'IdentifyAndVerifyFailed'
    ) {
      return {
        stage: 'PersonalDetails',
        message: 'IdentifyAndVerifyFailed',
      };
    }

    return null;
  };

  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };

  // change the stage of the form
  setStage = stage => {
    this.setState({ ...this.state, stage, message: null });
  };

  timeoutReset = () => {
    this.setState({
      ...this.state,
      data: {},
      stage: 'PersonalDetails',
      message: 'Timeout',
    });
  };

  timeoutObj = {
    history: this.props.history,
    userLogout: this.props.userLogout,
    message: 'ForgottenPasswordTimeout',
    url: '/forgotten-password',
    onTimeout: this.timeoutReset,
    showTimeoutModal: this.props.showTimeoutModal,
    type: 'FORGOT_CREDENTIALS',
  };

  timeout = new TimeOut(this.timeoutObj);

  // verify the identification and verfication entries
  verify = () => {
    const { data } = this.state;
    const { resetForgotten } = this.props;

    // personal data
    const request = {
      Entries: [
        {
          Type: 'firstName',
          Value: data.firstname,
        },
        {
          Type: 'lastName',
          Value: data.lastname,
        },
        {
          Type: 'dateOfBirth',
          Value: data.b_day,
        },
        {
          Type: 'postCode',
          Value: data.postcode,
        },
        {
          Type: 'accountNumber',
          Value: data.account_number,
        },
      ],
      ExtendedProperties: null,
    };

    var nextKeyCount = this.state.keyCount + 1;
    this.setState({
      data: {},
      dob: data.b_day,
      keyCount: nextKeyCount,
    });
    resetForgotten(request);
  };

  // select current stage
  currentStage = () => {
    switch (this.state.stage) {
      case 'PersonalDetails':
        return (
          <PersonalDetails
            verify={this.verify}
            saveData={this.saveData}
            setStage={this.setStage}
            key={this.state.keyCount}
            disableForm={this.props.isFetching}
          />
        );
      case 'Security':
        return (
          <SecurityDetails
            formData={this.state.data}
            timeout={this.timeout}
            dob={this.state.dob}
          />
        );
      default:
    }
  };

  render() {
    const {
      forgottenPasswordDetails = {},
      updateForgottenDetails = {},
    } = this.props;
    if (Session.isAuthenticated()) {
      Session.abandon(null);
    }
    const isNonSignatoryUser = forgottenPasswordDetails?.ExtendedProperties?.IdentityResponse?.CredentialErrors?.includes(
      'NonSignatory',
    );
    if (isNonSignatoryUser) {
      return <Redirect to="/forgotten-password/blocked" />;
    }

    switch (updateForgottenDetails.Status) {
      case 'Success':
        Session.set(
          updateForgottenDetails.SessionTicket,
          updateForgottenDetails.Customer,
        );
        return <Redirect to="/forgotten-password/success" />;
      case 'Blocked':
        return <Redirect to="/forgotten-password/blocked" />;
      default:
    }

    switch (forgottenPasswordDetails.Status) {
      case 'Blocked':
        return <Redirect to="/forgotten-password/blocked" />;
      default:
    }

    const { message } = this.state;

    return (
      <div id="forgotten-password-retrieve">
        <AppMeta
          id="meta-data"
          stage="child"
          title="Forgotten password Step 1"
          metaDescription="Forgotten password step 1"
        />

        {this.state.stage === 'PersonalDetails' ? (
          <h1>
            <Content
              id="personal-details-title"
              copytext="Forgotten password or memorable number"
            />
          </h1>
        ) : (
          ''
        )}
        {this.state.stage === 'Security' ? (
          <h1>
            <Content
              id="security-title"
              copytext="Reset password and memorable number"
            />
          </h1>
        ) : (
          ''
        )}
        <div className="breadcrumb-container">
          <Breadcrumb tag="nav">
            <BreadcrumbItem active={this.state.stage === 'PersonalDetails'}>
              <Content
                id="forgottenPassWord-step1"
                copytext="1 Personal details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active={this.state.stage === 'Security'}>
              <Content id="forgottenPassWord-step3" copytext="2 Security" />
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="clearfix"></div>
        <Switch
          id="forgotten-password-retrieve-error"
          value={message || ''}
          tag="div"
          className="alert alert-danger"
          role="alert"
          contents={{
            NotRegistered: {
              defaultValue:
                'You have not yet registered on the site. Please try again.',
            },
            Timeout: {
              defaultValue:
                'You took too long to complete the credentials reset process. Please try again.',
            },
            IdentifyAndVerifyFailed: {
              defaultValue:
                'The details you have entered do not match our records. Please try again.',
            },
            InvalidTicket: {
              defaultValue:
                'Your code has expired. Please restart the forgotten password process.',
            },
          }}
        />
        {this.state.stage === 'PersonalDetails' ? (
          <>
            <Markdown
              id="instructions"
              markdown={`If you're having trouble signing in, you can reset your security details.
              \n Please confirm your details below and we will then send you a one time passcode.`}
            />
            <p>
              <Content
                id="forgotten-password-text-2"
                copytext={`Don't have access to your mobile or email?\n You will need to contact us on `}
              />
              <ContactNumber />
              <Content
                id="instructions-2"
                copytext={` so we can help you reset your credentials.`}
              />
            </p>
          </>
        ) : (
          ''
        )}
        {this.currentStage()}
      </div>
    );
  }
}
Retrieve.propTypes = {
  forgottenPasswordDetails: PropTypes.object,
  history: PropTypes.any,
  resetForgotten: PropTypes.any,
  showTimeoutModal: PropTypes.any,
  updateForgottenDetails: PropTypes.object,
  userLogout: PropTypes.any,
  setupCredentialsActivate: PropTypes.any,
  isFetching: PropTypes.bool,
};

const mapStateToProps = state => {
  const { authenticationDefinition, customccbAuthenticationDefinition } = state;
  return {
    ...authenticationDefinition,
    ...customccbAuthenticationDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps_shared,
    ...ccbAuthenticationDispatch,
    ...authenticationDispatch,
  },
)(Retrieve);
