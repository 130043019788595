const mapStateToProps = state => {
  const { customccbOrganisationDefinition = {} } = state;
  const {
    organisation,
    error = null,
    isFetching,
  } = customccbOrganisationDefinition;
  //  const newOrg = {
  //    ...organisation,
  //    login: state.authenticationDefinition.login,
  //  };
  return {
    organisation,
    error,
    isFetching,
  };
};

export default mapStateToProps;
