import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Markdown, Content, appUtility } from '@myie/interact-dom';
import { Session } from '@myie/interact';

/**
 * AuthButton component
 */
class AuthIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: Session.isAuthenticated(),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isAuthenticated !== Session.isAuthenticated()) {
      this.setState({
        ...this.state,
        isAuthenticated: Session.isAuthenticated(),
      });
    }
  }

  logoutHandler = () => {
    appUtility.userSignout();
  };
  render() {
    const { history, userLogout, url } = this.props;
    const customer = Session.customer();
    return this.state.isAuthenticated && customer ? (
      <div className="logged-in-nav">
        <div className="mr-4 ml-1">
          <Markdown
            className="greeting"
            id="common-authorisedText1"
            template={{
              markdown: {
                name: customer.Name,
              },
            }}
            markdown="**Hi $[name]**"
          />
        </div>
        <Button
          color="info"
          size="xs"
          className="mr-0"
          onClick={() => {
            clearInterval(window.sessionStorage.getItem('timeoutID'));
            this.logoutHandler();
            Session.abandon();
            userLogout();
            history.push(url);
          }}
        >
          <Content id="common-signOutButton" copytext="Sign out" />
        </Button>
      </div>
    ) : (
      ''
    );
  }
}

AuthIndicator.propTypes = {
  /**
   * Used for routing
   */
  history: PropTypes.object,
  /**
   * Used for redirecting
   */
  url: PropTypes.string.isRequired,
  /**
   * Used to logout current session
   */
  userLogout: PropTypes.func.isRequired,
};

const AuthButton = withRouter(
  connect(
    null,
    mapDispatchToProps,
  )(AuthIndicator),
);

export default AuthButton;
