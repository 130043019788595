import SummaryMenuItems from './SummaryMenuItems';

const AccountsMenuItems = [
  {
    url: '/accounts',
    menuItem: SummaryMenuItems,
  },
];

export default AccountsMenuItems;
