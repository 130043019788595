import RegistrationSidebar from './registration/Sidebar';
import ForgottenNameSidebar from './forgotten-username/Sidebar';
import ForgottenPasswordSidebar from './forgotten-password/Sidebar';
import UpdateSecurityDetailsSidebar from './update-security-details/Sidebar';
import UpdateOrganisationDetailsSidebar from './update-organisation-details/Sidebar';
import SignedOutSidebar from './signed-out/Sidebar';

const AuthenticationSidebars = [
  { url: '/registration/', Sidebar: RegistrationSidebar },
  { url: '/forgotten-username/', Sidebar: ForgottenNameSidebar },
  { url: '/sign-in/', Sidebar: null },
  { url: '/signed-out', Sidebar: SignedOutSidebar },
  { url: '/forgotten-password/', Sidebar: ForgottenPasswordSidebar },
  { url: '/update-security-details', Sidebar: UpdateSecurityDetailsSidebar },
  {
    url: '/update-organisation-details',
    Sidebar: UpdateOrganisationDetailsSidebar,
  },
];

export default AuthenticationSidebars;
