import * as React from 'react';
import PropTypes from 'prop-types';
import { Settings } from '@myie/interact';

class SignOutCallback extends React.Component {
  signoutRedirectCallback = () => {
    Settings.userManager.signoutRedirectCallback();
  };
  render() {
    this.signoutRedirectCallback();
    return <span>loading</span>;
  }
}

SignOutCallback.propTypes = {
  userManager: PropTypes.any,
};

export default SignOutCallback;
