import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
} from 'reactstrap';
import Errors, { HasErrors } from './Errors';
import { Validate } from '@myie/interact';
import Datetime from 'react-datetime';
import contentManaged, { ContentType } from '../HO_components/contentManaged';
import { Icon, Content } from '@myie/interact-dom';

/**
 * Date component (calendar date picker)
 * will display an input field with the calendar icon
 */
class Date extends React.Component {
  constructor(props) {
    super(props);
    const {
      label,
      subLabel,
      description,
      placeholder,
      suffix,
      managedContent,
      validation,
    } = props;
    managedContent({
      label: { defaultValue: label },
      subLabel: { defaultValue: subLabel },
      description: { defaultValue: description },
      suffix: { defaultValue: suffix },
      calendar: { defaultValue: 'Open calendar' },
      placeholder: { defaultValue: placeholder },
      validation: validation,
    });
  }

  render() {
    const {
      children,
      validation = {},
      className = '',
      labelClassName = '',
      groupClassName = '',
      showErrors = true,
      id,
      field,
      disabled,
      required,
      onChange,
      onBlur,
      contentValue,
      managedContent,
      validRange,
      ...rest
    } = this.props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const { value = '', state = {} } = validation;
    const label = contentValue(ContentType.Text, 'label');
    const subLabel = contentValue(ContentType.Text, 'subLabel');
    const description = contentValue(ContentType.Text, 'description');
    const suffix = contentValue(ContentType.Text, 'suffix');
    const placeholder = contentValue(ContentType.Text, 'placeholder');
    let fieldName =
      typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;
    const isRequired =
      required || (validation && validation.rules && validation.rules.required);
    const renderInput = function(props, openCalendar) {
      return (
        <button
          aria-hidden="true"
          className="btn calendar"
          type="button"
          onClick={openCalendar}
          tabIndex="-1"
          aria-label={contentValue(ContentType.Text, 'calendar')}
        >
          <Icon name="Calendar" />
          <span className="sr-only">
            <Content
              id="calendar-buttonText"
              template={{
                copytext: {
                  id: id,
                },
              }}
              copytext="Open $[id] Calendar"
            ></Content>
          </span>
        </button>
      );
    };

    const dateChange = e => {
      const val = typeof e !== 'string' ? e.format('DD/MM/YYYY') : e;
      onBlur({
        target: {
          name: fieldName,
          value: val,
        },
      });
    };

    return (
      <FormGroup
        className={`${groupClassName} ${disabled ? 'disabled-input' : ''} ${
          isRequired ? 'required-input' : ''
        }`.trim()}
      >
        {label || subLabel || description ? (
          <Label className={labelClassName} id={`${id}-label`} htmlFor={id}>
            {label}
            {isRequired ? (
              <React.Fragment>
                <span aria-hidden="true">*</span>
                <span className="sr-only">(required field)</span>
              </React.Fragment>
            ) : (
              ''
            )}{' '}
            {subLabel.length > 0 ? <small>{subLabel}</small> : ''}
            {description.length > 0 ? (
              <span className="description">{description}</span>
            ) : (
              ''
            )}
          </Label>
        ) : (
          ''
        )}
        <InputGroup className={`${className}` + ` calendar `.trim()}>
          <Input
            aria-describedby={
              HasErrors(validation) ? null : `error-${fieldName}-validation`
            }
            //aria-labelledby={`${id}-label`}
            id={id}
            invalid={Validate.isInvalid(state)}
            valid={Validate.isValid(state)}
            name={fieldName}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            {...rest}
          />
          <InputGroupAddon addonType="append">
            <InputGroupText tag="div" className="input-group-calendar">
              <Datetime
                renderInput={renderInput}
                timeFormat={null}
                closeOnTab={true}
                onChange={dateChange}
                closeOnSelect={true}
                value={value}
                required={required}
                disabled={disabled}
                dateFormat={'DD/MM/YYYY'}
                isValidDate={validRange}
              />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
        {suffix && <p className="suffix">{suffix}</p>}
        {children}
        {showErrors ? (
          <Errors
            contentValue={contentValue}
            validation={validation}
            field={field}
          />
        ) : (
          ''
        )}
      </FormGroup>
    );
  }
}

Date.propTypes = {
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  /**
   * Text placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Any children of the component
   */
  children: PropTypes.element,
  /**
   * Style class name for InputGroup
   */
  className: PropTypes.string,
  /**
   * Date description text
   */
  description: PropTypes.string,
  /**
   * Input disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * Input field names
   */
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Style class name for group
   */
  groupClassName: PropTypes.string,
  /**
   * Date ID
   */
  id: PropTypes.string.isRequired,
  /**
   * Label text
   */
  label: PropTypes.string,
  /**
   * Style class name for label
   */
  labelClassName: PropTypes.string,
  /**
   * onBlur function
   */
  onBlur: PropTypes.func.isRequired,
  /**
   * onChange function
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Input required or not
   */
  required: PropTypes.bool,
  /**
   * Show errors or not
   */
  showErrors: PropTypes.bool,
  /**
   * Sub label text
   */
  subLabel: PropTypes.string,
  /**
   * Suffix text
   */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Input validation
   */
  validation: PropTypes.object,
  /**
   * Valid Date range (it disables any date outside the range)
   */
  validRange: PropTypes.func,
};

export default contentManaged(Date);
