import React from 'react';
import Alerts from './icons/Alerts';
import AppleStoreLogo from './icons/appleStoreBadge';
import BoxProduct from './icons/BoxProduct';
import Calendar from './icons/Calendar';
import CreditCardIcon from './icons/CreditCardIcon';
import Envelope from './icons/Envelope';
import Export from './icons/Export';
import Facebook from './icons/Facebook';
import GooglePlayLogo from './icons/GooglePlayLogo';
import HelpAdIcon from './icons/HelpAdIcon';
import HelpIcon from './icons/HelpIcon';
import Info from './icons/Info';
import LoanIcon from './icons/LoanIcon';
import MoveMoneyMakePayment from './icons/MoveMoneyMakePayment';
import MoveMoneyManagePayee from './icons/MoveMoneyManagePayee';
import MoveMoneyManagePayments from './icons/MoveMoneyManagePayments';
import MoveMoneyOneOffPayment from './icons/MoveMoneyOneOffPayment';
import MoveMoneyPayCard from './icons/MoveMoneyPayCard';
import MoveMoneyStandingOrder from './icons/MoveMoneyStandingOrder';
import MoveMoneyTransfer from './icons/MoveMoneyTransfer';
import MyServicesOverseasNotification from './icons/MyServicesOverseasNotification';
import MyServicesPaperlessStatement from './icons/MyServicesPaperlessStatement';
import MyServicesPersonalDetails from './icons/MyServicesPersonalDetails';
import MyServicesSecuritySettings from './icons/MyServicesSecuritySettings';
import MyServicesOrganisationSettings from './icons/MyServicesOrganisationSettings';
import MyServicesSettings from './icons/MyServicesSettings';
import MyServicesUser from './icons/MyServicesUser';
import Print from './icons/Print';
import SavingsIcon from './icons/SavingsIcon';
import Success from './icons/Success';
import Target from './icons/Target';
import TravelInsuranceAd from './icons/TravelInsuranceAd';
import Twitter from './icons/Twitter';
import LinkedIn from './icons/LinkedIn';
import Warning from './icons/Warning';
import Warning2 from './icons/Warning2';
import Dropdown from './icons/Dropdown';

const Icon = props => {
  switch (props.name) {
    case 'Alerts':
      return <Alerts {...props} />;
    case 'AppleStoreLogo':
      return <AppleStoreLogo {...props} />;
    case 'BoxProduct':
      return <BoxProduct {...props} />;
    case 'Calendar':
      return <Calendar {...props} />;
    case 'CreditCardIcon':
      return <CreditCardIcon {...props} />;
    case 'Envelope':
      return <Envelope {...props} />;
    case 'Export':
      return <Export {...props} />;
    case 'Facebook':
      return <Facebook {...props} />;
    case 'GooglePlayLogo':
      return <GooglePlayLogo {...props} />;
    case 'HelpAdIcon':
      return <HelpAdIcon {...props} />;
    case 'HelpIcon':
      return <HelpIcon {...props} />;
    case 'Info':
      return <Info {...props} />;
    case 'LoanIcon':
      return <LoanIcon {...props} />;
    case 'MoveMoneyMakePayment':
      return <MoveMoneyMakePayment {...props} />;
    case 'MoveMoneyManagePayee':
      return <MoveMoneyManagePayee {...props} />;
    case 'MoveMoneyManagePayments':
      return <MoveMoneyManagePayments {...props} />;
    case 'MoveMoneyOneOffPayment':
      return <MoveMoneyOneOffPayment {...props} />;
    case 'MoveMoneyPayCard':
      return <MoveMoneyPayCard {...props} />;
    case 'MoveMoneyStandingOrder':
      return <MoveMoneyStandingOrder {...props} />;
    case 'MoveMoneyTransfer':
      return <MoveMoneyTransfer {...props} />;
    case 'MyServicesOverseasNotification':
      return <MyServicesOverseasNotification {...props} />;
    case 'MyServicesPaperlessStatement':
      return <MyServicesPaperlessStatement {...props} />;
    case 'MyServicesPersonalDetails':
      return <MyServicesPersonalDetails {...props} />;
    case 'MyServicesSecuritySettings':
      return <MyServicesSecuritySettings {...props} />;
    case 'MyServicesOrganisationSettings':
      return <MyServicesOrganisationSettings {...props} />;
    case 'MyServicesSettings':
      return <MyServicesSettings {...props} />;
    case 'MyServicesUser':
      return <MyServicesUser {...props} />;
    case 'Print':
      return <Print {...props} />;
    case 'SavingsIcon':
      return <SavingsIcon {...props} />;
    case 'Success':
      return <Success {...props} />;
    case 'Target':
      return <Target {...props} />;
    case 'TravelInsuranceAd':
      return <TravelInsuranceAd {...props} />;
    case 'Twitter':
      return <Twitter {...props} />;
    case 'LinkedIn':
      return <LinkedIn {...props} />;
    case 'Warning':
      return <Warning {...props} />;
    case 'Warning2':
      return <Warning2 {...props} />;
    case 'Dropdown':
      return <Dropdown {...props} />;
    default:
      return;
  }
};

export default Icon;
