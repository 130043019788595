import React from 'react';
import { FormattedDate } from '@myie/interact-dom';

/** FormattedDate example */
class FormattedDateExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  onChange = e => {
    const { value } = e.target;
    this.setState({ value });
  };

  render() {
    return <FormattedDate date="2019-01-01 12:00 pm" />;
  }
}

export default FormattedDateExample;
