import {
  CANCEL_NOTICE_TO_WITHDRAWAL_FAILURE,
  CANCEL_NOTICE_TO_WITHDRAWAL_REQUEST,
  CANCEL_NOTICE_TO_WITHDRAWAL_SUCCESS,
  RESET_CANCEL_NOTICE_TO_WITHDRAWAL,
  SET_CREATE_NOTICE_TO_WITHDRAWAL_PARAMS,
  RESET_CREATE_NOTICE_TO_WITHDRAWAL,
  SET_MAKE_A_WITHDRAW_PARAM,
  MAKE_A_WITHDRAW_SUCCESS,
  MAKE_A_WITHDRAW_FALIURE,
  MAKE_A_WITHDRAW_REQUEST,
  RESET_MAKE_A_WITHDRAW,
  BANK_HOLIDAYS,
} from '../actions/withdrawalTypes';

const ccbWithdrawalDefinition = (state = {}, action) => {
  switch (action.type) {
    case CANCEL_NOTICE_TO_WITHDRAWAL_REQUEST:
    case MAKE_A_WITHDRAW_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case BANK_HOLIDAYS:
      return {
        ...state,
        holidays: action.holidays,
        isFetching: action.isFetching,
      };
    case CANCEL_NOTICE_TO_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        cancellationResponse: action.cancellationResponse,
        isFetching: action.isFetching,
      };
    case CANCEL_NOTICE_TO_WITHDRAWAL_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_CANCEL_NOTICE_TO_WITHDRAWAL:
      return {
        ...state,
        cancellationResponse: null,
        isFetching: false,
        error: null,
      };
    case SET_CREATE_NOTICE_TO_WITHDRAWAL_PARAMS:
      return {
        ...state,
        createNoticeParam: action.createNoticeParam,
        error: null,
      };
    case SET_MAKE_A_WITHDRAW_PARAM:
      return {
        ...state,
        withdrawalParam: action.withdrawalParam,
        error: null,
      };
    case RESET_CREATE_NOTICE_TO_WITHDRAWAL:
      return {
        ...state,
        createNoticeParam: null,
        createResponse: null,
        error: null,
      };
    case MAKE_A_WITHDRAW_SUCCESS: {
      return {
        ...state,
        makeWithdrawResponse: action.makeWithdrawResponse,
        isFetching: false,
        error: null,
      };
    }
    case MAKE_A_WITHDRAW_FALIURE: {
      return {
        ...state,
        makeWithdrawResponse: null,
        isFetching: false,
        error: action.error,
      };
    }
    case RESET_MAKE_A_WITHDRAW:
      return {
        ...state,
        makeWithdrawResponse: null,
        isFetching: false,
        error: null,
      };
    default:
      return state;
  }
};

export default ccbWithdrawalDefinition;
