import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { HasErrors } from './Errors';
import contentManaged, { ContentType } from '../HO_components/contentManaged';

/**
 * RadioButton component
 */
class RadioButton extends React.Component {
  constructor(props) {
    super(props);
    const { label, managedContent } = props;
    managedContent({
      label: { defaultValue: label },
    });
  }

  render() {
    const { props } = this;

    const {
      validation = {},
      value: setValue,
      children,
      id,
      field,
      contentValue,
      managedContent,
      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const { value = '' } = validation;
    const label = contentValue(ContentType.Text, 'label');
    var fieldName =
      typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;
    return (
      <Button
        aria-describedby={
          HasErrors(validation) ? null : `error-${fieldName}-validation`
        }
        id={id}
        color="primary"
        value={setValue}
        active={value === setValue}
        name={fieldName}
        {...rest}
      >
        {label}
        {children}
      </Button>
    );
  }
}

RadioButton.propTypes = {
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  /**
   * Any children of the component
   */
  children: PropTypes.element,
  /**
   * Input field names
   */
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * RadioButton ID
   */
  id: PropTypes.string.isRequired,
  /**
   * Label text
   */
  label: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.any,
  /**
   * Input validation
   */
  validation: PropTypes.object,
};

export default contentManaged(RadioButton);
