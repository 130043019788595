import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-ccb-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AppMeta, Content, Markdown } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Row, Col } from 'reactstrap';

class ApplicationSuccess extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={`Application Submitted`}
          metaDescription={`Application Submitted`}
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Short-apply:Application-success:h1"
          template={{
            copytext: {
              productName: this.props?.selectedProduct?.Product_Name,
            },
          }}
          copytext={`Application for $[productName]`}
        />
        <Markdown
          cmsTag="Savings-servicing:Short-apply:Application-success:section-1"
          markdown={`\nThank you, your application for a savings account has been submitted.`}
        />
        <div className="application-number-container card card-body">
          <Row tag="dl" title="Your application number" className="mb-0">
            <Col tag="dt" sm="12" md="6" className="text-right">
              <Content
                cmsTag="GLOBAL:Application-number-"
                copytext="Your application reference is: "
              />
            </Col>
            <Col tag="dd" sm="12" md="2" className="text-left">
              {this.props?.saveShortApplyData?.Reference
                ? this.props.saveShortApplyData.Reference
                : ''}
            </Col>
          </Row>
        </div>
        <div className="form-button-group">
          <Link
            id="back-to-my-accounts"
            className="btn btn-secondary"
            role="button"
            to={`/accounts/list`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-my-accounts"
              copytext="Back to my accounts"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

ApplicationSuccess.propTypes = {
  saveShortApplyData: PropTypes.object,
  selectedProduct: PropTypes.object,
};

export default connect(
  null,
  mapDispatchToProps,
)(ApplicationSuccess);
