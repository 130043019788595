export const form = {
  firstname: {
    rules: {
      stop: true,
      required: {
        message: 'Please enter your first name',
      },
      format: {
        regex: /^[a-zA-Z0-9`¬!"£$%^&*()_+-={};'#:@~,./<>?'€[\]]*\]{0,50}$/,
        message: 'Please enter a valid first name',
      },
    },
  },
  lastname: {
    rules: {
      stop: true,
      required: {
        message: 'Please enter your last name',
      },
      format: {
        regex: /^[a-zA-Z0-9`¬!" £$%^&*()_+-={};'#:@~,./<>?'€[\]]*\]{0,50}$/,
        message: 'Please enter a valid last name',
      },
    },
  },
  postcode: {
    rules: {
      title: 'Postcode',
      stop: true,
      required: {
        message: 'Please enter your home postcode',
      },
      postcode: {
        message: 'Please enter a valid home postcode',
      },
    },
  },

  b_day: {
    rules: {
      title: 'bday',
      stop: true,
      required: {
        message: 'Please enter your date of birth',
      },
      date: {
        format: 'ymd',
        message: 'Please select a valid date of birth.',
      },
    },
  },
  account_number: {
    rules: {
      title: 'accountNumber',
      stop: true,
      required: {
        message: 'Please enter your account number',
      },
      format: {
        regex: /^[0-9]{8,8}$/,
        message: 'Please enter a valid account number',
      },
    },
  },
};
