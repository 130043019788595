import React from 'react';
import PropTypes from 'prop-types';
import { Validate, Session } from '@myie/interact';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Radio, RadioGroup, Content, Markdown } from '@myie/interact-dom';
import { stateUtility } from '../shared';
import { CONTACTS } from '@myie/interact-local-dom';
import { TwoFactor } from '@myie/interact';
import { mapDispatchToProps } from '@myie/interact-two-factor';
import { Link, withRouter } from 'react-router-dom';
//import _ from 'lodash';
import NoDestinations from './NoDestinations';
import { returnPath } from './ReturnPages';
class Destinations extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initiateState(props);
  }

  initiateState = props => {
    const { setDestination, page, setResend } = props;

    const storage = stateUtility.getPageState();
    const storageName =
      storage && storage.global && storage.global.storage
        ? storage.global.storage
        : '';

    let stateData = stateUtility.getComponentState(storageName);
    if (
      stateData &&
      stateData.url &&
      stateData.url.twoFa &&
      stateData.url.twoFa === 'active'
    ) {
      if (stateData.destination) {
        setDestination(stateData.destination);
      }
      if (page === 'resend') {
        setResend();
      }
    }
    return {
      storageName,
      form: {
        destination: {
          rules: {
            required: true,
          },
        },
      },
      disabled: false,
    };
  };

  componentDidMount() {
    const { getDestinations, destinations } = this.props;
    if (!destinations || !destinations.Destinations) {
      getDestinations({
        ChallengeKey: TwoFactor.getKey(),
      });
    }
  }

  componentWillUnmount() {
    const { resetDestinations } = this.props;
    resetDestinations();
  }

  continue = e => {
    e.preventDefault();
    this.setState({ disabled: true });
    const {
      sendChallenge,
      setDestination,
      page,
      setResend,
      history,
    } = this.props;

    let { form } = this.state;
    form = Validate.form(form);
    this.setState({
      ...this.state,
      form,
    });
    if (!form.approved) {
      this.setState({ disabled: false });
      return;
    }
    if (page === 'resend') {
      setResend();
    }
    const selectedDestination = form.destination.value;
    setDestination(selectedDestination);
    const request = {
      Destination: selectedDestination,
      ChallengeKey: TwoFactor.getKey(),
    };
    sendChallenge(request);
    this.setState({ disabled: false });
    const storage = stateUtility.getPageState();

    let stateData = {
      destination: selectedDestination,
      twoFapage: page,
      destinationReq: request,
      url: {
        twoFa: 'active',
        twoFaDestination: 'active',
      },
    };

    if (storage && storage.global && storage.global.storage) {
      let page = storage[storage.global.storage];
      if (page && page.url && page.url.pageStage) {
        stateData.url.pageStage = page.url.pageStage;
      }
    }

    stateUtility.directlyUpdateUrl(
      stateData,
      storage && storage.global && storage.global.storage
        ? storage.global.storage
        : '',
      history,
    );
  };

  cancel = () => {
    if (localStorage.getItem('firstLogin')) {
      window.location.href = CONTACTS.externalHomeURL;
      return null;
    }

    const { finishTwoFactor, history } = this.props;
    const { storageName } = this.state;
    const returnTo = returnPath(storageName);
    finishTwoFactor({});
    if (Session.isAuthenticated() && returnTo) {
      stateUtility.resetComponentState(storageName);
      history.push(returnTo);
    }
  };

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };
  isDestinationValid = number => {
    return /^\d+$/.test(number.substring(number.length - 3));
  };

  getErrorMessage = () => {
    const { form } = this.state;

    let message = null;
    if (form.destination.state && Validate.isInvalid(form.destination.state)) {
      message = (
        <div
          className="invalid-feedback"
          aria-live="assertive"
          aria-relevant="additions removals"
        >
          <Content
            tag="p"
            cmsTag="Two-factor:Destinations:error-message-1"
            copytext="Please select a mobile phone number."
          />
        </div>
      );
    }
    return message;
  };

  render() {
    const { destinations, error, page, mobNumberNotFound } = this.props;
    const { form } = this.state;
    const numbers = [];
    if (error) {
      return <NoDestinations error={true} />;
    }
    if (!destinations) {
      return (
        <p aria-live="polite">
          <Content
            cmsTag="GLOBAL:Loading"
            copytext="Please wait while the page loads."
          />
        </p>
      );
    }
    if (
      mobNumberNotFound ||
      !destinations.Destinations ||
      destinations.Destinations.length === 0
    ) {
      return <NoDestinations />;
    }
    destinations.Destinations.forEach((d, i) => {
      if (this.isDestinationValid(d)) {
        numbers.push(
          <Radio
            autoFocus={i === 0}
            label={`Send the one time passcode to my mobile number ending ${d.substring(
              d.length - 3,
            )}`}
            key={`destination-${i}`}
            id={`destination-${i}`}
            field="destination"
            onChange={this.onChange}
            validation={form.destination}
            value={d}
          />,
        );
      }
    });

    return (
      <React.Fragment>
        <form onSubmit={this.continue}>
          <RadioGroup
            id="twofa-select-destination"
            label="Please confirm your mobile number"
            cmsTag="GLOBAL:Select-a-destination"
            required={false}
            field="destinations"
          >
            {numbers}
            {this.getErrorMessage()}
          </RadioGroup>

          <Markdown
            cmsTag="Two-factor:Destinations:p1"
            template={{
              markdown: {
                phone: CONTACTS.phone,
                phoneLink: CONTACTS.phoneLink,
                email: CONTACTS.email,
              },
            }}
            markdown={`\nIf this is not your mobile phone number, please contact us at <a href="mailto:$[email]">$[email]</a> or on <a href="tel:$[phoneLink]">$[phone]</a>.`}
          />
          {page === 'activate' ? (
            <React.Fragment>
              <div className="form-button-group">
                <Button
                  color="primary"
                  type="submit"
                  id="select-destination-submit"
                  disabled={this.disabled}
                >
                  <Content
                    cmsTag="Two-factor:Destinations:Continue"
                    copytext="Continue"
                  />
                  <span className="sr-only">
                    <Content cmsTag="GLOBAL:To-step-2" copytext=" to step 2" />
                  </span>
                </Button>
                {Session.isAuthenticated() ? (
                  <Button
                    color="secondary"
                    id="twofa-btn-back-to-home"
                    onClick={this.cancel}
                  >
                    <Content
                      cmsTag="GLOBAL:Back-to-home-page"
                      copytext="Back to home page"
                    />
                  </Button>
                ) : (
                  <Link
                    id="twofa-btn-back-to-sign-in"
                    className="btn btn-secondary"
                    role="button"
                    to="/sign-in/step-1"
                  >
                    <Content
                      cmsTag="GLOBAL:Back-to-sign-in"
                      copytext="Back to sign in"
                    />
                  </Link>
                )}
              </div>
            </React.Fragment>
          ) : (
            ''
          )}
        </form>
      </React.Fragment>
    );
  }
}

Destinations.propTypes = {
  destinations: PropTypes.any,
  history: PropTypes.any,
  error: PropTypes.any,
  finishTwoFactor: PropTypes.any,
  getDestinations: PropTypes.any,
  resetDestinations: PropTypes.any,
  sendChallenge: PropTypes.any,
  page: PropTypes.any,
  mobNumberNotFound: PropTypes.any,
  setMobNumberNotFound: PropTypes.any,
  setDestination: PropTypes.func,
  setResend: PropTypes.any,
  resetActivation: PropTypes.func,
  location: PropTypes.any,
  pathname: PropTypes.any,
};

const mapStateToProps = state => {
  const { twoFactorDefinition, customUserManagementDefinition } = state;
  return {
    ...twoFactorDefinition,
    ...customUserManagementDefinition,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { ...mapDispatchToProps },
  )(Destinations),
);
