import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TimeOut } from '@myie/interact-dom';

import Create from './Create';
import Update from './Update';
import Blocked from './Blocked';

import { NoMatch } from '@myie/interact-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-authentication';
import { mapDispatchToProps as mapDispatchToProps_shared } from '@myie/interact-shared';
import { Alert } from 'reactstrap';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = { rememberme: false, username: null };
  }

  componentDidMount() {
    const { setReferrer, location } = this.props;
    const { from } = location.state || { from: { pathname: '/accounts' } };
    setReferrer(from);
  }

  toggleRememberMe = value => {
    this.setState({ ...this.state, rememberme: !!value });
  };

  setRememberMeUserName = username => {
    this.setState({ ...this.state, username });
  };

  timeoutReset = () => {
    window.sessionStorage.setItem('timeoutMessage', 'TimeoutReset');
  };

  timeoutObj = {
    history: this.props.history,
    userLogout: this.props.userLogout,
    message: 'SignInTimeout',
    url: '/sign-in',
    onTimeout: this.timeoutReset,
    showTimeoutModal: this.props.showTimeoutModal,
    type: 'LOG_IN',
  };

  timeout = new TimeOut(this.timeoutObj);

  render() {
    const { error } = this.props;
    const { rememberme, username } = this.state;
    var re = new RegExp('^/sign-in[/]{0,1}$');
    if (this.props.location.pathname.match(re)) {
      return <Redirect to="/sign-in/step-1" />;
    }
    return (
      <>
        {error ? (
          <Alert color="danger" role="alert">
            {error}
          </Alert>
        ) : (
          ''
        )}
        <Switch>
          <Route
            exact
            path="/sign-in/step-1"
            render={props => (
              <Create
                {...props}
                timeout={this.timeout}
                toggleRememberMe={this.toggleRememberMe}
                setRememberMeUserName={this.setRememberMeUserName}
              />
            )}
          />
          <Route
            exact
            path="/sign-in/step-2"
            render={props => (
              <Update
                {...props}
                timeout={this.timeout}
                rememberme={rememberme}
                remembermeUsername={username}
              />
            )}
          />
          <Route exact path="/sign-in/blocked" component={Blocked} />
          <Route component={NoMatch} />
        </Switch>
      </>
    );
  }
}

SignIn.propTypes = {
  error: PropTypes.any,
  history: PropTypes.any,
  location: PropTypes.any,
  setReferrer: PropTypes.any,
  showTimeoutModal: PropTypes.any,
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps,
    ...mapDispatchToProps_shared,
  },
)(SignIn);
