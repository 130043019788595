import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Content, Markdown, ContactNumber } from '@myie/interact-dom';

class NotAllowed extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }

  render() {
    return (
      <div id="registration-not-allowed">
        <h1 id="registration-not-allowed-title">
          <Content id="title" copytext="Registration Not Allowed" />
        </h1>
        <h2>
          <Content
            id="subTitle"
            copytext={`Sorry, you are not allowed to register for online banking.`}
          />
        </h2>
        <Alert color="danger" role="alert">
          <Markdown
            id="notAllowed-top"
            markdown="Please contact our call centre so that we can help."
          />
          <p>
            <Content id="notAllowed-contact-details" copytext="Call: " />
            <ContactNumber />
            <Content id="notAllowed-charges" copytext=" (charges may apply)" />
          </p>

          <Markdown
            id="notAllowedText-bottom"
            markdown="Calls will be charged at your standard mobile operator rate. Our lines are open from 8am to 8pm, Monday to Saturday and from 9am to 5pm on Sundays."
          />
        </Alert>
        <div className="form-button-group">
          <Link
            id="registration-not-allowed-home-button"
            className="btn btn-primary"
            role="button"
            to="/"
          >
            <Content id="homeButton" copytext="Home" />
          </Link>
        </div>
      </div>
    );
  }
}

NotAllowed.propTypes = {
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotAllowed);
