import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '35.901', height = '35.907' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    version="1.1"
    viewBox="-2.9 -9.3 33.6 41.6"
    width={width}
    height={height}
  >
    <path
      className="secondary-fill"
      d="M5.8-.4C5.8-1.6 7.1-6 11.7-6c4.6 0 5.8 4.4 5.8 5.6v4.9H21V-.4c0-2.4-1-4.7-2.8-6.5-1.8-1.8-4.5-2.6-6.9-2.6-2.3 0-4.4.8-6.2 2.6C3.3-5.1 2.3-2.7 2.3-.4v4.9h3.5V-.4zM7.7 23.2c0-7.1 5.8-12.9 12.9-12.9 1.8 0 3.5.4 5.1 1V8.9c0-.6-.4-1.4-.8-1.8-.5-.5-1.2-.8-1.8-.8H0c-.6 0-1.2.4-1.6.8-.4.5-.7 1.2-.7 1.8V25c0 .6.2 1.1.7 1.6.5.5 1.1.6 1.6.6h8.4c-.4-1.2-.7-2.6-.7-4z"
    />
    <g>
      <path
        className={fill}
        d="M30 24.7V21h-2.3c-.2-.7-.5-1.4-.9-2.1l1.6-1.6-2.6-2.6-1.6 1.6c-.6-.4-1.3-.7-2.1-.9v-2.3h-3.6v2.3c-.7.2-1.4.5-2.1.9l-1.7-1.6-2.6 2.6L14 19c-.4.6-.7 1.3-.9 2.1h-2.3v3.6h2.3c.2.7.5 1.4.9 2.1l-1.7 1.6 2.6 2.6 1.7-1.6c.6.4 1.3.7 2.1.9v2.3h3.6v-2.3c.7-.2 1.4-.5 2.1-.9L26 31l2.6-2.6-1.6-1.6c.4-.6.7-1.3.9-2.1H30zm-9.6 2.9c-2.6 0-4.7-2.1-4.7-4.7s2.1-4.7 4.7-4.7 4.7 2.1 4.7 4.7-2.1 4.7-4.7 4.7z"
      />
      <circle className={fill} cx="20.4" cy="22.8" r="2.6" />
    </g>
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
