import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-ccb-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Content } from '@myie/interact-dom';
import { Link } from 'react-router-dom';

class CancellationSuccess extends Component {
  componentDidMount() {
    const { resetCancellation } = this.props;
    resetCancellation();
  }
  render() {
    const { accountKey } = this.props;

    return (
      <>
        <p>
          <Content
            id="success-message"
            copytext="You have successfully cancelled the Notice to Withdraw transaction"
          />
        </p>
        <div className="form-button-group">
          <Link
            id="back-to-notice"
            className="btn btn-primary"
            role="button"
            to={`/notice-to-withdraw/${accountKey}`}
          >
            <Content
              id="back-to-notice-to-withdraw"
              copytext="Back to notice to withdraw"
            />
          </Link>
        </div>
      </>
    );
  }
}

CancellationSuccess.propTypes = {
  resetCancellation: PropTypes.func,
  accountKey: PropTypes.any,
};

export default connect(
  null,
  mapDispatchToProps,
)(CancellationSuccess);
