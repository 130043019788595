import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Utility } from '@myie/interact';
import SavingsDetailSummary from './SavingsDetailSummary';
import { Row, Col, Nav, TabContent } from 'reactstrap';
//import { AppMeta } from '@myie/interact-dom';
import {
  TabNavItem,
  TabTransaction,
  TabDocument,
  AccountSupportDropdown,
  TabSummary,
} from '@myie/interact-accounts-dom';
import Balance from './Balance';
import { mapDispatchToProps as mapAccountsDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps } from '@myie/interact-account-type-savings';
import { mapDispatchToProps as ccbAccountsMapDispatch } from '@myie/interact-ccb-accounts';
import { TabNavigation, Content } from '@myie/interact-dom';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

class SavingsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tablist: TabNavigation.init(['transactions', 'downloads']),
      reversed: false,
      activeTab: 'summary',
      AccountKey: props.account.AccountKey.Key,
      count: 0,
      range: this.setInitialRange(props),
      downloadTypes: [],
    };
  }

  setInitialRange(props) {
    if (props.range) {
      return props.range;
    }
    const to = moment
      .utc()
      .startOf('day')
      .toISOString();
    const from = moment
      .utc()
      .subtract(1, 'months')
      .add(1, 'day')
      .startOf('day')
      .toISOString();
    return {
      To: to,
      From: from,
      Latest: true,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let { AccountKey, ...rest } = state;
    const { account, fetchSavingsAccountTransactions } = props;
    if (
      account &&
      account.AccountKey &&
      account.AccountKey.Key &&
      AccountKey !== account.AccountKey.Key &&
      state.activeTab === 'transactions'
    ) {
      let rangeLocal = state.range;
      const from = moment
        .utc()
        .subtract(2, 'years')
        .add(1, 'day')
        .startOf('day')
        .toISOString();
      rangeLocal.From = from;
      fetchSavingsAccountTransactions({
        AccountKey: account.AccountKey,
        Range: rangeLocal,
      });
    }

    if (
      account &&
      account.AccountKey &&
      account.AccountKey.Key &&
      AccountKey !== account.AccountKey.Key
    ) {
      return { ...rest, AccountKey: account.AccountKey.Key, count: 0 };
    }

    return null;
  }

  setCount = count => {
    this.setState({ ...this.state, count });
  };

  componentDidMount() {
    const { fetchSavingsAccountIfNeeded, account } = this.props;

    fetchSavingsAccountIfNeeded({
      AccountKey: account.AccountKey,
    });
  }
  componentWillUnmount() {
    //For the selected account, transaction tab on detail page might call reverse() function in the props (when click sort by date).
    //This changes the order of transactions in account list in redux
    //When the same account is used on account list to show latest transactions the above call can change the expected result.
    //This call will reset transactions to correct the order for account list page.
    let { account, fetchSavingsAccountTransactions } = this.props;
    let rangeLocal = this.state.range;
    const from = moment
      .utc()
      .subtract(40, 'years')
      .add(1, 'day')
      .startOf('day')
      .toISOString();
    rangeLocal.From = from;
    fetchSavingsAccountTransactions({
      AccountKey: account.AccountKey,
      Range: this.state.range,
    });
  }

  setTab(e) {
    e.preventDefault();
    const current = e.target.href.substring(e.target.href.indexOf('#') + 1);
    let { account, fetchSavingsAccountTransactions } = this.props;
    switch (current) {
      case 'transactions': {
        let rangeLocal = this.state.range;
        this.setCount(0);
        const from = moment
          .utc()
          .subtract(2, 'years')
          .add(1, 'day')
          .startOf('day')
          .toISOString();
        rangeLocal.From = from;
        this.setState({ ...this.state, range: rangeLocal });
        fetchSavingsAccountTransactions({
          AccountKey: account.AccountKey,
          Range: rangeLocal,
        });
        break;
      }

      case 'summary': {
        let rangeLocal = this.state.range;
        const from = moment
          .utc()
          .subtract(40, 'years')
          .add(1, 'day')
          .startOf('day')
          .toISOString();
        rangeLocal.From = from;
        fetchSavingsAccountTransactions({
          AccountKey: account.AccountKey,
          Range: rangeLocal,
        });
        break;
      }
      default:
    }
    this.setState({ ...this.state, activeTab: current });
  }

  render() {
    const { activeTab, tablist, range } = this.state;
    const { props } = this;
    let {
      account,
      accounts,
      location = { pathname: '' },
      fetchSavingsAccountTransactionsIfNeeded,
      loopIndex = 0,
    } = props;

    var currentAccount = null;
    if (accounts && accounts.Accounts) {
      currentAccount = accounts.Accounts.find(function(element) {
        if (element) {
          return (
            Utility.hexEncode(element.AccountKey.Key) ===
            location.pathname.split('/').pop()
          );
        }
        return false;
      });
    }

    if (!currentAccount) {
      currentAccount = account;
    }

    let activeTabOverride = activeTab;
    if (currentAccount.AccountStatus === 'Closed') {
      // Set the activetab to be 'Statements' for closed accounts
      activeTabOverride = 'statements';
    }

    return (
      <div id={`account-details-${loopIndex}`} className="account-header">
        {account.AccountStatus === 'Closed' ? (
          ''
        ) : (
          <>
            <h1 className="h2 mb-0">{account.AccountName}</h1>
          </>
        )}
        <p className="savings-account-number-title">
          {`${account.AccountNumber} ${account.SortCode}`}
        </p>
        <p className="savings-account-name-title">
          {account.ExtendedProperties.CompanyName
            ? account.ExtendedProperties.CompanyName
            : ''}
        </p>
        <Row>
          {account.AccountStatus !== 'Closed' ? (
            <Col xs={12} lg={6}>
              <Balance account={account} loopIndex={loopIndex} />
            </Col>
          ) : (
            <Col xs={12} lg={6}></Col>
          )}
          <Col xs={12} lg={6}>
            <AccountSupportDropdown
              key={Date.now()} //Force redraw when refreshing due to a bug in the core dropdown component
              account={currentAccount}
              actions={{
                NominatedAccount: {
                  optionName: 'Your nominated account',
                  redirectURL: `/accounts/nominated-account/${Utility.hexEncode(
                    currentAccount.AccountKey.Key,
                  )}`,
                },
                NoticeWithdrawal: {
                  optionName: 'Notice of withdrawal',
                  redirectURL: `/notice-to-withdraw/${Utility.hexEncode(
                    currentAccount.AccountKey.Key,
                  )}`,
                },
                Organisation: {
                  optionName: 'Organisation details',
                  redirectURL: `/update-organisation-details`,
                },
                SendMessage: {
                  optionName: 'Send a message',
                  redirectURL: `/messaging-twoway/new/${Utility.hexEncode(
                    currentAccount.AccountKey.Key,
                  )}`,
                },
                MakeWithdrawal: {
                  optionName: 'Make a withdrawal',
                  redirectURL: `/make-withdrawal/${Utility.hexEncode(
                    currentAccount.AccountKey.Key,
                  )}`,
                },
                Statements: {
                  optionName: 'Statement options',
                  redirectURL: `/messaging-twoway/new/${Utility.hexEncode(
                    currentAccount.AccountKey.Key,
                  )}`,
                },
              }}
            />
          </Col>
        </Row>
        <span className="sr-only" id="savings-account-tab-menu">
          <Content
            id="savings-account-tab-menu-text"
            copytext="Account details tab menu"
          />
        </span>
        <Nav tabs aria-labelledby="savings-account-tab-menu" role="tablist">
          {account.AccountStatus !== 'Closed' ? (
            <>
              <TabNavItem
                name="summary"
                caption="Summary"
                activeTab={activeTabOverride}
                tablist={tablist}
                onClick={e => this.setTab(e)}
              />
              <TabNavItem
                name="transactions"
                caption="Transactions"
                activeTab={activeTabOverride}
                tablist={tablist}
                onClick={e => this.setTab(e)}
              />
            </>
          ) : (
            ''
          )}
          <TabNavItem
            name="statements"
            caption="Statements"
            activeTab={activeTabOverride}
            tablist={tablist}
            onClick={e => this.setTab(e)}
          />
        </Nav>
        <TabContent activeTab={activeTabOverride}>
          <TabSummary
            name="summary"
            activeTab={activeTabOverride}
            tablist={tablist}
          >
            <SavingsDetailSummary
              loopIndex={loopIndex}
              account={account}
              brandProductType={account.BrandProductType}
            />
          </TabSummary>
          <TabTransaction
            name="transactions"
            fetchTransactions={fetchSavingsAccountTransactionsIfNeeded}
            account={account}
            activeTab={activeTabOverride}
            tablist={tablist}
            setCount={this.setCount}
            count={this.state.count}
            range={range}
            interestRates={
              this.props.interestHistory ? this.props.interestHistory : {}
            }
          />
          <TabDocument
            name="statements"
            account={account}
            activeTab={activeTabOverride}
            tablist={tablist}
          />
        </TabContent>
      </div>
    );
  }
}

SavingsDetails.propTypes = {
  account: PropTypes.any,
  accounts: PropTypes.object,
  documents: PropTypes.object,
  fetchSavingsAccountIfNeeded: PropTypes.any,
  fetchSavingsAccountTransactions: PropTypes.any,
  fetchSavingsAccountTransactionsIfNeeded: PropTypes.any,
  fetchDocumentsIfNeeded: PropTypes.func,
  loopIndex: PropTypes.number,
  getInterestHistory: PropTypes.any,
  interestHistory: PropTypes.any,
  location: PropTypes.any,
  range: PropTypes.any,
};

const mapStateToProps = state => {
  const { accountsDefinition, customccbAccountsDefinition } = state;
  return {
    ...accountsDefinition,
    ...customccbAccountsDefinition,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      ...mapDispatchToProps,
      ...mapAccountsDispatchToProps,
      ...ccbAccountsMapDispatch,
    },
  )(SavingsDetails),
);
