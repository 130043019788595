import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@myie/interact';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

class Modals extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    //const { i18n } = this.props;

    return (
      <section id="modals-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/components/modals.scss</strong>
          </code>
        </p>
        <p>
          There is a component made for <strong>modals</strong> defined within{' '}
          <code>shared/components/Dialog.js</code>. To see an interactive
          technical specification for this component,{' '}
          <Link to="/documents#Dialog.js">click here</Link>
        </p>
        <Button className="btn btn-primary btn-sm" id="openModal">
          Open a modal
        </Button>
        <Dialog
          id="modal-sample-modal"
          //isOpen={openModal.onClick}
          title="Two Factor Authentication"
        >
          <h2 onClick={this.onClick}>click Here to close the modal</h2>
        </Dialog>
      </section>
    );
  }
}

Modals.propTypes = {
  items: PropTypes.string,
};

export default Modals;
