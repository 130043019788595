import {
  ACTIVATION_REQUEST,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAILURE,
  RESET_ACTIVATION,
} from '../actions/registrationTypes';

const ccbRegistrationDefinition = (state = {}, action) => {
  switch (action.type) {
    case RESET_ACTIVATION:
      return {
        ...state,
        activationCheck: {},
      };
    case ACTIVATION_SUCCESS:
      return {
        ...state,
        activationCheck: action.activationCheck,
        isFetching: action.isFetching,
      };
    case ACTIVATION_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case ACTIVATION_REQUEST:
    default:
      return state;
  }
};

export default ccbRegistrationDefinition;
