import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'info-fill', width = '33.251', height = '33.251' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 33.251 33.251"
    viewBox="0 0 33.251 33.251"
    aria-hidden="true"
    className="dropdown-icon"
  >
    <g id="arrow-down-2">
      <polygon
        id="pagination_arrow_4_"
        className={fill}
        points="34,11.8 45.2,-0.1 22.9,-0.1 34,11.8"
      />
    </g>
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
