import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-accounts';
import { Utility } from '@myie/interact';
import { Content, NoMatch } from '@myie/interact-dom';
import { Row, Col } from 'reactstrap';

class NominatedAccount extends React.Component {
  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  render() {
    const { accounts } = this.props;

    let account = {};
    const thisProps = this.props;
    if (accounts && accounts.Accounts) {
      account = accounts.Accounts.find(function(element) {
        if (element) {
          return (
            Utility.hexEncode(element.AccountKey.Key) ===
            thisProps.match.params.id
          );
        }
        return false;
      });
      if (!account) {
        return <NoMatch />;
      }
    } else {
      return (
        <p>
          <Content id="loadingAccounts" copytext="Loading accounts..." />
        </p>
      );
    }

    return (
      <div id="nominated-account">
        <h1 id="nominated-account-title">
          <Content
            id="nominated-account-title"
            copytext="Your nominated account"
          />
        </h1>
        <p>
          <Content
            id="nominated-account-description"
            copytext="These are the details of the external account that you are allowed to transfer money from and to or pay interest into."
          />
        </p>
        <p>
          <Content
            id="nominated-account-description-send-message"
            copytext="If you need to change this account please "
          />
          <Link id="back-to-accounts-message-link" to="/messaging-twoway/">
            <Content id="back-to-accounts" copytext="send us a message." />
          </Link>
        </p>
        <h2>
          <Content id="subTitle" copytext="Account details" />
        </h2>
        <Row tag="dl" title="Nominated account details">
          <Col tag="dt" xs={6} md={4} lg={3}>
            <Content
              id="nominated-account-number-label"
              copytext="Account number"
            />
          </Col>
          <Col id="nominated-account-number" tag="dd" xs={6} md={8} lg={9}>
            {account &&
            account.ExtendedProperties &&
            account.ExtendedProperties.NominatedAccountNumber
              ? account.ExtendedProperties.NominatedAccountNumber
              : ''}
          </Col>
          <Col tag="dt" xs={6} md={4} lg={3}>
            <Content id="nominated-sort-code-label" copytext="Sort code" />
          </Col>
          <Col id="nominated-sort-code" tag="dd" xs={6} md={8} lg={9}>
            {account &&
            account.ExtendedProperties &&
            account.ExtendedProperties.NominatedSortCode
              ? account.ExtendedProperties.NominatedSortCode
              : ''}
          </Col>
        </Row>

        <div className="form-button-group">
          <Link
            id="back-to-accounts-button"
            className="btn btn-primary"
            role="button"
            to="/"
          >
            <Content id="back-to-accounts" copytext="Back To Accounts" />
          </Link>
        </div>
      </div>
    );
  }
}

NominatedAccount.propTypes = {
  fetchAccountsIfNeeded: PropTypes.any,
  accounts: PropTypes.any,
  match: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NominatedAccount);
