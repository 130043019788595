import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-ccb-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Content, FormattedCurrency } from '@myie/interact-dom';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Currency from 'react-currency-formatter';

class WithdrawalSuccess extends Component {
  componentDidMount() {
    const { resetMakeWithdrawal } = this.props;
    resetMakeWithdrawal();
  }
  render() {
    const { form = {}, account = {} } = this.props;
    let amount =
      form && form.amount && form.amount.value ? form.amount.value : '';
    const closeOption = form.closeAccount.value;
    const {
      CurrencyCode,
      Balance,
      ExtendedProperties: {
        NominatedAccountNumber,
        NominatedSortCode,
        NominatedAccountName,
      } = {},
    } = account;
    return (
      <>
        <p>
          <Content
            id="success-message"
            copytext="Your withdrawal to your nominated account will occur in the next working day."
          />
        </p>
        <div className="form-button-group">
          <Row tag="dl" title="Account details review">
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content id="lbl-from-account" copytext="From" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {account.AccountName}{' '}
              {Currency({
                quantity: Balance,
                currency: CurrencyCode,
              })}
              <span className="fw-normal d-block">
                {account.SortCode} - {account.AccountNumber}
              </span>
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content id="lbl-to-account" copytext="To" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {NominatedAccountName}
              <span className="fw-normal d-block">
                {NominatedSortCode} - {NominatedAccountNumber}
              </span>
            </Col>
            {!closeOption ? (
              <>
                <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
                  <Content id="lbl-amount" copytext="Amount" />
                </Col>
                <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
                  <FormattedCurrency
                    quantity={amount.replace(/,/g, '')}
                    currency={CurrencyCode}
                  />
                </Col>
              </>
            ) : (
              ''
            )}
          </Row>
          <Link
            id="back-to-notice"
            className="btn btn-primary"
            role="button"
            to={`/accounts/list`}
          >
            <Content id="back-to-make-withdrawal" copytext="Back To Accounts" />
          </Link>
        </div>
      </>
    );
  }
}

WithdrawalSuccess.propTypes = {
  match: PropTypes.any,
  resetMakeWithdrawal: PropTypes.func,
  account: PropTypes.any,
  form: PropTypes.any,
};

const mapStateToProps = state => {
  const { customccbSavingsServicingDefinition } = state;
  return {
    ...customccbSavingsServicingDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithdrawalSuccess);
