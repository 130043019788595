import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';
import { Content, Markdown } from '@myie/interact-dom';
import { Link } from 'react-router-dom';

class ExpiredChallenge extends React.Component {
  render() {
    return (
      <>
        <Content
          tag="h1"
          cmsTag="Two-factor:Expired-challenge:h1"
          copytext="OTP (one time passcode) expired"
        />
        <Markdown
          cmsTag="Two-factor:Expired-challenge:p1"
          markdown={`\nThe <abbr title='one time passcode'> OTP </abbr> you entered has expired. Please start the session again by clicking the button below.`}
        />
        <div className="form-button-group">
          <Link
            id="expired-challenge-cancel"
            className="btn btn-secondary"
            role="button"
            to="/sign-in/step-1"
          >
            <Content
              cmsTag="GLOBAL:Back-to-sign-in"
              copytext="Back to sign in"
            />
          </Link>
        </div>
      </>
    );
  }
}

ExpiredChallenge.propTypes = {
  error: PropTypes.any,
  finishTwoFactor: PropTypes.any,
  resetChallenge: PropTypes.any,
  resetDestinations: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpiredChallenge);
