import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-cms';
import { withRouter } from 'react-router-dom';
import { Settings } from '@myie/interact';
import ContentEditing from './ContentEditing';

// TODO: use connect HOC to connect to CMS data in store

const ContentType = {
  Text: '_text_',
  Error: '_error_',
  Option: '_option_',
};

function contentManaged(Component) {
  class ContentManagedComponent extends React.Component {
    constructor(props) {
      super(props);
      let prefix = props.match.path.substring(1);
      if (prefix.indexOf(':') !== -1) {
        prefix = prefix.substring(0, prefix.indexOf(':'));
      }
      prefix = (prefix.replace(/[/]/g, '.') || 'root').replace(/\.$/, '');
      let composedTag =
        props.cmsTag || prefix + ':' + (props.id || 'IDMISSING');
      this.state = {
        tag: composedTag,
        managed: null,
        template: props.template,
      };
    }

    managedContent = (managed, onShow = false, noEdit = false) => {
      if (!managed) {
        return this.state.managed;
      }
      const { template = {} } = this.state;
      if (managed.validation && managed.validation.rules) {
        var rules = {};
        var templates = {};
        Object.keys(managed.validation.rules).forEach(rule => {
          if (managed.validation.rules[rule].message) {
            rules[rule] = {
              defaultValue: managed.validation.rules[rule].message,
            };
          } else if (managed.validation.rules[rule].trans) {
            rules[rule] = {
              defaultValue: managed.validation.rules[rule].trans(),
            };
          }
          if (managed.validation.rules[rule].template) {
            templates[rule] = managed.validation.rules[rule].template;
          }
        });
        template.errors = templates;
        managed.errors = rules;
        delete managed.validation;
      }

      this.setState({ ...this.state, managed, template, onShow, noEdit });
    };

    applyTemplate = (bindings, text, wrapper) => {
      const showOrigin = (text, wrapper) => {
        if (this.props.showOrigin) {
          if (typeof text === 'string') {
            return `${wrapper}\n\n${text}\n\n${wrapper}`;
          }
        }
        return text;
      };
      var renderText = text || '';
      if (bindings) {
        const keys = Object.keys(bindings);
        keys.forEach(key => {
          const search = new RegExp(
            `(\\$\\[` + key + `\\]|\\$\\{` + key + `\\})`,
            'g',
          );
          let bindingKey = bindings[key];
          if (typeof bindings[key] === 'function') {
            bindingKey = bindingKey();
          }
          renderText = renderText.replace(search, bindingKey);
        });
      }
      return showOrigin(renderText, wrapper);
    };

    contentValue = (type, name, binding) => {
      if (!this.state.managed) return '';
      switch (type) {
        case ContentType.Error:
          return this.contentErrorValue(name, binding);
        case ContentType.Option:
          return this.contentOptionValue(name, binding);
        case ContentType.Text:
          return this.contentTextValue(name, binding);
        default:
          // old style shift the params
          return this.contentTextValue(type, name);
      }
    };

    contentTextValue = (name, binding) => {
      const { tag, managed, template = {} } = this.state;
      const t = binding ? binding : template[name];
      if (tag) {
        const { publishedContent, pendingContent } = this.props;
        if (Settings.cmsAvailable) {
          if (pendingContent) {
            let componentContent = pendingContent[tag]
              ? pendingContent[tag].content
              : null;
            if (componentContent) {
              let elementContent = componentContent[name];
              if (elementContent) {
                if (elementContent.updatedValue) {
                  if (elementContent.updatedValue === '<<deleted>>') {
                    return this.applyTemplate(t, '', '++');
                  }
                  return this.applyTemplate(
                    t,
                    elementContent.updatedValue,
                    '++',
                  );
                } else if (elementContent.pendingValue) {
                  if (elementContent.pendingValue === '<<deleted>>') {
                    return this.applyTemplate(t, '', '++');
                  }
                  return this.applyTemplate(
                    t,
                    elementContent.pendingValue,
                    '^^',
                  );
                }
              }
            }
          }
        }
        if (publishedContent) {
          let componentContent = publishedContent[tag]
            ? publishedContent[tag].content
            : null;
          if (componentContent) {
            let elementContent = componentContent[name];
            if (elementContent) {
              if (elementContent.publishedValue) {
                if (elementContent.publishedValue === '<<deleted>>') {
                  return this.applyTemplate(t, '', '++');
                }
                return this.applyTemplate(
                  t,
                  elementContent.publishedValue,
                  '--',
                );
              }
            }
          }
        }
      }
      if (managed) {
        let element = managed[name];
        if (element) {
          return this.applyTemplate(t, element.defaultValue, '!!');
        }
      }
      return '';
    };

    contentErrorValue = (error, binding) => {
      const { tag, managed, template = { Errors: {} } } = this.state;
      const t = binding
        ? binding
        : typeof template.errors[error] == 'function'
        ? template.errors[error]()
        : template.errors[error];

      if (tag) {
        const { publishedContent, pendingContent } = this.props;
        if (Settings.cmsAvailable) {
          if (pendingContent) {
            let componentContent = pendingContent[tag]
              ? pendingContent[tag]
              : null;
            if (componentContent) {
              let elementContent = componentContent.errors
                ? componentContent.errors[error]
                : null;
              if (elementContent) {
                if (elementContent.updatedValue) {
                  if (elementContent.updatedValue === '<<deleted>>') {
                    return this.applyTemplate(t, '', '++');
                  }
                  return this.applyTemplate(
                    t,
                    elementContent.updatedValue,
                    '++',
                  );
                } else if (elementContent.pendingValue) {
                  if (elementContent.pendingValue === '<<deleted>>') {
                    return this.applyTemplate(t, '', '++');
                  }
                  return this.applyTemplate(
                    t,
                    elementContent.pendingValue,
                    '^^',
                  );
                }
              }
            }
          }
        }
        if (publishedContent) {
          let componentContent = publishedContent[tag]
            ? publishedContent[tag]
            : null;
          if (componentContent) {
            let elementContent = componentContent.errors
              ? componentContent.errors[error]
              : null;
            if (elementContent) {
              if (elementContent.publishedValue) {
                if (elementContent.publishedValue === '<<deleted>>') {
                  return this.applyTemplate(t, '', '++');
                }
                return this.applyTemplate(
                  t,
                  elementContent.publishedValue,
                  '--',
                );
              }
            }
          }
        }
      }

      if (managed) {
        let element = managed.errors[error];
        if (element) {
          return this.applyTemplate(t, element.defaultValue, '!!');
        }
      }
      return '';
    };

    contentOptionValue = (dropDown, binding) => {
      const { tag, managed, template = { Options: {} } } = this.state;
      const t = binding
        ? binding
        : template.options
        ? template.options[dropDown]
        : { Options: {} };

      if (tag) {
        const { publishedContent, pendingContent } = this.props;
        if (Settings.cmsAvailable) {
          if (pendingContent) {
            let componentContent = pendingContent[tag]
              ? pendingContent[tag]
              : null;
            if (componentContent) {
              let elementContent = componentContent.options
                ? componentContent.options[dropDown]
                : null;
              if (elementContent) {
                if (elementContent.updatedValue) {
                  if (elementContent.updatedValue === '<<deleted>>') {
                    return this.applyTemplate(t, '', '++');
                  }
                  return this.applyTemplate(
                    t,
                    elementContent.updatedValue,
                    '++',
                  );
                } else if (elementContent.pendingValue) {
                  if (elementContent.pendingValue === '<<deleted>>') {
                    return this.applyTemplate(t, '', '++');
                  }
                  return this.applyTemplate(
                    t,
                    elementContent.pendingValue,
                    '^^',
                  );
                }
              }
            }
          }
        }
        if (publishedContent) {
          let componentContent = publishedContent[tag]
            ? publishedContent[tag].options
            : null;
          if (componentContent) {
            let elementContent = componentContent.options
              ? componentContent.options[dropDown]
              : null;
            if (elementContent) {
              if (elementContent.publishedValue) {
                if (elementContent.publishedValue === '<<deleted>>') {
                  return this.applyTemplate(t, '', '++');
                }
                return this.applyTemplate(
                  t,
                  elementContent.publishedValue,
                  '--',
                );
              }
            }
          }
        }
      }
      if (managed) {
        let element = managed.options ? managed.options[dropDown] : null;
        if (element) {
          return this.applyTemplate(t, element.defaultValue, '!!');
        }
      }
      return '';
    };

    render() {
      // eslint-disable-next-line no-unused-vars
      const { inEditMode, template, ...rest } = this.props;
      const { tag } = this.state;

      delete rest.contentEditModeSuccess;
      delete rest.contentEditModeRequest;
      delete rest.saveContentSuccess;
      delete rest.saveContentFailed;
      delete rest.cmsTag;
      delete rest.loadStoredContent;
      delete rest.editContent;
      delete rest.saveContent;
      delete rest.publishedContent;
      delete rest.contentEdited;
      delete rest.tagEdited;
      delete rest.contentSaved;
      delete rest.setEditMode;
      delete rest.isFetching;
      delete rest.fetchPublishedContentIfNeeded;
      delete rest.loadPublishedContentRequest;
      delete rest.loadPublishedContentFailed;
      delete rest.loadPublishedContentSuccess;
      delete rest.shouldFetchPublishedContent;
      delete rest.editContentRequest;
      delete rest.editContentFailed;
      delete rest.editContentSuccess;
      delete rest.saveContentRequest;
      delete rest.loadPublishedContent;
      delete rest.fetchPendingContentIfNeeded;
      delete rest.shouldFetchPendingContent;
      delete rest.loadPendingContentRequest;
      delete rest.loadPendingContentSuccess;
      delete rest.loadPendingContentFailed;
      delete rest.loadPendingContent;
      delete rest.pendingContent;
      delete rest.commitContent;
      delete rest.pushContent;
      delete rest.contentCommitted;
      delete rest.contentPushed;
      delete rest.commitContentRequest;
      delete rest.commitContentFailed;
      delete rest.commitContentSuccess;
      delete rest.pushContentRequest;
      delete rest.pushContentFailed;
      delete rest.pushContentSuccess;
      delete rest.deleteContentRequest;
      delete rest.deleteContentFailed;
      delete rest.deleteContentSuccess;
      delete rest.deleteContent;
      delete rest.historyListRequest;
      delete rest.historyListFailed;
      delete rest.historyListSuccess;
      delete rest.fetchHistoryList;
      delete rest.historyList;
      delete rest.fetchHistoryListIfNeeded;
      delete rest.shouldFetchHistoryList;
      delete rest.historyDetail;
      delete rest.historyDetailRequest;
      delete rest.historyDetailFailed;
      delete rest.historyDetailSuccess;
      delete rest.resetHistoryDetail;
      delete rest.fetchHistoryDetail;
      delete rest.savedPendingContent;
      delete rest.setShowOrigin;
      delete rest.showOrigin;
      delete rest.error;
      delete rest.dispatch;
      delete rest.staticContext;
      delete rest.history;
      delete rest.location;
      delete rest.match;

      if (inEditMode && tag) {
        return (
          <ContentEditing
            {...this.props}
            cmsTag={tag}
            managed={this.state.managed}
            onShow={this.state.onShow}
            noEdit={this.state.noEdit}
          >
            <Component
              {...rest}
              managedContent={this.managedContent}
              contentValue={this.contentValue}
            />
          </ContentEditing>
        );
      }
      return (
        <Component
          {...rest}
          managedContent={this.managedContent}
          contentValue={this.contentValue}
        />
      );
    }
  }
  ContentManagedComponent.propTypes = {
    cmsTag: PropTypes.any,
    id: PropTypes.any,
    inEditMode: PropTypes.any,
    match: PropTypes.any,
    pendingContent: PropTypes.any,
    publishedContent: PropTypes.any,
    showOrigin: PropTypes.any,
    template: PropTypes.any,
  };

  return withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(ContentManagedComponent),
  );
}

export { ContentType };
export default contentManaged;
