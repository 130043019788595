import React from 'react';
import PropTypes from 'prop-types';
import { TwoFactor } from '@myie/interact';
import { Validate } from '@myie/interact';
import { Row, Col, Alert, Button } from 'reactstrap';
import { Text, Content } from '@myie/interact-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';

class CodeFormAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        code: {
          rules: {
            title: 'Code',
            stop: true,
            required: {
              message: 'Please enter your one time passcode',
            },
            format: {
              regex: /^.{6}$/,
              message: 'Please enter a valid one time passcode',
            },
          },
        },
      },
    };
  }

  onChange = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  removeCode = () => {
    var { form } = this.state;
    form.code.value = '';
    form.code.state.dirty = false;
    form.code.state.approval = {};
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  continue = e => {
    e.preventDefault();
    var { form } = this.state;
    form = Validate.form(form);
    this.setState({
      ...this.state,
      form,
    });
    if (!form.approved) {
      return;
    }
    TwoFactor.setStatus('');
    TwoFactor.replay(form.code.value);
    this.removeCode();
  };

  render() {
    const { form } = this.state;
    const { failed } = this.props;
    return (
      <div id="enter-form-code">
        {failed && (
          <Alert id="code-failed" color="danger" role="alert">
            <Content
              id="twofa-code-failed"
              cmsTag="Two-factor:Code-form:alert-2"
              copytext="You have entered an incorrect OTP (one time passcode). Please check and try again."
            />
          </Alert>
        )}
        <form onSubmit={this.continue}>
          <Row>
            <Col xs={12} sm={6}>
              <Text
                label="Enter one time passcode you receive"
                id="code"
                autoFocus
                field="code"
                validation={form.code}
                onChange={this.onChange}
                onBlur={this.onBlur}
              />
            </Col>
          </Row>

          <div className="form-button-group">
            <Button id="code-submit" color="primary" type="submit">
              <Content cmsTag="GLOBAL:Confirm" copytext="Confirm" />
            </Button>
            <Link
              id="code-cancel"
              className="btn btn-secondary"
              role="button"
              to="/sign-in/step-1"
            >
              <Content cmsTag="GLOBAL:Cancel" copytext="Cancel" />
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

CodeFormAuth.propTypes = {
  failed: PropTypes.any,
  finishTwoFactor: PropTypes.any,
  sendChallenge: PropTypes.any,
  selectedDestination: PropTypes.string,
  retry: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CodeFormAuth);
