import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-ccb-organisation';
import { Markdown, Content } from '@myie/interact-dom';
import { Link } from 'react-router-dom';
import OrganisationDetails from './OrganisationDetails';
import { Session } from '@myie/interact';

class UpdateOrganisationDetails extends React.Component {
  componentDidMount() {
    const { organisation = { state: 'INIT' } } = this.props;

    if (Array.isArray(organisation.codes) && organisation.details.length > 0) {
      return;
    }

    const customer = Session.customer();
    const request = {
      PersonId: customer.UserId,
    };

    const { organisationfetch } = this.props;
    organisationfetch(request);
  }

  componentDidUpdate() {
    const { organisation = { state: 'INIT' } } = this.props;
    if (Array.isArray(organisation.codes)) {
      const { organisationDetailsFetch, isFetching } = this.props;
      let detailsCount = organisation.details.length;
      if (!isFetching && organisation.codes.length > detailsCount) {
        const request = {
          CustomerId: organisation.codes[detailsCount].Id,
        };
        organisationDetailsFetch(request);
      }
    }
  }
  render() {
    const { organisation = { state: 'INIT' } } = this.props;
    let orgs = [];
    if (organisation == null) {
      return null;
    }
    if (organisation?.details) {
      organisation.details.forEach(org => {
        orgs.push(
          <OrganisationDetails organisation={org} key={org.CustomerId} />,
        );
      });
    } else {
      return null;
    }

    return (
      <>
        <h1>
          <Content id="pageTitle" copytext="Organisation details" />
        </h1>
        <Markdown
          id="generalDescription"
          markdown={`\nYou can view your organisation details below.
          \nWe will never share your details with any third party organisation without your express consent.`}
        />
        <p className="lead">
          <Markdown
            id="organisations-count"
            template={{
              markdown: {
                state: organisation.codes.length,
              },
            }}
            markdown={`There are $[state] organisations associated with this account.`}
          />
        </p>
        {orgs}
        <div className="form-button-group">
          <Link
            id="security-details-cancel"
            className="btn btn-secondary"
            role="button"
            to="/accounts"
          >
            <Content id="cancelButton" copytext="Back to Accounts" />
          </Link>
        </div>
      </>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateOrganisationDetails);

UpdateOrganisationDetails.propTypes = {
  account: PropTypes.any,
  loopIndex: PropTypes.any,
  mode: PropTypes.any,
  brandProductType: PropTypes.any,
  organisationfetch: PropTypes.any,
  organisationDetailsFetch: PropTypes.any,
  organisation: PropTypes.any,
  isFetching: PropTypes.bool,
};
