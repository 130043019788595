import React from 'react';

const FormatBody = body => {
  const lines = body.split('\n');
  const formattedBody = [];
  lines.forEach((line, index) => {
    formattedBody.push(
      <React.Fragment key={`message-body-${index}`}>
        {line}
        <br />
      </React.Fragment>,
    );
  });
  return formattedBody;
};

export default FormatBody;
