import React from 'react';
import PropTypes from 'prop-types';
import Errors from './Errors';
import contentManaged from '../HO_components/contentManaged';

class ErrorPanel extends React.Component {
  constructor(props) {
    super(props);
    const { managedContent, validation } = props;
    managedContent(
      {
        validation: validation,
      },
      false,
      true,
    );
  }

  render() {
    const { props } = this;
    const {
      validation = {},
      showErrors = true,
      field,
      contentValue,
      managedContent,
      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });

    return (
      <React.Fragment>
        {showErrors ? (
          <Errors
            contentValue={contentValue}
            validation={validation}
            field={field}
          />
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

ErrorPanel.propTypes = {
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  /**
   * Input field names
   */
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Style class name for label
   */
  labelClassName: PropTypes.string,

  showErrors: PropTypes.bool,

  validation: PropTypes.object,
};

export default contentManaged(ErrorPanel);
