import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import MakePayment from './MakePayment';
import ReviewPayment from './ReviewPayment';
import CompletePayment from './CompletePayment';
import SuccessfulPayment from './SuccessfulPayment';
import PaymentBreadcrumbs from './PaymentBreadcrumbs';
import { Validate, Utility } from '@myie/interact';
import { mapDispatchToProps as mapDispatchToPropsPayment } from '@myie/interact-pay-by-card';
import { mapDispatchToProps as mapDispatchToPropsAccounts } from '@myie/interact-accounts';
import { Content, Switch } from '@myie/interact-dom';

class PayByCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  initialState = () => {
    const { accounts, match } = this.props;
    let account;
    if (accounts) {
      account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
    } else {
      account = null;
    }
    return {
      account: account,
      stage: 'MakePayment',
      form: {
        amount: {
          rules: {
            title: 'Amount',
            stop: true,
            required: true,
            currency: true,
          },
        },
        cardNumber: {
          rules: {
            title: 'Card number',
            stop: true,
            required: true,
            cc: {
              message: 'Please enter a valid credit card number',
            },
          },
        },
        expiryDate: {
          rules: {
            title: 'Expiry date',
            stop: true,
            required: true,
            cardExpiryDate: {
              message: 'must be in MM/YY format',
            },
            expiryDate: {},
          },
        },
        securityCode: {
          rules: {
            title: 'CVV',
            stop: true,
            required: true,
            securityCode: {
              message: 'must be 3 digits',
            },
          },
        },
        paymentType: {
          value: 'specific',
          rules: {
            title: 'Payment selection',
            required: true,
          },
        },
      },
    };
  };

  // initialise when component umounts
  componentDidMount() {
    const { resetPayByCard, fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
    resetPayByCard();
  }

  componentDidUpdate(prevProps) {
    const { match, accounts } = this.props;
    if (accounts && prevProps.accounts !== accounts) {
      const account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
      this.setState({ ...this.state, account });
    }
  }

  // reset payment detail when component unmounts
  componentWillUnmount() {
    const { resetPayByCard } = this.props;
    this.setState({ ...this.state, stage: 'MakePayment' });
    resetPayByCard();
  }

  // common onChangeAccount function
  onChangeAccount = e => {
    const { resetPayByCard, fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
    resetPayByCard();
    const { value } = e.target;
    if (this.props && this.props.accounts && this.props.accounts.Accounts) {
      const account = this.props.accounts.Accounts.find(function(element) {
        return element.AccountKey.Key === value;
      });
      this.setState({ ...this.initialState(), account });
    }
  };

  // common onChange function
  onChange = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form);

    this.setState({ ...this.state, form });
  };

  // common onBlur function
  onBlur = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  // update the stage in state
  updateStage = (e, stageId) => {
    e.preventDefault();

    if (stageId === 'MakePayment') {
      const { resetPayByCard } = this.props;
      resetPayByCard();
    }
    const state = this.state;
    const stage = update(this.state.stage, { $set: stageId });
    this.setState({ ...state, stage });
  };

  // validation for payment entry form in state
  validateStage = (e, stageId) => {
    e.preventDefault();
    var { form } = this.state;
    form = Validate.form(form);

    if (!form.approved) {
      this.setState({ ...this.state, form });
      return;
    }

    if (stageId === 'MakePayment') {
      const { resetPayByCard } = this.props;
      resetPayByCard();
    }
    const stage = update(this.state.stage, { $set: stageId });
    this.setState({ ...this.state, stage, form });
  };

  // submit from review page
  submitMake = e => {
    e.preventDefault();
    const { makePayByCard } = this.props;
    var { form, account } = this.state;
    var request = {
      AccountKey: account.AccountKey,
      Amount: form.amount.value - 0,
      CurrencyCode: account.CurrencyCode,
      CardNumber: form.cardNumber.value,
      CardHoldersName: null,
      ExpiryDate: form.expiryDate.value,
      Address: {},
      PostCode: null,
      SecurityCode: form.securityCode.value,
      PaymentType: form.paymentType.value,
      ProductType: account.ProductType,
      SaveCardDetails: false,
      ExtendedProperties: null,
    };
    makePayByCard(request);
  };

  // get error text for payment ResponseStatus
  errorText = () => {
    const { payment } = this.props;
    let error = payment ? (
      <Switch
        id="error"
        tag="div"
        className="alert alert-danger"
        value={payment.ResponseStatus}
        defaultText=""
        contents={{
          IncorrectPaymentDetails: {
            defaultValue: 'Incorrect payment details were entered',
          },
          Failed: {
            defaultValue:
              'The payment failed to go through due to technical difficulties',
          },
          Declined: {
            defaultValue: 'The payment was declined by the card issuer',
          },
          CardBlocked: {
            defaultValue: 'The card is blocked by the card issuer',
          },
          LimitExceeded: {
            defaultValue: 'Maximum limit exceeded',
          },
        }}
      />
    ) : null;
    return error;
  };

  // render page for current stage
  renderStage = stage => {
    const {
      error,
      accounts = {},
      isFetching,
      referrer = '/accounts',
      payment,
      history,
    } = this.props;
    const errorText = error || this.errorText();

    var { form, account, formState } = this.state;
    if (stage === 'MakePayment') {
      return (
        <>
          <MakePayment
            form={form}
            formState={formState}
            onChangeAccount={this.onChangeAccount}
            onChange={this.onChange}
            onBlur={this.onBlur}
            updateStage={this.validateStage}
            submit={this.submit}
            error={errorText}
            accounts={accounts}
            account={account}
            referrer={referrer}
            isFetching={isFetching}
          />
        </>
      );
    }

    if (stage === 'ReviewPayment') {
      return (
        <ReviewPayment
          form={form}
          submit={this.submitMake}
          error={errorText}
          account={account}
          referrer={referrer}
          updateStage={this.updateStage}
          isFetching={isFetching}
        />
      );
    }

    if (stage === 'CompletePayment') {
      return (
        <CompletePayment
          form={form}
          submit={this.submitComplete}
          error={errorText}
          account={account}
          referrer={referrer}
          updateStage={this.updateStage}
          isFetching={isFetching}
          payment={payment}
          history={history}
        />
      );
    }

    if (stage === 'SuccessfulPayment') {
      return (
        <SuccessfulPayment
          form={form}
          submit={this.submit}
          error={errorText}
          account={account}
          referrer={referrer}
          updateStage={this.updateStage}
          isFetching={isFetching}
          payment={payment}
        />
      );
    }
  };

  // submit from 3D Secure page
  submitComplete = (e, acknowledgement) => {
    e.preventDefault();
    const { completePayByCard, payment } = this.props;
    var { form, account } = this.state;
    var request = {
      PaymentKey: payment.PaymentKey,
      AccountKey: account.AccountKey,
      Amount: parseFloat(form.amount.value),
      CurrencyCode: account.CurrencyCode,
      CardNumber: form.cardNumber.value,
      CardHoldersName: null,
      ExpiryDate: form.expiryDate.value,
      Address: {},
      PostCode: null,
      SecurityCode: form.securityCode.value,
      PaymentType: form.paymentType.value,
      ProductType: account.ProductType,
      ThreeDSecureResult: acknowledgement,
      SaveCardDetails: false,
      ExtendedProperties: null,
    };
    completePayByCard(request);
  };

  // set stage depending on payment response
  static getDerivedStateFromProps(nextProps, prevState) {
    const { payment } = nextProps;
    if (payment) {
      switch (payment.ResponseStatus) {
        case 'PerformThreeDSecure':
          return { ...prevState, stage: 'CompletePayment' };
        case 'Completed':
          return { ...prevState, stage: 'SuccessfulPayment' };

        default:
      }
    } else if (
      prevState.stage === 'SuccessfulPayment' ||
      prevState.stage === 'CompletePayment'
    ) {
      return { ...prevState, stage: 'MakePayment' };
    }
    return null;
  }

  render() {
    const { accounts = {} } = this.props;
    const { stage, account } = this.state;
    let { error } = this.props;

    if (!accounts.Accounts) {
      return null;
    } else if (!account) {
      return (
        <p>
          <Content id="loading" copytext="Loading..." />
        </p>
      );
    }

    return (
      <>
        <h1>
          <Content id="title" copytext="Pay savings or loan account" />
        </h1>
        <div className="breadcrumb-container">
          <PaymentBreadcrumbs stage={stage} />
        </div>
        <div className="clearfix"></div>
        {this.renderStage(stage, error)}
      </>
    );
  }
}

PayByCard.propTypes = {
  accounts: PropTypes.object,
  completePayByCard: PropTypes.any,
  error: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  history: PropTypes.any,
  isFetching: PropTypes.any,
  makePayByCard: PropTypes.any,
  match: PropTypes.any,
  payment: PropTypes.any,
  referrer: PropTypes.string,
  resetPayByCard: PropTypes.any,
};

const mapStateToProps = state => {
  const { payByCardDefinition, accountsDefinition } = state;
  return {
    ...payByCardDefinition,
    ...accountsDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToPropsPayment,
    ...mapDispatchToPropsAccounts,
  },
)(PayByCard);
