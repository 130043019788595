import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '35.469', height = '33.886' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 35.469 33.886"
    viewBox="0 0 35.469 33.886"
  >
    <path
      className={fill}
      d="M15.967 18.605a24.964 24.964 0 012.618-5.958 4.176 4.176 0 00-6.663-1.027 4.178 4.178 0 004.045 6.985z"
    />
    <path
      className={fill}
      d="M15.032 29.805a28.889 28.889 0 01.04-6.88c-.067.002-.13.008-.196.008a8.322 8.322 0 01-5.909-2.448 8.354 8.354 0 010-11.818 8.353 8.353 0 0111.819 0c.156.157.301.322.441.488 1.563-1.669 3.355-3.007 5.309-3.924L23.47 2.163l-2.678 2.622c-1.029-.628-2.02-1.08-3.329-1.354V.114h-5.239v3.314c-.872.275-2.266.726-3.294 1.354L6.295 2.159 2.466 5.98l2.621 2.637c-.627 1.029-1.082 2.413-1.356 3.286H0v5.676h3.731c.274.873.726 2.091 1.352 3.12L2.46 23.246l3.822 3.784 2.648-2.645c1.028.628 2.421 1.063 3.295 1.337v4.082h2.807z"
    />
    <g>
      <path
        className={fill}
        d="M32.188 28.146c-.627-.145-2.133-.677-2.133-.677-.977-.186-1.504-.569-1.504-1.613 0 0 .037-.76.053-1.028.525-.763.907-1.753 1.304-2.814.672-.497.832-1.396.766-2.247-.045-.584-.687-.793-.687-1.166v-1.749c0-2.874-2.15-5.203-5-5.203s-5 2.33-5 5.203V18.6c0 .373-.049.778-.049 1.203 0 .854.742 1.629 1.662 2.21.396 1.061.796 2.052 1.322 2.813l.057 1.029c0 1.044-.69 1.428-1.667 1.613 0 0-2.088.532-2.716.677-1.94.447-3.609 2.369-3.609 4.459v1.281h20v-1.281c-.001-2.089-.858-4.011-2.799-4.458z"
      />
    </g>
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
