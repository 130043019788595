import PersonalDetailsServiceItems from './PersonalDetails/PersonalDetailsServiceItems';

const UserManagementServiceItems = [
  {
    name: 'PersonalDetailsServiceItems',
    serviceItem: PersonalDetailsServiceItems,
  },
];

export default UserManagementServiceItems;
