import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

class CustomAlert extends React.Component {
  onDismiss = () => {
    const { closeAlert, index } = this.props;
    closeAlert(index);
  };

  render() {
    const { message, index, alertVisibleArray } = this.props;
    return (
      <>
        <Alert
          color="info"
          key={index}
          aria-describedby={`system-message-${index}`}
          isOpen={alertVisibleArray[index]}
          toggle={this.onDismiss}
        >
          {message}
        </Alert>
        <p className="sr-only" id={`system-message-${index}`}>
          {message}
        </p>
      </>
    );
  }
}

CustomAlert.propTypes = {
  alertVisibleArray: PropTypes.any,
  closeAlert: PropTypes.any,
  index: PropTypes.any,
  message: PropTypes.any,
};

export default CustomAlert;
