import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Input, Label } from 'reactstrap';
import { Validate } from '@myie/interact';
import moment from 'moment';
import Errors, { HasErrors } from './Errors';
import contentManaged, { ContentType } from '../HO_components/contentManaged';

/**
 * Datepicker component
 * will display Day Month Year dropdowns
 */
class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      daysInMonth: 31,
      day: false,
      month: false,
      year: false,
    };
    const {
      label,
      subLabel,
      description,
      suffix,
      managedContent,
      validation,
    } = props;
    managedContent({
      label: { defaultValue: label },
      subLabel: { defaultValue: subLabel },
      description: { defaultValue: description },
      suffix: { defaultValue: suffix },
      dayLabel: { defaultValue: 'Day' },
      monthLabel: { defaultValue: 'Month' },
      yearLabel: { defaultValue: 'Year' },
      daySelect: { defaultValue: 'Day' },
      monthSelect: { defaultValue: 'Month' },
      yearSelect: { defaultValue: 'Year' },
      validation: validation,
    });
  }

  componentDidMount() {
    if (this.props.selectedDate) {
      let dateArr = this.props.selectedDate.split('-');
      this.setState({
        year: dateArr[0],
        month: dateArr[1],
        day: dateArr[2],
      });
    }
  }

  getDaysInMonth = (year, month) => {
    return moment(year + '-' + month, 'YYYY-MM').daysInMonth();
  };

  changeDate = (e, f) => {
    const { name, value } = e.target;
    const { field } = this.props;
    const data = { ...this.state };
    data[name] = value;
    if (data.year && data.month) {
      data.daysInMonth = this.getDaysInMonth(data.year, data.month);
      data.day = data.day > data.daysInMonth ? null : data.day;
    }
    const date = data.year + '-' + data.month + '-' + data.day;
    f({ target: { name: field, value: date } });
    this.setState({
      ...this.state,
      [name]: data[name],
      daysInMonth: data.daysInMonth,
    });
  };

  dateOnChange = e => {
    const { onChange } = this.props;
    this.renderMonths();
    this.renderDays();
    this.renderYears();
    this.changeDate(e, onChange);
  };

  dateOnBlur = e => {
    const { onBlur, onChange } = this.props;
    const data = { ...this.state };
    if (data.day && data.month && data.year) {
      this.changeDate(e, onBlur);
    } else {
      this.changeDate(e, onChange);
    }
  };

  renderDays = () => {
    let startFrom = moment().format('DD') - 0;
    let days = [];
    if (this.props.showFutureDate) {
      for (let i = startFrom; i <= this.state.daysInMonth; i++) {
        days.push(
          <option key={`day${i}`} value={('0' + i).slice(-2)}>
            {i}
          </option>,
        );
      }
    } else {
      Array.from(Array(this.state.daysInMonth).keys()).map(counter => {
        let day = counter + 1;
        days.push(
          <option key={`day${day}`} value={('0' + day).slice(-2)}>
            {day}
          </option>,
        );
        return true;
      });
    }
    return days;
  };

  renderMonths = () => {
    let startFrom = moment().format('MM') - 0;
    let year = moment().format('YYYY') - 0;
    let options = [];
    if (
      this.props.showFutureDate &&
      (!this.state.year || year === this.state.year - 0)
    ) {
      for (let i = startFrom; i <= moment.months().length - 0; i++) {
        options.push(
          <option
            key={`month${i}`}
            value={moment()
              .month(moment.months()[i - 1])
              .format('MM')}
          >
            {moment.months()[i - 1]}
          </option>,
        );
      }
    } else {
      moment.months().map(month => {
        options.push(
          <option
            key={`month${month}`}
            value={moment()
              .month(month)
              .format('MM')}
          >
            {month}
          </option>,
        );
        return true;
      });
    }
    this.renderDays();
    return options;
  };

  renderYears = () => {
    let years = [];
    const thisYear = new Date().getFullYear();
    if (this.props.showFutureDate) {
      Array.from(Array(2).keys()).map(counter => {
        let year = thisYear + counter;
        years.push(
          <option key={`day${year}`} value={year}>
            {year}
          </option>,
        );
        return true;
      });
    } else {
      Array.from(Array(120).keys()).map(counter => {
        let year = thisYear - counter - 18;
        years.push(
          <option key={`day${year}`} value={year}>
            {year}
          </option>,
        );
        return true;
      });
    }
    return years;
  };

  render() {
    const {
      showErrors = true,
      field,
      id,
      validation = {},
      groupClassName,
      labelClassName,
      disabled,
      required,
      contentValue,
    } = this.props;
    const { state = {} } = validation;
    const label = contentValue(ContentType.Text, 'label');
    const subLabel = contentValue(ContentType.Text, 'subLabel');
    const description = contentValue(ContentType.Text, 'description');
    const suffix = contentValue(ContentType.Text, 'suffix');
    var fieldName =
      typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;
    const isRequired =
      required || (validation && validation.rules && validation.rules.required);
    return (
      <fieldset
        className={`${groupClassName} ${disabled ? 'disabled-input' : ''} ${
          required ? 'required-input' : ''
        }`.trim()}
      >
        <legend className={`${labelClassName} label`.trim()}>
          {label}
          {isRequired ? (
            <React.Fragment>
              <span aria-hidden="true">*</span>
              <span className="sr-only">(required field)</span>
            </React.Fragment>
          ) : (
            ''
          )}{' '}
          {subLabel.length > 0 ? <small>{subLabel}</small> : ''}
          {description.length > 0 ? (
            <span className="description">{description}</span>
          ) : (
            ''
          )}
        </legend>
        <InputGroup
          id={id + '-input-group'}
          className="date-picker no-calendar"
        >
          <Label
            id={id + '-day-label'}
            htmlFor={id + '-day'}
            className="sr-only"
          >
            {contentValue(ContentType.Text, 'dayLabel')}
          </Label>
          <Input
            aria-describedby={
              HasErrors(validation) ? null : `error-${fieldName}-validation`
            }
            //aria-labelledby={id + '-day-label'}
            type="select"
            disabled={disabled}
            required={required}
            name={'day'}
            id={id + '-day'}
            invalid={Validate.isInvalid(state)}
            valid={Validate.isValid(state)}
            value={this.state.day}
            onChange={this.dateOnChange}
            onBlur={this.dateOnBlur}
          >
            <option value="" hidden>
              {contentValue(ContentType.Text, 'daySelect')}
            </option>
            {this.renderDays()}
          </Input>
          <Label
            id={id + '-month-label'}
            htmlFor={id + '-month'}
            className="sr-only"
          >
            {contentValue(ContentType.Text, 'monthLabel')}
          </Label>
          <Input
            aria-describedby={
              HasErrors(validation) ? null : `error-${fieldName}-validation`
            }
            //aria-labelledby={id + '-month-label'}
            disabled={disabled}
            required={required}
            type="select"
            name={'month'}
            id={id + '-month'}
            invalid={Validate.isInvalid(state)}
            valid={Validate.isValid(state)}
            value={this.state.month}
            onChange={this.dateOnChange}
            onBlur={this.dateOnBlur}
          >
            <option value="" hidden>
              {contentValue(ContentType.Text, 'monthSelect')}
            </option>
            {this.renderMonths()}
          </Input>
          <Label
            id={id + '-year-label'}
            htmlFor={id + '-year'}
            className="sr-only"
          >
            {contentValue(ContentType.Text, 'yearLabel')}
          </Label>
          <Input
            aria-describedby={
              HasErrors(validation) ? null : `error-${fieldName}-validation`
            }
            //aria-labelledby={id + '-year-label'}
            disabled={disabled}
            required={required}
            type="select"
            name={'year'}
            id={id + '-year'}
            invalid={Validate.isInvalid(state)}
            valid={Validate.isValid(state)}
            value={this.state.year}
            onChange={this.dateOnChange}
            onBlur={this.dateOnBlur}
          >
            <option value="" hidden>
              {contentValue(ContentType.Text, 'yearSelect')}
            </option>
            {this.renderYears()}
          </Input>
        </InputGroup>
        {suffix.length > 0 ? <p className="suffix clearfix">{suffix}</p> : ''}
        {showErrors ? (
          <Errors
            contentValue={contentValue}
            validation={validation}
            field={field}
          />
        ) : (
          ''
        )}
      </fieldset>
    );
  }
}

DatePicker.propTypes = {
  contentValue: PropTypes.any,
  description: PropTypes.string,
  disabled: PropTypes.any,
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  groupClassName: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  managedContent: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  selectedDate: PropTypes.string,
  showErrors: PropTypes.bool,
  showFutureDate: PropTypes.bool,
  subLabel: PropTypes.string,
  suffix: PropTypes.string,
  validation: PropTypes.object,
};

export default contentManaged(DatePicker);
