import * as React from 'react';
import { Settings } from '@myie/interact';

class SignOut extends React.Component {
  logout = () => {
    Settings.userManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    });
    Settings.userManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.sessionStorage.clear();
      window.location.replace('/');
    });
    window.localStorage.clear();
    window.sessionStorage.clear();
    Settings.userManager.clearStaleState();
  };
  render() {
    this.logout();
    return <span>loading</span>;
  }
}

export default SignOut;
