import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label, FormGroup } from 'reactstrap';
import Errors, { HasErrors } from './Errors';
import { Validate } from '@myie/interact';
import contentManaged, { ContentType } from '../HO_components/contentManaged';

/**
 * Check box component
 */
class Check extends React.Component {
  constructor(props) {
    super(props);
    const {
      label,
      subLabel,
      description,
      suffix,
      managedContent,
      validation,
    } = props;
    managedContent({
      label: { defaultValue: label },
      subLabel: { defaultValue: subLabel },
      description: { defaultValue: description },
      suffix: { defaultValue: suffix },
      validation: validation,
    });
  }

  render() {
    const { props } = this;

    const {
      showErrors = true,
      validation = {},
      children,
      id,
      disabled,
      required,
      checked,
      field,
      className = '',
      labelClassName = '',
      groupClassName = '',
      contentValue,
      managedContent,
      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const { value = '', state = {} } = validation;
    const label = contentValue(ContentType.Text, 'label');
    const subLabel = contentValue(ContentType.Text, 'subLabel');
    const description = contentValue(ContentType.Text, 'description');
    const suffix = contentValue(ContentType.Text, 'suffix');
    var fieldName =
      typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;
    return (
      <FormGroup
        className={`custom-control custom-checkbox ${groupClassName} ${
          disabled ? 'disabled-input' : ''
        } ${required ? 'required-input' : ''}`.trim()}
      >
        <Input
          aria-describedby={
            HasErrors(validation) ? null : `error-${fieldName}-validation`
          }
          //aria-labelledby={`${id}-label`}
          invalid={Validate.isInvalid(state)}
          valid={Validate.isValid(state)}
          className={`custom-control-input ${className}`.trim()}
          id={id}
          disabled={disabled}
          checked={checked}
          type="checkbox"
          name={fieldName}
          value={value}
          required={required}
          {...rest}
        />
        <Label
          className={`custom-control-label ${labelClassName}`.trim()}
          id={`${id}-label`}
          htmlFor={id}
          check
        >
          <span className="custom-control-text">
            {label} {subLabel.length > 0 ? <small>{subLabel}</small> : ''}
            {description.length > 0 ? (
              <span className="description">{description}</span>
            ) : (
              ''
            )}
          </span>
        </Label>
        {children}
        {suffix.length > 0 ? <p className="suffix">{suffix}</p> : ''}
        {showErrors ? (
          <Errors
            contentValue={contentValue}
            validation={validation}
            field={field}
          />
        ) : (
          ''
        )}
      </FormGroup>
    );
  }
}

Check.propTypes = {
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  /**
   * if check box is ticked
   */
  checked: PropTypes.bool,
  /**
   * Any children of the component
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
  /**
   * checkbox description
   */
  description: PropTypes.string,
  /**
   * is checkbox disabled?
   */
  disabled: PropTypes.bool,
  /**
   * input field
   */
  field: PropTypes.any,
  groupClassName: PropTypes.string,
  /**
   * checkbox id?
   */
  id: PropTypes.any,
  label: PropTypes.any,
  labelClassName: PropTypes.string,
  /**
   * should show errors
   */
  required: PropTypes.any,
  /**
   * Should show errors?
   */
  showErrors: PropTypes.bool,
  subLabel: PropTypes.string,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Validation
   */
  validation: PropTypes.object,
};

export default contentManaged(Check);
