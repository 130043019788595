import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-account-type-savings';
import MaturityDetails from './MaturityDetails';
import Transactions from './Transactions';
import moment from 'moment';

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      range: this.setInitialRange(props),
    };
  }

  setInitialRange(props) {
    if (props.range) {
      return props.range;
    }
    const to = moment
      .utc()
      .startOf('day')
      .toISOString();
    const from = moment
      .utc()
      .subtract(40, 'years')
      .add(1, 'day')
      .startOf('day')
      .toISOString();
    return {
      To: to,
      From: from,
      Latest: true,
    };
  }

  accountType = () => {
    let { account, loopIndex } = this.props;
    switch (account.BrandProductType) {
      case 'EASY_ACCESS':
        return <Transactions loopIndex={loopIndex} account={account} />;
      case 'FIXED_TERM':
        return <MaturityDetails loopIndex={loopIndex} account={account} />;
      default:
    }
  };

  render() {
    return <>{this.accountType()}</>;
  }
}

Overview.propTypes = {
  account: PropTypes.any,
  fetchSavingsAccountTransactionsIfNeeded: PropTypes.func,
  loopIndex: PropTypes.any,
  range: PropTypes.any,
};

export default connect(
  null,
  mapDispatchToProps,
)(Overview);
