import _ from 'lodash';
import queryString from 'query-string';

const stateUtility = (function() {
  // const addOtherParamsToUrl = passedParams => {
  //   const params = Object.keys(passedParams);
  //   let strings = '/';
  //   params.forEach(param => {
  //     if (
  //       passedParams[param] &&
  //       passedParams[param].search('propState') === -1
  //     ) {
  //       strings = `${strings}/${passedParams[param]}`;
  //     }
  //   });

  //   return strings;
  // };

  // const updateFormData = (data, formData) => {
  //   let newFormData = formData;
  //   const keys = Object.keys(data);
  //   keys.forEach(key => {
  //     if (newFormData[key]) {
  //       newFormData[key] = data[key];
  //     } else {
  //       newFormData[`${key}`] = data[key];
  //     }
  //   });

  //   return newFormData;
  // };

  const setPageState = (storageName, obj, formData) => {
    let currenObj = {};
    currenObj = JSON.parse(window.sessionStorage.getItem('pageState'));
    if (!currenObj) {
      currenObj = {};
    }
    if (currenObj && currenObj[storageName]) {
      currenObj[storageName][`${formData}`] = obj;
    } else {
      currenObj[`${storageName}`] = {};
      let thisObj = currenObj;
      thisObj[storageName][`${formData}`] = obj;
    }
    window.sessionStorage.setItem('pageState', JSON.stringify(currenObj));
  };

  const setGlobalValue = data => {
    let currenObj = JSON.parse(window.sessionStorage.getItem('pageState'));
    if (!currenObj) {
      currenObj = {};
    }
    if (currenObj['global']) {
      let currentValues = currenObj['global'];
      const keys = Object.keys(data);
      keys.forEach(key => {
        if (currentValues[key]) {
          currentValues[key] = data[key];
        } else {
          currentValues[`${key}`] = data[key];
        }
      });
      currenObj['global'] = currentValues;
    } else {
      currenObj['global'] = data;
    }
    window.sessionStorage.setItem('pageState', JSON.stringify(currenObj));
  };

  const directlyUpdateUrl = (data, storage, history) => {
    if (!storage) {
      return null;
    }

    if (!data && !_.isEmpty(data.url)) {
      data.url = {};
    }

    const keys = Object.keys(data);
    keys.forEach(key => {
      setPageState(storage, data[key], key);
    });
    const newUrl = queryString.stringifyUrl({
      url: history.location.pathname,
      query: data.url,
    });
    history.push(newUrl);
  };

  const getPageState = () => {
    const data = JSON.parse(window.sessionStorage.getItem('pageState'));
    return data;
  };

  const getComponentState = storageName => {
    let currenObj = {};
    const Obj = JSON.parse(window.sessionStorage.getItem('pageState'));
    if (!Obj) {
      currenObj = {};
    }
    if (Obj && storageName && Obj[storageName]) {
      currenObj = Obj[storageName];
    }
    return currenObj;
  };

  const resetComponentState = storageName => {
    const Obj = JSON.parse(window.sessionStorage.getItem('pageState'));

    if (Obj && Obj[storageName]) {
      delete Obj[storageName];
    }
    window.sessionStorage.setItem('pageState', JSON.stringify(Obj));
  };

  // const generateUrl = (path, strings, urlData) => {
  //   let newUrlString = '';
  //   let splitedUrl = path.split(':');
  //   let removeLastString = splitedUrl[0].slice(0, -1);
  //   let urlParam = urlData;
  //   urlParam.propState = true;
  //   newUrlString = `${removeLastString}${strings}${JSON.stringify(urlParam)}`;
  //   return newUrlString;
  // };

  return {
    //addOtherParamsToUrl: addOtherParamsToUrl,
    directlyUpdateUrl: directlyUpdateUrl,
    //updateFormData: updateFormData,
    setGlobalValue: setGlobalValue,
    //generateUrl: generateUrl,
    setPageState: setPageState,
    getPageState: getPageState,
    getComponentState: getComponentState,
    resetComponentState: resetComponentState,
  };
})();

export default stateUtility;
