import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Utility } from '@myie/interact';
import {
  FormattedDate,
  Pagination,
  Content,
  AppMeta,
} from '@myie/interact-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-messaging-twoway';
import moment from 'moment';
import messagingUtility from './../utility';

class View extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Offset: 0,
      Size: 8,
    };
    this.getMessages(this.state.Offset, this.state.Size);
  }

  getMessages = (Offset, Size) => {
    const request = {
      Offset: Offset,
      Size: Size,
      // Page: 0,
      ExtendedProperties: {},
    };

    this.props.fetchAllMessagesIfNeeded(request);
  };

  render() {
    const { allMessages } = this.props;
    const { Offset, Size } = this.state;

    if (allMessages) {
      const messageList = allMessages.Conversations.sort(
        (a, b) => moment(b.LastModifiedDate) - moment(a.LastModifiedDate),
      );

      return (
        <div id={'messages'}>
          <AppMeta
            id="meta-data"
            stage="child"
            title="2 way messaging"
            metaDescription="2 way message list"
          />
          <Row>
            <Col md={8}>
              <h1>
                <Content id="title" copytext="Messages" />
              </h1>
            </Col>
            <Col className="text-right" md={4}>
              <Link
                id="send-new-message-btn"
                className="btn btn-primary btn-sm"
                role="button"
                to="/messaging-twoway/new"
              >
                <Content id="newMessageButton" copytext="Send a new message" />
              </Link>
            </Col>
          </Row>
          <div className="list-group conversation-list">
            {messageList.map(Conversation => (
              <Link
                id={'message-thread-' + Conversation.MessageCount}
                className={
                  (Conversation.HasUnreadMessages ? 'alert-link ' : '') +
                  'list-group-item list-group-item-action'
                }
                key={Conversation.ConversationKey.Key + '_link'}
                to={
                  '/messaging-twoway/message/view/' +
                  Utility.hexEncode(Conversation.ConversationKey.Key)
                }
              >
                <span
                  key={Conversation.ConversationKey.Key + '_reason'}
                  id={'message-thread-' + Conversation.MessageCount + '-reason'}
                >
                  {messagingUtility.stripEndQuotes(Conversation.Reason)}
                </span>
                <span className="float-right">
                  <FormattedDate
                    format={
                      moment(Conversation.LastModifiedDate).format(
                        'yyyy-MM-DD',
                      ) !== moment().format('yyyy-MM-DD')
                        ? 'DD MMMM h:mm a'
                        : 'h:mm a'
                    }
                    date={Conversation.LastModifiedDate}
                  />
                </span>
              </Link>
            ))}
          </div>
          <Pagination
            Total={allMessages.TotalConversationsCount}
            Offset={Offset}
            Size={Size}
            getPreviousPage={this.getPreviousPage}
            getNextPage={this.getNextPage}
          />
        </div>
      );
    } else {
      return (
        <p aria-live="polite">
          <Content id="loading-messages" copytext="Loading messages..." />
        </p>
      );
    }
  }

  getPreviousPage = (Offset, Size) => {
    const previousOffset = Offset - Size;
    if (previousOffset >= 0) {
      this.setState({ ...this.state, Offset: previousOffset });
      this.getMessages(previousOffset, Size);
    }
  };

  getNextPage = (Offset, Size, TotalConversationsCount) => {
    const nextOffset = Offset + Size;
    if (nextOffset <= TotalConversationsCount) {
      this.setState({ ...this.state, Offset: nextOffset });
      this.getMessages(nextOffset, Size);
    }
  };
}

View.propTypes = {
  allMessages: PropTypes.object,
  fetchAllMessagesIfNeeded: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View);
