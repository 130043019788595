import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ width = '150.707', height = '234.05' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 150.707 234.05"
  >
    <g transform="translate(-195.136 -306.05)">
      <rect
        className="icon-fill"
        width="150.707"
        height="130.337"
        transform="translate(195.136 409.762)"
      />
      <rect
        className="opacity-6"
        width="87.022"
        height="10.936"
        transform="translate(226.978 430.11)"
      />
      <circle
        className="secondary-2-fill"
        cx="35.716"
        cy="35.716"
        r="35.716"
        transform="translate(234.773 306.049)"
      />
      <path
        className="secondary-2-fill"
        d="M297.6,441.046a35.716,35.716,0,1,0-54.216,0Z"
      />
    </g>
  </svg>
);

SVG.propTypes = { height: PropTypes.string, width: PropTypes.string };

export default SVG;
