import React from 'react';
import { Route } from 'react-router-dom';
import Docs from './Docs';

const Routes =
  process.env.NODE_ENV !== 'production'
    ? [<Route key="/documents" path="/documents" component={Docs} />]
    : [];

export default Routes;
