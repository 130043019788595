import { Connection, TwoFactor } from '@myie/interact';

import {
  SETUP_CREDENTIALS_ACTIVATE_REQUEST,
  SETUP_CREDENTIALS_ACTIVATE_SUCCESS,
  SETUP_CREDENTIALS_ACTIVATE_FAILURE,
  RESET_SETUP_CREDENTIALS_ACTIVATE,
} from './registrationTypes';

export const resetActivation = () => ({
  type: RESET_SETUP_CREDENTIALS_ACTIVATE,
});
export const setupCredentialsRequest = () => ({
  type: SETUP_CREDENTIALS_ACTIVATE_REQUEST,
  isFetching: true,
  error: null,
});

export const failedSetupCredentials = error => ({
  type: SETUP_CREDENTIALS_ACTIVATE_FAILURE,
  isFetching: false,
  error: error.message,
});

export const activationSetupCredentials = json => ({
  type: SETUP_CREDENTIALS_ACTIVATE_SUCCESS,
  setupcredentials: json,
  isFetching: false,
  error: null,
});

export const setupcredentialsActivate = request => dispatch => {
  return TwoFactor.run(() => {
    dispatch(setupCredentialsRequest());
    return fetch(
      Connection.baseUrl('webauthentication') +
        `v2.0/user/setupcredentials/activate`,
      {
        method: 'post',
        headers: Connection.headers(),
        body: JSON.stringify(request),
      },
    )
      .then(response => Connection.errored(response))
      .then(response => response.json())
      .then(json => dispatch(activationSetupCredentials(json)))
      .catch(error => dispatch(failedSetupCredentials(error)));
  });
};
