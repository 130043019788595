import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '29.39', height = '31.84' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 29.39 31.84"
    viewBox="0 0 29.39 31.84"
  >
    <path
      className={fill}
      d="M24.568 23.497c-.894-.205-3.545-.96-3.545-.96-1.395-.265-2.41-.978-2.41-2.467v-.112c0-.407.002-.804.021-1.187.752-1.086 1.29-2.498 1.854-4.008 1.31-.826 2.116-1.929 2.116-3.147 0-.606-.201-1.183-.563-1.714v-2.49c0-4.094-3.29-7.412-7.347-7.412-4.058 0-7.347 3.317-7.347 7.412v2.49c-.364.531-.566 1.108-.566 1.714 0 1.218.808 2.321 2.12 3.147.564 1.51 1.101 2.922 1.853 4.008.018.383.021.778.021 1.187v.112c0 1.489-1.017 2.202-2.409 2.467 0 0-2.651.755-3.547.96C2.056 24.137 0 26.874 0 29.85v1.99h29.39v-1.99c0-2.976-2.057-5.713-4.822-6.353z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
