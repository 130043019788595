import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '33.891', height = '29.654' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 33.891 30.654"
    viewBox="0 0 33.891 30.654"
  >
    <path
      className={fill}
      d="M33.455 24.918L19.73 1.579c-1.238-2.104-4.333-2.104-5.57 0L.435 24.918c-1.237 2.105.31 4.736 2.785 4.736h27.451c2.475 0 4.021-2.631 2.784-4.736zm-14.554.692H14.99v-2.696h3.911v2.696zm0-5.392H14.99l-.652-10.783h5.214l-.651 10.783z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
