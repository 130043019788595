import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'reactstrap';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as ccbAccountsMapDispatch } from '@myie/interact-ccb-accounts';
import { Content } from '@myie/interact-dom';
import moment from 'moment';

class DownloadDocument extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      date: moment(new Date())
        .utc()
        .format('YYYY-MM-DD'),
      message: '',
    };
  }

  componentDidUpdate() {
    const { downloadedDocument, document, documentIdData } = this.props;
    if (
      downloadedDocument &&
      documentIdData &&
      document.DocumentKey.Key === downloadedDocument.documentKey.Key &&
      downloadedDocument.documentKey.Key !== documentIdData.id
    ) {
      this.download(downloadedDocument, document);
    }
  }

  download = (downloadedDocument, docInfo) => {
    const { ccbResetDocumentDownload } = this.props;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //For IE and the legacy version of Microsoft Edge, use atob convert base64 encoded data to byte array
      var byteCharacters = window.atob(downloadedDocument.document.Document); // Convert from base64
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], { type: 'application/pdf' });
      window.navigator.msSaveOrOpenBlob(
        blob,
        `${docInfo.Name + docInfo.FileExtension}`,
      );
    } else {
      const downloadLink = document.createElement('a');
      downloadLink.href =
        'data:' +
        downloadedDocument.document.Mime +
        ';base64,' +
        downloadedDocument.document.Document;
      downloadLink.download = `${docInfo.Name + docInfo.FileExtension}`;
      ccbResetDocumentDownload();
      downloadLink.click();
    }
  };

  downloadFileRequest = () => {
    const { ccbFetchDocumentDownload, document, selectedAccount } = this.props;
    const request = {
      DocumentKey: document.DocumentKey,
      ExtendedProperties: {
        Date: this.state.date,
        Type: document.Type,
        AccountKey: selectedAccount.AccountKey,
      },
    };
    ccbFetchDocumentDownload(request);
  };

  render() {
    return (
      <NavLink
        className="pt-0"
        onClick={() => this.downloadFileRequest()}
        href="#"
      >
        <Content id={'download'} copytext="Download" />
        {document.Name}
      </NavLink>
    );
  }
}

DownloadDocument.propTypes = {
  ccbFetchDocumentDownload: PropTypes.func,
  ccbResetDocumentDownload: PropTypes.func,
  downloadedDocument: PropTypes.object,
  document: PropTypes.object,
  selectedAccount: PropTypes.any,
  DocumentKey: PropTypes.object,
  index: PropTypes.number,
  setDocument: PropTypes.any,
  documentIdData: PropTypes.object,
};
const mapStateToProps = state => {
  const { accountsDefinition, customccbAccountsDefinition } = state;
  return {
    ...accountsDefinition,
    ...customccbAccountsDefinition,
  };
};
export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...ccbAccountsMapDispatch },
)(DownloadDocument);
