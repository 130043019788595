import ccbRegistrationDefinition from './reducers/registrationDefinition';
import ccbSetupCredentialsDefinition from './reducers/setupCredentialsDefinition';
import ccbRegistrationTokenDefinition from './reducers/tokenAccountDefinition';

const customccbAuthenticationDefinition = (state = {}, action) => {
  var newState = state;
  newState = ccbRegistrationDefinition(newState, action);
  newState = ccbSetupCredentialsDefinition(newState, action);
  newState = ccbRegistrationTokenDefinition(newState, action);
  return newState;
};

export default customccbAuthenticationDefinition;
