import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '@myie/interact-dom';
import { Link } from 'react-router-dom';

class PaginationStyleguide extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  getPreviousPage = () => {};

  getNextPage = () => {};

  render() {
    return (
      <section id="pagination-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/components/pagination.scss</strong>
          </code>
        </p>
        <p>
          There is a component made for <strong>pagination</strong> defined
          within <code>shared/components/Pagination.js</code>. To see an
          interactive technical specification for this component,{' '}
          <Link to="/documents#Pagination.js">click here</Link>.
        </p>
        <Pagination
          Total={3}
          Offset={1}
          Size={3}
          getPreviousPage={this.getPreviousPage}
          getNextPage={this.getNextPage}
        />
      </section>
    );
  }
}

PaginationStyleguide.propTypes = {
  items: PropTypes.string,
};

export default PaginationStyleguide;
