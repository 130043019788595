import { Connection } from '@myie/interact';

import {
  ORGANISATION_REQUEST,
  ORGANISATION_DETAILS_REQUEST,
  ORGANISATION_PROGRESS,
  ORGANISATION_SUCCESS,
  ORGANISATION_FAILURE,
} from './organisationTypes';

export const organisationRequest = () => ({
  type: ORGANISATION_REQUEST,
  organisation: { state: 'REQUEST', codes: [], details: [], detail: {} },
  isFetching: true,
  error: null,
});

export const organisationDetailsRequest = () => ({
  type: ORGANISATION_DETAILS_REQUEST,
  organisation: { state: 'REQUEST', details: [], codes: [], detail: {} },
  isFetching: true,
  error: null,
});

export const organisationFailed = error => ({
  type: ORGANISATION_FAILURE,
  isFetching: false,
  error: error.message,
});

export const organisationDetailsReceive = json => ({
  type: ORGANISATION_PROGRESS,
  detail: json,
  isFetching: false,
  error: null,
});

export const organisationReceive = json => ({
  type: ORGANISATION_SUCCESS,
  organisation: {
    codes: [...json.CustomerCollection.Customers],
    details: [],
  },
  isFetching: false,
  error: null,
});

export const organisationfetch = request => dispatch => {
  //console.log('In organisationfetch ' + JSON.stringify(request));
  dispatch(organisationRequest());

  return fetch(Connection.baseUrl('customccb') + `v1.0/getcustomersforperson`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(organisationReceive(json)))
    .catch(error => dispatch(organisationFailed(error)));
};

export const organisationDetailsFetch = request => dispatch => {
  //console.log('In organisationDetailsFetch ' + JSON.stringify(request));
  dispatch(organisationDetailsRequest());
  return fetch(Connection.baseUrl('customccb') + `v1.0/getcustomer`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json =>
      dispatch(
        organisationDetailsReceive({
          ...json.Customer,
          CustomerId: request.CustomerId,
        }),
      ),
    )
    .catch(error => dispatch(organisationFailed(error)));
};
