import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';
import { Dialog, Content } from '@myie/interact-dom';
import TwoFactorFlow from './TwoFactorFlow';

class TwoFactorModal extends React.Component {
  render() {
    const { inProgress, children } = this.props;
    const title = (
      <Content
        cmsTag="Two-Factor:Two-factor-modal:h1"
        copytext="Two Factor Authentication"
      />
    );
    return (
      <React.Fragment>
        {inProgress ? (
          <Dialog
            id="two-factor-authentication-modal"
            isOpen={true}
            title={title}
          >
            <TwoFactorFlow />
          </Dialog>
        ) : null}
        <React.Fragment>{children}</React.Fragment>
      </React.Fragment>
    );
  }
}

TwoFactorModal.propTypes = {
  children: PropTypes.any,
  finishTwoFactor: PropTypes.any,
  inProgress: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TwoFactorModal);
