function ErrorScroll() {
  var count = 0;
  var int = setInterval(function() {
    var bodyRect = document.body.getBoundingClientRect();
    const elements = document.getElementsByClassName('is-invalid');
    count++;
    if (count > 50) {
      clearInterval(int);
    }
    if (elements && elements.length > 0) {
      clearInterval(int);
      const tabScroll = elements[0];
      const elemRect = tabScroll.getBoundingClientRect();
      const offset = elemRect.top - bodyRect.top;
      window.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: offset - 50,
      });
    }
  }, 10);
}

export default ErrorScroll;
