import { Connection } from '@myie/interact';
import {
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAILURE,
  FETCH_DOCUMENTS_REQUEST,
  RESET_DOCUMENT_DOWNLOAD,
  FETCH_DOCUMENT_DOWNLOAD_SUCCESS,
  FETCH_DOCUMENT_DOWNLOAD_FAILURE,
  FETCH_DOCUMENT_DOWNLOAD_REQUEST,
} from './documentTypes';

export const ccbRequestDocuments = () => ({
  type: FETCH_DOCUMENTS_REQUEST,
  isFetching: true,
  error: null,
});

export const ccbFailedDocuments = error => ({
  type: FETCH_DOCUMENTS_FAILURE,
  isFetching: false,
  error: error.message,
});

export const ccbReceiveDocuments = (json, accountkey) => ({
  type: FETCH_DOCUMENTS_SUCCESS,
  documents: json,
  accountkey: accountkey,
  isFetching: false,
  error: null,
});

export const ccbFetchDocuments = request => dispatch => {
  dispatch(ccbRequestDocuments());
  return fetch(Connection.baseUrl('accounts') + `v3.0/documents/getdocuments`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(ccbReceiveDocuments(json, request.AccountKey)))
    .catch(error => dispatch(ccbFailedDocuments(error)));
};

export const ccbShouldFetchDocuments = (state, request) => {
  if (!state.accounts || !state.accounts.Accounts) {
    return false;
  }
  const index = state.accounts.Accounts.findIndex(
    x => x.AccountKey.Key === request.AccountKey.Key,
  );
  if (index === -1) {
    return false;
  }
  if (!state.accounts.Accounts[index].documents) {
    return true;
  }

  return false;
};

export const ccbFetchDocumentsIfNeeded = request => (dispatch, getState) => {
  if (ccbShouldFetchDocuments(getState().accountsDefinition, request)) {
    return dispatch(ccbFetchDocuments(request));
  }
};

export const ccbRequestDocumentDownload = () => ({
  type: FETCH_DOCUMENT_DOWNLOAD_REQUEST,
  isFetching: true,
  error: null,
});

export const ccbFailedDocumentDownload = error => ({
  type: FETCH_DOCUMENT_DOWNLOAD_FAILURE,
  isFetching: false,
  error: error.message,
});

export const ccbReceiveDocumentDownload = (json, documentKey) => ({
  type: FETCH_DOCUMENT_DOWNLOAD_SUCCESS,
  downloadedDocument: json,
  documentKey: documentKey,
  isFetching: false,
  error: null,
});

export const ccbFetchDocumentDownload = request => dispatch => {
  dispatch(ccbRequestDocumentDownload());
  return fetch(Connection.baseUrl('accounts') + `v3.0/documents/getdocument`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json =>
      dispatch(ccbReceiveDocumentDownload(json, request.DocumentKey)),
    )
    .catch(error => dispatch(ccbFailedDocumentDownload(error)));
};

export const ccbResetDocumentDownload = () => ({
  type: RESET_DOCUMENT_DOWNLOAD,
});
