import React from 'react';
import PropTypes from 'prop-types';
import { mapStateToProps } from '@myie/interact-accounts';
import { connect } from 'react-redux';
import { NoMatch, AppMeta, Content } from '@myie/interact-dom';
import { Utility } from '@myie/interact';
import AccountDetails from './AccountDetails';

class AccountDetailsTemplate extends React.Component {
  render() {
    const { accounts } = this.props;

    let account = {};
    const thisProps = this.props;
    if (accounts && accounts.Accounts) {
      account = accounts.Accounts.find(function(element) {
        if (element) {
          return (
            Utility.hexEncode(element.AccountKey.Key) ===
            thisProps.match.params.id
          );
        }
        return false;
      });
      if (!account) {
        return <NoMatch />;
      }
    } else {
      return (
        <p>
          <Content id="loadingAccounts" copytext="Loading accounts..." />
        </p>
      );
    }

    return (
      <>
        <AppMeta
          id="meta-data"
          stage="child"
          title="Account details"
          metaDescription="Account Details Description"
        />
        <AccountDetails account={account} />
      </>
    );
  }
}

AccountDetailsTemplate.propTypes = {
  match: PropTypes.object,
  accounts: PropTypes.any,
};
export default connect(mapStateToProps)(AccountDetailsTemplate);
