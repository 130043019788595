import React from 'react';
import { RadioButton } from '@myie/interact-dom';
import { Row, Col, FormGroup } from 'reactstrap';

/** RadioButton example */
class RadioButtonExample extends React.Component {
  render() {
    return (
      <Row>
        <Col md={6}>
          <FormGroup>
            <RadioButton
              key="checkbox1"
              label="Sample Checkbox 1"
              id="amount"
              field="checkbox1"
              value=""
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default RadioButtonExample;
