import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Content, AppMeta } from '@myie/interact-dom';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-ccb-savings-servicing';
import { ApplyAccountSummaryComponent } from '@myie/interact-savings-servicing-dom';
import PropTypes from 'prop-types';
import { CONTACTS } from '@myie/interact-local-dom';

class ShortApplyList extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  renderOutput = () => {
    const { allProducts } = this.props;
    let output = [];
    let shortApplyAvailable = false;
    if (allProducts) {
      allProducts.forEach(product => {
        if (product.Display_For_Short_Apply === 'Y') {
          shortApplyAvailable = true;
        }
      });
    }
    if (shortApplyAvailable) {
      output.push(
        <Fragment key="product">
          <AppMeta
            id="meta-data"
            stage="child"
            title="Apply for a new account"
            metaDescription="A list of accounts you can apply for"
          />
          <Content
            tag="p"
            cmsTag="Savings-servicing:Short-apply:Short-apply-list:p1"
            copytext="Our savings accounts are simple and easy to manage, perfect for established and growing businesses."
          />
          <Content
            tag="p"
            cmsTag="Savings-servicing:Short-apply:Short-apply-list:p2"
            copytext="Notice accounts have annual and monthly options - please select the account that best meets your needs."
          />
          <ApplyAccountSummaryComponent
            page={'short-apply'}
            DepositProducts={allProducts ? allProducts : []}
          />
        </Fragment>,
      );
    }

    if (output.length === 0) {
      if (!this.props.isFetching && allProducts) {
        output.push(
          <Fragment key="noProduct">
            <Content
              tag="p"
              cmsTag="Savings-servicing:Short-apply:Short-apply-list:p3"
              copytext="Unfortunately, we don't have any savings accounts available for you at this time. Please check back at another time."
            />
            <div className="form-button-group">
              <Link
                id="back-to-accounts"
                className="btn btn-secondary"
                role="button"
                to="/accounts"
              >
                <Content id="backButton" copytext="Back to my accounts" />
              </Link>
            </div>
          </Fragment>,
        );
      } else {
        output.push(
          <p key="loading">
            <Content id="loadingProducts" copytext="Loading products..." />
          </p>,
        );
      }
    }
    return output;
  };

  render() {
    return (
      <div id="short-apply-list">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Apply for a new savings account"
          metaDescription="Apply for a new savings account"
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Short-apply:Short-apply-list:h1"
          copytext="Apply for a savings account"
        />
        {this.renderOutput()}
      </div>
    );
  }
}

ShortApplyList.propTypes = {
  accounts: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  updateUrl: PropTypes.func,
  getAllProducts: PropTypes.any,
  allProducts: PropTypes.any,
  setStage: PropTypes.func,
  setStateDynamic: PropTypes.func,
  isFetching: PropTypes.any,
};
const mapStateToProps = state => {
  const { accountsDefinition, customccbSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...customccbSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...mapSavingsServicingDispatchToProps,
  },
)(ShortApplyList);
