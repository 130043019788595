import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import Icon from '../svgs';

class SideLink extends React.Component {
  render() {
    const { props } = this;

    const {
      linkTitle = '',
      linkDescription = '',
      linkID = '',
      linkURL = '',
      linkImage = '',
      iconImage,
      iconImageFill,
      iconImageWidth,
      newMessageCount,
    } = props;

    return (
      <React.Fragment>
        <Link id={linkID} className="nav-link media" to={linkURL}>
          <div className="mr-3">
            {linkImage && (
              <img alt={linkImage} src={linkImage} aria-hidden="true" />
            )}
            {iconImage && (
              <Icon
                name={iconImage}
                fill={iconImageFill}
                width={iconImageWidth}
              />
            )}

            {newMessageCount > 0 && (
              <Badge className="new-message-notification-badge">
                {newMessageCount}
              </Badge>
            )}
          </div>
          <div className="media-body">
            {linkTitle}
            <small className="d-sm-none d-md-none d-lg-block">
              {linkDescription}
            </small>
          </div>
          <div className="ml-3 align-self-center">
            <div className="link-arrow"></div>
          </div>
        </Link>
      </React.Fragment>
    );
  }
}

SideLink.propTypes = {
  items: PropTypes.string,
  linkTitle: PropTypes.object,
  linkDescription: PropTypes.object,
  linkURL: PropTypes.string,
  linkImage: PropTypes.any,
  linkID: PropTypes.string,
  newMessageCount: PropTypes.any,
  iconImage: PropTypes.any,
  iconImageFill: PropTypes.string,
  iconImageWidth: PropTypes.string,
};

export default SideLink;
