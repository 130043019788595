import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import stateUtility from './stateUtility';
import queryString from 'query-string';
import _ from 'lodash';
// TODO: use connect HOC to connect to CMS data in store

function loadStateHOC(Component, storageName) {
  class StateManagedComponent extends React.Component {
    constructor(props) {
      super(props);
      const { location } = props;
      const currentState = queryString.parse(location.search);
      const definition = stateUtility.getComponentState(storageName);
      let url = {};
      let formData = {};
      if (definition) {
        formData = definition;
      }

      if (currentState && !_.isEmpty(currentState)) {
        url = currentState;
      } else {
        url = {};
        if (definition && definition.excludePaths) {
          if (!definition.excludePaths.includes(this.props.location.pathname)) {
            stateUtility.resetComponentState(storageName);
            formData = stateUtility.getComponentState(storageName);
          }
        } else {
          stateUtility.resetComponentState(storageName);
          formData = stateUtility.getComponentState(storageName);
        }
      }

      this.state = { stateData: formData, urlParams: url };
    }

    //Update urls with the state and form objects.
    updateUrl = (data, url = null) => {
      const { history } = this.props;
      let path = url;
      if (!url) {
        path = history.location.pathname;
      }
      if (data && !data.url) {
        data.url = {};
      }
      // let strings;
      // strings = stateUtility.addOtherParamsToUrl(match.params);
      this.updateState(data);

      //const newUrl = stateUtility.generateUrl(match.path, strings, data.url);
      const newUrl = queryString.stringifyUrl({
        url: path,
        query: data.url,
      });
      history.push(newUrl);
    };

    refreshUrl = () => {
      const { history } = this.props;
      const newUrl = queryString.stringifyUrl({
        url: history.location.pathname,
        query: {},
      });

      history.push(newUrl);
      this.setState({ urlParams: {} });
    };

    updateState = data => {
      const keys = Object.keys(data);
      keys.forEach(key => {
        stateUtility.setPageState(storageName, data[key], key);
      });
      const definition = stateUtility.getComponentState(storageName);
      this.setState({ stateData: definition, urlParams: definition.url });
    };

    resetComponentState = () => {
      stateUtility.resetComponentState(storageName);
      const definition = stateUtility.getComponentState(storageName);
      this.setState({ stateData: definition, urlParams: {} });
    };

    render() {
      // eslint-disable-next-line no-unused-vars
      const { ...rest } = this.props;
      const { stateData, urlParams } = this.state;

      return (
        <Component
          updateState={this.updateState}
          updateUrl={this.updateUrl}
          refreshUrl={this.refreshUrl}
          resetComponentState={this.resetComponentState}
          urlParams={urlParams}
          stateData={stateData}
          {...rest}
        />
      );
    }
  }
  StateManagedComponent.propTypes = {
    cmsTag: PropTypes.any,
    history: PropTypes.any,
    id: PropTypes.any,
    match: PropTypes.any,
    pendingContent: PropTypes.any,
    publishedContent: PropTypes.any,
    showOrigin: PropTypes.any,
    location: PropTypes.any,
  };

  return withRouter(StateManagedComponent);
}
export default loadStateHOC;
