const messagingUtility = (function() {
  const stripEndQuotes = s => {
    // var t = s.length;
    // if (s.charAt(0) == '"') s = s.substring(1, t--);
    // if (s.charAt(--t) == '"') s = s.substring(0, t);
    return s;
  };

  return {
    stripEndQuotes: stripEndQuotes,
  };
})();

export default messagingUtility;
