import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import MinusGylph from './MinusGlyph';
import PlusGylph from './PlusGlyph';
import Colours from './Colours';
import Typography from './Typography';
import Fonts from './Fonts';
import Lists from './components/Lists';
import Badges from './components/Badges';
import Buttons from './components/Buttons';
import Breadcrumbs from './components/Breadcrumbs';
import Pagination from './components/Pagination';
import Alerts from './components/Alerts';
import ProgressBars from './components/ProgressBars';
import Tabs from './components/Tabs';
import Tables from './components/Tables';
import Inputs from './components/Inputs';
import Adverts from './components/Adverts';
import SidebarWidgets from './components/SidebarWidgets';
import Modals from './components/Modals';

class StyleguideIndex extends React.Component {
  constructor(props) {
    super(props);
    const toggleData = [];
    toggleData[1] = true;
    this.state = {
      toggleData,
    };
  }

  toggleDescription(index) {
    let toggleData = this.state;
    toggleData[index] = !toggleData[index];
    if (toggleData.length > 1) {
      toggleData = toggleData.map((toggleValue, arrayIndex) => {
        return arrayIndex !== index ? false : toggleValue;
      });
    }
    this.setState({
      toggleData,
    });
  }

  render() {
    const { toggleData } = this.state;

    return (
      <div className="styleguide-container">
        <h1>Styleguide</h1>
        <div aria-describedby="colours-panel" className="mb-2">
          <a
            href="#colours-panel"
            id={`colours-title`}
            onClick={() => this.toggleDescription(1)}
            className={`w-100 text-left mr-2 accordion-trigger info-${
              toggleData[1] ? 'open' : 'closed'
            }`}
          >
            <div className="accordion-icon">
              {toggleData[1] ? <MinusGylph /> : <PlusGylph />}
            </div>
            <div className="pl-5">
              <h2>Colours</h2>
            </div>
          </a>
        </div>
        <Collapse
          tag="div"
          className="ml-5"
          id={`colours-panel`}
          isOpen={!toggleData[1] ? false : toggleData[1]}
        >
          {toggleData[1] ? <Colours /> : null}
        </Collapse>
        <div aria-describedby="fonts-panel" className="mb-2">
          <a
            href="#fonts-panel"
            id={`fonts-title`}
            onClick={() => this.toggleDescription(2)}
            className={`w-100 text-left mr-2 accordion-trigger info-${
              toggleData[2] ? 'open' : 'closed'
            }`}
          >
            <div className="accordion-icon">
              {toggleData[2] ? <MinusGylph /> : <PlusGylph />}
            </div>
            <div className="pl-5">
              <h2>Fonts &amp; Typography</h2>
            </div>
          </a>
        </div>
        <Collapse
          tag="div"
          className="ml-5"
          id={`fonts-panel`}
          isOpen={!toggleData[2] ? false : toggleData[2]}
        >
          {toggleData[2] ? <Fonts /> : null}
          {toggleData[2] ? <Typography /> : null}
        </Collapse>
        <div aria-describedby="lists-panel" className="mb-2">
          <a
            href="#lists-panel"
            id={`lists-title`}
            onClick={() => this.toggleDescription(4)}
            className={`w-100 text-left mr-2 accordion-trigger info-${
              toggleData[4] ? 'open' : 'closed'
            }`}
          >
            <div className="accordion-icon">
              {toggleData[4] ? <MinusGylph /> : <PlusGylph />}
            </div>
            <div className="pl-5">
              <h2>Lists</h2>
            </div>
          </a>
        </div>
        <Collapse
          tag="div"
          className="ml-5"
          id={`lists-panel`}
          isOpen={!toggleData[4] ? false : toggleData[4]}
        >
          {toggleData[4] ? <Lists /> : null}
        </Collapse>
        <div aria-describedby="badges-panel" className="mb-2">
          <a
            href="#badges-panel"
            id={`badges-title`}
            onClick={() => this.toggleDescription(5)}
            className={`w-100 text-left mr-2 accordion-trigger info-${
              toggleData[5] ? 'open' : 'closed'
            }`}
          >
            <div className="accordion-icon">
              {toggleData[5] ? <MinusGylph /> : <PlusGylph />}
            </div>
            <div className="pl-5">
              <h2>Badges</h2>
            </div>
          </a>
        </div>
        <Collapse
          tag="div"
          className="ml-5"
          id={`badges-panel`}
          isOpen={!toggleData[5] ? false : toggleData[5]}
        >
          {toggleData[5] ? <Badges /> : null}
        </Collapse>
        <div aria-describedby="buttons-panel" className="mb-2">
          <a
            href="#buttons-panel"
            id={`buttons-title`}
            onClick={() => this.toggleDescription(6)}
            className={`w-100 text-left mr-2 accordion-trigger info-${
              toggleData[6] ? 'open' : 'closed'
            }`}
          >
            <div className="accordion-icon">
              {toggleData[6] ? <MinusGylph /> : <PlusGylph />}
            </div>
            <div className="pl-5">
              <h2>Buttons</h2>
            </div>
          </a>
        </div>
        <Collapse
          tag="div"
          className="ml-5"
          id={`buttons-panel`}
          isOpen={!toggleData[6] ? false : toggleData[6]}
        >
          {toggleData[6] ? <Buttons /> : null}
        </Collapse>
        <div aria-describedby="breadcrumbs-panel" className="mb-2">
          <a
            href="#breadcrumbs-panel"
            id={`breadcrumbs-title`}
            onClick={() => this.toggleDescription(7)}
            className={`w-100 text-left mr-2 accordion-trigger info-${
              toggleData[7] ? 'open' : 'closed'
            }`}
          >
            <div className="accordion-icon">
              {toggleData[7] ? <MinusGylph /> : <PlusGylph />}
            </div>
            <div className="pl-5">
              <h2>Breadcrumbs</h2>
            </div>
          </a>
        </div>
        <Collapse
          tag="div"
          className="ml-5"
          id={`breadcrumbs-panel`}
          isOpen={!toggleData[7] ? false : toggleData[7]}
        >
          {toggleData[7] ? <Breadcrumbs /> : null}
        </Collapse>
        <div aria-describedby="pagination-panel" className="mb-2">
          <a
            href="#pagination-panel"
            id={`pagination-title`}
            onClick={() => this.toggleDescription(8)}
            className={`w-100 text-left mr-2 accordion-trigger info-${
              toggleData[8] ? 'open' : 'closed'
            }`}
          >
            <div className="accordion-icon">
              {toggleData[8] ? <MinusGylph /> : <PlusGylph />}
            </div>
            <div className="pl-5">
              <h2>Pagination</h2>
            </div>
          </a>
        </div>
        <Collapse
          tag="div"
          className="ml-5"
          id={`pagination-panel`}
          isOpen={!toggleData[8] ? false : toggleData[8]}
        >
          {toggleData[8] ? <Pagination /> : null}
        </Collapse>
        <div aria-describedby="alerts-panel" className="mb-2">
          <a
            href="#alerts-panel"
            id={`alerts-title`}
            onClick={() => this.toggleDescription(9)}
            className={`w-100 text-left mr-2 accordion-trigger info-${
              toggleData[9] ? 'open' : 'closed'
            }`}
          >
            <div className="accordion-icon">
              {toggleData[9] ? <MinusGylph /> : <PlusGylph />}
            </div>
            <div className="pl-5">
              <h2>Alerts</h2>
            </div>
          </a>
        </div>
        <Collapse
          tag="div"
          className="ml-5"
          id={`alerts-panel`}
          isOpen={!toggleData[9] ? false : toggleData[9]}
        >
          {toggleData[9] ? <Alerts /> : null}
        </Collapse>
        <div aria-describedby="progress-bars-panel" className="mb-2">
          <a
            href="#progress-bars-panel"
            id={`progress-bars-title`}
            onClick={() => this.toggleDescription(10)}
            className={`w-100 text-left mr-2 accordion-trigger info-${
              toggleData[10] ? 'open' : 'closed'
            }`}
          >
            <div className="accordion-icon">
              {toggleData[10] ? <MinusGylph /> : <PlusGylph />}
            </div>
            <div className="pl-5">
              <h2>Progress Bars</h2>
            </div>
          </a>
        </div>
        <Collapse
          tag="div"
          className="ml-5"
          id={`progress-bars-panel`}
          isOpen={!toggleData[10] ? false : toggleData[10]}
        >
          {toggleData[10] ? <ProgressBars /> : null}
        </Collapse>
        <div aria-describedby="tabs-panel" className="mb-2">
          <a
            href="#tabs-panel"
            id={`tabs-title`}
            onClick={() => this.toggleDescription(11)}
            className={`w-100 text-left mr-2 accordion-trigger info-${
              toggleData[11] ? 'open' : 'closed'
            }`}
          >
            <div className="accordion-icon">
              {toggleData[11] ? <MinusGylph /> : <PlusGylph />}
            </div>
            <div className="pl-5">
              <h2>Tabs</h2>
            </div>
          </a>
        </div>
        <Collapse
          tag="div"
          className="ml-5"
          id={`tabs-panel`}
          isOpen={!toggleData[11] ? false : toggleData[11]}
        >
          {toggleData[11] ? <Tabs /> : null}
        </Collapse>
        <div aria-describedby="Tables-panel" className="mb-2">
          <a
            href="#Tables-panel"
            id={`Tables-title`}
            onClick={() => this.toggleDescription(12)}
            className={`w-100 text-left mr-2 accordion-trigger info-${
              toggleData[12] ? 'open' : 'closed'
            }`}
          >
            <div className="accordion-icon">
              {toggleData[12] ? <MinusGylph /> : <PlusGylph />}
            </div>
            <div className="pl-5">
              <h2>Tables</h2>
            </div>
          </a>
        </div>
        <Collapse
          tag="div"
          className="ml-5"
          id={`Tables-panel`}
          isOpen={!toggleData[12] ? false : toggleData[12]}
        >
          {toggleData[12] ? <Tables /> : null}
        </Collapse>
        <div aria-describedby="Inputs-panel" className="mb-2">
          <a
            href="#Inputs-panel"
            id={`Inputs-title`}
            onClick={() => this.toggleDescription(13)}
            className={`w-100 text-left mr-2 accordion-trigger info-${
              toggleData[13] ? 'open' : 'closed'
            }`}
          >
            <div className="accordion-icon">
              {toggleData[13] ? <MinusGylph /> : <PlusGylph />}
            </div>
            <div className="pl-5">
              <h2>Inputs</h2>
            </div>
          </a>
        </div>
        <Collapse
          tag="div"
          className="ml-5"
          id={`Inputs-panel`}
          isOpen={!toggleData[13] ? false : toggleData[13]}
        >
          {toggleData[13] ? <Inputs /> : null}
        </Collapse>
        <div aria-describedby="Adverts-panel" className="mb-2">
          <a
            href="#Adverts-panel"
            id={`Adverts-title`}
            onClick={() => this.toggleDescription(14)}
            className={`w-100 text-left mr-2 accordion-trigger info-${
              toggleData[14] ? 'open' : 'closed'
            }`}
          >
            <div className="accordion-icon">
              {toggleData[14] ? <MinusGylph /> : <PlusGylph />}
            </div>
            <div className="pl-5">
              <h2>Adverts</h2>
            </div>
          </a>
        </div>
        <Collapse
          tag="div"
          className="ml-5"
          id={`Adverts-panel`}
          isOpen={!toggleData[14] ? false : toggleData[14]}
        >
          {toggleData[14] ? <Adverts /> : null}
        </Collapse>

        <div aria-describedby="Sidebar-widgets-panel" className="mb-2">
          <a
            href="#Sidebar-widgets-panel"
            id={`Sidebar-widgets-title`}
            onClick={() => this.toggleDescription(15)}
            className={`w-100 text-left mr-2 accordion-trigger info-${
              toggleData[15] ? 'open' : 'closed'
            }`}
          >
            <div className="accordion-icon">
              {toggleData[15] ? <MinusGylph /> : <PlusGylph />}
            </div>
            <div className="pl-5">
              <h2>Sidebar Widgets</h2>
            </div>
          </a>
        </div>
        <Collapse
          tag="div"
          className="ml-5"
          id={`Sidebar-widgets-panel`}
          isOpen={!toggleData[15] ? false : toggleData[15]}
        >
          {toggleData[15] ? <SidebarWidgets /> : null}
        </Collapse>

        <div aria-describedby="Modals-panel" className="mb-2">
          <a
            href="#Modals-panel"
            id={`Modals-title`}
            onClick={() => this.toggleDescription(16)}
            className={`w-100 text-left mr-2 accordion-trigger info-${
              toggleData[16] ? 'open' : 'closed'
            }`}
          >
            <div className="accordion-icon">
              {toggleData[16] ? <MinusGylph /> : <PlusGylph />}
            </div>
            <div className="pl-5">
              <h2>Modals</h2>
            </div>
          </a>
        </div>
        <Collapse
          tag="div"
          className="ml-5"
          id={`Sidebar-widgets-panel`}
          isOpen={!toggleData[16] ? false : toggleData[16]}
        >
          {toggleData[16] ? <Modals /> : null}
        </Collapse>
      </div>
    );
  }
}

StyleguideIndex.propTypes = {
  accounts: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  i18n: PropTypes.any,
  colours: PropTypes.any,
};

export default StyleguideIndex;
