import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import { Text, Content, Markdown } from '@myie/interact-dom';

class AboutYou extends React.Component {
  constructor(props) {
    super(props);
    const { setForm } = this.props;
    this.state = {
      message: null,
      submitForm: {},
      userDetails: null,
      yourTitle: null,
      firstName: null,
      middleNames: null,
      lastName: null,
      aboutYouForm: {
        EmailAddress: {
          rules: {
            title: 'EmailAddress',
            stop: true,
            required: {
              message: 'Please enter a email.',
            },
            email: {
              message: 'Please enter a valid email.',
            },
          },
        },
        ConfirmEmailAddress: {
          rules: {
            title: 'ConfirmEmailAddress',
            stop: true,
            required: {
              message: 'Please enter a email.',
            },
            email: {
              message: 'Please enter a valid email.',
            },
            compare: {
              message: "Email address doesn't match",
              field: 'EmailAddress',
              comparison: () => {
                return this.state.aboutYouForm.EmailAddress.value;
              },
            },
          },
        },
        HomeNumber: {
          rules: {
            title: 'HomeNumber',
            stop: true,
            required: {
              message: 'Please enter a home number.',
            },
            phone: {
              message: 'Please enter a valid home number.',
            },
          },
        },
        WorkNumber: {
          rules: {
            title: 'WorkNumber',
            stop: true,
            required: {
              message: 'Please enter a work number.',
            },
            phone: {
              message: 'Please enter a valid work number.',
            },
          },
        },
        MobileNumber: {
          rules: {
            title: 'MobileNumber',
            stop: true,
            required: {
              message: 'Please enter a mobile number.',
            },
            phone: {
              message: 'Please enter a valid mobile number.',
            },
          },
        },
      },
    };
    setForm('aboutYouForm', this.state.aboutYouForm);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { aboutYouForm, userDetails } = nextProps;

    if (aboutYouForm && aboutYouForm !== prevState.aboutYouForm) {
      return {
        ...prevState,
        aboutYouForm,
      };
    }

    //Set userDetails

    if (aboutYouForm && userDetails !== prevState.userDetails) {
      aboutYouForm.EmailAddress.value = userDetails.EmailAddress;
      aboutYouForm.ConfirmEmailAddress.value = userDetails.EmailAddress;
      aboutYouForm.HomeNumber.value = userDetails.HomeNumber;
      aboutYouForm.WorkNumber.value = userDetails.WorkNumber;
      aboutYouForm.MobileNumber.value = userDetails.MobileNumber;
      return {
        ...prevState,
        yourTitle: userDetails.Title,
        firstName: userDetails.FirstName,
        middleNames: userDetails.MiddleName,
        lastName: userDetails.Surname,
        aboutYouForm,
        userDetails,
      };
    } else {
      return null;
    }
  }

  //Override OnEvent if needed
  onChange = e => {
    const { onEvent } = this.props;
    onEvent('aboutYouForm', e);
  };

  //Override OnEvent if needed
  onBlur = e => {
    const { onEvent } = this.props;
    onEvent('aboutYouForm', e);
  };

  render() {
    let {
      aboutYouForm,
      yourTitle,
      firstName,
      middleNames,
      lastName,
    } = this.state;

    return (
      <fieldset>
        <legend className="h2">
          <Content id="aboutYou" copytext="About you" />
        </legend>
        <Markdown
          id="about-you-description"
          markdown={
            '\nIf you need to change your registered name please call customer service on 0800 1234 5678.'
          }
        />
        <Row tag="dl">
          <Col tag="dt" xs={12} sm={4}>
            <Content id="about-you.yourTitle" copytext="Your title" />
          </Col>
          <Col tag="dd" xs={12} sm={8}>
            {yourTitle}
          </Col>

          <Col tag="dt" xs={12} sm={4}>
            <Content id="about-you.firstName" copytext="First name" />
          </Col>
          <Col tag="dd" xs={12} sm={8}>
            {firstName}
          </Col>

          <Col tag="dt" xs={12} sm={4}>
            <Content id="about-you.middleNames" copytext="Middle name/s" />
          </Col>
          <Col tag="dd" xs={12} sm={8}>
            {middleNames}
          </Col>

          <Col tag="dt" xs={12} sm={4}>
            <Content id="about-you.lastName" copytext="Last name" />
          </Col>
          <Col tag="dd" xs={12} sm={8}>
            {lastName}
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={6}>
            <Text
              label="Enter your email address"
              id="EmailAddress"
              field="EmailAddress"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={aboutYouForm.EmailAddress}
            />
            <Text
              label="Confirm your email address"
              id="ConfirmEmailAddress"
              field="ConfirmEmailAddress"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={aboutYouForm.ConfirmEmailAddress}
            />
            <Text
              label="Home number"
              id="HomeNumber"
              field="HomeNumber"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={aboutYouForm.HomeNumber}
            />
            <Text
              label="Work number"
              id="WorkNumber"
              field="WorkNumber"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={aboutYouForm.WorkNumber}
            />
            <Text
              label="Mobile number"
              id="MobileNumber"
              field="MobileNumber"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={aboutYouForm.MobileNumber}
            />
          </Col>
        </Row>
      </fieldset>
    );
  }
}

AboutYou.propTypes = {
  onEvent: PropTypes.any,
  saveData: PropTypes.any,
  setForm: PropTypes.any,
  userDetails: PropTypes.any,
  aboutYouForm: PropTypes.any,
};

export default AboutYou;
