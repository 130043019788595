import React from 'react';
import { Col, Row } from 'reactstrap';
import { Content } from '@myie/interact-dom';
import PropTypes from 'prop-types';

const NominatedAccount = ({ account }) => {
  const {
    ExtendedProperties: { NominatedAccountNumber, NominatedSortCode } = {},
  } = account;

  return (
    <div className="nominated-account-details" id="nominated-account-details">
      <Row tag="dl" title={`Account details summary`}>
        <Col tag="dt" xs={12} sm={6} md={4} lg={4}>
          <Content id="nominated-acc-no" copytext="Account number" />
        </Col>
        <Col tag="dd" xs={12} sm={6} md={8} lg={8}>
          {NominatedAccountNumber}
        </Col>
        <Col tag="dt" xs={12} sm={6} md={4} lg={4}>
          <Content id="nominated-acc-sort-code" copytext="Sort code" />
        </Col>
        <Col tag="dd" xs={12} sm={6} md={8} lg={8}>
          {NominatedSortCode}
        </Col>
      </Row>
    </div>
  );
};

NominatedAccount.propTypes = {
  account: PropTypes.object,
};

export default NominatedAccount;
