import UpdateSecurityDetailsServiceItems from './UpdateSecurityDetailsServiceItems';
import UpdateOrganisationDetailsServiceItems from './UpdateOrganisationDetailsServiceItems';

const AuthenticationServiceItems = [
  {
    name: 'update-security-details-service-item',
    serviceItem: UpdateSecurityDetailsServiceItems,
  },
  {
    name: 'update-organisation-details-service-item',
    serviceItem: UpdateOrganisationDetailsServiceItems,
  },
];

export default AuthenticationServiceItems;
