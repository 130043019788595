import React from 'react';
import PropTypes from 'prop-types';
import MarkdownToJsx from 'markdown-to-jsx';
import contentManaged, { ContentType } from '../HO_components/contentManaged';
import { Settings } from '@myie/interact';

class Hacker extends React.Component {
  render() {
    return Settings.cmsAvailable ? (
      <span style={{ color: 'red' }}>no scripts allowed</span>
    ) : null;
  }
}

class Image extends React.Component {
  render() {
    const { src, alt, ...rest } = this.props;
    const srcTest = (src || '').toLowerCase();
    if (srcTest.indexOf('javascript') !== 0) {
      return <img src={src} alt={alt} {...rest} />;
    }
    return Settings.cmsAvailable ? (
      <span style={{ color: 'red' }}>no scripts allowed</span>
    ) : null;
  }
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

class Markdown extends React.Component {
  constructor(props) {
    super(props);
    const { markdown, managedContent } = props;
    managedContent({
      markdown: { defaultValue: markdown },
    });
  }

  render() {
    const { contentValue, managedContent, ...rest } = this.props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    delete rest.key;
    const markdown = contentValue(ContentType.Text, 'markdown');
    return (
      <MarkdownToJsx
        options={{
          overrides: {
            script: Hacker,
            img: Image,
          },
        }}
        {...rest}
      >
        {markdown}
      </MarkdownToJsx>
    );
  }
}

Markdown.propTypes = {
  contentValue: PropTypes.func.isRequired,
  markdown: PropTypes.any,
  managedContent: PropTypes.func.isRequired,
  tag: PropTypes.any,
  template: PropTypes.any,
};

export default contentManaged(Markdown);
