const mapStateToProps = state => {
  const { customccbSavingsServicingDefinition = {} } = state;
  const {
    cancellationResponse,
    createNoticeParam,
    makeWithdrawResponse,
    withdrawalParam,
    createResponse,
    isFetching,
    savingshortapply,
    saveShortApplyResponse,
    error = null,
  } = customccbSavingsServicingDefinition;

  return {
    cancellationResponse,
    createNoticeParam,
    withdrawalParam,
    makeWithdrawResponse,
    isFetching,
    createResponse,
    savingshortapply,
    saveShortApplyResponse,
    error,
  };
};

export default mapStateToProps;
