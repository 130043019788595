import React from 'react';
import PropTypes from 'prop-types';
import { Session } from '@myie/interact';

/**
 * IfAuthenticated component
 * will display the content only if the session is authenticated
 */
class IfAuthenticated extends React.Component {
  render() {
    const { children } = this.props;
    if (Session.isAuthenticated()) {
      return <React.Fragment>{children}</React.Fragment>;
    } else return null;
  }
}

IfAuthenticated.propTypes = {
  /**
   * Any children of the component
   */
  children: PropTypes.element,
};

export default IfAuthenticated;
