import React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { Validate } from '@myie/interact';
import { Row, Col, Form, Button, BreadcrumbItem } from 'reactstrap';
import {
  Content,
  RadioGroup,
  Radio,
  FormErrorList,
  CONSTANTS,
  RULES,
  DropDown,
  Currency,
  TextArea,
  AppMeta,
} from '@myie/interact-dom';
import { Link } from 'react-router-dom';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-ccb-savings-servicing';
import { mapDispatchToProps as ccbOrganisationDispatches } from '@myie/interact-ccb-organisation';
import { Session } from '@myie/interact';

import PropTypes from 'prop-types';

class ShortApplyApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState(props);
  }

  initialState = props => {
    const { formData, selectedProduct, selectedOrganisationDetails } = props;
    let data = formData ? formData : null;
    const organisationDetailsChangedInputRules = {
      rules: {
        stop: true,
        required: {
          message:
            'Please confirm which details about the organisation have changed.',
        },
        format: {
          regex: /^[^\s](.|[\r\n]){1,750}$/,
          message: 'Please enter a valid message.',
        },
      },
    };
    const formItems = {
      organisationSource: {
        rules: {
          stop: true,
          required: {
            message:
              'Please confirm which organisation you are opening the account on behalf of',
          },
        },
        value: data ? data.organisationSource : '',
      },
      orgDetailsChanged: {
        rules: {
          required: {
            message:
              'Please confirm if your organisation details have changed.',
          },
        },
        value: data ? data.orgDetailsChanged : '',
      },
      organisationDetailsChangedInput: {
        rules:
          data?.orgDetailsChanged === CONSTANTS.YES
            ? { ...organisationDetailsChangedInputRules.rules }
            : {},
        value: data ? data.organisationDetailsChangedInput : '',
      },
      depositAmount: {
        rules: {
          stop: true,
          required: {
            message: 'Please enter the amount you would like to invest.',
          },
          format: {
            regex: RULES.ammountFormat,
            message: 'Please enter a valid amount.',
          },
          minAmountCheckWithInputValue: {
            message: `Please enter a value that is above the minimum investment amount.`,
            comparison: () => {
              return selectedProduct.Minimum_investment;
            },
          },
          maxAmountCheckWithInputValue: {
            message: `Please enter a value that is below the maximum investment amount`,
            comparison: () => {
              return selectedProduct.Maximum_investment;
            },
          },
        },
        value: data ? data.depositAmount : '',
      },
      depositSource: {
        rules: {
          stop: true,
          required: {
            message: 'Please select where the deposit has come from.',
          },
        },
        value: data ? data.depositSource : '',
      },
      depositMethod: {
        rules: {
          stop: true,
          required: {
            message: 'Please confirm how you will make your deposit.',
          },
        },
        value: data ? data.depositMethod : '',
      },
    };

    return {
      data: data ? data : {},
      form: formItems,
      organisationDetailsChangedInputRules:
        organisationDetailsChangedInputRules.rules,
      selectedOrganisationDetails: selectedOrganisationDetails,
    };
  };

  componentDidUpdate() {
    this.initialState(this.props);
    const { organisation } = this.props;
    if (organisation && Array.isArray(organisation.codes)) {
      const { organisationDetailsFetch, isFetching } = this.props;
      let detailsCount = organisation.details?.length;
      if (!isFetching && organisation.codes?.length > detailsCount) {
        const request = {
          CustomerId: organisation.codes[detailsCount].Id,
        };
        organisationDetailsFetch(request);
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { organisation } = this.props;
    if (!organisation) {
      const customer = Session.customer();
      const request = {
        PersonId: customer.UserId,
      };
      const { organisationfetch } = this.props;
      organisationfetch(request);
    }
  }

  updateCurrency = e => {
    let { name, value } = e.target;
    const { data } = this.state;
    let newValue = value;
    newValue = new Intl.NumberFormat().format(value.replace(/,/g, ''));

    // Deal with trailing . or .0 or .n0
    if (value.charAt(value.length - 1) === '.') {
      newValue += '.';
    }
    if (value.slice(value.length - 2) === '.0') {
      newValue += '.0';
    }
    if (
      value.charAt(value.length - 3) === '.' &&
      value.charAt(value.length - 1) === '0'
    ) {
      if (value.charAt(value.length - 2) === '0') {
        newValue += '.0';
      }
      newValue += '0';
    }
    if (newValue === '0') {
      newValue = '';
    }
    let { form } = this.state;
    form = Validate.input(name, newValue, form, true);
    data[name] = value;

    this.setState({ ...this.state, form });
  };

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  updateForm = e => {
    const { name, value } = e.target;
    let { form, data } = this.state;
    form = Validate.input(name, value, form, true);
    data[name] = value;
    this.setState({ ...this.state, form, data }, () => {
      if (name === 'organisationSource') {
        this.updateOrganisationDetails(form.organisationSource.value);
      }
    });
  };

  updateOrganisationDetails = orgId => {
    const { organisationDetails } = this.props;
    let selectedOrgDetails = organisationDetails.find(
      item => item.CustomerId === orgId,
    );
    this.setState({ selectedOrganisationDetails: selectedOrgDetails });
  };

  onEvent = e => {
    const { name, value } = e.target;
    let { form, organisationDetailsChangedInputRules, data } = this.state;
    form = Validate.input(name, value, form, true);
    if (name === 'orgDetailsChanged' && value === CONSTANTS.YES) {
      form.organisationDetailsChangedInput.rules = {
        ...organisationDetailsChangedInputRules,
      };
    }
    if (name === 'orgDetailsChanged' && value === CONSTANTS.NO) {
      form.organisationDetailsChangedInput.rules = {};
      form = Validate.input('organisationDetailsChangedInput', '', form, true);
      data['organisationDetailsChangedInput'] = '';
    }
    data[name] = value;
    this.setState({ ...this.state, form, data });
  };

  onSubmitHandler = e => {
    e.preventDefault();

    let { form, data, selectedOrganisationDetails } = this.state;
    let { updateUrl, setStateDynamic } = this.props;
    let actualForm;
    if (form.depositAmount.value === '0') {
      form.depositAmount.value = '';
    }
    actualForm = Validate.form(form);
    this.setState({ ...this.state, form: actualForm });

    if (!actualForm.approved) {
      window.scrollTo(0, 0);
      return;
    }
    setStateDynamic({
      stage: 'review',
    });

    let stateData = {
      formData: data,
      selectedOrganisationDetails,
      url: {
        pageStage: 'review',
      },
    };
    updateUrl(stateData);
    //setShortapply(request);
  };

  onClick = e => {
    const { name, checked } = e.target;
    let { form, data } = this.state;

    form = Validate.input(name, checked ? 'true' : null, form);
    data[name] = checked ? 'true' : null;

    this.setState({ ...this.state, form, data });
  };

  dropdownOptions(item, index) {
    let keys = Object.keys(item);
    return (
      <option key={index} value={keys[0]}>
        {item[keys[0]]}
      </option>
    );
  }

  organisationDropdownOption(item, index, name) {
    let keys = Object.keys(item);
    return (
      <option id={`${name}-option-${keys[0]}`} key={index} value={item.Id}>
        {item.FullName}
      </option>
    );
  }

  onBlur = e => {
    this.updateForm(e);
  };

  changeStage = () => {
    const { setStateDynamic } = this.props;
    setStateDynamic({ stage: 'list' });
  };

  valueToCurrencyFormat = (number, code) => {
    const formatter = new Intl.NumberFormat('en-us', {
      style: 'currency',
      currency: code,
    });
    return formatter.format(number);
  };

  render() {
    const currencyCode = 'GBP';
    const { selectedProduct, organisation } = this.props;
    const organisationList = organisation?.codes;
    const { form, selectedOrganisationDetails } = this.state;
    if (!selectedProduct) {
      return null;
    }
    let { CorrespondenceAddress = null, RegisteredAddress = null } =
      selectedOrganisationDetails || {};

    return (
      <div id="short-apply-application">
        <AppMeta
          id="meta-data"
          stage="child"
          title="Apply for a new account"
          metaDescription="Apply for a new account"
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Shared:Short-apply-application:h1"
          template={{
            copytext: {
              productName: selectedProduct.Product_Name,
            },
          }}
          copytext="Apply for $[productName]"
        />
        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem active>
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Account-details"
                copytext="1 Account details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem>
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Review-and-submit"
                copytext="2 Review and submit"
              />
            </BreadcrumbItem>
          </ol>
        </div>
        <div className="clear-both"></div>
        <FormErrorList
          validations={form}
          disabled={false}
          required={true}
          groupClassName=""
          title="h2"
          showErrors={true}
        />
        <Form
          autoComplete="off"
          onSubmit={this.onSubmitHandler}
          id="apply-form"
          className="short-apply-form"
        >
          <Content
            tag="h2"
            cmsTag="GLOBAL:Account-details"
            copytext="Account Details"
          />
          <Row tag="dl" title="Account details">
            <Col tag="dt" xs="12" sm="6" md="3">
              <Content cmsTag="GLOBAL:Account" copytext="Account" />
            </Col>
            <Col tag="dd" xs="12" sm="6" md="9">
              {selectedProduct.Product_Name}
            </Col>
          </Row>
          {organisationList?.length > 0 && (
            <DropDown
              cmsTag="GLOBAL:Organisation-name"
              label="Which organisation are you opening the account on behalf of?"
              id="organisationSource"
              field="organisationSource"
              onChange={this.onChange}
              validation={form.organisationSource}
            >
              <option
                id={`organisation-source-option-please-select`}
                key="1"
                value=""
                defaultText="Select"
              ></option>
              {organisationList?.length &&
                organisationList.map((item, index) => {
                  return this.organisationDropdownOption(
                    item,
                    index,
                    'organisationSource',
                  );
                })}
            </DropDown>
          )}

          {this.state.selectedOrganisationDetails && (
            <Row tag="dl" title="Organisation details">
              <Col tag="dt" xs="12" sm="6" md="3">
                <Content
                  cmsTag="GLOBAL:Organisation-address"
                  copytext="Organisation Address"
                />
              </Col>
              <Col tag="dd" xs="12" sm="6" md="9">
                <address className="organisation-address">
                  {CorrespondenceAddress?.Street ? (
                    <React.Fragment>
                      {CorrespondenceAddress?.Street}
                      <br />
                    </React.Fragment>
                  ) : null}
                  {CorrespondenceAddress?.District ? (
                    <React.Fragment>
                      {CorrespondenceAddress?.District}
                      <br />
                    </React.Fragment>
                  ) : null}
                  {CorrespondenceAddress?.Town ? (
                    <React.Fragment>
                      {CorrespondenceAddress?.Town}
                      <br />
                    </React.Fragment>
                  ) : null}
                  {CorrespondenceAddress?.County ? (
                    <React.Fragment>
                      {CorrespondenceAddress?.County}
                      <br />
                    </React.Fragment>
                  ) : null}
                  {CorrespondenceAddress?.Postcode ? (
                    <React.Fragment>
                      {CorrespondenceAddress?.Postcode}
                      <br />
                    </React.Fragment>
                  ) : null}
                </address>
              </Col>
              <Col tag="dt" xs="12" sm="6" md="3">
                <Content
                  cmsTag="GLOBAL:Registered-office"
                  copytext="Registered Office"
                />
              </Col>
              <Col tag="dd" xs="12" sm="6" md="9">
                <address className="registered-office">
                  {RegisteredAddress?.Street ? (
                    <React.Fragment>
                      {RegisteredAddress?.Street}
                      <br />
                    </React.Fragment>
                  ) : null}
                  {RegisteredAddress?.District ? (
                    <React.Fragment>
                      {RegisteredAddress?.District}
                      <br />
                    </React.Fragment>
                  ) : null}
                  {RegisteredAddress?.Town ? (
                    <React.Fragment>
                      {RegisteredAddress?.Town}
                      <br />
                    </React.Fragment>
                  ) : null}
                  {RegisteredAddress?.County ? (
                    <React.Fragment>
                      {RegisteredAddress?.County}
                      <br />
                    </React.Fragment>
                  ) : null}
                  {RegisteredAddress?.Postcode ? (
                    <React.Fragment>
                      {RegisteredAddress?.Postcode}
                      <br />
                    </React.Fragment>
                  ) : null}
                </address>
              </Col>
            </Row>
          )}
          <RadioGroup
            id="orgDetailsChangedControl"
            cmsTag="Savings-servicing:Shared:Short-apply-application:legend-1"
            validation={form.orgDetailsChanged}
            required={true}
            field="orgDetailsChanged"
            label="Have any of your organisation details changed?"
          >
            <Radio
              cmsTag="GLOBAL:Yes"
              label={`Yes`}
              id={`orgDetailsChanged`}
              field="orgDetailsChanged"
              onChange={this.onEvent}
              validation={form.orgDetailsChanged}
              value={CONSTANTS.YES}
            />
            <Radio
              label={`No`}
              cmsTag="GLOBAL:No"
              id={`orgDetailsChanged-no`}
              field="orgDetailsChanged"
              onChange={this.onEvent}
              validation={form.orgDetailsChanged}
              value={CONSTANTS.NO}
            />
            {form.orgDetailsChanged &&
            form.orgDetailsChanged.value &&
            form.orgDetailsChanged.value === CONSTANTS.YES ? (
              <TextArea
                cmsTag="Savings-servicing:Shared:Short-apply-application:organisation-details-changed-feedback"
                label="Please confirm which details about the organisation have changed. Please note, we may need to contact you about these changes before opening the account."
                id="organisationDetailsChangedInput"
                field="organisationDetailsChangedInput"
                onBlur={this.onBlur}
                onChange={this.onChange}
                validation={form.organisationDetailsChangedInput}
                maxLength="750"
              />
            ) : (
              ''
            )}
          </RadioGroup>
          <Content
            tag="h3"
            cmsTag="Savings-servicing:Shared:Short-apply-application:h3-4"
            copytext="Deposit Details"
          />
          <Currency
            showErrors={true}
            cmsTag="GLOBAL:deposit-amount"
            label={`How much would you like to invest?`}
            validation={form.depositAmount}
            onChange={this.updateCurrency}
            onBlur={this.updateCurrency}
            code={currencyCode}
            field="depositAmount"
            id="depositAmount"
            dp={2}
          />
          <DropDown
            cmsTag="GLOBAL:initial-deposit-source"
            label="Where has the deposit come from?"
            id="depositSource"
            field="depositSource"
            onChange={this.onChange}
            validation={form.depositSource}
          >
            <option
              id={`deposit-source-option-please-select`}
              key="1"
              value=""
              defaultText="Select"
            ></option>
            {CONSTANTS &&
              CONSTANTS.DEPOSIT_SOURCE.map((item, index) => {
                return this.dropdownOptions(item, index, 'depositSource');
              })}
          </DropDown>
          <DropDown
            cmsTag="GLOBAL:initial-deposit-method"
            label="How will you make your deposit?"
            id="depositMethod"
            field="depositMethod"
            onChange={this.onChange}
            validation={form.depositMethod}
            subLabel="This needs to come from your nominated account"
          >
            <option
              id={`deposit-method-option-please-select`}
              key="1"
              value=""
              defaultText="Select"
            ></option>
            {CONSTANTS &&
              CONSTANTS.DEPOSIT_METHOD.map((item, index) => {
                return this.dropdownOptions(item, index, 'depositMethod');
              })}
          </DropDown>
          <div className="form-button-group">
            <Button
              id="continue-shortapply-application"
              type="submit"
              color="primary"
            >
              <Content
                cmsTag="GLOBAL:Continue-shortapply-application"
                copytext="Continue"
              />
            </Button>
            <Link
              id="back-to-product-list-link"
              className="btn btn-secondary"
              role="button"
              to={`/short-apply/index`}
            >
              <Content
                cmsTag="GLOBAL:Back-to-product-list"
                copytext="Cancel application"
              />
            </Link>
          </div>
        </Form>
      </div>
    );
  }
}

ShortApplyApplication.propTypes = {
  accounts: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  onSubmit: PropTypes.func,
  setStage: PropTypes.func,
  userDetails: PropTypes.any,
  updateUrl: PropTypes.func,
  setStateDynamic: PropTypes.any,
  formData: PropTypes.any,
  selectedProduct: PropTypes.any,
  data: PropTypes.any,
  setShortapply: PropTypes.func,
  organisationDetails: PropTypes.any,
  organisation: PropTypes.any,
  organisationDetailsFetch: PropTypes.func,
  organisationfetch: PropTypes.func,
  isFetching: PropTypes.bool,
};
const mapStateToProps = state => {
  const {
    accountsDefinition,
    customccbSavingsServicingDefinition,
    customccbOrganisationDefinition,
  } = state;
  return {
    ...accountsDefinition,
    ...customccbSavingsServicingDefinition,
    ...customccbOrganisationDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps,
    ...mapSavingsServicingDispatchToProps,
    ...ccbOrganisationDispatches,
  },
)(ShortApplyApplication);
