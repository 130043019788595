import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import Register from './Retrieve';
import Blocked from './Blocked';
import Success from './Success';

import { NoMatch } from '@myie/interact-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-authentication';
import { Alert } from 'reactstrap';

class ForgottenPassword extends React.Component {
  componentDidMount() {
    const { setReferrer, location } = this.props;
    const { from } = location.state || {
      from: {
        pathname: '/accounts',
      },
    };
    setReferrer(from);
  }

  render() {
    const { error } = this.props;
    var re = new RegExp('^/forgotten-password[/]{0,1}$');
    if (this.props.location.pathname.match(re)) {
      return <Redirect to="/forgotten-password/retrieve" />;
    }
    return (
      <div id="forgotten-password">
        {error ? (
          <Alert color="danger" role="alert">
            {error}
          </Alert>
        ) : (
          ''
        )}
        <Switch>
          <Route
            exact
            path="/forgotten-password/retrieve"
            component={Register}
          />
          <Route exact path="/forgotten-password/blocked" component={Blocked} />
          <Route exact path="/forgotten-password/success" component={Success} />
          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

ForgottenPassword.propTypes = {
  error: PropTypes.any,
  location: PropTypes.any,
  setReferrer: PropTypes.any,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ForgottenPassword),
);
