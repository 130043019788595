export const RESET_SET_SHORT_APPLY = 'RESET_SET_SHORT_APPLY';
export const SET_SHORT_APPLY_REQUEST = 'SET_SHORT_APPLY_REQUEST';
export const SET_SHORT_APPLY_SUCCESS = 'SET_SHORT_APPLY_SUCCESS';
export const SET_SHORT_APPLY_FALIURE = 'SET_SHORT_APPLY_FALIURE';
export const RESET_SHORT_APPLY_OPTIONS = 'RESET_SHORT_APPLY_OPTIONS';
export const GET_ALL_PRODUCTS_REQUEST = 'GET_ALL_PRODUCTS_REQUEST';
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';
export const GET_ALL_PRODUCTS_FALIURE = 'GET_ALL_PRODUCTS_FALIURE';
export const RESET_SAVE_SHORT_APPLY = 'RESET_SAVE_SHORT_APPLY';
export const SAVE_SHORT_APPLY_REQUEST = 'SAVE_SHORT_APPLY_REQUEST';
export const SAVE_SHORT_APPLY_SUCCESS = 'SAVE_SHORT_APPLY_SUCCESS';
export const SAVE_SHORT_APPLY_FALIURE = 'SAVE_SHORT_APPLY_FALIURE';
