import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-messaging-twoway';
import { Content } from '@myie/interact-dom';
import PropTypes from 'prop-types';

class DeleteConversation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'View',
      message: null,
    };
  }

  confirmDelete = () => {
    const { deleteConversation, viewMessage } = this.props;
    const request = {
      ConversationKey: viewMessage.Conversation.ConversationKey,
      ProductType: 'string',
      ConversationProperties: [
        {
          Name: 'Deleted',
          Value: true,
        },
      ],
      ExtendedProperties: {},
    };
    deleteConversation(request);
  };

  // select current stag
  render() {
    const { viewMessage, match } = this.props;

    let Conversation;

    if (!viewMessage) {
      return null;
    } else {
      Conversation = viewMessage.Conversation;
    }

    return (
      <div id="conversation-delete">
        <h1>
          <Content id="title" copytext="Delete message" />
        </h1>
        <h2>
          <Content
            id="description"
            copytext="You are about to delete the following conversation"
          />
        </h2>
        <Row tag="dl" title="delete message details">
          <Col tag="dt" xs={12} md={4} lg={3}>
            <Content id="subject" copytext="Subject" />
          </Col>
          <Col tag="dd" xs={12} md={8} lg={9}>
            <Content id="reason" copytext="Re: " />
            {Conversation.Reason}
          </Col>
          <Col tag="dt" xs={12} md={4} lg={3}>
            <Content id="relatedAccountMsg" copytext="Related account:" />
          </Col>
          <Col tag="dd" xs={12} md={8} lg={9}>
            {`${Conversation.AccountNumber}${Conversation.SortCode}`}
          </Col>
          <Col tag="dt" xs={12} md={4} lg={3}>
            <Content id="referenceNumber" copytext="Reference number:" />
          </Col>
          <Col tag="dd" xs={12} md={8} lg={9}>
            {Conversation.Reference}
          </Col>
        </Row>
        <div className="form-button-group">
          <Button
            id="messaging-twoway-delete-submit"
            type="submit"
            color="primary"
            onClick={this.confirmDelete}
          >
            <Content id="submitButton" copytext="Confirm Delete" />
          </Button>
          <Link
            id="messaging-twoway-delete-cancel"
            className="btn btn-secondary"
            role="button"
            to={`/messaging-twoway/message/view/${match.params.id}`}
          >
            <Content id="cancelButton" copytext="Cancel" />
          </Link>
        </div>
      </div>
    );
  }
}

DeleteConversation.propTypes = {
  baseUrl: PropTypes.any,
  deleteConversation: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  setReplyStage: PropTypes.any,
  viewMessage: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteConversation);
