/* eslint-disable no-mixed-spaces-and-tabs, prettier/prettier */

import React from 'react';
import PropTypes from 'prop-types'
import { Alert } from 'reactstrap'
import { Markdown } from '@myie/interact-dom';
import { SavingsDetails } from '@myie/interact-account-type-savings-dom';


class AccountDetails extends React.Component {
render() {
// eslint-disable-next-line no-unused-vars
const { account, loopIndex, mode } = this.props
switch (account.ProductType) {
		case "Savings": return (<SavingsDetails loopIndex={loopIndex} account={account} mode={mode}/>); 

default: return (
<Alert color="warning" className="account-card account-summary">
    <div>
        <Markdown id="template-detail-notSupported" template={ { markdown: { productType: account.ProductType, }, } }
            markdown={`Account type **$[productType]** not supported`} />
    </div>
</Alert>
)
}
}
}

AccountDetails.propTypes = {
account: PropTypes.any,
loopIndex: PropTypes.any,
mode: PropTypes.any
}

export default AccountDetails