import React from 'react';
import PropTypes from 'prop-types';
import { Validate } from '@myie/interact';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Radio, RadioGroup, Content } from '@myie/interact-dom';
import { TwoFactor } from '@myie/interact';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';
import { mapDispatchToProps as mapDispatchToProps_auth } from '@myie/interact-authentication';
import { Link } from 'react-router-dom';
import NoDestinations from './NoDestinations';

class DestinationAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        destination: {
          rules: {
            required: true,
          },
        },
      },
      disabled: false,
    };
  }

  componentDidMount() {
    const { getDestinations, destinations } = this.props;
    if (!destinations || !destinations.Destinations) {
      getDestinations({
        ChallengeKey: TwoFactor.getKey(),
      });
    }
  }

  componentWillUnmount() {
    const { resetDestinations } = this.props;
    resetDestinations();
  }

  continue = e => {
    e.preventDefault();
    this.setState({ disabled: true });
    const { sendChallenge, setDestination, page, setResend } = this.props;
    if (page === 'resend') {
      setResend();
    }
    var { form } = this.state;
    form = Validate.form(form);
    this.setState({
      ...this.state,
      form,
    });
    if (!form.approved) {
      this.setState({ disabled: false });
      return;
    }
    const selectedDestination = form.destination.value;
    setDestination(selectedDestination);
    const request = {
      Destination: selectedDestination,
      ChallengeKey: TwoFactor.getKey(),
    };
    sendChallenge(request);
    this.setState({ disabled: false });
  };

  cancel = () => {
    const { finishTwoFactor } = this.props;
    finishTwoFactor({});
  };

  onChange = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  getErrorMessage = () => {
    const { form } = this.state;

    let message = null;
    if (form.destination.state && Validate.isInvalid(form.destination.state)) {
      message = (
        <Content
          cmsTag="Two-factor:Destinations:error-message-1"
          copytext="Please select a mobile phone number."
        />
      );
    }

    return message;
  };

  render() {
    const { destinations, error, page, mobNumberNotFound } = this.props;
    const { form } = this.state;
    const numbers = [];
    if (error) {
      return <NoDestinations error={true} />;
    }
    if (!destinations) {
      return <Content cmsTag="GLOBAL:Loading" copytext="Loading..." />;
    }
    if (
      mobNumberNotFound ||
      !destinations.Destinations ||
      destinations.Destinations.length === 0
    ) {
      return <NoDestinations />;
    }
    destinations.Destinations.forEach((d, i) => {
      if (d.includes('@')) {
        numbers.push(
          <Radio
            autoFocus={i === 0}
            label={`Send the one time passcode to my email ${d
              .split('@')[0]
              .slice(0, 2)}**@${d.split('@')[1]}`}
            key={`destination-${Date.now()}-${i}`}
            id={`destination-${Date.now()}-${i}`}
            field="destination"
            onChange={this.onChange}
            validation={form.destination}
            value={d}
          />,
        );
      } else {
        numbers.push(
          <Radio
            autoFocus={i === 0}
            label={`Send the one time passcode to my mobile ending ${d.substring(
              d.length - 3,
            )}`}
            key={`destination-${i}`}
            id={`destination-${i}`}
            field="destination"
            onChange={this.onChange}
            validation={form.destination}
            value={d}
          />,
        );
      }
    });

    return (
      <>
        <form onSubmit={this.continue}>
          <RadioGroup
            id="twofa-select-destination"
            label="Select a destination"
            labelClassName="sr-only"
            cmsTag="GLOBAL:Select-a-destination"
          >
            {numbers}
          </RadioGroup>
          {this.getErrorMessage()}
          {page === 'activate' ? (
            <>
              <div className="form-button-group">
                <Button
                  color="primary"
                  type="submit"
                  id="select-destination-submit"
                  disabled={this.disabled}
                >
                  <Content
                    cmsTag="Two-factor:Destinations:Continue"
                    copytext="Send passcode"
                  />
                </Button>
                <Link
                  id="twofa-btn-cancel"
                  className="btn btn-secondary"
                  role="button"
                  to="/sign-in/step-1"
                >
                  <Content
                    id="twofa-btn-cancel"
                    copytext="Cancel"
                    cmsTag="GLOBAL:Cancel"
                  />
                </Link>
              </div>
            </>
          ) : (
            ''
          )}
          {page === 'resend' ? (
            <>
              <div className="form-button-group">
                <Button color="primary" type="submit" id="resend-Passcode">
                  <Content
                    cmsTag="GLOBAL:Resend:Passcode"
                    copytext="Resend Passcode"
                  />
                </Button>
                <Link
                  id="expired-challenge-cancel"
                  className="btn btn-secondary"
                  role="button"
                  to="/sign-in/step-1"
                >
                  <Content cmsTag="GLOBAL:Resend:Cancel" copytext="Cancel" />
                </Link>
              </div>
            </>
          ) : (
            ''
          )}
        </form>
      </>
    );
  }
}

DestinationAuth.propTypes = {
  destinations: PropTypes.any,
  error: PropTypes.any,
  finishTwoFactor: PropTypes.any,
  getDestinations: PropTypes.any,
  resetDestinations: PropTypes.any,
  sendChallenge: PropTypes.any,
  page: PropTypes.any,
  mobNumberNotFound: PropTypes.any,
  setMobNumberNotFound: PropTypes.any,
  setDestination: PropTypes.func,
  setResend: PropTypes.any,
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps,
    ...mapDispatchToProps_auth,
  },
)(DestinationAuth);
