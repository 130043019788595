import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Session, Connection } from '@myie/interact';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { sessionTimer } from '@myie/interact-dom';
import { mapDispatchToProps } from '@myie/interact-shared';
import { mapDispatchToProps as mapDispatchToProps2FA } from '@myie/interact-two-factor';

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    const { location } = props;
    this.state = { pathname: location.pathname };
  }

  componentWillUnmount() {}

  componentDidMount() {
    if (Session.isAuthenticated()) {
      sessionTimer(this.props, true);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      Session.isAuthenticated() &&
      prevState.pathname !== nextProps.location.pathname &&
      nextProps.computedMatch.url === nextProps.location.pathname
    ) {
      const lastRequestTime = new Date().getTime();
      window.sessionStorage.setItem('lastRequestTime', lastRequestTime);
      fetch(Connection.baseUrl('utility') + `v1.0/ping`, {
        method: 'get',
        headers: Connection.headers(),
      });
      return { ...prevState, pathname: nextProps.location.pathname };
    }
    return null;
  }

  render() {
    const { component: Component, render, ...rest } = this.props;
    if (Session.isAuthenticated()) {
      if (render) {
        return <Route {...rest} render={render} />;
      } else {
        return <Route {...rest} render={props => <Component {...props} />} />;
      }
    }

    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: '/mgr-sign-in',
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }
}

PrivateRoute.propTypes = {
  Component: PropTypes.any,
  component: PropTypes.any,
  computedMatch: PropTypes.any,
  location: PropTypes.any,
  render: PropTypes.any,
};

export default withRouter(
  connect(
    null,
    { ...mapDispatchToProps2FA, ...mapDispatchToProps },
  )(PrivateRoute),
);
