import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Form, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Validate } from '@myie/interact';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-user-management';
import AboutYou from './AboutYou';
import YourAddress from './YourAddress';
import CorrespondenceAddress from './CorrespondenceAddress';
import ContactPreferences from './ContactPreferences';
import Confirmation from './Confirmation';
import approve from 'approvejs';
import { Content } from '@myie/interact-dom';

const buildingNameNumberValidate = {
  expects: ['comparison', 'field'],
  message: 'Please enter a date greater than payment start date',
  validate: function(value, pars) {
    const { comparison } = pars;

    if (typeof comparison === 'function') {
      if (value.length > 0) {
        return true;
      } else {
        return comparison().length > 0;
      }
    }

    if (value.length > 0) {
      return true;
    } else {
      return comparison().length > 0;
    }
  },
};

approve.addTest(buildingNameNumberValidate, 'buildingNameNumberValidate');

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: null,
      contactOptions: null,
      message: null,
      stage: 'Edit',
      aboutYouForm: null, // Form is defined by child component
      yourAddressForm: null, // Form is defined by child component
      correspondenceAddressForm: null, // Form is defined by child component
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { userDetails, contactOptions, updateUserDetailSuccess } = nextProps;

    if (updateUserDetailSuccess && updateUserDetailSuccess.ResponseStatus) {
      return {
        ...prevState,
        stage: 'Success',
      };
    }

    if (userDetails && contactOptions) {
      return {
        ...prevState,
        userDetails,
        contactOptions,
      };
    } else {
      return null;
    }
  }

  componentDidMount() {
    const {
      fetchUserDetailsIfNeeded,
      fetchContactOptionsIfNeeded,
    } = this.props;
    let request = {
      ExtendedProperties: {},
    };
    fetchUserDetailsIfNeeded(request);
    fetchContactOptionsIfNeeded(request);
  }

  setForm = (form, data) => {
    this.setState({ [form]: data });
  };

  onEvent = (form, e) => {
    const { name, value } = e.target;
    let childForm = this.state[form];
    childForm = Validate.input(name, value, childForm, true);
    this.setState({
      ...this.state,
      [form]: childForm,
    });
  };

  addressOnEvent = (form, e) => {
    const { name, value } = e.target;
    let childForm = this.state[form];
    childForm = Validate.input(name, value, childForm, true);
    if (name === 'BuildingNumber') {
      childForm = Validate.input(
        'BuildingName',
        childForm.BuildingName.value,
        childForm,
        true,
      );
    }
    if (name === 'BuildingName') {
      childForm = Validate.input(
        'BuildingNumber',
        childForm.BuildingNumber.value,
        childForm,
        true,
      );
    }

    this.setState({
      ...this.state,
      [form]: childForm,
    });
  };

  saveDataContactOptions = (name, value) => {
    const { contactOptions } = this.state;
    contactOptions[name] = value;
    this.setState({ ...this.state, contactOptions, message: null });
  };

  submitData = () => {
    let {
      userDetails,
      aboutYouForm,
      yourAddressForm,
      correspondenceAddressForm,
      contactOptions,
    } = this.state;
    const { updateUserDetails } = this.props;
    let userDetailsRequest = {
      Title: userDetails.Title,
      MiddleName: userDetails.MiddleName,
      Surname: userDetails.Surname,
      EmailAddress: aboutYouForm.EmailAddress.value,
      PrimaryContactNumber: userDetails.PrimaryContactNumber,
      Postcode: userDetails.Postcode,
      LastUpdated: userDetails.Surname,
      HomeNumber: userDetails.HomeNumber,
      WorkNumber: userDetails.WorkNumber,
      MobileNumber: userDetails.MobileNumber,
      Address: {
        Organisation: 'userDetails.Address.Organisation',
        PoBox: 'userDetails.Address.PoBox',
        SuiteNumber: 'userDetails.Address.SuiteNumber',
        FlatNumber: 'userDetails.Address.FlatNumber',
        BuildingNumber: yourAddressForm.BuildingNumber.value,
        BuildingName: yourAddressForm.BuildingName.value,
        AddressLine1: yourAddressForm.AddressLine1.value,
        AddressLine2: yourAddressForm.AddressLine2.value,
        Street: 'userDetails.Address.Street',
        VillageOrDistrict: 'userDetails.Address.VillageOrDistrict',
        TownOrCity: 'userDetails.Address.TownOrCity',
        PostTown: yourAddressForm.PostTown.value,
        Country: yourAddressForm.County.Country,
        PostCode: yourAddressForm.County.PostCode,
        CheckedPostCode: 'userDetails.Address.CheckedPostCode',
        HomeStatus: 'userDetails.Address.HomeStatus',
      },
      CorrespondenceAddress: {
        Organisation: 'userDetails.Address.Organisation',
        PoBox: 'userDetails.Address.PoBox',
        SuiteNumber: 'userDetails.Address.SuiteNumber',
        FlatNumber: 'userDetails.Address.FlatNumber',
        BuildingNumber: correspondenceAddressForm.BuildingNumber.value,
        BuildingName: correspondenceAddressForm.BuildingName.value,
        AddressLine1: correspondenceAddressForm.AddressLine1.value,
        AddressLine2: correspondenceAddressForm.AddressLine2.value,
        Street: 'userDetails.Address.Street',
        VillageOrDistrict: 'userDetails.Address.VillageOrDistrict',
        TownOrCity: 'userDetails.Address.TownOrCity',
        PostTown: correspondenceAddressForm.PostTown.value,
        Country: correspondenceAddressForm.County.Country,
        PostCode: correspondenceAddressForm.County.PostCode,
        CheckedPostCode: 'userDetails.Address.CheckedPostCode',
        HomeStatus: 'userDetails.Address.HomeStatus',
      },
      ExtendedProperties: {},
    };
    let contactOptionsRequest = {
      Options: contactOptions.Options,
      ExtendedProperties: {},
    };
    updateUserDetails(userDetailsRequest, contactOptionsRequest);
  };

  submit = e => {
    e.preventDefault();
    let {
      aboutYouForm,
      yourAddressForm,
      correspondenceAddressForm,
    } = this.state;
    aboutYouForm = Validate.form(aboutYouForm);
    yourAddressForm = Validate.form(yourAddressForm);
    correspondenceAddressForm = Validate.form(correspondenceAddressForm);
    this.setState({
      ...this.state,
      aboutYouForm,
      yourAddressForm,
      correspondenceAddressForm,
    });
    if (
      !aboutYouForm.approved ||
      !yourAddressForm.approved ||
      !correspondenceAddressForm.approved
    ) {
      return;
    }
    this.submitData();
  };

  render() {
    let {
      message,
      userDetails,
      contactOptions,
      aboutYouForm,
      yourAddressForm,
      correspondenceAddressForm,
    } = this.state;
    const { updateUserDetailSuccess } = this.props;
    const { stage } = this.state;
    let alert;
    if (!userDetails) {
      return null;
    }
    if (message) {
      alert = (
        <Alert id="edit-personal-details-alert" color="danger" role="alert">
          {message}
        </Alert>
      );
    } else {
      alert = '';
    }
    if (stage === 'Success') {
      return <Confirmation updateUserDetailSuccess={updateUserDetailSuccess} />;
    } else {
      return (
        <div>
          <h1>
            <Content id="title" copytext="Personal details" />
          </h1>
          {alert ? alert : ''}
          <p>
            <strong>
              <Content
                id="description"
                copytext="Are your contact details below up-to-date?"
              />
            </strong>
          </p>
          <Form
            autoComplete="off"
            id="edit-personal-details"
            onSubmit={this.submit}
          >
            <AboutYou
              userDetails={userDetails}
              aboutYouForm={aboutYouForm}
              setForm={this.setForm}
              onEvent={this.onEvent}
            />
            <YourAddress
              userDetails={userDetails}
              yourAddressForm={yourAddressForm}
              addressOnEvent={this.addressOnEvent}
              setForm={this.setForm}
              onEvent={this.onEvent}
            />
            <CorrespondenceAddress
              userDetails={userDetails}
              correspondenceAddressForm={correspondenceAddressForm}
              addressOnEvent={this.addressOnEvent}
              setForm={this.setForm}
              onEvent={this.onEvent}
            />
            <ContactPreferences
              contactOptions={contactOptions}
              saveDataContactOptions={this.saveDataContactOptions}
            />
            <div className="form-button-group">
              <Button id="edit-details-submit" type="submit" color="primary">
                <Content id="save-changes" copytext="Save Changes" />
              </Button>
              <Link
                id="edit-details-cancel"
                className="btn btn-secondary"
                role="button"
                to="/personal-details/"
              >
                <Content id="go-back" copytext="Back to personal details" />
              </Link>
            </div>
          </Form>
        </div>
      );
    }
  }
}

Edit.propTypes = {
  contactOptions: PropTypes.any,
  fetchContactOptionsIfNeeded: PropTypes.any,
  fetchUserDetailsIfNeeded: PropTypes.any,
  history: PropTypes.any,
  resetContactOptions: PropTypes.any,
  resetUserDetails: PropTypes.any,
  setContactOptions: PropTypes.any,
  setUserDetails: PropTypes.any,
  updateUserDetails: PropTypes.any,
  userDetails: PropTypes.any,
  updateUserDetailSuccess: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Edit);
