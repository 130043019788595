import { Session } from '@myie/interact';
import { appUtility } from '@myie/interact-dom';
import TimeOut from './timeout';

const sessionTimer = function(props, check = false) {
  if (Session.isAuthenticated()) {
    const timeoutObj = {
      history: props.history,
      userLogout: props.userLogout,
      message: 'SessionTimeout',
      url: '/signed-out',
      beforeTimeout: function() {
        appUtility.userSignout();
      },
      onTimeout: function() {
        window.sessionStorage.setItem('timeoutMessage', 'SignedOut');
      },
      showTimeoutModal: props.showTimeoutModal,
      type: 'VALID_SESSION',
    };
    const timeout = new TimeOut(timeoutObj);
    timeout.startTimer(check);
  }
};

export default sessionTimer;
