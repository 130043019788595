import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, BreadcrumbItem, Breadcrumb, Card } from 'reactstrap';
import { Redirect, Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { ErrorPanel, CommonError } from '@myie/interact-dom';
import { mapDispatchToProps } from '@myie/interact-authentication';
import { mapDispatchToProps as mapSharedDispatchToProps } from '@myie/interact-shared';
import { mapDispatchToProps as ccbAuthenticationDispatch } from '@myie/interact-ccb-authentication';
import { Session, Validate, Utility } from '@myie/interact';
import { Content, Switch, Text, AppMeta } from '@myie/interact-dom';

class UpdateLogin extends React.Component {
  constructor(props) {
    super(props);
    if (!props.login) {
      return;
    }
    let contextStr;
    props.login.SignInTicket.Data.CredentialStates.forEach(element => {
      if (element.Name === 'Pin') {
        contextStr = element.Context.replace(/'/g, '"');
      }
    });
    if (!contextStr) {
      this.props.history.push('/error');
    }

    const context = JSON.parse(contextStr);
    const pin = context.Positions;
    this.state = {
      numberStatus: '',
      count: 0,
      form: {
        pin: [
          {
            value: '',
            state: {},
            rules: {
              stop: true,
              title: `Position ${pin[0] + 1}`,
              required: {
                message: 'Please enter the 3 digits requested above',
                template: {
                  position: pin[0] + 1,
                },
              },
              format: {
                regex: /^[0-9]{1}$/,
                message: 'Please enter a valid memorable number',
                template: {
                  position: pin[0] + 1,
                },
              },
            },
          },
          {
            value: '',
            state: {},
            rules: {
              title: `Position ${pin[1] + 1}`,
              stop: true,
              required: {
                message: 'Please enter the 3 digits requested above',
                template: {
                  position: pin[1] + 1,
                },
              },
              format: {
                regex: /^[0-9]{1}$/,
                message: 'Please enter a valid memorable number',
                template: {
                  position: pin[1] + 1,
                },
              },
            },
          },

          {
            value: '',
            state: {},
            rules: {
              title: `Position ${pin[2] + 1}`,
              stop: true,
              required: {
                message: 'Please enter the 3 digits requested above',
                template: {
                  position: pin[2] + 1,
                },
              },
              format: {
                regex: /^[0-9]{1}$/,
                message: 'Please enter a valid memorable number',
                template: {
                  position: pin[2] + 1,
                },
              },
            },
          },
        ],
      },
    };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const {
      login,
      setupcredentialsActivate,
      credentialsActivate,
      isFetching,
    } = nextProps;
    if (credentialsActivate && credentialsActivate.Status === 'Success') {
      Session.set(
        credentialsActivate.SessionTicket,
        credentialsActivate.Customer,
      );
      return {
        numberStatus: 'ActivationSuccess',
      };
    }
    if (login) {
      if (
        login.Status === 'RequireActivation' &&
        !isFetching &&
        !prevState.activationSent
      ) {
        if (login.RequireActivationTicket) {
          setupcredentialsActivate({
            RequireActivationTicket:
              login && login.RequireActivationTicket
                ? login.RequireActivationTicket
                : '',
          });
          return {
            activationSent: true,
          };
        }
      }
      if (prevState.count === 3 && login.Status === 'Failed') {
        return {
          numberStatus: 'warning',
        };
      }
      if (login.Status !== 'Failed') {
        return {
          numberStatus: login.Status,
          count: 0,
        };
      }
      if (prevState.count === 3 && prevState.numberStatus === 'warning') {
        return null;
      }
      return {
        numberStatus: login.Status,
      };
    }
    return null;
  };

  componentDidMount = () => {
    this.props.timeout.startTimer();
  };

  componentWillUnmount = () => {
    this.props.timeout.clearTimer();
  };

  numberSuffix = number => {
    switch (number % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  onChange = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  submit = e => {
    e.preventDefault();
    const { updateLogin, login } = this.props;
    var { form, count } = this.state;
    let countValue = count;
    form = Validate.form(form);
    if (form.approved) {
      countValue = count + 1;
    }
    this.setState({ ...this.state, form, count: countValue });
    if (!form.approved) {
      return;
    }
    const request = {
      Ticket: login.SignInTicket,
      CredentialValues: [
        {
          Name: 'Pin',
          Value: form.pin[0].value + form.pin[1].value + form.pin[2].value,
          Context: null,
        },
      ],
      ExtendedProperties: null,
    };
    updateLogin(request);
  };

  render() {
    const { rememberme, remembermeUsername, credentialsActivate } = this.props;
    if (!this.props.login) {
      return <Redirect to="/sign-in/step-1" />;
    }
    const { numberStatus, activationSent } = this.state;
    var pin = [];
    var error;
    const login = this.props.login;
    if (login) {
      switch (numberStatus) {
        case 'Success':
          if (rememberme) {
            localStorage.setItem(
              'rememberMe',
              Utility.hexEncode(remembermeUsername),
            );
          } else {
            localStorage.removeItem('rememberMe');
          }
          Session.set(login.SessionTicket, login.Customer);
          return <Redirect to={this.props.referrer.pathname} />;
        case 'ActivationSuccess': {
          if (rememberme) {
            localStorage.setItem(
              'rememberMe',
              Utility.hexEncode(remembermeUsername),
            );
          } else {
            localStorage.removeItem('rememberMe');
          }
          Session.set(
            credentialsActivate.SessionTicket,
            credentialsActivate.Customer,
          );

          if (localStorage.getItem('firstLogin'))
            return <Redirect to="/accounts" />;

          if (activationSent) {
            localStorage.setItem('firstLogin', true);
            return <Redirect to="/accounts" />;
          }
          return <Redirect to={this.props.referrer.pathname} />;
        }
        case 'Failed':
          error = 'Failed';
          break;
        case 'Blocked':
          return <Redirect to="/sign-in/blocked" />;
        case 'CredentialBlocked':
          error = 'CredentialBlocked';
          break;
        case 'warning':
          error = 'warning';
          break;
        case 'Incomplete':
          error = null;
          break;
        case 'RequireActivation':
          error = null;
          break;
        case 'InvalidTicket':
          return <Redirect to="/sign-in/step-1" />;
        default:
          error = 'UnknownStatus';
      }
    }

    let contextStr;
    login.SignInTicket.Data.CredentialStates.forEach(element => {
      if (element.Name === 'Pin') {
        contextStr = element.Context.replace(/'/g, '"');
      }
    });
    if (!contextStr) {
      return <Redirect to="/error" />;
    }
    const context = JSON.parse(contextStr);
    pin = context.Positions;

    let ErrorListHandler = [];
    ErrorListHandler.push(this.state.form.pin[0]);
    ErrorListHandler.push(this.state.form.pin[1]);
    ErrorListHandler.push(this.state.form.pin[2]);

    const errorOrder = CommonError(ErrorListHandler);

    return (
      <div id="sign-in-step-2">
        <AppMeta
          id="meta-data"
          stage="child"
          title="Sign in Step 2"
          metaDescription="Sign in step 2"
        />
        <h1>
          <Content id="signin-step-two-title" copytext="Welcome" />
        </h1>
        <div className="breadcrumb-container">
          <Breadcrumb tag="nav">
            <BreadcrumbItem>
              <Content id="signin-step1" copytext="Sign in step 1" />
            </BreadcrumbItem>
            <BreadcrumbItem active>
              <Content id="signin-step2" copytext="Sign in step 2" />
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="clearfix"></div>
        <Switch
          id="login-pin-alert"
          value={error ? error : ''}
          defaultText={error ? error : ''}
          tag="div"
          className="alert alert-danger"
          template={{
            UnknownStatus: {
              loginStatus: this.props.login.Status,
            },
          }}
          contents={{
            Timeout: {
              defaultValue:
                'For security reasons we have logged you out. Please restart the registration process.',
            },
            CredentialBlocked: {
              defaultValue:
                'Please contact the CCB Helpline on 0800 1234 5678 as you have reached the maximum number of failed attempts, therefore your account is now blocked',
            },
            Failed: {
              defaultValue:
                'Please check and re-enter your memorable number as the details entered do not match the data we have stored',
            },
            warning: {
              defaultValue:
                'Please be aware, as this is your 4th attempt, you only have 1 attempt left before your account will be blocked. Please use the links below to reset your security credentials if you are unsure of your log in details.',
            },
            UnknownStatus: {
              defaultValue: 'Returned unknown status $[loginStatus]',
            },
          }}
        />
        <Form autoComplete="off" id="login-update-form" onSubmit={this.submit}>
          <Card color="light">
            <fieldset>
              <legend className="label">
                <Content
                  template={{
                    copytext: {
                      pos1: `${pin[0] + 1}${this.numberSuffix(pin[0] + 1)}`,
                      pos2: `${pin[1] + 1}${this.numberSuffix(pin[1] + 1)}`,
                      pos3: `${pin[2] + 1}${this.numberSuffix(pin[2] + 1)}`,
                    },
                  }}
                  id="pinTitle"
                  copytext="Enter the $[pos1], $[pos2], and $[pos3] characters of your memorable number."
                />
              </legend>

              <Text
                type="password"
                label="Pin position 1"
                groupClassName="d-inline"
                labelClassName="sr-only"
                className="pin-input"
                id="pin-value-1"
                field="pin[0]"
                onChange={this.onChange}
                onBlur={this.onBlur}
                autoFocus={true}
                validation={this.state.form.pin[0]}
                showErrors={false}
                maxLength="1"
              />

              <div className="pin-hyphen" aria-hidden="true">
                {' - '}
              </div>
              <Text
                type="password"
                label="Pin position 2"
                groupClassName="d-inline"
                labelClassName="sr-only"
                className="pin-input"
                id="pin-value-2"
                field="pin[1]"
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={this.state.form.pin[1]}
                showErrors={false}
                maxLength="1"
              />
              <div className="pin-hyphen" aria-hidden="true">
                {' - '}
              </div>
              <Text
                type="password"
                label="Pin position 3"
                groupClassName="d-inline"
                labelClassName="sr-only"
                className="pin-input"
                id="pin-value-3"
                field="pin[2]"
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={this.state.form.pin[2]}
                showErrors={false}
                maxLength="1"
              />
              <p className="suffix clearfix">
                <Link to="/forgotten-password">
                  <Content
                    id="forgottenWord"
                    copytext="Forgotten your memorable number?"
                  />
                </Link>
              </p>
              <div id="pin-error-group" className="clearfix">
                {errorOrder.showError ? (
                  <ErrorPanel
                    id="pin-value-1"
                    validation={this.state.form.pin[errorOrder.index]}
                    field={['pin', errorOrder.index]}
                  />
                ) : (
                  ''
                )}
              </div>
            </fieldset>
            <div className="form-button-group">
              {error === 'CredentialBlocked' ? (
                <Link
                  to="/sign-in/step-1"
                  className="btn btn-secondary"
                  role="button"
                >
                  <Content id="blocked-cancel" copytext="Home" />
                </Link>
              ) : (
                <Button
                  id="login-update-submit"
                  type="submit"
                  color="primary"
                  disabled={this.props.isFetching}
                >
                  <Content id="signin" copytext="Continue" />
                </Button>
              )}
            </div>
          </Card>
        </Form>
      </div>
    );
  }
}

UpdateLogin.propTypes = {
  history: PropTypes.any,
  login: PropTypes.any,
  referrer: PropTypes.any,
  timeout: PropTypes.any,
  updateLogin: PropTypes.any,
  rememberme: PropTypes.bool,
  remembermeUsername: PropTypes.string,
  isFetching: PropTypes.bool,
  credentialsActivate: PropTypes.any,
};

const mapStateToProps = state => {
  const { authenticationDefinition, customccbAuthenticationDefinition } = state;
  return {
    ...authenticationDefinition,
    ...customccbAuthenticationDefinition,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      ...mapDispatchToProps,
      ...mapSharedDispatchToProps,
      ...ccbAuthenticationDispatch,
    },
  )(UpdateLogin),
);
