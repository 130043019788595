import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import moment from 'moment';
import { Content } from '@myie/interact-dom';

class MaturityAlert extends React.Component {
  onDismiss = () => {
    const { closeAlert, accountKey } = this.props;
    closeAlert(accountKey);
  };

  getMessage = MaturityDate => {
    let today = moment();
    let maturity = moment(MaturityDate);

    if (maturity.isSame(today, 'day')) {
      return (
        <Content
          id="type-fixed-rate-today"
          copytext={`Your account is maturing today – you should have received your maturity options from us, if not please contact us.`}
        />
      );
    } else {
      if (maturity.diff(today, 'day') < 14) {
        return (
          <Content
            id="type-fixed-rate-2-weeks"
            copytext={`You should have received your maturity options from us, if not please contact us.`}
          />
        );
      } else {
        return (
          <Content
            id="type-fixed-rate-2-months"
            copytext={`We'll contact you 14 days before it matures.`}
          />
        );
      }
    }
  };

  render() {
    const { index, visibility, MaturityDate, alert } = this.props;
    return (
      <>
        {alert ? (
          <Alert
            color="info"
            key={index}
            aria-describedby={`system-message-${index}`}
            isOpen={visibility}
            toggle={this.onDismiss}
          >
            {this.getMessage(MaturityDate)}
          </Alert>
        ) : (
          this.getMessage(MaturityDate)
        )}
      </>
    );
  }
}

MaturityAlert.propTypes = {
  alertVisibleArray: PropTypes.any,
  closeAlert: PropTypes.any,
  accountKey: PropTypes.any,
  message: PropTypes.any,
  index: PropTypes.any,
  MaturityDate: PropTypes.any,
  visibility: PropTypes.any,
  alert: PropTypes.any,
};

export default MaturityAlert;
