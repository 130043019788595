import React, { Component } from 'react';
import { BreadcrumbItem, Breadcrumb } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Content } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { Utility } from '@myie/interact';
import moment from 'moment';
import { AppMeta } from '@myie/interact-dom';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-ccb-savings-servicing';
import Create from './Create';
import Confirmation from './Confirmation';
import CreationSuccess from './CreationSuccess';
import dateValidation from './../../utility';

class CreateNotice extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  initialState = () => {
    const { accounts, match } = this.props;
    let account;
    if (accounts) {
      account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
    } else {
      account = null;
    }
    return {
      account: account,
      stage: 'createNotice',
      withdrawDate: moment(),
      form: {},
    };
  };

  componentWillUnmount() {
    const { resetMakeWithdrawal } = this.props;
    resetMakeWithdrawal();
  }

  componentDidMount() {
    const { resetMakeWithdrawal, fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
    resetMakeWithdrawal();
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = nextProps => {
    const { makeWithdrawResponse = {} } = nextProps;

    if (
      makeWithdrawResponse &&
      makeWithdrawResponse.ResponseStatus === 'Success'
    ) {
      return {
        stage: 'CompleteNotice',
      };
    }

    return null;
  };

  componentDidUpdate(prevProps) {
    const { match, accounts } = this.props;
    if (accounts && prevProps.accounts !== accounts) {
      const account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
      this.setState({ ...this.state, account });
    }
  }

  // change the stage of the form
  setStage = stage => {
    this.setState({ ...this.state, stage, message: null });
  };

  setForm = form => {
    this.setState({ ...this.state, form, message: null });
  };

  //update the stage in state
  submitForm = (stage, form) => {
    if (stage === 'createNotice') {
      const { resetMakeWithdrawal } = this.props;
      resetMakeWithdrawal();
    }

    this.setState({ ...this.state, stage, form, message: null });
  };

  getActionDate = account => {
    const { holidays } = this.props;
    const { ExtendedProperties: { NoticePeriodInDays } = {} } = account;
    const NumberOfDays = NoticePeriodInDays === 0 ? 0 : NoticePeriodInDays;
    let days = [];
    if (holidays) {
      days = holidays.split(',');
    }
    let date = moment(new Date())
      .utc()
      .add(NumberOfDays, 'days')
      .format('YYYY-MM-DD');

    while (
      dateValidation.isWeekEnd(date) ||
      dateValidation.isHoliday(date, days)
    ) {
      let createdDate = moment(date).format('YYYY-MM-DD');
      date = moment(createdDate)
        .add(1, 'days')
        .format('YYYY-MM-DD');
    }

    return date;
  };

  onSubmitWithdrawal = () => {
    const { account, form } = this.state;
    const { makeaWithdraw } = this.props;
    let formCloseAccount = form.closeAccount.value;
    let request = {
      AccountKey: account.AccountKey,
      WithdrawalInformation: {
        ActionDate: this.getActionDate(account),
        Amount: form.amount.value.replace(/,/g, ''),
        CloseAccount: false,
        PaymentReference: form.reference.value ? form.reference.value : null,
      },
    };
    if (formCloseAccount) {
      request = {
        AccountKey: account.AccountKey,
        WithdrawalInformation: {
          ActionDate: this.getActionDate(account),
          CloseAccount: true,
        },
      };
    }

    makeaWithdraw(request);
  };

  // common onChangeAccount function
  onChangeAccount = e => {
    const { resetMakeWithdrawal, fetchAccountsIfNeeded, accounts } = this.props;
    fetchAccountsIfNeeded();
    resetMakeWithdrawal();
    const { value } = e.target;
    if (accounts && accounts.Accounts) {
      const account = accounts.Accounts.find(function(element) {
        return element.AccountKey.Key === value;
      });
      this.setState({ ...this.initialState(), account });
    }
  };
  // select current stage
  currentStage = account => {
    const { accounts = {} } = this.props;
    const actionDate = this.getActionDate(account);
    switch (this.state.stage) {
      case 'createNotice':
        return (
          <Create
            setForm={this.setForm}
            submitForm={this.submitForm}
            onChangeAccount={this.onChangeAccount}
            actionDate={actionDate}
            accounts={accounts}
            account={account}
            onCancelled={this.onCancelleWithdrawal}
          />
        );
      case 'ReviewNotice':
        return (
          <Confirmation
            onSubmitWithdrawal={this.onSubmitWithdrawal}
            actionDate={actionDate}
            form={this.state.form}
            setStage={this.setStage}
            account={account}
          />
        );
      case 'CompleteNotice':
        return (
          <CreationSuccess
            account={account}
            setStage={this.setStage}
            activate={this.activate}
          />
        );

      default:
    }
  };

  onCancelleWithdrawal = () => {
    const { history } = this.props;
    const { account } = this.state;
    const key =
      (account.AccountKey &&
        account.AccountKey.Key &&
        Utility.hexEncode(account.AccountKey.Key)) ||
      '';
    history.push(`/notice-to-withdraw/${key}`);
  };

  render() {
    const { stage, account } = this.state;
    if (!account) {
      return null;
    }

    return (
      <div id="create-notice-to-withdraw">
        {stage === 'createNotice' ? (
          <>
            <AppMeta
              id="meta-data"
              stage="child"
              title="Create Notice to Withdraw"
              metaDescription="Create Notice to Withdraw"
            />
            <h1>
              <Content id="pageTitle" copytext="Notice to Withdraw" />
            </h1>
            <div className="breadcrumb-container">
              <Breadcrumb tag="nav">
                <BreadcrumbItem active={stage === 'createNotice'}>
                  <Content
                    id="notice-to-withdrawal-step1"
                    copytext="1 Withdrawal Details"
                  />
                </BreadcrumbItem>
                <BreadcrumbItem active={stage === 'ReviewNotice'}>
                  <Content
                    id="notice-to-withdrawal-step2"
                    copytext="2 Review"
                  />
                </BreadcrumbItem>
                <BreadcrumbItem active={stage === 'CompleteNotice'}>
                  <Content
                    id="notice-to-withdrawal-step3"
                    copytext="3 Confirmation"
                  />
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </>
        ) : (
          ''
        )}

        {stage === 'ReviewNotice' ? (
          <>
            <AppMeta
              id="meta-data"
              stage="child"
              title="Confirm Notice to Withdraw"
              metaDescription="Confirm Notice to Withdraw"
            />
            <h1>
              <Content id="pageTitle" copytext="Confirm notice to withdraw" />
            </h1>
            <div className="breadcrumb-container">
              <Breadcrumb tag="nav">
                <BreadcrumbItem active={stage === 'createNotice'}>
                  <Content
                    id="notice-to-withdrawal-step1"
                    copytext="1 Withdrawal Details"
                  />
                </BreadcrumbItem>
                <BreadcrumbItem active={stage === 'ReviewNotice'}>
                  <Content
                    id="notice-to-withdrawal-step2"
                    copytext="2 Review"
                  />
                </BreadcrumbItem>
                <BreadcrumbItem active={stage === 'CompleteNotice'}>
                  <Content
                    id="notice-to-withdrawal-step3"
                    copytext="3 Confirmation"
                  />
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </>
        ) : (
          ''
        )}

        {stage === 'CompleteNotice' ? (
          <>
            <AppMeta
              id="meta-data"
              stage="child"
              title="Notice to withdraw creation successful"
              metaDescription="Notice to withdraw creation successful"
            />
            <h1>
              <Content
                id="pageTitle"
                copytext="Notice to withdraw creation successful"
              />
            </h1>
            <div className="breadcrumb-container">
              <Breadcrumb tag="nav">
                <BreadcrumbItem active={stage === 'createNotice'}>
                  <Content
                    id="notice-to-withdrawal-step1"
                    copytext="1 Withdrawal Details"
                  />
                </BreadcrumbItem>
                <BreadcrumbItem active={stage === 'ReviewNotice'}>
                  <Content
                    id="notice-to-withdrawal-step2"
                    copytext="2 Review"
                  />
                </BreadcrumbItem>
                <BreadcrumbItem active={stage === 'CompleteNotice'}>
                  <Content
                    id="notice-to-withdrawal-step3"
                    copytext="3 Confirmation"
                  />
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </>
        ) : (
          ''
        )}

        {this.currentStage(account)}
      </div>
    );
  }
}

CreateNotice.propTypes = {
  accounts: PropTypes.any,
  withdrawalAccount: PropTypes.object,
  isFetching: PropTypes.bool,
  history: PropTypes.any,
  onCreate: PropTypes.func,
  match: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.func,
  resetMakeWithdrawal: PropTypes.func,
  makeaWithdraw: PropTypes.func,
  holidays: PropTypes.any,
  account: PropTypes.any,
};

const mapStateToProps = state => {
  const { accountsDefinition, customccbSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...customccbSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
)(withRouter(CreateNotice));
