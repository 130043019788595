import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '35.901', height = '35.907' }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 35.913 30.473"
  >
    <g>
      <path
        className={fill}
        d="M6.987,16.602v10.831c0,0.707,0.121,1.316,0.672,1.856
   C8.208,29.828,8.804,30,9.525,30l4.39,0.229l-0.021-8.756h8.093v9h4.431c0.721,0,1.318-0.408,1.867-0.947
   c0.551-0.54,0.702-1.386,0.702-2.093V16.602L17.985,5.461L6.987,16.602z"
      />
      <path
        className="secondary-fill"
        d="M20.951,1.16c-0.764-0.754-1.83-1.515-2.994-1.515
   c-1.163,0-2.229,0.908-2.991,1.658L0,16.473h4.085L16.964,3.107c0.256-0.249,0.6-0.695,0.993-0.696
   c0.394,0.001,0.737,0.595,0.996,0.847l12.876,13.214h4.084L20.951,1.16z"
      />
    </g>
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
