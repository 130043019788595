import React from 'react';
import { Col, Row } from 'reactstrap';
import { Content, FormattedCurrency, FormattedDate } from '@myie/interact-dom';
import PropTypes from 'prop-types';

const WithdrawalAccount = ({ account, withdrawDate }) => {
  const { ExtendedProperties: { NoticePeriodInDays } = {} } = account;

  const noticePeriod = NoticePeriodInDays ? `${NoticePeriodInDays} Days` : '';

  return (
    <div className="withdraw-account-details" title="Account details">
      <Row tag="dl">
        <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
          <Content id="withdrawal-acc-no" copytext="Account number" />
        </Col>
        <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
          {account.AccountNumber}
        </Col>
        <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
          <Content id="balance" copytext="Available Balance" />
        </Col>
        <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
          <FormattedCurrency
            quantity={account.RemainingAllowance}
            currency={account.CurrencyCode}
          />
        </Col>
        {account.brandProductType === 'NOTICE_ACCOUNT' ? (
          <>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content id="notice-period" copytext="Notice Period" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {noticePeriod}
            </Col>
          </>
        ) : null}
        <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
          <Content id="withdraw-date" copytext="Withdraw date" />
        </Col>
        <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
          <FormattedDate date={withdrawDate} />
        </Col>
      </Row>
    </div>
  );
};

WithdrawalAccount.propTypes = {
  account: PropTypes.object,
  withdrawDate: PropTypes.any,
};

export default WithdrawalAccount;
