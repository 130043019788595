import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-authentication';
import { Redirect } from 'react-router-dom';
import PersonalDetails from '../shared/PersonalDetails';
import UserNameReminder from './UserNameReminder';
import { Switch, Content, AppMeta } from '@myie/interact-dom';
import { Session } from '@myie/interact';

class Retrieve extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'PersonalDetails',
      message: null,
      data: {},
    };
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = prevProps => {
    const { retrivedUserName = {}, resetForgottenUN } = prevProps;
    if (retrivedUserName.Status === 'IdentifyAndVerifyFailed') {
      resetForgottenUN();
      return {
        stage: 'PersonalDetails',
        message: 'IdentifyAndVerifyFailed',
      };
    }
    if (retrivedUserName.Status === 'NotRegistered') {
      resetForgottenUN();
      return {
        data: {},
        stage: 'PersonalDetails',
        message: 'NotRegistered',
      };
    }
    return null;
  };

  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };

  // change the stage of the form
  setStage = stage => {
    this.setState({ ...this.state, stage, message: null });
  };

  // verify the identification and verfication entries
  verify = () => {
    const { data } = this.state;
    const { retrieveUserName } = this.props;

    // personal data
    const request = {
      Entries: [
        {
          Type: 'firstName',
          Value: data.firstname,
        },
        {
          Type: 'lastName',
          Value: data.lastname,
        },
        {
          Type: 'dateOfBirth',
          Value: data.b_day,
        },
        {
          Type: 'postCode',
          Value: data.postcode,
        },
        {
          Type: 'accountNumber',
          Value: data.account_number,
        },
      ],
      ExtendedProperties: {},
    };
    retrieveUserName(request);
  };

  // select current stage
  currentStage = () => {
    switch (this.state.stage) {
      case 'PersonalDetails':
        return (
          <PersonalDetails
            verify={this.verify}
            saveData={this.saveData}
            setStage={this.setStage}
          />
        );
      default:
    }
  };

  render() {
    const { retrivedUserName = {} } = this.props;
    if (Session.isAuthenticated()) {
      Session.abandon(null);
    }
    const isNonSignatoryUser = retrivedUserName?.ExtendedProperties?.IdentityResponse?.CredentialErrors?.includes(
      'NonSignatory',
    );
    if (isNonSignatoryUser) {
      return <Redirect to="/forgotten-username/blocked" />;
    }
    /* To be replaced by HOC */
    switch (retrivedUserName.Status) {
      case 'Blocked':
        return <Redirect to="/forgotten-username/blocked" />;
      case 'Success':
        return <UserNameReminder username={retrivedUserName.Username} />;
      default:
    }
    const { message } = this.state;

    return (
      <div id="forgotten-un-retrieve">
        <AppMeta
          id="meta-data"
          stage="child"
          title="Forgotten username Step 1"
          metaDescription="Forgotten username step 1"
        />
        <h1>
          <Content id="title" copytext="Forgotten username" />
        </h1>
        <div className="breadcrumb-container">
          <Breadcrumb tag="nav">
            <BreadcrumbItem active={this.state.stage === 'PersonalDetails'}>
              <Content
                id="personalDetails-step1"
                copytext="1 Personal details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active={this.state.stage === 'AccountDetails'}>
              <Content id="accountDetails-step2" copytext="2 Account details" />
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="clearfix"></div>
        <p className="lead">
          <Content
            id="personalDetails-para"
            copytext="Please provide a few details and we’ll display your username."
          />
        </p>
        <Switch
          id="registration-alert"
          value={message || ''}
          tag="div"
          className="alert alert-danger"
          contents={{
            NotRegistered: {
              defaultValue:
                'You have not yet registered on the site. Please try again.',
            },

            IdentifyAndVerifyFailed: {
              defaultValue:
                'The details you have entered do not match our records. Please try again.',
            },
          }}
        />
        {this.currentStage()}
      </div>
    );
  }
}

Retrieve.propTypes = {
  retrieveUserName: PropTypes.any,
  retrivedUserName: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Retrieve);
