import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '21.117', height = '32.197' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 21.117 32.197"
    viewBox="0 0 21.117 32.197"
  >
    <path
      className={fill}
      d="M21.117 27.328v4.869H0V28.67c3.18-1.59 5.267-4.621 5.267-8.248 0-.696-.1-1.342-.199-2.037H.1v-3.429l3.329-.248h.596c-.547-1.739-1.043-3.429-1.043-5.068C2.981 3.677 7.056 0 12.919 0c3.628 0 6.161 1.49 8.147 3.875l-3.179 3.13c-1.242-1.441-2.584-2.335-4.472-2.335-3.081 0-4.919 1.938-4.919 5.267 0 1.64.397 3.181.845 4.771h7.652v3.677h-6.807c.099.695.149 1.341.149 2.087 0 2.932-.845 4.77-2.634 6.658v.198h13.416z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
