import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-ccb-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Content } from '@myie/interact-dom';
import { Link } from 'react-router-dom';
import { Utility } from '@myie/interact';

class CreationSuccess extends Component {
  componentDidMount() {
    const { resetCreateNoticeToWithdraw } = this.props;
    resetCreateNoticeToWithdraw();
  }

  render() {
    const { account = {} } = this.props;
    if (!account) {
      return null;
    }

    return (
      <>
        <p>
          <Content
            id="success-message"
            copytext="You have successfully created Notice to Withdraw"
          />
        </p>
        <div className="form-button-group">
          <Link
            id="back-to-notice"
            className="btn btn-primary"
            role="button"
            to={`/notice-to-withdraw/${Utility.hexEncode(
              account.AccountKey.Key,
            )}`}
          >
            <Content
              id="back-to-notice-to-withdraw2"
              copytext="Back to notice to withdraw"
            />
          </Link>
        </div>
      </>
    );
  }
}

CreationSuccess.propTypes = {
  resetCreateNoticeToWithdraw: PropTypes.func,
  match: PropTypes.any,
  account: PropTypes.any,
};

export default connect(
  null,
  mapDispatchToProps,
)(CreationSuccess);
