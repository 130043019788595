import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

/**
 * RoundBadge component
 * will display a rounded badge
 */
class RoundBadge extends React.Component {
  render() {
    const { children, ...rest } = this.props;
    return (
      <Badge className={rest.className + ' round'} {...rest}>
        {children}
      </Badge>
    );
  }
}

RoundBadge.propTypes = {
  /**
   * Text to display on the badge
   */
  children: PropTypes.string,
};

export default RoundBadge;
