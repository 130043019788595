import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-messaging-twoway';
import DeleteConversation from './Delete';
import ReplyConfirmation from './Confirmation';
import { Utility } from '@myie/interact';
import PropTypes from 'prop-types';

class DeleteIndex extends React.Component {
  constructor(props) {
    super(props);
    const {
      viewConversation,
      viewMessage,
      history,
      match,
      allMessages,
    } = this.props;
    let conversation;
    this.state = {
      stage: 'Delete',
      message: null,
      data: {},
    };

    if (allMessages) {
      if (!viewMessage) {
        conversation = allMessages.Conversations.find(function(element) {
          if (element) {
            return (
              Utility.hexEncode(element.ConversationKey.Key) === match.params.id
            );
          }
          return false;
        });

        let request = {
          ConversationKey: conversation.ConversationKey,
          ProductType: 'string',
          ExtendedProperties: {},
        };
        viewConversation(request);
      }
    } else {
      const newUrl = '/messaging-twoway/view';
      history.push(newUrl);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.deleteMessageStatus && prevState.stage === 'Delete') {
      return { ...prevState, stage: 'Confirmation' };
    } else return null;
  }

  // save the data for the request from several forms
  saveReplyData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };

  // change the stage of the form
  setReplyStage = stage => {
    const { data } = this.state;
    this.setState({ ...this.state, stage, data, message: null });
  };

  // select current stage
  currentStage = () => {
    const { stage } = this.state;
    const { match } = this.props;

    switch (stage) {
      case 'Delete':
        return (
          <DeleteConversation
            match={match}
            saveReplyData={this.saveReplyData}
            setReplyStage={this.setReplyStage}
            transactionData={this.state.data}
          />
        );
      case 'Confirmation':
        return (
          <ReplyConfirmation
            saveReplyData={this.saveReplyData}
            setReplyStage={this.setReplyStage}
            transactionData={this.state.data}
            verify={this.verify}
          />
        );
      default:
    }
  };

  render() {
    const { error } = this.props;
    let alert;

    const { message } = this.state;
    if (message || error) {
      alert = (
        <Alert id="delete-message-alert" color="danger" role="alert">
          {message || error}
        </Alert>
      );
    }

    return (
      <div id="messaging-twoway-delete">
        <Row>
          {alert ? <Col xs={12}>{alert}</Col> : ''}
          <Col xs={12}>{this.currentStage()}</Col>
        </Row>
      </div>
    );
  }
}

DeleteIndex.propTypes = {
  accounts: PropTypes.any,
  allMessages: PropTypes.any,
  deleteMessageStatus: PropTypes.any,
  error: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  history: PropTypes.any,
  initializeMoveMoney: PropTypes.any,
  match: PropTypes.any,
  retrivedUserName: PropTypes.object,
  transfer: PropTypes.any,
  viewConversation: PropTypes.any,
  viewMessage: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteIndex);
