import React from 'react';
import PropTypes from 'prop-types';
import contentManaged, { ContentType } from '../HO_components/contentManaged';
import { Errors } from '@myie/interact-dom';
import { HasErrors } from './Errors';
/**
 * RadioGroup component
 */
class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
    const {
      label,
      subLabel,
      description,
      suffix,
      managedContent,
      validation,
      id,
    } = props;
    managedContent({
      label: { defaultValue: label },
      subLabel: { defaultValue: subLabel },
      description: { defaultValue: description },
      suffix: { defaultValue: suffix },
      validation: validation,
      id: { defaultValue: id },
    });
  }

  render() {
    const { props } = this;

    const {
      children,
      groupClassName = '',
      labelClassName = '',
      contentValue,
      managedContent,
      disabled,
      required,
      validation = {},
      showErrors = true,
      field,
      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const label = contentValue(ContentType.Text, 'label');
    const subLabel = contentValue(ContentType.Text, 'subLabel');
    const description = contentValue(ContentType.Text, 'description');
    const suffix = contentValue(ContentType.Text, 'suffix');
    return (
      <fieldset
        className={`${groupClassName} ${disabled ? 'disabled-input' : ''} ${
          required ? 'required-input' : ''
        }`.trim()}
      >
        <legend
          className={`${labelClassName} ${
            !HasErrors(validation) ? 'is-invalid' : ''
          } label`.trim()}
        >
          {label}
          {required && (
            <React.Fragment>
              <span aria-hidden="true">*</span>
              <span className="sr-only">(required field)</span>
            </React.Fragment>
          )}{' '}
          {subLabel.length > 0 ? <small>{subLabel}</small> : ''}
          {description.length > 0 ? (
            <span className="description">{description}</span>
          ) : (
            ''
          )}
        </legend>
        <div {...rest}>{children}</div>
        {suffix && <p className="suffix">{suffix}</p>}
        {showErrors && field ? (
          <Errors
            contentValue={contentValue}
            validation={validation}
            field={field}
          />
        ) : (
          ''
        )}
      </fieldset>
    );
  }
}

RadioGroup.propTypes = {
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  /**
   * Input required or not
   */
  required: PropTypes.bool,
  /**
   * Text input disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * Any children of the component
   */
  children: PropTypes.any,
  /**
   * Date description text
   */
  description: PropTypes.string,
  /**
   * Style class name for group
   */
  groupClassName: PropTypes.string,
  /**
   * Style class name for label
   */
  labelClassName: PropTypes.string,
  /**
   * Sub label text
   */
  label: PropTypes.any,
  /**
   * Sub label text
   */
  subLabel: PropTypes.string,
  /**
   * Suffix text
   */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  validation: PropTypes.object,
  showErrors: PropTypes.any,
  field: PropTypes.any,
  id: PropTypes.any,
};

export default contentManaged(RadioGroup);
