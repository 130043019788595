import React from 'react';
import { HelpContactSidebar } from '@myie/interact-dom';

class ConversationSidebar extends React.Component {
  render() {
    return (
      <aside className="right-container">
        <HelpContactSidebar />
      </aside>
    );
  }
}

export default ConversationSidebar;
