import { combineReducers } from 'redux';
import interactReducers from './interactCCbReducers';
import { customccbAuthenticationDefinition } from '@myie/interact-ccb-authentication';
import { customccbOrganisationDefinition } from '@myie/interact-ccb-organisation';
import { customccbSavingsServicingDefinition } from '@myie/interact-ccb-savings-servicing';

const includeCustomccbReducers = {
  ...interactReducers,
  customccbAuthenticationDefinition,
  customccbOrganisationDefinition,
  customccbSavingsServicingDefinition,
};

const appReducer = combineReducers({ ...includeCustomccbReducers });

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = { cmsDefinition: state.cmsDefinition };
  }
  return appReducer(state, action);
};
export default rootReducer;
