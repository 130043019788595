import React from 'react';
import PropTypes from 'prop-types';
import { Markdown, SideLink } from '@myie/interact-dom';
import { connect } from 'react-redux';
import { mapStateToProps } from '@myie/interact-messaging-twoway';
import { HelpContactSidebar } from '@myie/interact-dom';
import { Session } from '@myie/interact';
import { SystemMessage } from '@myie/interact-widget-system-message-dom';

class SummarySideBar extends React.Component {
  render() {
    return (
      <aside className="right-container">
        <HelpContactSidebar />
        {Session.isAuthenticated() ? <SystemMessage /> : null}
        <div className="side-links-container">
          <SideLink
            linkTitle={
              <Markdown
                id="summarySidebarMissingAccountTitle"
                markdown="Add a missing account"
              />
            }
            linkDescription={
              <Markdown
                id="summarySidebarMissingAccountDescription"
                markdown="Let us know if you can't see one of your accounts"
              />
            }
            linkURL="/messaging-twoway/"
            linkID="missing-account-sidebar-link"
            iconImage="Warning2"
          />
        </div>
      </aside>
    );
  }
}

SummarySideBar.propTypes = {
  i18n: PropTypes.any,
  newMessageCount: PropTypes.object,
};

export default connect(mapStateToProps)(SummarySideBar);

/*export default ('accounts')(
  connect(mapStateToProps)(SummarySideBar),
); */
