import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Text, Content, Markdown } from '@myie/interact-dom';

class CorrespondenceAddress extends React.Component {
  constructor(props) {
    super(props);
    const { setForm } = this.props;
    this.state = {
      message: null,
      submitForm: {},
      correspondenceAddressForm: {
        BuildingName: {
          rules: {
            title: 'BuildingName',
            stop: true,
            buildingNameNumberValidate: {
              message: 'Please enter a house name or flat number.',
              field: 'BuildingNumber',
              comparison: () => {
                return this.state.correspondenceAddressForm.BuildingNumber
                  .value;
              },
            },
          },
        },
        BuildingNumber: {
          rules: {
            title: 'BuildingNumber',
            stop: true,
            buildingNameNumberValidate: {
              message: 'Please enter a house number.',
              field: 'BuildingName',
              comparison: () => {
                return this.state.correspondenceAddressForm.BuildingName.value;
              },
            },
          },
        },
        AddressLine1: {
          rules: {
            title: 'AddressLine1',
            stop: true,
            required: {
              message: 'Please enter address line 1.',
            },
          },
        },
        AddressLine2: {
          rules: {
            title: 'AddressLine2',
            stop: true,
          },
        },
        PostTown: {
          rules: {
            title: 'townCity',
            stop: true,
            required: {
              message: 'Please enter town/city.',
            },
          },
        },
        County: {
          rules: {
            title: 'County',
            stop: true,
            required: {
              message: 'Please enter County.',
            },
            country: {
              message: 'Please enter a valid County name.',
            },
          },
        },
        PostCode: {
          rules: {
            title: 'postCode',
            stop: true,
            required: {
              message: 'Please enter postcode.',
            },
            postcode: {
              message:
                'Please enter your postcode in a valid UK postcode format.',
            },
          },
        },
      },
    };
    setForm('correspondenceAddressForm', this.state.correspondenceAddressForm);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { correspondenceAddressForm, userDetails } = nextProps;

    if (
      correspondenceAddressForm &&
      correspondenceAddressForm !== prevState.correspondenceAddressForm
    ) {
      return {
        ...prevState,
        correspondenceAddressForm,
      };
    }

    //Set userDetails
    if (
      correspondenceAddressForm &&
      userDetails !== prevState.userDetails &&
      userDetails.Address
    ) {
      correspondenceAddressForm.BuildingName.value =
        userDetails.Address.BuildingName;
      correspondenceAddressForm.BuildingNumber.value =
        userDetails.Address.BuildingNumber;
      correspondenceAddressForm.AddressLine1.value =
        userDetails.Address.AddressLine1;
      correspondenceAddressForm.AddressLine2.value =
        userDetails.Address.AddressLine2;
      correspondenceAddressForm.PostTown.value = userDetails.Address.PostTown;
      correspondenceAddressForm.County.value = userDetails.Address.County;
      correspondenceAddressForm.PostCode.value = userDetails.Address.PostCode;
      return {
        ...prevState,
        correspondenceAddressForm,
        userDetails,
      };
    } else {
      return null;
    }
  }

  onChange = e => {
    const { onEvent } = this.props;
    onEvent('correspondenceAddressForm', e);
  };

  onChangeNameNumber = e => {
    const { addressOnEvent } = this.props;
    addressOnEvent('correspondenceAddressForm', e);
  };

  onBlur = e => {
    const { onEvent } = this.props;
    onEvent('correspondenceAddressForm', e);
  };

  submit = e => {
    e.preventDefault();
  };

  render() {
    let { correspondenceAddressForm } = this.state;
    return (
      <fieldset>
        <legend className="h2">
          <Content
            id="correspondence-address.title"
            copytext="Correspondence address"
          />
        </legend>
        <Markdown
          id="correspondence-address-description"
          markdown={
            '\nIf you would like us to use a separate correspondence address to contact you, please enter it below'
          }
        />

        <Row>
          <Col sm={12} lg={6}>
            <Text
              label="House name or Flat number (if applicable)"
              id="BuildingName"
              field="BuildingName"
              onChange={this.onChangeNameNumber}
              onBlur={this.onChangeNameNumber}
              validation={correspondenceAddressForm.BuildingName}
              maxLength={50}
            />
            <Text
              label="House number (if applicable)"
              id="BuildingNumber"
              field="BuildingNumber"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={correspondenceAddressForm.BuildingNumber}
              maxLength={50}
            />
            <Text
              label="Address line 1"
              id="AddressLine1"
              field="AddressLine1"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={correspondenceAddressForm.AddressLine1}
              maxLength={100}
            />
            <Text
              label="Address line 2"
              id="AddressLine2"
              field="AddressLine2"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={correspondenceAddressForm.AddressLine2}
              maxLength={100}
            />
            <Text
              label="Town/City"
              id="PostTown"
              field="PostTown"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={correspondenceAddressForm.PostTown}
              maxLength={60}
            />
            <Text
              label="County"
              id="County"
              field="County"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={correspondenceAddressForm.County}
              maxLength={60}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} lg={4}>
            <Text
              label="Postcode"
              id="PostCode"
              field="PostCode"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={correspondenceAddressForm.PostCode}
            />
          </Col>
        </Row>
      </fieldset>
    );
  }
}

CorrespondenceAddress.propTypes = {
  addressOnEvent: PropTypes.any,
  correspondenceAddressForm: PropTypes.any,
  onEvent: PropTypes.any,
  saveDataAddressC: PropTypes.any,
  setForm: PropTypes.any,
  userDetails: PropTypes.any,
};

export default CorrespondenceAddress;
