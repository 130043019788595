import React from 'react';

const TabNavigation = (function() {
  const callback = (event, index, refs) => {
    const { tabs, panels } = refs;
    setTimeout(() => {
      panels.forEach((element, i) => {
        tabs[i].current.tabIndex = element.current.clientHeight !== 0 ? 0 : -1;
      });
    }, 250);
  };

  const panelKeyDown = (event, name, refs) => {
    const { tabs, panels } = refs;
    const key = event.which || event.keyCode;
    var keys = {
      tab: 9,
    };
    const index = refs.names.indexOf(name);
    switch (key) {
      case keys.tab:
        if (event.shiftKey) {
          if (event.target === panels[index].current) {
            event.preventDefault();
            tabs.forEach((element, i) => {
              element.current.tabIndex = index === i ? 0 : -1;
            });
            tabs[index].current.focus();
          }
        }
        break;
      default:
    }
  };

  const tabKeyDown = (event, name, refs) => {
    const { tabs, panels } = refs;
    const key = event.which || event.keyCode;
    var keys = {
      end: 35,
      home: 36,
      left: 37,
      right: 39,
      tab: 9,
      space: 32,
    };
    const index = refs.names.indexOf(name);
    switch (key) {
      case keys.home:
        event.preventDefault();
        tabs[0].current.focus();
        break;
      case keys.end:
        event.preventDefault();
        tabs[tabs.length - 1].current.focus();
        break;
      case keys.right:
        event.preventDefault();
        var rightIndex = index < tabs.length - 1 ? index + 1 : 0;
        tabs.forEach((element, i) => {
          element.current.tabIndex = rightIndex === i ? 0 : -1;
        });
        tabs[rightIndex].current.focus();
        break;
      case keys.left:
        event.preventDefault();
        var leftIndex = index > 0 ? index - 1 : tabs.length - 1;
        tabs.forEach((element, i) => {
          element.current.tabIndex = leftIndex === i ? 0 : -1;
        });
        tabs[leftIndex].current.focus();
        break;
      case keys.tab:
        if (!event.shiftKey) {
          event.preventDefault();
          panels.forEach(element => {
            element.current.focus();
          });
        }
        if (event.shiftKey) {
          callback(event, index, refs);
        }
        break;
      case keys.space:
        event.preventDefault();
        tabs[index].current.click();
        break;
      default:
    }
  };

  const init = names => {
    const t = {
      refs: {
        names: names,
        tabs: [],
        panels: [],
      },
      panelKeyDown: panelKeyDown,
      tabKeyDown: tabKeyDown,
    };

    for (let index = 0; index < names.length; index++) {
      t.refs.tabs.push(React.createRef());
      t.refs.panels.push(React.createRef());
    }
    return t;
  };

  return { init: init };
})();

export default TabNavigation;
