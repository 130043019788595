import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, Row, Col, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-authentication';
import { form } from './PersonalDetailsFormObj';
import { Text, DatePicker, Content, LinkTag } from '@myie/interact-dom';
import { Validate } from '@myie/interact';
import { mapDispatchToProps as ccbAuthenticationDispatch } from '@myie/interact-ccb-authentication';
class PersonalDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: form,
    };
  }

  onChange = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form);
    this.props.saveData(name, value);
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.props.saveData(name, value);
    this.setState({ ...this.state, form });
  };

  submit = e => {
    e.preventDefault();
    const { verify } = this.props;
    var { form } = this.state;
    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      return;
    }
    verify();
  };

  render() {
    let { form } = this.state;
    const { message, accountdetails, resetTokenAccount } = this.props;
    if (accountdetails?.Success && !form.account_number.value) {
      form = Validate.input(
        'account_number',
        accountdetails.AccountNumber,
        form,
      );
      this.props.saveData('account_number', accountdetails.AccountNumber);
      this.setState({ ...this.state, form }, () => {
        resetTokenAccount();
      });
    }
    var disabledForm = false;
    if (this.props.disableForm || this.props.isFetching) {
      disabledForm = true;
    }
    var error;
    return (
      <>
        {error ? error : ''}
        <Form
          autoComplete="off"
          id="authentication-personal-details"
          onSubmit={this.submit}
        >
          <Row>
            <Col sm={12} lg={6}>
              <Text
                label="Your first name"
                id="firstname"
                field="firstname"
                maxLength={'50'}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.firstname}
                disabled={disabledForm}
              />

              <Text
                label="Your last name"
                id="lastname"
                field="lastname"
                maxLength={'50'}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.lastname}
                disabled={disabledForm}
              />

              <DatePicker
                label="Your date of birth"
                id="b_day"
                field="b_day"
                showErrors={true}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.b_day}
                disabled={disabledForm}
              />

              <Text
                label="Your home postcode"
                id="postcode"
                field="postcode"
                maxLength={'8'}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.postcode}
                disabled={disabledForm}
              />

              <h2>
                <Content id="account-title" copytext="Account details" />
              </h2>
              <Text
                label="Your account number"
                description="This can be the number of any savings accounts you have with us"
                id="account_number"
                field="account_number"
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.account_number}
                maxLength={'8'}
                disabled={disabledForm}
              />
            </Col>
          </Row>
          <p>
            <Content
              id="terms-conditions-1"
              copytext="By continuing you confirm that you have read and accepted the "
            />
            <LinkTag
              id="terms-and-conditions-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://ccbank.co.uk/online-account-access-terms/"
            >
              <Content
                id="terms-conditions-link"
                copytext="Terms and Conditions"
              />
            </LinkTag>

            <Content id="terms-conditions-2" copytext=" and agree to the " />
            <LinkTag
              id="privacy-policy-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://ccbank.co.uk/about-us/our-commitments/privacy-notice/"
            >
              <Content id="privacy-policy" copytext="Privacy Policy" />
            </LinkTag>
          </p>
          {message ? (
            <Alert
              id="error-exists"
              color="danger"
              className="mt-4"
              role="alert"
              aria-live="assertive"
            >
              <Content
                cmsTag="credential-setup-error-exists"
                copytext="Unfortunately something  is incorrect – please scroll to the top for more details."
              />
            </Alert>
          ) : null}
          <div className="form-button-group">
            <Button
              id="personal-details-submit"
              type="submit"
              color="primary"
              disabled={disabledForm}
            >
              <Content id="continue" copytext="Continue" />
            </Button>
            {disabledForm ? (
              <Link
                id="personal-details-cancel"
                className="btn btn-secondary disabled"
                role="button"
                to="/sign-in/step-1"
                onClick={event => event.preventDefault()}
              >
                <Content id="cancel" copytext="Cancel" />
              </Link>
            ) : (
              <Link
                id="personal-details-cancel"
                className="btn btn-secondary"
                role="button"
                to="/sign-in/step-1"
              >
                <Content id="cancel" copytext="Cancel" />
              </Link>
            )}
          </div>
        </Form>
      </>
    );
  }
}

PersonalDetails.propTypes = {
  saveData: PropTypes.any,
  setStage: PropTypes.any,
  verify: PropTypes.any,
  disableForm: PropTypes.bool,
  isFetching: PropTypes.bool,
  message: PropTypes.string,
  accountdetails: PropTypes.object,
  resetTokenAccount: PropTypes.func,
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps,
    ...ccbAuthenticationDispatch,
  },
)(PersonalDetails);
