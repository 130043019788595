import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CardColumns, Button, ButtonGroup, Fade, Col, Row } from 'reactstrap';
import AccountSummary from './AccountSummary';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-accounts';
import { Markdown, AppMeta, Content, DropDown } from '@myie/interact-dom';
import { Validate } from '@myie/interact';

class AccountsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyList: [],
      shown: true,
      form: {
        company: {
          rules: {
            title: 'Filter by company',
            stop: true,
          },
          value: '',
        },
      },
    };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const elements = [];
    const keyValues = [];
    const { accounts = {}, status } = nextProps;
    let { Accounts: list = [] } = accounts;
    if (list.length > 0) {
      // status === 1 means show only closed accounts
      list.forEach(account => {
        if (
          (account.AccountStatus !== 'Closed') === !status &&
          account.ExtendedProperties.CompanyName
        ) {
          if (
            !keyValues.find(function(key) {
              return account.ExtendedProperties.CompanyName === key;
            })
          ) {
            elements.push(
              <option
                id={`company-${account.AccountNumber}-option`}
                key={`company-${account.AccountNumber}-option`}
                value={account.ExtendedProperties.CompanyName}
              >
                {account.ExtendedProperties.CompanyName}
              </option>,
            );
          }
          keyValues.push(account.ExtendedProperties.CompanyName);
        } else {
          // status === 1 means show only closed accounts
          if (status === 1 && account.ExtendedProperties.CompanyName) {
            if (
              !keyValues.find(function(key) {
                return account.ExtendedProperties.CompanyName === key;
              })
            ) {
              elements.push(
                <option
                  id={`company-${account.AccountNumber}-option`}
                  key={`company-${account.AccountNumber}-option`}
                  value={account.ExtendedProperties.CompanyName}
                >
                  {account.ExtendedProperties.CompanyName}
                </option>,
              );
            }
            keyValues.push(account.ExtendedProperties.CompanyName);
          }
        }
      });

      return {
        companyList: elements,
      };
    }

    return null;
  };

  setMode = mode => {
    const props = this.props;
    if (mode === props.mode) {
      return;
    }
    const { setListMode } = props;
    this.setState({ shown: false });
    setTimeout(
      function() {
        setListMode(mode);
        this.setState({ shown: true });
      }.bind(this),
      250,
    );
  };

  onChangeAccount = e => {
    const { value, name } = e.target;

    let { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  setStatus = status => {
    const props = this.props;
    if (status === props.status) {
      return;
    }
    let { form } = this.state;
    let companyName = form.company.value;
    if (status === 0) {
      // If now show open accounts
      // and current company doesn't have any
      // clear it out
      if (
        this.props.accounts.Accounts.filter(a => {
          return (
            a.ExtendedProperties.CompanyName === companyName &&
            a.AccountStatus !== 'Closed'
          );
        }).length === 0
      ) {
        form.company.value = '';
      }
    }
    const { setStatusMode } = props;
    this.setState({ shown: false, form });
    setTimeout(
      function() {
        setStatusMode(status);
        this.setState({ shown: true });
      }.bind(this),
      250,
    );
  };

  render() {
    let { accounts = {}, isFetching, mode, status } = this.props;
    let { Accounts: list = [] } = accounts;
    let children = [];
    let gridToggle = null;
    let statusToggle = null;
    let accountsToList = [];
    const { shown, form, companyList } = this.state;
    list.forEach(account => {
      if ((account.AccountStatus !== 'Closed') === !status) {
        accountsToList.push(account);
      }
    });
    if (accountsToList.length > 0 && form.company.value !== '') {
      accountsToList = accountsToList.filter(function(account) {
        return account.ExtendedProperties.CompanyName === form.company.value;
      });
    }

    if (accountsToList.length > 1) {
      gridToggle = (
        <ButtonGroup className="d-none d-lg-inline mr-3">
          <Button
            id="list-mode-btn"
            color="primary"
            active={!mode}
            aria-pressed={!mode}
            onClick={() => this.setMode(0)}
          >
            <Content id="list" copytext="List" />
          </Button>
          <Button
            id="grid-mode-btn"
            color="primary"
            active={!!mode}
            aria-pressed={!!mode}
            onClick={() => this.setMode(1)}
          >
            <Content id="grid" copytext="Grid" />
          </Button>
        </ButtonGroup>
      );
    } else {
      mode = 0;
    }

    if (accountsToList.length !== list.length || status === 1) {
      statusToggle = (
        <ButtonGroup className="d-inline">
          <Button
            id="filter-active-btn"
            color="primary"
            active={!status}
            aria-pressed={!status}
            onClick={() => this.setStatus(0)}
          >
            <Content id="active" copytext="Active" />
          </Button>
          <Button
            id="filter-closed-btn"
            color="primary"
            active={!!status}
            aria-pressed={!!status}
            onClick={() => this.setStatus(1)}
          >
            <Content id="closed" copytext="Closed" />
          </Button>
        </ButtonGroup>
      );
    } else {
      status = 0;
    }

    accountsToList.forEach((account, index) => {
      children.push(
        <AccountSummary
          mode={mode}
          loopIndex={index}
          account={account}
          key={account.AccountKey.Key}
          status={status}
        />,
      );
    });

    if (children.length === 0) {
      if (!isFetching) {
        const state = !status ? 'Active' : 'Closed';
        children.push(
          <p id="no-accounts-in-list" key={0}>
            <Markdown
              id="accountsList-noAccounts"
              template={{
                markdown: {
                  state: state,
                },
              }}
              markdown={`There are no $[state] accounts to view`}
            />
          </p>,
        );
      } else {
        children.push(
          <p key={0}>
            <Content id="loadingAccounts" copytext="Loading accounts" />
            {`...`}
          </p>,
        );
      }
    }

    return (
      <div id="account-list">
        <AppMeta
          id="meta-data"
          stage="child"
          title="Accounts"
          metaDescription="Accounts description"
        />
        <h1>
          <Content id="pageTitle" copytext="Accounts" />
        </h1>
        <Row>
          <Col xs={12} className="text-xs-left text-sm-right">
            <div className="account-summary-filter">
              <DropDown
                disabled={false}
                id="company"
                field="company"
                label="Filter by company"
                labelClassName="sr-only"
                onChange={this.onChangeAccount}
                validation={form.company}
              >
                <option
                  id="pay-from-item-empty"
                  value=""
                  defaultText="Filter by company"
                />

                {companyList.sort((a, b) => a.props.value > b.props.value)}
              </DropDown>
              {statusToggle} {gridToggle}
            </div>
          </Col>
        </Row>

        <Fade in={shown}>
          <CardColumns
            id="accounts-container"
            className={!mode ? 'list-mode' : 'grid-mode'}
          >
            {children}
          </CardColumns>
        </Fade>
      </div>
    );
  }
}

AccountsList.propTypes = {
  acc: PropTypes.object,
  accounts: PropTypes.object,
  history: PropTypes.any,
  isFetching: PropTypes.any,
  mode: PropTypes.any,
  setListMode: PropTypes.any,
  setStatusMode: PropTypes.any,
  status: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountsList);
