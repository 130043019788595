import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Content, ContactNumber } from '@myie/interact-dom';

class Blocked extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }

  render() {
    return (
      <div id="sign-in-blocked">
        <h1 id="sign-in-blocked-title">
          <Content id="title" copytext="Access denied" />
        </h1>
        <h2>
          <Content
            id="subTitle"
            copytext="Access to your account was denied for security reasons."
          />
        </h2>
        <Alert color="danger" role="alert">
          <p>
            <Content
              id="alertText-1"
              copytext="Please contact our call centre so that we can help."
            />
          </p>
          <p>
            <Content id="alertText-2" copytext="Call: " />
            <ContactNumber />
            <Content id="alertText-3" copytext=" (charges may apply)" />
          </p>
          <Content
            id="alertText-4"
            copytext="Calls will be charged at your standard mobile operator rate. Our lines are open from 8am to 8pm, Monday to Saturday and from 9am to 5pm on Sundays."
          />
        </Alert>

        <div className="form-button-group">
          <Link
            id="sign-in-blocked-home-button"
            className="btn btn-primary"
            role="button"
            to="/"
          >
            <Content id="homeButton" copytext="Home" />
          </Link>
        </div>
      </div>
    );
  }
}

Blocked.propTypes = {
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Blocked);
