import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, Row, Col, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { mapDispatchToProps } from '@myie/interact-authentication';
import { mapDispatchToProps as mapDispatchToProps_shared } from '@myie/interact-shared';
import { mapDispatchToProps as ccbAuthenticationDispatch } from '@myie/interact-ccb-authentication';
import { Text, Content, Switch, AppMeta } from '@myie/interact-dom';
import { Validate } from '@myie/interact';

class SecurityDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        password: {
          rules: {
            title: 'password',
            stop: true,
            required: {
              message: '	Please enter your password',
            },
            format: {
              regex: /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s$).{10,50}$/,
              message: 'Please enter a valid password',
            },
          },
        },
        confirmPassword: {
          rules: {
            title: 'confirm password',
            stop: true,
            required: {
              message: 'Please confirm your password',
            },
            format: {
              regex: /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s$).{10,50}$/,
              message: 'Please enter a valid value for Confirm your password',
            },
            compare: {
              message:
                'Your passwords do not match. Please check and try again.',

              field: 'password',
              comparison: () => {
                return this.state.form.password.value;
              },
            },
          },
        },
        memorableNumber: {
          rules: {
            title: 'memorable number',
            stop: true,
            required: {
              message: 'Please enter your memorable number',
            },
            format: {
              regex: /^[0-9]{6}$/,
              message: 'Please enter a valid memorable number',
            },
          },
        },
        confirmMemorableNumber: {
          rules: {
            title: 'confirm memorable number',
            stop: true,
            required: {
              message: 'Please confirm your memorable number',
            },
            format: {
              regex: /^[0-9]{6}$/,
              message: 'Please enter a valid memorable number',
            },
            compare: {
              message: 'Please ensure the memorable number fields match',
              field: 'memorableNumber',
              comparison: () => {
                return this.state.form.memorableNumber.value;
              },
            },
          },
        },
      },
    };
  }

  componentDidMount = () => {
    this.props.timeout.startTimer();
  };

  componentWillUnmount = () => {
    this.props.timeout.clearTimer();
  };

  onChange = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  submit = e => {
    e.preventDefault();
    var { form } = this.state;
    const { updateForgotten, setupCredentialsActivate } = this.props;
    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      return;
    }
    let birthDay = this.props.dob;

    const request = {
      Ticket: setupCredentialsActivate.ResetUserCredentialsTicket,
      CredentialValues: [
        {
          Name: 'Password',
          Value: form.password.value,
          Context: '',
        },
        {
          Name: 'Pin',
          Value: form.memorableNumber.value,
          Context: '',
        },
      ],
      TermsAndConditionsDocumentVersion: '1.0',
      ExtendedProperties: {
        IdentityRequest: {
          DateOfBirth: birthDay,
        },
      },
    };
    updateForgotten(request);
  };

  getErrors() {
    const { updateForgottenDetails = {} } = this.props;
    let errors = [];
    if (
      updateForgottenDetails.ExtendedProperties &&
      updateForgottenDetails.ExtendedProperties.IdentityResponse &&
      updateForgottenDetails.ExtendedProperties.IdentityResponse
        .CredentialErrors
    ) {
      updateForgottenDetails.ExtendedProperties.IdentityResponse.CredentialErrors.forEach(
        element => {
          switch (element) {
            case 'Dob':
              return errors.push(
                <Alert id={element} key={element} color="danger" role="alert">
                  <Content
                    id={element}
                    copytext="Memorable number cannot be your date of birth"
                  />
                </Alert>,
              );
            case 'AllSame':
              return errors.push(
                <Alert id={element} key={element} color="danger" role="alert">
                  <Content
                    id={element}
                    copytext="You cannot have all the digits the same"
                  />
                </Alert>,
              );
            case 'Sequential':
              return errors.push(
                <Alert id={element} key={element} color="danger" role="alert">
                  <Content
                    id={element}
                    copytext="You aren't allowed to have a sequential sequence of digits"
                  />
                </Alert>,
              );
            case 'Known':
              return errors.push(
                <Alert id={element} key={element} color="danger" role="alert">
                  <Content
                    id={element}
                    copytext="Your chosen password is on a list of well known passwords"
                  />
                </Alert>,
              );
            case 'Username':
              return errors.push(
                <Alert id={element} key={element} color="danger" role="alert">
                  <Content
                    id={element}
                    copytext="Your password cannot be the same as your username"
                  />
                </Alert>,
              );
            default:
          }
        },
      );
    }

    return errors;
  }

  buildErrorMsg = updateForgottenDetails => {
    var errorsExist = false;
    var errorMsg = [];

    if (updateForgottenDetails.Status === 'InvalidCredentials') {
      errorMsg = this.getErrors();
      errorsExist = true;
    } else {
      if (
        updateForgottenDetails &&
        updateForgottenDetails.Status &&
        updateForgottenDetails.Status !== ''
      ) {
        errorsExist = true;
      }
      errorMsg = (
        <Switch
          cmsTag="authentication.registration.securityDetails.serverErrorMessages"
          value={updateForgottenDetails.Status || ''}
          tag="div"
          className="alert alert-danger"
          template={{
            UsernameAlreadyExists: {
              name: () => {
                return this.state.form.username.value;
              },
              random: () => {
                return Math.floor(Math.random() * (9999 - 1000)) + 1000;
              },
            },
          }}
          contents={{
            UsernameAlreadyExists: {
              defaultValue:
                'The username you specified is already in use. try something like $[name]$[random]',
            },

            NotEnoughCredentials: {
              defaultValue: 'Not enough credentials were submitted',
            },
            InvalidUsername: {
              defaultValue:
                'Your username must be between 6 and 23 characters.',
            },
          }}
        />
      );
    }

    return { errorMsg: errorMsg, errorsExist: errorsExist };
  };

  render() {
    const { updateForgottenDetails = {} } = this.props;
    const { form } = this.state;
    var errorMsgObj = this.buildErrorMsg(updateForgottenDetails);
    return (
      <>
        <AppMeta
          id="meta-data"
          stage="child"
          title="Forgotten password Step 2"
          metaDescription="Forgotten password step 2"
        />
        <p>
          <Content
            id="security-title"
            copytext="You can now set your password and memorable number."
          />
        </p>
        <Form
          autoComplete="off"
          id="forgotten-pw-security-details"
          onSubmit={this.submit}
        >
          {' '}
          {errorMsgObj.errorMsg}
          <Row>
            <Col sm={12} lg={6}>
              <Text
                description="Your password must be between 10 and 50 characters, and must include lower and uppercase letters, a number and a symbol"
                label="Set your password"
                id="password"
                field="password"
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.password}
                type="password"
              />
              <Text
                label="Confirm your password"
                id="confirmPassword"
                field="confirmPassword"
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.confirmPassword}
                type="password"
              />
              <Text
                description="Your memorable number must be 6 numbers long. You will be asked for 3 numbers from this code when you login."
                label="Set your memorable number"
                id="memorableNumber"
                field="memorableNumber"
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.memorableNumber}
                type="password"
              />
              <Text
                label="Confirm your memorable number"
                id="confirmMemorableNumber"
                field="confirmMemorableNumber"
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.confirmMemorableNumber}
                type="password"
              />
              {errorMsgObj.errorsExist ? (
                <Alert
                  id="error-exists"
                  className="mt-4"
                  color="danger"
                  role="alert"
                  aria-live="assertive"
                >
                  <Content
                    cmsTag="credential-setup-error-exists"
                    copytext="Unfortunately something  is incorrect – please scroll to the top for more details."
                  />
                </Alert>
              ) : (
                ''
              )}
              <div className="form-button-group">
                <Button
                  id="security-details-submit"
                  type="submit"
                  color="primary"
                >
                  <Content cmsTag="continueButton" copytext="Continue" />
                </Button>
                <Link
                  id="security-details-cancel"
                  className="btn btn-secondary"
                  role="button"
                  to="/"
                >
                  <Content cmsTag="cancelButton" copytext="Cancel" />
                </Link>
              </div>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

SecurityDetails.propTypes = {
  updateForgottenDetails: PropTypes.object,
  updateForgotten: PropTypes.any,
  timeout: PropTypes.any,
  setupCredentialsActivate: PropTypes.any,
  formData: PropTypes.any,
  dob: PropTypes.string,
};

const mapStateToProps = state => {
  const { authenticationDefinition, customccbAuthenticationDefinition } = state;
  return {
    ...authenticationDefinition,
    ...customccbAuthenticationDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps,
    ...mapDispatchToProps_shared,
    ...ccbAuthenticationDispatch,
  },
)(SecurityDetails);
