import { render } from 'react-dom';
import React from 'react';
import { PropTypes } from 'prop-types';

import Modal from './Modal';

export default class Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  static defaultProps = {
    message: 'Are you sure?',
    title: 'Warning!',
    confirmText: 'Ok',
    cancelText: 'Cancel',
    confirmColor: 'primary',
    cancelColor: '',
  };

  static propTypes = {
    message: PropTypes.any,
    title: PropTypes.any,
    confirmText: PropTypes.any,
    cancelText: PropTypes.any,
    confirmColor: PropTypes.any,
    cancelColor: PropTypes.any,
  };

  open() {
    let confirmPromise = new Promise(resolve => {
      render(
        <Modal
          {...this.props}
          onClose={result => {
            resolve(result);
          }}
        />,
        this.el,
      );
    });

    return confirmPromise;
  }
}
