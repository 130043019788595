import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Content, Markdown, ContactNumber } from '@myie/interact-dom';

class Blocked extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }

  render() {
    return (
      <div id="registration-blocked">
        <h1 id="registration-blocked-title">
          <Content id="title" copytext="Access denied" />
        </h1>
        <h2>
          <Content
            id="subTitle"
            copytext="Access to your account was denied for security reasons."
          />
        </h2>
        <Alert color="danger" role="alert">
          <Markdown
            id="blockedText-top"
            markdown="Please be aware your account is no longer active. "
          />
          <p>
            <Content
              id="blocked-contact-details"
              copytext="Please contact CCB on: "
            />
            <ContactNumber />
            <Content id="blocked-charges" copytext=" (charges may apply)" />
          </p>

          <Markdown
            id="blockedText-bottom"
            markdown="to discuss further options on how to proceed."
          />
        </Alert>
        <div className="form-button-group">
          <Link
            id="registration-blocked-home-button"
            className="btn btn-primary"
            role="button"
            to="/"
          >
            <Content id="homeButton" copytext="Home" />
          </Link>
        </div>
      </div>
    );
  }
}

Blocked.propTypes = {
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Blocked);
