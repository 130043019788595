import React from 'react';
import { Check } from '@myie/interact-dom';
import { Row, Col, FormGroup } from 'reactstrap';

/** Check example */
class CheckExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: false,
    };
  }

  onChange = () => {
    const { value } = this.state;
    this.setState({ value: !value });
  };

  render() {
    return (
      <Row>
        <Col md={6}>
          <FormGroup>
            <Check
              key="checkbox1"
              label="Sample Checkbox 1"
              id="amount"
              field="checkbox1"
              onChange={this.onChange}
              value={this.state.value}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default CheckExample;
