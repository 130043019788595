import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Navigation = ({ components }) => {
  return (
    <ul className="document-navigation">
      <li>
        <Link className="btn btn-sm btn-primary" to="/styleguide">
          &lt; Back to styleguide
        </Link>
      </li>
      {components.map(name => {
        return (
          <li key={name}>
            <a href={`#${name}`}>{name}</a>
          </li>
        );
      })}
    </ul>
  );
};

Navigation.propTypes = {
  components: PropTypes.array.isRequired,
};

export default Navigation;
