import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '29.123', height = '33.848' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 29.123 33.848"
    viewBox="0 0 29.123 33.848"
  >
    <g>
      <path
        className={fill}
        d="M14.562 29.927c2.371 0 3.87-2.079 3.87-3.079h-7.74c-.001 1 1.498 3.079 3.87 3.079z"
      />
      <path
        className={fill}
        d="M27.25 21.742a40.83 40.83 0 00-.511-4.521c-.988-5.694-3.07-10.127-5.418-12.27-1.699-1.699-3.897-2.657-4.853-2.864l.002-.015C16.471.928 15.615 0 14.562 0c-1.054 0-1.909.927-1.909 2.072 0 .014.003.025.004.039-.96.22-2.971 1.026-4.291 2.346-2.574 2.149-4.914 6.617-5.982 12.764a40.844 40.844 0 00-.51 4.521C1.644 25.543 0 26.474 0 27.854c0 .311.055.615.162.912 1.033 2.879 6.899 5.082 14.399 5.082s13.366-2.203 14.4-5.082a2.7 2.7 0 00.161-.912c.001-1.38-1.643-2.311-1.872-6.112zM14.42 32.038c-4.146 0-12.306-1.458-12.306-4.123 0-2.46 7.264-3.793 12.306-3.793 2.438 0 12.571.624 12.571 3.728 0 2.267-8.759 4.188-12.571 4.188z"
      />
    </g>
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
