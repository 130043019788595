export const SET_LIST_MODE = 'SET_LIST_MODE';
export const SET_STATUS_MODE = 'SET_STATUS_MODE';

export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE';
export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST';
export const RESET_DOCUMENT_DOWNLOAD = 'RESET_DOCUMENT_DOWNLOAD';
export const FETCH_DOCUMENT_DOWNLOAD_SUCCESS =
  'FETCH_DOCUMENT_DOWNLOAD_SUCCESS';
export const FETCH_DOCUMENT_DOWNLOAD_FAILURE =
  'FETCH_DOCUMENT_DOWNLOAD_FAILURE';
export const FETCH_DOCUMENT_DOWNLOAD_REQUEST =
  'FETCH_DOCUMENT_DOWNLOAD_REQUEST';
