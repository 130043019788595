import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Session, Settings } from '@myie/interact';
import { Switch, Route, withRouter } from 'react-router-dom';
import {
  Switch as MessageSwitch,
  Content,
  Markdown,
  NoMatch,
  NetworkError,
  GoogleTagManager,
  TimeOutModal,
  AvailableRoutes,
  AuthButton,
  Interceptor,
  BankHolidays,
} from '@myie/interact-dom';
import { Sidebar, HasSidebar } from '@myie/interact-widget-sidebar-dom';
import { Media, Container, Row, Col } from 'reactstrap';
import { Menu } from '@myie/interact-widget-menu-dom';
import Home from './Home';
import moment from 'moment';
import { AppMeta, ScrollToTop } from '@myie/interact-dom';
import { TwoFactorPages } from '@myie/interact-two-factor-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Routes as cmuRoutes } from '@myie/interact-manager-dom';

class Connect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: '',
    };
  }

  componentDidMount() {
    this.updateLocation();
  }

  componentDidUpdate() {
    this.updateLocation();
  }

  updateLocation = () => {
    this.unlisten = this.props.history.listen(location => {
      this.event = { eventupdate: location, test: 'test' };
    });
    const location = this.props.location;
    if (location !== this.state.location) {
      this.setState({ location });
    }
  };

  render() {
    return (
      <HelmetProvider>
        <AppMeta
          id="meta-data-default"
          title="Cambridge & Counties Bank"
          metaDescription="Cambridge & Counties Bank Description"
          stage="child"
        />

        <ScrollToTop>
          <div className="connect">
            <div className="sr-only" aria-hidden="true">
              <BankHolidays
                id="bank-holidays"
                holidayList="2020-08-31,2020-12-25,2020-12-28,2021-01-01,2021-04-02,2021-05-03,2021-08-30,2021-12-27,2021-12-28"
              />
            </div>
            <MessageSwitch
              value="NotDisplayed"
              cmsTag="timeout.model.Messages"
              tag="div"
              className="alert alert-info w-100"
              template={{
                TimerCountDown: { count: 0 },
              }}
              contents={{
                TimerCountDown: {
                  defaultValue:
                    'For security reasons, this page will timeout in $[count] seconds. Would you like more time?',
                },
                SessionTimeout: {
                  defaultValue:
                    'You will be logged out in one minute. Do you want to continue your session?',
                },
                yesButton: {
                  defaultValue: 'Yes',
                },
                noButton: {
                  defaultValue: 'No',
                },
                SignInTimeout: {
                  defaultValue:
                    'You have taken too long to complete the sign in process. You will be returned to the first sign in page.',
                },
                ForgottenPasswordTimeout: {
                  defaultValue:
                    'You have taken too long to complete the credentials reset process. You will be returned to the first forgotten credentials page.',
                },
                RegistreationTimeout: {
                  defaultValue:
                    'You have taken too long to complete the registration process. You will be returned to the first registration page.',
                },
              }}
            />
            <GoogleTagManager
              gtmId={Settings.gtmId}
              scriptId={'gtmId'}
              additionalEvents={this.event}
            />
            <div className="content">
              <header>
                <span>
                  {Session.isAuthenticated() ? (
                    <span>
                      <a
                        href="#main-menu"
                        className="sr-only sr-only-focusable"
                      >
                        <Content
                          id="connect-skip-to-menu"
                          copytext="Skip to Menu"
                        />
                      </a>
                    </span>
                  ) : null}
                  <span>
                    <a
                      href="#main-content"
                      className="sr-only sr-only-focusable btn btn-secondary"
                    >
                      <Content
                        id="connect-skip-to-content"
                        copytext="Skip to Content"
                      />
                    </a>
                  </span>
                </span>

                <Container>
                  <Media>
                    <div className="d-flex align-self-center">
                      <img
                        className="logo-svg"
                        src="/ccb-logo.png"
                        alt="Cambridge and Counties Bank"
                      />
                    </div>
                    <Media body />
                    <div className="d-flex align-self-center">
                      <AuthButton url="/signed-out" />
                    </div>
                  </Media>
                  <Menu />
                </Container>
              </header>
              <div className="content-background">
                <Container className="content-container clearfix">
                  <Interceptor TwoFactorWrapper={TwoFactorPages}>
                    <main
                      className={`left-container ${
                        HasSidebar(this.props) ? '' : 'w-100'
                      }`.trim()}
                      id="main-content"
                    >
                      <Switch>
                        <Route exact path="/" component={Home} />
                        <Route
                          exact
                          path="/network-error"
                          component={NetworkError}
                        />
                        {AvailableRoutes}
                        {cmuRoutes}
                        <Route component={NoMatch} />
                      </Switch>
                    </main>
                    <Sidebar location={this.state.location} />
                  </Interceptor>
                </Container>
              </div>
            </div>
            <footer>
              <Container>
                <Row>
                  <Col xs={6} sm={4} md={3}>
                    <nav aria-label="Help Navigation">
                      <a
                        href="https://ccbank.co.uk/business/savings/savings-help-page/#faqs"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Content
                          id="connect-footer-link-faqs"
                          copytext="FAQs"
                        />
                        <span className="sr-only">
                          <Content
                            id="connect-footer-new-window"
                            copytext=" (opens in a new window)"
                          />
                        </span>
                      </a>

                      <a
                        href="https://ccbank.co.uk/business/savings/savings-help-page/#getintouch"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Content
                          id="connect-footer-link-contact"
                          copytext="Contact us"
                        />
                        <span className="sr-only">
                          <Content
                            id="connect-footer-contactus-new-window"
                            copytext=" (opens in a new window)"
                          />
                        </span>
                      </a>
                    </nav>
                  </Col>
                  <Col xs={6} sm={4} md={3}>
                    <nav aria-label="Legal Navigation">
                      <a
                        href="https://ccbank.co.uk/about-us/our-commitments/website-terms-use/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Content
                          id="connect-footer-link-legal-info"
                          copytext="Website terms"
                        />
                        <span className="sr-only">
                          <Content
                            id="connect-footer-new-window"
                            copytext=" (opens in a new window)"
                          />
                        </span>
                      </a>
                      <a
                        href="https://ccbank.co.uk/help-centre/online-account-access-t-and-cs/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Content
                          id="connect-footer-link-online-account-tcs"
                          copytext="Account access terms"
                        />
                        <span className="sr-only">
                          <Content
                            id="connect-footer-new-window"
                            copytext=" (opens in a new window)"
                          />
                        </span>
                      </a>
                      <a
                        href="https://ccbank.co.uk/about-us/our-commitments/cookies-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Content
                          id="connect-footer-link-cookie-policy"
                          copytext="Website cookies"
                        />
                        <span className="sr-only">
                          <Content
                            id="connect-footer-new-window"
                            copytext=" (opens in a new window)"
                          />
                        </span>
                      </a>
                      <a
                        href="https://ccbank.co.uk/about-us/our-commitments/privacy-notice/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Content
                          id="connect-footer-link-privacy-policy"
                          copytext="Privacy notice"
                        />
                        <span className="sr-only">
                          <Content
                            id="connect-footer-new-window"
                            copytext=" (opens in a new window)"
                          />
                        </span>
                      </a>
                      <a
                        href="https://ccbank.co.uk/about-us/our-commitments/accessibility/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Content
                          id="connect-footer-link-accessibility"
                          copytext="Accessibility"
                        />
                        <span className="sr-only">
                          <Content
                            id="connect-footer-new-window"
                            copytext=" (opens in a new window)"
                          />
                        </span>
                      </a>
                      <a
                        href="https://ccbank.co.uk/about-us/our-commitments/security/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Content
                          id="connect-footer-link-security"
                          copytext="Security"
                        />
                        <span className="sr-only">
                          <Content
                            id="connect-footer-new-window"
                            copytext=" (opens in a new window)"
                          />
                        </span>
                      </a>
                    </nav>
                  </Col>
                </Row>
                <div className="d-block d-md-flex">
                  <div className="version pr-0 pr-md-4">
                    <Markdown
                      options={{ forceBlock: true }}
                      template={{
                        markdown: {
                          year: () => {
                            return moment().format('YYYY');
                          },
                        },
                      }}
                      id="connect-version"
                      markdown={`Cambridge & Counties Bank Ltd is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority under firm registration number 579415. Our authorisation can be checked at the Financial Services Register at [www.fca.org.uk](https://www.fca.org.uk).
                    \n Cambridge & Counties Bank Ltd Registered Office: Charnwood Court, 5b New Walk, Leicester England, LE1 6TE. Registered in England and Wales No. 07972522.
                    \n VAT Registration Number GB 208354420.
                    \n © Cambridge & Counties Bank Limited
                    `}
                    />
                  </div>
                  <img
                    className="b-corp-logo pt-4 pt-md-0"
                    src="/b-corp-logo.svg"
                    alt="B Corporation logo"
                  />
                </div>
              </Container>
            </footer>
            <TimeOutModal history={this.props.history} />
          </div>
        </ScrollToTop>
      </HelmetProvider>
    );
  }
}

Connect.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
};

export default withRouter(Connect);
