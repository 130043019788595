import {
  TOKEN_ACCOUNT_REQUEST,
  TOKEN_ACCOUNT_SUCCESS,
  TOKEN_ACCOUNT_FAILURE,
  RESET_TOKEN_ACCOUNT,
} from '../actions/registrationTypes';

const ccbRegistrationTokenDefinition = (state = {}, action) => {
  switch (action.type) {
    case RESET_TOKEN_ACCOUNT:
      return {
        ...state,
        tokenAccountDetails: {},
      };
    case TOKEN_ACCOUNT_SUCCESS:
      return {
        ...state,
        tokenAccountDetails: action.tokenAccountDetails,
        isFetching: action.isFetching,
      };
    case TOKEN_ACCOUNT_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case TOKEN_ACCOUNT_REQUEST:
    default:
      return state;
  }
};

export default ccbRegistrationTokenDefinition;
