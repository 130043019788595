import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Input,
  Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Alert,
  FormGroup,
} from 'reactstrap';
import { Form as FormUpdater, Connection } from '@myie/interact';
import contentManaged, { ContentType } from '../HO_components/contentManaged';

/**
 * AddressLookup component
 */
class AddressLookup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      response: {},
      form: {
        address: {
          rules: {
            required: true,
          },
        },
      },
    };
    const { label, subLabel, description, managedContent } = props;
    managedContent({
      label: { defaultValue: label },
      subLabel: { defaultValue: subLabel },
      description: { defaultValue: description },
      invalidPostcodeFormat: {
        defaultValue: 'You have entered an invalide postcode format.',
      },
      notFound: {
        defaultValue: `No Address has been found for entered Postcode.`,
      },
      failed: {
        defaultValue: `Postcode lookup is currently unavailable.`,
      },
      singleAddress: {
        defaultValue: `A single Address has been found.`,
      },
      multipleAddresses: {
        defaultValue: `Multiple Addresses have been found please select your address.`,
      },
    });
  }

  selectAddress = (e, address, callback) => {
    if (typeof callback === 'function') {
      callback(address);
    }
    this.setState({ ...this.state, error: {}, response: {} });
  };

  processAddresses = (json, callback) => {
    if (json.ResponseStatus === 'Success') {
      this.setState({ ...this.state, response: json, error: null });
      if (json.Addresses.length === 1 && typeof callback === 'function') {
        callback(json.Addresses[0]);
      }
    } else {
      this.setState({ ...this.state, error: {}, response: json });
    }
  };

  onChange = e => {
    const form = FormUpdater.update(this.state.form, e.target);
    this.setState({ ...this.state, form });
  };

  onClick = (e, callback) => {
    const { value = '' } = this.state.form.address;

    const request = {
      Postcode: value,
      HouseNumber: '',
    };
    return fetch(Connection.baseUrl() + `/addresslookup/v1.0/addresses/find`, {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    })
      .then(response => Connection.errored(response))
      .then(response => response.json())
      .then(json => this.processAddresses(json, callback))
      .catch(error => this.setState({ ...this.state, error, response: {} }));
  };

  render() {
    const {
      children,
      labelClassName = '',
      groupClassName = '',
      id,
      callback,
      contentValue,
      managedContent,
      ...rest
    } = this.props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const label = contentValue(ContentType.Text, 'label');
    const subLabel = contentValue(ContentType.Text, 'subLabel');
    const description = contentValue(ContentType.Text, 'description');

    const { value = '' } = this.state.form.address;
    const { response = {}, error = {} } = this.state;
    const addressComponents = [];
    if (Array.isArray(response.Addresses)) {
      response.Addresses.forEach(address => {
        addressComponents.push(
          <ListGroupItem
            tag="button"
            type="button"
            className="btn btn-secondary"
            onClick={e => this.selectAddress(e, address, callback)}
          >
            {address.BuildingName} {address.BuildingNumber}{' '}
            {address.AddressLine1} {address.Postcode}
          </ListGroupItem>,
        );
      });
    }
    return (
      <FormGroup className={groupClassName}>
        <Label className={labelClassName} id={`${id}-label`} htmlFor={id}>
          {label} {subLabel.length > 0 ? <small>{subLabel}</small> : ''}
          {description.length > 0 ? (
            <span className="description">{description}</span>
          ) : (
            ''
          )}
        </Label>
        <InputGroup>
          <Input
            value={value}
            id={id}
            {...rest}
            name="address"
            onChange={this.onChange}
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <button
                className="btn search"
                type="button"
                onClick={e => this.onClick(e, callback)}
              />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
        {children}

        {error && error.message ? (
          <Alert className="mt-3" color="danger" role="alert">
            {error.message}
          </Alert>
        ) : (
          ''
        )}
        {response.ResponseStatus === 'InvalidPostcodeFormat' ? (
          <Alert className="mt-3" color="danger" role="alert">
            {contentValue(ContentType.Text, 'invalidPostcodeFormatError')}
          </Alert>
        ) : (
          ''
        )}
        {response.ResponseStatus === 'NotFound' ? (
          <Alert className="mt-3" color="warning" role="alert">
            {contentValue(ContentType.Text, 'notFound')}
          </Alert>
        ) : (
          ''
        )}
        {response.ResponseStatus === 'Failed' ? (
          <Alert className="mt-3" color="warning" role="alert">
            {contentValue(ContentType.Text, 'failed')}
          </Alert>
        ) : (
          ''
        )}
        {addressComponents.length === 1 ? (
          <Alert className="mt-3" color="success" role="alert">
            {contentValue(ContentType.Text, 'singleAddress')}
          </Alert>
        ) : (
          ''
        )}
        {addressComponents.length > 1 ? (
          <>
            <Alert className="mt-3" color="success" role="alert">
              {contentValue(ContentType.Text, 'multipleAddresses')}
            </Alert>
            <Card className="mt-3">
              <ListGroup flush>{addressComponents}</ListGroup>
            </Card>
          </>
        ) : (
          ''
        )}
      </FormGroup>
    );
  }
}

AddressLookup.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.element.isRequired,
  contentValue: PropTypes.any,
  description: PropTypes.string,
  groupClassName: PropTypes.string,
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  managedContent: PropTypes.any,
  subLabel: PropTypes.string,
};

export default contentManaged(AddressLookup);
