import React from 'react';
import PropTypes from 'prop-types';
import { Content, LinkTag } from '@myie/interact-dom';

class ContactNumber extends React.Component {
  render() {
    return (
      <LinkTag id="contact-phone-number" href="tel:03442253939">
        <Content id="contact-number" copytext="0344 225 3939" />
      </LinkTag>
    );
  }
}

ContactNumber.propTypes = {
  items: PropTypes.string,
};

export default ContactNumber;
