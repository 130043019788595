import moment from 'moment';
const dateValidation = (function() {
  const isWeekEnd = date => {
    let day = moment(date).day();
    if (day === 6 || day === 0) {
      return true;
    }
    return false;
  };

  const isHoliday = (date, days) => {
    const found = days.find(element => element === date);
    if (found) {
      return true;
    }
    return false;
  };

  return {
    isWeekEnd: isWeekEnd,
    isHoliday: isHoliday,
  };
})();

export default dateValidation;
