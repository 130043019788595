import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import moment from 'moment';

class TransactionDate extends React.Component {
  render() {
    const { props } = this;

    const getDateStr = date => {
      const thisDate = moment(date)
        .startOf('day')
        .toString();
      const today = moment()
        .startOf('day')
        .toString();
      const yesterday = moment()
        .subtract(1, 'days')
        .startOf('day')
        .toString();

      if (thisDate === today) {
        return 'Today';
      }
      if (thisDate === yesterday) {
        return 'Yesterday';
      }

      return moment(date)
        .format('DD MMM YYYY')
        .toString();
    };
    return (
      <Col
        xs={12}
        tag="dt"
        className="transactionDate"
        itemRef={`transactionDate-${props.transactionDate}`}
      >
        {getDateStr(props.transactionDate)}
      </Col>
    );
  }
}

TransactionDate.propTypes = {
  transactionDate: PropTypes.any,
  position: PropTypes.any,
};

export default TransactionDate;
