import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label, FormGroup } from 'reactstrap';
import { Validate } from '@myie/interact';
import contentManaged, { ContentType } from '../HO_components/contentManaged';

/**
 * Radio component
 */
class Radio extends React.Component {
  constructor(props) {
    super(props);
    const { label, subLabel, description, suffix, managedContent } = props;
    managedContent({
      label: { defaultValue: label },
      subLabel: { defaultValue: subLabel },
      description: { defaultValue: description },
      suffix: { defaultValue: suffix },
    });
  }

  render() {
    const { props } = this;

    const {
      validation = {},
      value: setValue,
      children,
      disabled,
      required,
      id,
      field,
      className = '',
      labelClassName = '',
      groupClassName = '',
      contentValue,
      managedContent,
      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const { value = '', state = {} } = validation;
    const label = contentValue(ContentType.Text, 'label');
    const subLabel = contentValue(ContentType.Text, 'subLabel');
    const description = contentValue(ContentType.Text, 'description');
    const suffix = contentValue(ContentType.Text, 'suffix');
    var fieldName =
      typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;
    return (
      <FormGroup
        className={`custom-control custom-radio ${groupClassName} ${
          disabled ? 'disabled-input' : ''
        } ${required ? 'required-input' : ''}`.trim()}
      >
        <Input
          //aria-labelledby={`${id}-label`}
          invalid={Validate.isInvalid(state)}
          valid={Validate.isValid(state)}
          className={`custom-control-input ${className}`.trim()}
          id={id}
          type="radio"
          name={fieldName}
          value={setValue}
          disabled={disabled}
          required={required}
          checked={value === setValue}
          {...rest}
        />
        <Label
          className={`custom-control-label ${labelClassName}`.trim()}
          id={`${id}-label`}
          htmlFor={id}
          check
        >
          <span className="custom-control-text">
            {label} {subLabel.length > 0 ? <small>{subLabel}</small> : ''}
            {description.length > 0 ? (
              <span className="description">{description}</span>
            ) : (
              ''
            )}
          </span>
        </Label>
        {children}
        {suffix && <p className="suffix">{suffix}</p>}
      </FormGroup>
    );
  }
}

Radio.propTypes = {
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  /**
   * Any children of the component
   */
  children: PropTypes.any,
  /**
   * Class name
   */
  className: PropTypes.string,
  /**
   * Description for radio
   */
  description: PropTypes.string,
  /**
   * Boolean flag whether radio is disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * Field name
   */
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Class name
   */
  groupClassName: PropTypes.string,
  /**
   * Radio id
   */
  id: PropTypes.any.isRequired,
  /**
   * Radio label
   */
  label: PropTypes.any.isRequired,
  /**
   * Class name for the label
   */
  labelClassName: PropTypes.string,
  /**
   * Whether radio is required or not
   */
  required: PropTypes.bool,
  /**
   * Set value for radio
   */
  setValue: PropTypes.any,
  /**
   * Value for radio
   */
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  /**
   * Sub label for radio
   */
  subLabel: PropTypes.string,
  /**
   * Suffix for radio
   */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Validation object
   */
  validation: PropTypes.object,
};

export default contentManaged(Radio);
