import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '31.931', height = '31.932' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 31.931 31.932"
    viewBox="0 0 31.931 31.932"
  >
    <path
      className={fill}
      d="M9.616 22.315a8.976 8.976 0 010-12.698 8.98 8.98 0 110 12.698zM13.063 0v3.99a12.228 12.228 0 00-3.514 1.454L6.731 2.625 2.623 6.731l2.82 2.818a12.242 12.242 0 00-1.453 3.514H0v5.806h3.99c.294 1.225.78 2.409 1.453 3.517l-2.82 2.817 4.108 4.104 2.817-2.819a12.234 12.234 0 003.514 1.456v3.987h5.807v-3.987a12.245 12.245 0 003.516-1.456l2.814 2.819 4.107-4.104-2.818-2.817a12.257 12.257 0 001.454-3.517h3.988v-5.806h-3.988a12.217 12.217 0 00-1.454-3.514l2.818-2.818-4.107-4.106-2.814 2.819a12.285 12.285 0 00-3.516-1.454V0h-5.806z"
    />
    <path
      className={fill}
      d="M19.141 19.142a4.49 4.49 0 01-6.35 0 4.49 4.49 0 010-6.35 4.49 4.49 0 016.35 0 4.49 4.49 0 010 6.35z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
