import {
  UPDATE_FORGOTTEN_REQUEST,
  UPDATE_FORGOTTEN_SUCCESS,
  UPDATE_FORGOTTEN_FAILURE,
} from '../actions/forgottenPasswordTypes';
import {
  FORGOTTEN_PW_REQUEST,
  FORGOTTEN_PW_SUCCESS,
  FORGOTTEN_PW_FAILURE,
} from '../actions/forgottenPasswordTypes';
import { RESET_FORGOTTEN_PW } from '../actions/forgottenPasswordTypes';

const forgottenPasswordDefinition = (state = {}, action) => {
  switch (action.type) {
    case RESET_FORGOTTEN_PW:
      return {
        ...state,
        forgottenPasswordDetails: {},
        updateForgottenDetails: {},
      };
    case FORGOTTEN_PW_SUCCESS:
      return {
        ...state,
        forgottenPasswordDetails: action.forgottenPassword,
        isFetching: action.isFetching,
      };
    case UPDATE_FORGOTTEN_SUCCESS:
      return {
        ...state,
        updateForgottenDetails: action.updateForgotten,
        isFetching: action.isFetching,
      };
    case UPDATE_FORGOTTEN_REQUEST:
    case UPDATE_FORGOTTEN_FAILURE:
    case FORGOTTEN_PW_REQUEST:
    case FORGOTTEN_PW_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    default:
      return state;
  }
};

export default forgottenPasswordDefinition;
