import React from 'react';
import PropTypes from 'prop-types';
import View from './View';
import ReplyIndex from './Reply';
import DeleteIndex from './Delete';
import { Alert } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapDispatchToProps as mapDispatchToPropsAccounts } from '@myie/interact-accounts';
import { mapDispatchToProps as mapDispatchToPropsMessaging } from '@myie/interact-messaging-twoway';
import { NoMatch } from '@myie/interact-dom';

class Conversation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'View',
      message: null,
      data: {},
    };
  }

  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  componentWillUnmount() {
    const { resetReply } = this.props;
    resetReply();
  }

  render() {
    const { error } = this.props;

    return (
      <div
        className="messaging-twoway-conversation"
        id="messaging-twoway-index"
      >
        {error ? (
          <Alert color="danger" role="alert">
            {error}
          </Alert>
        ) : (
          ''
        )}
        <Switch>
          <Route
            path="/messaging-twoway/message/reply/:id"
            render={routeProps => <ReplyIndex {...routeProps} />}
          />
          <Route
            path="/messaging-twoway/message/view/:id"
            render={routeProps => <View {...routeProps} />}
          />
          <Route
            path="/messaging-twoway/message/delete/:id"
            render={routeProps => <DeleteIndex {...routeProps} />}
          />
          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

Conversation.propTypes = {
  error: PropTypes.any,
  location: PropTypes.any,
  resetAccountPayees: PropTypes.any,
  resetReply: PropTypes.any,
  accounts: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
};

const mapStateToProps = state => {
  const { messagingTwowayDefinition, accountsDefinition } = state;
  return {
    ...messagingTwowayDefinition,
    ...accountsDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToPropsMessaging, ...mapDispatchToPropsAccounts },
)(Conversation);
