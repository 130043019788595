import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';

class Tables extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    return (
      <section id="tables-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/components/tables.scss</strong>
          </code>
        </p>
        <p>
          There is a component made for <strong>tables</strong> defined within
          reactstrap. To see an interactive technical specification for this
          component, go to{' '}
          <Link to="https://reactstrap.github.io/components/tables/">
            https://reactstrap.github.io/components/tables/
          </Link>
          .
        </p>
        <h3>Table Columns</h3>
        <Table>
          <caption className="sr-only">Manage statements table</caption>
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Type</th>
              <th scope="col">Payee</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr data-transaction-id="id1">
              <td>08 May 2014 </td>
              <td>One-off Payment</td>
              <td>Natwest</td>
              <td>£100.00</td>
            </tr>
            <tr>
              <td>10 Jun 2014</td>
              <td>Transfer</td>
              <td>Current Account</td>
              <td>£pound;500.00</td>
            </tr>
            <tr>
              <td>05 Aug 2014 </td>
              <td>Direct debit Electricity bill</td>
              <td>British Gas</td>
              <td>£120.00</td>
            </tr>
          </tbody>
        </Table>
        <h3>Table Rows</h3>
        <Table>
          <caption>Description of table for accessibility purposes</caption>
          <tbody>
            <tr>
              <th scope="row">Payee</th>
              <td>Jo Bloggs</td>
              <td>Current Account</td>
            </tr>
            <tr>
              <th scope="row">Payee</th>
              <td>Jane Doe</td>
              <td>Savings Account</td>
            </tr>
            <tr>
              <th scope="row">Payee</th>
              <td>John Smith</td>
              <td>Loan Account</td>
            </tr>
          </tbody>
        </Table>
      </section>
    );
  }
}

Tables.propTypes = {
  items: PropTypes.string,
};

export default Tables;
