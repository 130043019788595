import React from 'react';
import { Link } from 'react-router-dom';
import { Media } from 'reactstrap';
import { Markdown, Icon } from '@myie/interact-dom';

class UpdateOrganisationDetails extends React.Component {
  render() {
    return (
      <Link
        className="nav-link"
        role="button"
        id="update-organisation-details-service-item-link"
        to="/update-organisation-details"
      >
        <Media>
          <Media left middle>
            <Icon name="MyServicesOrganisationSettings" fill="primary-fill" />
          </Media>
          <Media body middle>
            <Markdown
              options={{ forceBlock: true }}
              id="update-organisation-details"
              markdown={`## Organisation details\nView the details we hold for your organisation.`}
            />
          </Media>
        </Media>
      </Link>
    );
  }
}

export default UpdateOrganisationDetails;
