import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Content } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import WithdrawalAccount from '../../shared/WithdrawalAccount';
import NominatedAccount from '../../shared/NominatedAccount';
import { RedirectByAccountDropdown } from '@myie/interact-accounts-dom';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-ccb-savings-servicing';
import WithdrawalWithClose from '../../shared/WithdrawalWithClose';

class Create extends Component {
  submitForm = form => {
    const { submitForm } = this.props;
    submitForm('ReviewNotice', form);
  };

  render() {
    const {
      account,
      accounts = {},
      onChangeAccount,
      onCancelled,
      actionDate,
      isFetching,
    } = this.props;

    return (
      <>
        <RedirectByAccountDropdown
          accounts={accounts}
          baseUrl="/notice-to-withdraw/"
          defaultSelect={`${account.AccountKey.Key}`}
          onChangeAccount={onChangeAccount}
          filter="notice"
        />

        <h2 className="h4">
          <Content id="accounts-details" copytext="Account details" />
        </h2>
        <WithdrawalAccount account={account} withdrawDate={actionDate} />

        <h2 className="h4">
          <Content
            id="nominated-accounts-details"
            copytext="Your nominated account"
          />
        </h2>

        <NominatedAccount account={account} />
        {!isFetching && Object.keys(account).length ? (
          <WithdrawalWithClose
            account={account}
            onCancelled={onCancelled}
            onSubmit={this.submitForm}
          />
        ) : null}
      </>
    );
  }
}

Create.propTypes = {
  accounts: PropTypes.any,
  withdrawalAccount: PropTypes.object,
  isFetching: PropTypes.bool,
  history: PropTypes.any,
  onCreate: PropTypes.func,
  match: PropTypes.any,
  actionDate: PropTypes.any,
  onChangeAccount: PropTypes.func,
  onCancelled: PropTypes.func,
  fetchAccountsIfNeeded: PropTypes.func,
  resetMakeWithdrawal: PropTypes.func,
  makeaWithdraw: PropTypes.func,
  account: PropTypes.any,
  submitForm: PropTypes.any,
};

const mapStateToProps = state => {
  const { accountsDefinition, customccbSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...customccbSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
)(withRouter(Create));
