import React from 'react';
import PropTypes from 'prop-types';
import { Content } from '@myie/interact-dom';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';
import CodeFormAuth from './CodeFormAuth';

class CodeAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div id="enter-auth-code">
        <Content
          tag="h1"
          cmsTag="Two-factor:Code:h1"
          copytext="Enter Authentication Code"
        />
        <CodeFormAuth
          selectedDestination={this.props.selectedDestination}
          retry={this.props.reSet}
          failed={this.props.failed}
        />
      </div>
    );
  }
}

CodeAuth.propTypes = {
  failed: PropTypes.any,
  finishTwoFactor: PropTypes.any,
  sendChallenge: PropTypes.any,
  selectedDestination: PropTypes.string,
  retry: PropTypes.func,
  reSet: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CodeAuth);
