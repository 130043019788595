import * as loginActions from './actions/loginActions';
import * as registrationActions from './actions/registrationActions';
import * as forgottenUserNameActions from './actions/forgottenUserNameActions';
import * as forgottenPasswordActions from './actions/forgottenPasswordActions';
import * as updateSecurityDetailsActions from './actions/updateSecurityDetailsActions';

const mapDispatchToProps = {
  ...loginActions,
  ...registrationActions,
  ...forgottenUserNameActions,
  ...forgottenPasswordActions,
  ...updateSecurityDetailsActions,
};

export default mapDispatchToProps;
