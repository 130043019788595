import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Content, LinkTag } from '@myie/interact-dom';
class Success extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }
  render() {
    return (
      <div id="registration-success">
        <h1 id="registration-success-title">
          <Content id="title" copytext="Next steps" />
        </h1>
        <h2>
          <Content id="subTitle" copytext="Thank you for registering." />
        </h2>

        <p>
          <Content
            id="sign-in-details"
            copytext="You can now login with the username, password and memorable number you have just registered so do not forget them!"
          />
        </p>
        <div className="form-button-group">
          <LinkTag
            id="registration-success-home-button"
            className="btn btn-primary"
            role="button"
            target="_blank"
            href="https://ccbank.co.uk/business/savings/online-savings-access"
          >
            <Content id="doneButton" copytext="Login" />
          </LinkTag>
        </div>
      </div>
    );
  }
}

Success.propTypes = {
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Success);
