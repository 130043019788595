import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Text, Content } from '@myie/interact-dom';

class YourAddress extends React.Component {
  constructor(props) {
    super(props);
    const { setForm } = this.props;
    this.state = {
      userDetails: null,
      message: null,
      submitForm: {},
      yourAddressForm: {
        BuildingName: {
          rules: {
            title: 'BuildingName',
            stop: true,
            buildingNameNumberValidate: {
              message: 'Please enter a house name or flat number.',
              field: 'BuildingNumber',
              comparison: () => {
                return this.state.yourAddressForm.BuildingNumber.value;
              },
            },
          },
        },
        BuildingNumber: {
          rules: {
            title: 'BuildingNumber',
            stop: true,
            buildingNameNumberValidate: {
              message: 'Please enter a house number.',
              field: 'BuildingName',
              comparison: () => {
                return this.state.yourAddressForm.BuildingName.value;
              },
            },
          },
        },
        AddressLine1: {
          rules: {
            title: 'AddressLine1',
            stop: true,
            required: {
              message: 'Please enter address line 1.',
            },
          },
        },
        AddressLine2: {
          rules: {
            title: 'AddressLine2',
            stop: true,
          },
        },
        PostTown: {
          rules: {
            title: 'townCity',
            stop: true,
            required: {
              message: 'Please enter town/city.',
            },
          },
        },
        County: {
          rules: {
            title: 'country',
            stop: true,
            required: {
              message: 'Please enter country.',
            },
            country: {
              message: 'Please enter a valid country name.',
            },
          },
        },
        PostCode: {
          rules: {
            title: 'postCode',
            stop: true,
            required: {
              message: 'Please enter postcode.',
            },
            postcode: {
              message:
                'Please enter your postcode in a valid UK postcode format.',
            },
          },
        },
      },
    };
    setForm('yourAddressForm', this.state.yourAddressForm);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { yourAddressForm, userDetails } = nextProps;

    if (yourAddressForm && yourAddressForm !== prevState.yourAddressForm) {
      return {
        ...prevState,
        yourAddressForm,
      };
    }

    //Set userDetails
    if (
      yourAddressForm &&
      userDetails !== prevState.userDetails &&
      userDetails.Address
    ) {
      yourAddressForm.BuildingName.value = userDetails.Address.BuildingName;
      yourAddressForm.BuildingNumber.value = userDetails.Address.BuildingNumber;
      yourAddressForm.AddressLine1.value = userDetails.Address.AddressLine1;
      yourAddressForm.AddressLine2.value = userDetails.Address.AddressLine2;
      yourAddressForm.PostTown.value = userDetails.Address.PostTown;
      yourAddressForm.County.value = userDetails.Address.County;
      yourAddressForm.PostCode.value = userDetails.Address.PostCode;
      return {
        ...prevState,
        yourAddressForm,
        userDetails,
      };
    } else {
      return null;
    }
  }

  onChange = e => {
    const { onEvent } = this.props;
    onEvent('yourAddressForm', e);
  };

  onChangeNameNumber = e => {
    const { addressOnEvent } = this.props;
    addressOnEvent('yourAddressForm', e);
  };

  onBlur = e => {
    const { onEvent } = this.props;
    onEvent('yourAddressForm', e);
  };

  submit = e => {
    e.preventDefault();
  };

  render() {
    let { yourAddressForm } = this.state;
    return (
      <fieldset>
        <legend className="h2">
          <Content id="your-address.title" copytext="Your address" />
        </legend>
        <Row>
          <Col sm={12} lg={6}>
            <Text
              label="House name or Flat number (if applicable)"
              id="YourBuildingName"
              field="BuildingName"
              onChange={this.onChangeNameNumber}
              onBlur={this.onChangeNameNumber}
              validation={yourAddressForm.BuildingName}
              maxLength={50}
            />
            <Text
              label="House number (if applicable)"
              id="YourBuildingNumber"
              field="BuildingNumber"
              onChange={this.onChangeNameNumber}
              onBlur={this.onChangeNameNumber}
              validation={yourAddressForm.BuildingNumber}
              maxLength={50}
            />
            <Text
              label="Address line 1"
              id="YourAddressLine1"
              field="AddressLine1"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={yourAddressForm.AddressLine1}
              maxLength={100}
            />
            <Text
              label="Address line 2"
              id="YourAddressLine2"
              field="AddressLine2"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={yourAddressForm.AddressLine2}
              maxLength={100}
            />
            <Text
              label="Town/City"
              id="YourPostTown"
              field="PostTown"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={yourAddressForm.PostTown}
              maxLength={60}
            />
            <Text
              label="County"
              id="YourCounty"
              field="County"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={yourAddressForm.County}
              maxLength={60}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} lg={4}>
            <Text
              label="Postcode"
              id="YourPostCode"
              field="PostCode"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={yourAddressForm.PostCode}
            />
          </Col>
        </Row>
      </fieldset>
    );
  }
}

YourAddress.propTypes = {
  addressOnEvent: PropTypes.any,
  onEvent: PropTypes.any,
  saveData: PropTypes.any,
  saveDataAddress: PropTypes.any,
  setForm: PropTypes.any,
  setFormValidations: PropTypes.any,
  userDetails: PropTypes.any,
  yourAddressForm: PropTypes.any,
};

export default YourAddress;
