import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'reactstrap';
import { Icon, Content } from '@myie/interact-dom';

class HelpAdvert1 extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    return (
      <Card className="advert help-advert">
        <Row>
          <Col xs={9}>
            <h2>
              <Content id="HelpAdvert1:Advert.title" copytext="Help" />
            </h2>
            <p>
              <Content
                id="HelpAdvert1:Advert.paragraph1"
                copytext="If you need help with registration, please consult our FAQs or
              contact us directly using the contact form or telephone"
              />{' '}
              <a href="/">
                <Content
                  id="HelpAdvert1:Advert.link"
                  copytext="012234 568 678"
                />
              </a>
              .
            </p>
          </Col>
          <Col xs={3}>
            <Icon name="HelpAdIcon" />
          </Col>
        </Row>
      </Card>
    );
  }
}

HelpAdvert1.propTypes = {
  items: PropTypes.string,
};

export default HelpAdvert1;
