import React from 'react';
import { TextArea } from '@myie/interact-dom';
import { FormGroup, Row, Col } from 'reactstrap';

/** Text Area Example */
class TextAreaExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  onChange = e => {
    const { value } = e.target;
    this.setState({ value });
  };

  render() {
    return (
      <Row>
        <Col md={6}>
          <FormGroup>
            <TextArea
              label="Enter Comments"
              id="comments"
              field="comments"
              onChange={this.onChange}
              onBlur={this.onChange}
              value={this.state.value}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default TextAreaExample;
