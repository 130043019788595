import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { stateUtility } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Utility } from '@myie/interact';
import { storage } from './storageName';
import ShortApplyApplication from '../shared/ShortApplyApplication';
import ShortApplyReview from '../shared/ShortApplyReview';
import ApplicationSuccess from './ApplicationSuccess';
import { mapDispatchToProps as accountsDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as userManagementDispatches } from '@myie/interact-user-management';
import { mapDispatchToProps } from '@myie/interact-ccb-savings-servicing';
import loadStateHOC from '../../shared/stateManager/loadStateHOC';

class ShortApplyProduct extends Component {
  constructor(props) {
    super(props);
    const { urlParams, stateData, resetSaveShortApply } = this.props;
    const stateList = ['apply', 'review', 'applySuccess'];

    const hasStage = stateList.find(element => element === urlParams.pageStage);
    let stage =
      stateData &&
      stateData.url &&
      stateData.url.pageStage &&
      this.props.urlParams &&
      this.props.urlParams.pageStage
        ? this.props.urlParams.pageStage
        : 'apply';
    if (!hasStage) {
      stage = 'apply';
      resetSaveShortApply();
    }

    stateUtility.setGlobalValue({ storage: storage.name });
    this.timeoutID = null;
    this.state = {
      message: '',
      selectedProduct: null,
      stage: stage,
      disabled: false,
    };
  }

  componentWillUnmount() {
    const { resetSaveShortApply } = this.props;
    resetSaveShortApply();
  }
  static getDerivedStateFromProps = (nextProps, thisState) => {
    const {
      allProducts,
      match,
      saveShortApplyResponse,
      stateData,
      updateUrl,
    } = nextProps;
    const { selectedProduct, stage } = thisState;

    let product = null;

    if (allProducts && !selectedProduct) {
      allProducts.forEach(element => {
        if (
          match.params &&
          match.params.id === Utility.hexEncode(element.Product_Code)
        ) {
          product = element;
        }
      });

      return {
        selectedProduct: product,
      };
    }

    if (
      saveShortApplyResponse &&
      saveShortApplyResponse.Status === 'Success' &&
      stage !== 'applySuccess'
    ) {
      let data = {
        formData: {
          ...stateData.formData,
        },
        url: {
          pageStage: 'applySuccess',
        },
      };

      updateUrl(data);
      return {
        stage: 'applySuccess',
      };
    }

    return null;
  };
  // change the stage of the form
  setStage = stage => {
    this.setState({ ...this.state, stage, message: null });
  };

  setStateDynamic = list => {
    this.setState({ ...this.state, ...list });
  };

  selectProduct = product => {
    const { setStateDynamic, form } = this.props;
    this.setState({ ...this.state, selectedProduct: product });
    setStateDynamic({
      reviewPaymentHeader:
        form && form.destination && form.destination.value === 'new'
          ? 'review'
          : 'optionThree',
      selectedProduct: product,
    });
  };

  render() {
    const { stage } = this.state;
    const { userDetails, accounts = {}, organisation } = this.props;

    if (!this.state.selectedProduct) {
      return <Redirect to="/short-apply/index" />;
    }

    if (stage === 'applySuccess') {
      return (
        <ApplicationSuccess
          saveShortApplyData={this.props.saveShortApplyResponse}
          selectedProduct={this.state.selectedProduct}
        />
      );
    }

    switch (stage) {
      case 'apply':
        return (
          <ShortApplyApplication
            accounts={accounts}
            selectedProduct={this.state.selectedProduct}
            formData={
              this.props.stateData && this.props.stateData.formData
                ? this.props.stateData.formData
                : {}
            }
            updateUrl={this.props.updateUrl}
            setStateDynamic={this.setStateDynamic}
            userDetails={userDetails}
            organisationDetails={organisation?.details}
            selectedOrganisationDetails={
              this.props.stateData &&
              this.props.stateData.selectedOrganisationDetails
                ? this.props.stateData.selectedOrganisationDetails
                : null
            }
          />
        );
      case 'review':
        return (
          <ShortApplyReview
            accounts={accounts}
            selectedProduct={this.state.selectedProduct}
            setStateDynamic={this.setStateDynamic}
            updateUrl={this.props.updateUrl}
            userDetails={userDetails}
            formData={
              this.props.stateData && this.props.stateData.formData
                ? this.props.stateData.formData
                : {}
            }
            selectedOrganisationDetails={
              this.props.stateData &&
              this.props.stateData.selectedOrganisationDetails
                ? this.props.stateData.selectedOrganisationDetails
                : null
            }
          />
        );

      default:
    }

    return null;
  }
}

ShortApplyProduct.propTypes = {
  submit: PropTypes.func,
  account: PropTypes.any,
  getAllProducts: PropTypes.any,
  form: PropTypes.any,
  reviewPaymentHeader: PropTypes.string,
  showMaturity: PropTypes.any,
  cancelWithdrawal: PropTypes.func,
  changeHeader: PropTypes.func,
  saveShortApplyResponse: PropTypes.object,
  setStateDynamic: PropTypes.func,
  getAccountDetails: PropTypes.func,
  userDetails: PropTypes.any,
  accounts: PropTypes.object,
  fetchAccountsIfNeeded: PropTypes.func,
  resetSaveShortApply: PropTypes.func,
  fetchUserDetailsIfNeeded: PropTypes.func,
  savingshortapply: PropTypes.object,
  stateData: PropTypes.any,
  getDepositProduct: PropTypes.func,
  match: PropTypes.any,
  updateUrl: PropTypes.any,
  depositProduct: PropTypes.object,
  urlParams: PropTypes.any,
  organisation: PropTypes.any,
};

const mapStateToProps = state => {
  const {
    customccbSavingsServicingDefinition,
    userManagementDefinition,
    accountsDefinition,
    customccbOrganisationDefinition,
  } = state;
  return {
    ...customccbSavingsServicingDefinition,
    ...userManagementDefinition,
    ...accountsDefinition,
    ...customccbOrganisationDefinition,
  };
};
export default loadStateHOC(
  connect(
    mapStateToProps,
    {
      ...userManagementDispatches,
      ...mapDispatchToProps,
      ...accountsDispatchToProps,
    },
  )(withRouter(ShortApplyProduct)),
  storage.name,
);
