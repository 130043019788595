import React from 'react';
import PropTypes from 'prop-types';

class Typography extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    return (
      <section id="typography-section">
        <h1>Heading level 1 - used for page titles</h1>
        <h2>Heading level 2</h2>
        <h3>Heading level 3</h3>
        <h4>Heading level 4</h4>
        <h5>Heading level 5</h5>
        <h6>Heading level 6</h6>

        <p>
          This is a normal paragraph. On static pages, it’s designed for
          readability and optimising the number of characters per line.{' '}
          <a href="/">This is an inline link</a> and{' '}
          <a href="/" className="hover-state">
            this is the link state on hover
          </a>
          .
        </p>
        <p className="lead">
          This is a lead paragraph for text that requires more emphasis. It
          might be used as a longer-form tagline or subheading for a page or
          content section. It may have a slightly larger font size and
          line-height.
        </p>
        <p>
          Only use <strong>bold in small amounts</strong> at a time, too much
          together and it might not have the desired effect.
        </p>
        <p>
          <small>Smaller text is used in various places around the site.</small>
        </p>
      </section>
    );
  }
}

Typography.propTypes = {
  items: PropTypes.string,
};

export default Typography;
